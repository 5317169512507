
<ng-container *ngIf="fCName == null; else formInput">
  <div class="textarea-container">
    <label *ngIf="label" [ngClass]="{'mb-1': !labelMargin}" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
    <div class="textarea-border">
      <textarea type="text" [(ngModel)]="inputValue" [ngClass]="{'expand': shouldGrow, 'error': showError}" #textareaInput class="w-100 custom-scroll" [placeholder]="placeholder" [rows]="rowSize"
       [debounceTime]="debounceTime" [maxLength]="charLength" (onDebounce)="onValueChange($event)" (blur)="onFocusOut()">
      </textarea>
    </div>
    <div class="character-counter" *ngIf="showCharacterCount && charLength !== undefined">
      <p>{{ maxCharactersLabel }} <span>{{inputValue.length}} / {{textareaInput.getAttribute('maxLength')}}</span></p>
    </div>
  </div>
</ng-container>

<ng-template #formInput>
  <form [formGroup]="form">
    <div class="textarea-container">
      <label *ngIf="label" [ngClass]="{
        'error': form.controls[fCName].touched && !form.controls[fCName].valid,
        'mb-1': !labelMargin
      }"
      [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
      <div class="textarea-border">
        <textarea type="text" formControlName="{{fCName}}" [ngClass]="{'expand': shouldGrow, 'error': form.controls[fCName].touched && !form.controls[fCName].valid }" #textareaInput
          class="w-100 custom-scroll" [placeholder]="placeholder" [rows]="rowSize" [maxLength]="charLength"></textarea>
      </div>
      <div class="character-counter" *ngIf="showCharacterCount && charLength !== undefined">
        <p>{{ maxCharactersLabel }} <span>{{ form.controls[fCName].value ? form.controls[fCName].value.length : 0 }} / {{textareaInput.getAttribute('maxLength')}}</span></p>
      </div>
    </div>
  </form>
</ng-template>

