import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { TabItem } from 'src/app/_generic-components-lib/tabs/generic-tabs-model';
import { ApiService } from 'src/app/_services/api.service';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { HtmlContents } from '../terms.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  tabsList: Array<TabItem> = [];

  constructor(
    private generalService: GeneralService,
    private apiService: ApiService
  ) { }

  initTabs(contents: Array<HtmlContents>): void {
    this.tabsList = [];
    contents.forEach(content => {
      this.tabsList.push({
        id: content.id,
        name: content.name
      });
    })
  }

  getTerms(): Observable<Array<HtmlContents>> {
    return this.apiService.get('terms-and-conditions', {}, '1.0', true, environment.useMockedData.termsConditionsGet).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<HtmlContents>) => {
        if(this.generalService.isValidServerReply(response)){
          return response;
        } else {
          throw response;
        }
    }));
  }
}
