<ng-container *ngIf="fCName == null; else formInput">
  <div class="input-group search-container" (click)="searchFocused = disabled ? false : true; forceFocus();" (mouseover)="hover = true" (mouseleave)="hover = false">
    <input type="text" class="form-control" [(ngModel)]="searchValue" [placeholder]="searchPlaceholder" [ngClass]="{'hover branding-border-color' : hover || searchFocused}"
      (keydown)="checkEnter($event)" [disabled]="disabled" value="{{ selectedOption?.name }}">
    <div class="search-suffix-container" (click)="search()">
      <div class="input-group-text search-suffix" role="button" [ngClass]="{'hover branding-border-color' : hover || searchFocused}">
        <i class="bi bi-search" *ngIf="hasSearchIcon"></i>
      </div>
    </div>
  </div>

  <ng-container *ngIf="searchFocused && !disabled">
    <div (click)="clickOutside($event);" id="dropdownContainer" class="outside-dropdown"></div>

    <div class="dropdown custom-scroll" id="dropdownScrollContainer" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'" *ngIf="searchFocused">

      <ng-container *ngIf="dropdownOptions.length !== 0; else noResults">
        <ng-container *ngFor="let option of dropdownOptions">
          <div class="option-item option-list-item" [ngClass]="{'selected': option.selected}"
            (click)="toggleOptionSelected(option.id!)">
            <span class="name">{{option.name}}</span>
            <i *ngIf="option.selected && !activeText && checkIcon" class="check-icon bi bi-check-lg"></i>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noResults>
        <div class="option-item">
          <span class="title">{{ noResultsLabel }}</span>
        </div>
      </ng-template>

    </div>
  </ng-container>
</ng-container>

<ng-template #formInput>
  <form [formGroup]="form">
    <div class="input-group search-container" (click)="searchFocused = disabled ? false : true; forceFocus();" (mouseover)="hover = true" (mouseleave)="hover = false">
      <input type="text" class="form-control" formControlName="{{fCName}}" [placeholder]="searchPlaceholder" [ngClass]="{'hover branding-border-color' : hover || searchFocused}"
        (keydown)="checkEnter($event)">
      <div class="search-suffix-container" (click)="search()">
        <div class="input-group-text search-suffix" role="button" [ngClass]="{'hover branding-border-color' : hover || searchFocused}">
          <i class="bi bi-search" *ngIf="hasSearchIcon"></i>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="searchFocused && !disabled">
      <div (click)="clickOutside($event);" id="dropdownContainer" class="outside-dropdown"></div>

      <div class="dropdown custom-scroll" id="dropdownScrollContainer" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'" *ngIf="searchFocused">

        <ng-container *ngIf="dropdownOptions.length !== 0; else noResults">
          <ng-container *ngFor="let option of dropdownOptions">
            <div class="option-item option-list-item" [ngClass]="{'selected': option.selected}"
              (click)="toggleOptionSelected(option.id!)">
              <span class="name">{{option.name}}</span>
              <i *ngIf="option.selected && !activeText && checkIcon" class="check-icon bi bi-check-lg"></i>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noResults>
          <div class="option-item">
            <span class="title">{{ noResultsLabel }}</span>
          </div>
        </ng-template>

      </div>
    </ng-container>
</ng-template>
