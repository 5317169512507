export enum apisToMock {

  // General
  getLabels,
  getInfo,

  // User
  me,

  // Landing Page
  getLPBanners,

  // Menus
  getMenus,

  // Languages
  getLanguages,

  // Marketplace
  marketplaceGetTiers,
  marketplaceGetFilters,
  marketplaceGetProducts,
  marketplaceGetProduct,

  // Partner
  getPartnersList,
  getCategories,
  getPartnerDetails,

  // Order Entry
  createOrderEntry,
  getOrderEntry,
  getOrderEntryList,

  // Profile
  getUserTransactionListHeaders,
  getUserTransactionList,

  // Terms & Conditions
  getTerms,

  //Contact-us
  getFAQs,

  // Tutorial
  getTutorialInfo,

  // Blog
  getBlogList,
  getBlogDetails,
  getBlogFilters,

  // Our Products
  getOurProductsList,

  // About us
  getAboutUsInfo,

  // Onboarding
  getOnboardingList,
  getOnboardingStep,

  // User Goals
  getUserGoalOptionDTOsList,

  // History
  getHistoryProductList,
  getHistoryTransactionList,

  // Activities
  getActivityList,
  getActivityInfo,
  addEditActivityUserInput, // INPUT TYPE
  removeActivityUserInput // INPUT TYPE
}
