import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingListItemComponent } from './loading-list-item/loading-list-item.component';
import { LoadingSmallListItemComponent } from './loading-small-list-item/loading-small-list-item.component';


@NgModule({
  declarations: [
    LoadingListItemComponent,
    LoadingSmallListItemComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LoadingListItemComponent,
    LoadingSmallListItemComponent
  ]
})
export class LoadingListModule { }
