import { Injectable } from '@angular/core';
import { Language } from '../_general-service/general-service.model';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  public languageList: Array<Language> = [];
  public selectedLanguage?: Language;

  constructor() { }
}
