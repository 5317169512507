import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { OurProductsContainerComponent } from './our-products-container/our-products-container.component';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { OurProductBannerComponent } from './our-product-banner/our-product-banner.component';
import { KnownForBannerComponent } from './known-for-banner/known-for-banner.component';
import { SafeHtmlPipeModule } from 'src/app/_shared-modules/safe-html-pipe-module/safe-html-pipe.module';

@NgModule({
  declarations: [
    OurProductsContainerComponent,
    OurProductBannerComponent,
    KnownForBannerComponent,
    IntroBannerComponent
  ],
  imports: [
    CommonModule,
    PageBannerModule,
    LabelPipeModule,
    SafeHtmlPipeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  exports: [
    OurProductsContainerComponent
  ]
})
export class OurProductsModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { IntroBannerComponent } from './intro-banner/intro-banner.component';


export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
