import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { AboutUsService } from '../_services/about-us.service';

import { take } from 'rxjs';
import { AboutUsInfo, Animation } from '../about-us.model';

import { Rive } from "@rive-app/canvas";

@Component({
  selector: 'app-about-us-content',
  templateUrl: './about-us-content.component.html',
  styleUrls: ['./about-us-content.component.sass']
})
export class AboutUsContentComponent implements OnInit, AfterViewInit {
  @ViewChild('lottieTimelineAnimation', { static: false }) private lottieTimelineAnimation: LottieComponent;
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;


  public environment = environment;
  public mainBannerInfo: MenuBanner;

  public lottieOptions: AnimationOptions;
  public lottieOptionsMobile: AnimationOptions;

  public lottieAnimationItem: AnimationItem | null = null;
  public animation: Animation;

  public animationMobile: Animation;
  public aboutUsDescription: string;
  public showOurTeam: boolean;
  public ourTeamImageUrl: string;

  private r: Rive;

  constructor(
    public labelService: LabelsService,
    public generalService: GeneralService,
    private aboutUsService: AboutUsService
  ) {

    window.addEventListener('resize', () => {
      this.screenResized();
    });

    this.screenResized();
  }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/about-us');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.aboutUsService.getAboutUsInfo().pipe(take(1)).subscribe((resp: AboutUsInfo) => {
      this.aboutUsDescription = resp.aboutUsDescription;
      this.ourTeamImageUrl = resp.ourTeamImageUrl;
      this.showOurTeam = resp.showOurTeam;

      this.animation = resp.animation;
      this.animationMobile = resp.animationMobile;

      if (resp.animation.show) {
        switch(resp.animation.type) {
          case 'lottie':
            this.lottieOptions = {
              autoplay: false,
              loop: false,
              path: `assets/imgs/environments/${environment.tenantName}/timeline-lottie.json`
            };
            break;

          case 'rive':
            this.initiateRiveAnimation(`assets/mock/environments/${environment.tenantName}/test.riv`);
            break;
        }
      }

      if (resp.animationMobile.show) {
        switch(resp.animationMobile.type) {
          case 'lottie':
            this.lottieOptionsMobile = {
              autoplay: false,
              loop: false,
              path: `assets/imgs/environments/${environment.tenantName}/timeline-lottie-mobile.json`
            };
            break;

          case 'rive':
            this.initiateRiveAnimation(`assets/imgs/environments/${environment.tenantName}/timeline-rive-mobile.riv`);
            break;
        }
      }
    });

  }

  ngAfterViewInit(): void {
    this.generalService.scrollEventListener();
  }

  private screenResized(): void {
    if (this.lottieTimelineAnimation) {
      this.animationCreated('lottie');
    }

    if (this.canvasRive) {
      this.r.resizeDrawingSurfaceToCanvas();
      this.animationCreated('rive');
    }
  }

  public animationCreated(type: string): void {
    // play animation only if it is currently in view and shown more than 40%
    this.generalService.addScrollableEvent({id: 'aboutUs', fn: () => {

      let animationPercentage: number;

      switch(type) {
        case 'lottie':
          animationPercentage = this.generalService.isElementInView(this.lottieTimelineAnimation.container).percentageShown;

          if (animationPercentage > 40) {
            this.lottieAnimationItem?.play();
          } else {
            this.lottieAnimationItem?.pause();
          }
          break;

        case 'rive':
          animationPercentage = this.generalService.isElementInView(this.canvasRive).percentageShown;

          if (animationPercentage > 40) {
            this.r?.play();
          } else {
            this.r?.pause();
          }
          break;
      }
    }});
  }

  private initiateRiveAnimation(riveAnimationUrl: string): void {
    this.generalService.asyncFunction(() => {
      this.r = new Rive({
        src: riveAnimationUrl,
        canvas: this.canvasRive.nativeElement,
        autoplay: true,
        onLoad: () => {
          this.r.resizeDrawingSurfaceToCanvas();
          this.animationCreated('rive');
        },
      });
    }, 0);
  }

}
