<div class="toggle-container" *ngIf="imageAsButton; else buttonWithText" [ngClass]="{'hovered': isHovered, 'active': isActive}" (click)="toggleAction()">
  <div class="toggle-button" (mouseover)="isHovered = true" (mouseout)="isHovered = false">
    <i [class]="iconClass"></i>
  </div>
</div>
<ng-template #buttonWithText>
  <div class="toggle-button-icon" [ngClass]="{'active': isActive}" (click)="toggleAction()">
    <span>{{ buttonName }}</span>
    <i [class]="iconClass" *ngIf="iconClass"></i>
  </div>
</ng-template>
