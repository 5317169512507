import { Component, ElementRef, Input } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-accordion-base-component',
  template: '',
  styleUrls: ['./accordion-base.component.sass']
})
export class AccordionBaseComponent extends BaseComponent {

  @Input() numberFontSize: string;
  @Input() titleFontSize: string;
  @Input() activeColor: string;

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  public setSpecificCSSVars(): void {
    if (this.numberFontSize || this.titleFontSize || this.activeColor) {

      if (this.titleFontSize)
        this.host.nativeElement.style.setProperty('--title-font-size', this.titleFontSize);
      if (this.numberFontSize)
        this.host.nativeElement.style.setProperty('--number-font-size', this.numberFontSize);
      if (this.activeColor)
        this.host.nativeElement.style.setProperty('--active-color', this.libraryService.convertToHexColor(this.host, this.activeColor));
    }
  }
}
