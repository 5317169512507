<div class="list-item-content-container w-100 ps-3 pe-3 mt-auto" [ngClass]="{ 'highlighted': activity.type === ActivityTypes.INPUT }">
  <div class="prize-container left-top-column" *ngIf="activity.rewards">
    <!-- {{ labelsService.activityListItemPrizeLabel | label: { amount: activity.totalValue, unit: getCurrencySymbol(activity.totalValue.unit, 'narrow') | label } }} -->
    {{ labelsService.activityListItemPrizeLabel | label: { amount: activity.totalReward, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
  </div>

  <div class="quantity-container right-top-column" *ngIf="activity.totalSubActivities">
    <span class="quantity-normal-msg">
      {{
        activity.totalSubActivities === 1 ?
          (labelsService.activityListItemSubtaskSingular | label : { quantity : activity.totalSubActivities }) : labelsService.activityListItemSubtaskPlural | label : { quantity : activity.totalSubActivities }
      }}
    </span>
  </div>

  <div class="partner-container left-bottom-column" *ngIf="activity.partner">
    <img [src]="activity.partner.images.profilePictureImage.href" alt="" *ngIf="activity.partner.images && activity.partner.images.profilePictureImage">
    <span class="partner-name" *ngIf="activity.partner.name">{{ activity.partner.name }}</span>
  </div>

  <div class="progress-bar-wrapper" *ngIf="activity.totalSubActivities && showProgress">
    <div class="progress-bar-container">
      <div class="progress-bar" role="progressbar" [ngClass]="{'danger': activity.totalCompletedSubActivityPercentage! >= 80}" [ngStyle]="{'width': activity.totalCompletedSubActivityPercentage + '%' }" [attr.aria-valuenow]="activity.totalCompletedSubActivityPercentage" aria-valuemin="0" [attr.aria-valuemax]="100"></div>
    </div>

    <span> {{ activity.totalCompletedSubActivityPercentage }}%</span>
  </div>

  <div class="date-container right-bottom-column" *ngIf="activity.endDate && !(activity.totalSubActivities && showProgress)">
    <ng-container>
      <span *ngIf="activity.endDate">{{ labelsService.activityListItemDateLabel | label: { date: activity.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}</span>
    </ng-container>
  </div>
</div>
