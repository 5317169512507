import { Component, OnInit, Input } from '@angular/core';
import { PartnerListBanner } from 'src/app/_services/_general-service/general-service.model';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.sass']
})
export class PartnerListComponent implements OnInit {

  @Input() content: PartnerListBanner

  constructor() { }

  ngOnInit(): void {
  }

}
