<div class="activity-info mt-4 mb-5">
  <div class="activity-info-title-container">
    <span>{{ activity.name }}</span>
  </div>

  <div class="activity-info-table">
    <div class="activity-detail-container">
      <p class="info-table-header">{{labelService.activityDetailsInfoTitle | label}}</p>

      <div class="quantity-container" *ngIf="activity.subActivities && activity.subActivities.length > 0">
        <div class="value">
          {{ activity.subActivities.length === 1 ?
            (labelService.activityListItemSubtaskSingular | label : { quantity : activity.subActivities.length }) : labelService.activityListItemSubtaskPlural | label : { quantity : activity.subActivities.length }
          }}
        </div>
      </div>

      <div class="date-container" *ngIf="activity.endDate">
        {{ labelService.activityListItemDateLabel | label: { date: activity.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
      </div>
    </div>

    <div class="activity-prize-container">
      <div class="activity-prize">
        {{ labelService.activityListItemPrizeLabel | label: { amount: activity.totalReward, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
      </div>
    </div>
  </div>

  <div class="activity-info-table mt-3" *ngIf="activity.partner || (activity.addresses && activity.addresses.length > 0)">
    <div class="addresses-container" *ngIf="activity.addresses && activity.addresses.length > 0">
      <swiper #swiperRef class="item-address-info-swiper"
        [slidesPerView]="1"
        [centeredSlides]="true"
        [pagination]="activity.addresses && activity.addresses.length > 1? {dynamicBullets: true, dynamicMainBullets: 3} : false"
        [navigation]="false"
        [spaceBetween]="20"
        [speed]="500"
        >

        <ng-template swiperSlide class="item-info-item-container"
          *ngFor="let address of activity.addresses; let index = index">
          <img [src]="'assets/imgs/environments/' + environment.tenantName + '/icons/maps-pin-icon.png'">

          <div class="address-info ms-2">
            <p class="address-line">{{ address.addressLine1 + ', ' + (address.addressLine2 ? address.addressLine2 + ', ' : '') + address.city }}</p>
            <p class="user-distance-from-address" *ngIf="address.distance">{{ labelService.activityDetailsUserDistance | label: { distanceKm : address.distance / 1000 | number : '1.2-2' } }}</p>

            <a target="_blank" class="maps-redirect"
              href="https://www.google.pt/maps/search/?api=1&query={{address.gpsCoords!.x}}%2C{{address.gpsCoords!.y}}">
              {{ labelService.activityDetailsSeeAddress | label }} <i class="bi bi-arrow-right-short"></i>
            </a>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="partner-container" *ngIf="activity.partner">
      <p class="info-table-header">{{labelService.activityDetailsPartnerLabel | label}}</p>

      <div class="partner-info-container">
        <img [src]="activity.partner.images ? activity.partner.images.profilePictureImage.href : 'assets/imgs/environments/'+ environment.tenantName +'/default-images/default-image-square.svg'" [alt]="activity.partner.name" class="partner-img">
        <div class="partner-name">{{ activity.partner.name }}</div>
      </div>
    </div>
  </div>

  <div class="activity-info-text w-100 mt-3" *ngIf="activity.description">
    <span class="title">{{ labelService.activityDetailsDescriptionLabel | label }}</span>
    <div class="description">{{ activity.description }}</div>
  </div>
</div>

<ng-container [ngSwitch]="activity.type">
  <ng-container *ngSwitchCase="ActivityTypes.INPUT">
    <app-activity-type-input [activity]="activity" (triggerGetActivityInfo)="getActivityInfo($event)"></app-activity-type-input>
  </ng-container>
  <ng-container *ngSwitchCase="ActivityTypes.MULTI_STEP">
    <app-activity-type-multi-step [activity]="activity"></app-activity-type-multi-step>
  </ng-container>
  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
