import { Injectable } from '@angular/core';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { FilterGroup, FilterItem, SelectedFilterGroup } from './filter.model';

@Injectable({
  providedIn: 'root'
})
export class FiltersListService {

  public selectedFilters: Array<SelectedFilterGroup>;

  constructor() {
  }


  public filterSelected(group: FilterGroup, filter: FilterItem, selected: boolean): void {
    filter.selected = selected;
    const groupIndex = this.selectedFilters.findIndex(g => g.queryParameter === group.queryParameter);

    if (selected) {
      this.selectedFilters[groupIndex].items.push({
        name: filter.description,
        value: filter.id
      });
    } else {
      const filterIndex = this.selectedFilters[groupIndex].items.findIndex(item => item.value === filter.id);

      if (filterIndex !== -1) {
        this.selectedFilters[groupIndex].items.splice(filterIndex, 1);
      }
    }
  }

  public radioBtnfilterChanged(group: FilterGroup, filterSelected: number): void {
    this.selectedFilters.forEach(g => {
      if (g.queryParameter === group.queryParameter) {
        g.items = [{
          name: (group.values.find(value => (value as RadioButton).value === filterSelected) as RadioButton).label,
          value: filterSelected
        }];
      }
    });
  }
}
