import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {

  constructor() {}

  /**
   * The `convertToHexColor` function converts a color value to a hexadecimal format.
   * @param {ElementRef} host - The `host` parameter is of type `ElementRef`, which represents a
   * reference to the host element in the DOM. It is used to access the computed style of the host
   * element.
   * @param {string} inputString - The `inputString` parameter is a string that represents a color value.
   * It can be in various formats such as hex, rgb, rgba, or a CSS variable.
   * @param {string} [alphaValue=1] - The alphaValue parameter is a string that represents the alpha
   * value of the color. It is optional and has a default value of '1', which means the color is fully
   * opaque.
   * @returns a string representing the converted hex color value.
  */
  public convertToHexColor(host: ElementRef, inputString: string, alphaValue: string = '1'): string {

    inputString = this.convertColorKeywordToHex(inputString);

    //Extract value from variable if needed
    if (inputString.startsWith('var(') && inputString.endsWith(')')) {
      const style = window.getComputedStyle(host.nativeElement);
      inputString = style.getPropertyValue(
        inputString.substring(4, inputString.length - 1)
      );
    }

    if (inputString.startsWith('rgba(') && inputString.endsWith(')')) {
      return this.convertRGBA(inputString);
    } else if (inputString.startsWith('rgb(') && inputString.endsWith(')')) {
      return this.convertRGB(inputString, alphaValue);
    }

    // Check if the input string is in hex format
    const hexMatch = /^#([0-9A-Fa-f]{3}){1,2}([0-9A-Fa-f]{2})?$/.exec(inputString);
    if (hexMatch) {
      const hex = hexMatch[0];
      if (hex.length === 7) {
        return `${hex}${this.parseAlpha(alphaValue)}`; // Hex color already includes alpha, return as is
      } else if(hex.length > 7) {
        return hex;
      } else {
        throw new Error(`Invalid hex format ${hexMatch}`);
      }
    }

    throw new Error(`Unsupported color format ${inputString}`);
  }

  /**
   * The function `convertRGBA` converts an RGBA color string to a hexadecimal color string.
   * @param {string} inputString - The inputString parameter is a string that represents an RGBA color
   * value.
   * @returns a string value.
  */
  private convertRGBA(inputString: string): string {
    const rgbaMatch = inputString.match(
      /rgba\((\d+), (\d+), (\d+), ([\d.]+%?)\)/
    );
    if (rgbaMatch) {
      const [r, g, b, alpha] = rgbaMatch.slice(1);
      return `#${Math.round(+r).toString(16).padStart(2, '0')}${Math.round(+g)
        .toString(16)
        .padStart(2, '0')}${Math.round(+b)
        .toString(16)
        .padStart(2, '0')}${this.parseAlpha(alpha)}`;
    } else {
      throw new Error(`Invalid RGBA format ${inputString}`);
    }
  }

  /**
   * The `convertRGB` function takes an input string representing an RGB color value and an alpha value,
   * converts it to a hexadecimal color value, and returns it.
   * @param {string} inputString - The `inputString` parameter is a string representing an RGB color
   * value in the format `rgb(r, g, b)`, where `r`, `g`, and `b` are integers between 0 and 255
   * representing the red, green, and blue color channels respectively.
   * @param {string} alphaValue - The `alphaValue` parameter is a string representing the alpha value of
   * the color. It is used to determine the transparency of the color.
   * @returns a string in the format "#RRGGBBAA", where RR, GG, BB are the hexadecimal values of the red,
   * green, and blue components of the input RGB string, and AA is the hexadecimal value of the alpha
   * component specified by the alphaValue parameter.
  */
  private convertRGB(inputString: string, alphaValue: string): string {
    const rgbValues = inputString
      .substring(4, inputString.length - 1)
      .split(',')
      .map(parseFloat);

    if (rgbValues.length !== 3) {
      throw new Error(`Invalid RGB format ${inputString}`);
    }

    const [r, g, b] = rgbValues;
    return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g)
      .toString(16)
      .padStart(2, '0')}${Math.round(b)
      .toString(16)
      .padStart(2, '0')}${this.parseAlpha(alphaValue)}`;
  }

  /**
   * The function `parseAlpha` takes a string representing an alpha value and returns a hexadecimal
   * representation of the alpha value.
   * @param {string} alphaString - The `alphaString` parameter is a string representing the alpha value
   * of a color. It can be either a decimal value between 0 and 1, or a percentage value between 0% and
   * 100%.
   * @returns The function `parseAlpha` returns a string representing the alpha value.
  */
  private parseAlpha(alphaString: string): string {
    if (alphaString.endsWith('%')) {
      const alphaPercentage = parseFloat(alphaString) / 100;
      const alphaValue = Math.round(alphaPercentage * 255);
      return alphaValue.toString(16).padStart(2, '0');
    } else {
      const alpha = parseFloat(alphaString);
      if (alpha >= 0 && alpha <= 1) {
        if (alpha === 0) {
          return '00'; // Handle alpha value 0 as 00 (fully transparent)
        } else if (alpha === 1) {
          return 'FF'; // Handle alpha value 1 as FF (opaque)
        }
        return Math.round(alpha * 255)
          .toString(16)
          .padStart(2, '0');
      }
      throw new Error(`Invalid alpha value ${alphaString}`);
    }
  }

  /**
   * The function converts a color keyword to its corresponding hexadecimal value.
   * @param {string} keyword - The `keyword` parameter is a string that represents a color keyword, such
   * as "white", "black", "red", etc.
   * @returns the hexadecimal color code corresponding to the given color keyword. If the keyword is not
   * found in the colorKeywords object, it returns the original keyword.
  */
  private convertColorKeywordToHex(keyword: string): string {
    const colorKeywords: { [key: string]: string } = {
      white: '#FFFFFF',
      black: '#000000',
      red: '#FF0000',
      green: '#008000',
      blue: '#0000FF',
      yellow: '#FFFF00',
      magenta: '#FF00FF',
      cyan: '#00FFFF',
      transparent: '#00000000'
    };

    const hexColor = colorKeywords[keyword.toLowerCase()];

    if(hexColor) {
      return hexColor;
    }

    return keyword;
  }
}
