import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { ItemImageSliderComponent } from './item-image-slider/item-image-slider.component';




@NgModule({
  declarations: [
    ItemImageSliderComponent
  ],
  imports: [
    CommonModule,
    SwiperModule
  ],
  exports: [
    ItemImageSliderComponent
  ]
})
export class ItemImageSliderModule { }
