import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject } from 'rxjs';
import { Info, ListInfoType, ListItem, Sizes } from 'src/app/pages/_shared-components/generic-list-item-module/generic-list-item.model';
import { ApiService } from 'src/app/_services/api.service';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { ProductsService } from '../_products/products.service';
import { OrderEntry, OrderEntryDTO, OrderEntryReplyDTO, OrderEntryStatusEnum, Payment, PaymentDTO, PaymentType, PaymentTypeEnum, RelatedParty, RelatedPartyDTO, RelatedPartyTypes, RelatedPartyTypesEnum } from './order-entry.model';
import { environment } from 'src/environments/environment';
import { Params } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class OrderEntryService {

  private endOfScrollReached$ = new Subject<boolean>();
  public infiniteScrollActive: boolean = false;

  public currentPageIndex: number = 0;
  private pageSize: number = 16;
  public totalPages: number = 1;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private productsService: ProductsService,
    private labelService: LabelsService,
    private labelPipe: LabelPipe
  ) { }

  public sizesArray: Array<Sizes> = [
    { size: 100, sizeSmall: 50 },
    { size: 100, sizeSmall: 20 },
    { size: 100, sizeSmall: 40 },
    { size: 100, sizeSmall: 20 },
    { size: 100, sizeSmall: 28 },
    { size: 100, sizeSmall: 20 },
    { size: 100, sizeSmall: 20 },
  ];

  createOrderEntry(quantity: number, productId: string, paymentType: PaymentTypeEnum): Observable<string> {
    const body: OrderEntryDTO = {
      productOrderItems: [
        {
          quantity: quantity,
          action: 'ADD',
          product: {
            productSpecification: {
              id: productId,
            }
          }
        }
      ],
      /*relatedParties: [/* {
        id: walletAddress,
        type: RelatedPartyTypesEnum.WALLET
      }, // {
        type: RelatedPartyTypesEnum.BUYER
      }], */
      payment: {
        mediumType: paymentType,
        /* characteristic: {
          phoneNumber: phoneNumber
        } */
      }
    };

    return this.apiService.post('orderentry-management/product-order-instant', body, '1.0', true, environment.useMockedData.marketplaceOrderEntryCreate).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: OrderEntryReplyDTO | GenericErrorReply) => {
        if(!response || this.generalService.isValidServerReply(response)) {

          /* return (response as OrderEntryReplyDTO).id; */
          return 'success';
        } else {
          throw response;
        }
      })
    )
  }

  getOrderEntry(id: string): Observable<OrderEntry> {
    return this.apiService.get(`orderentry-management/product-order/${id}`, {}, '1.0', false, environment.useMockedData.marketplaceOrderEntryGet, id).pipe(
      catchError(
        error => {
          throw error.error.Message;
        }
      ),
      map((response: OrderEntryReplyDTO | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {

          return this.getOrderEntryFromDTO(response as OrderEntryReplyDTO);
        } else {
          throw response;
        }
      }));
  }

  getOrderEntryList(pageIndex: number, params: Params = {}, pageSize?: number):Observable<Array<ListItem>> {
    return this.apiService.get(`orderentry-management/product-order`, {}, '1.0', true, environment.useMockedData.marketplaceOrderEntryGetList).pipe(
      catchError(
        error => {
          throw error.error.Message;
        }
      ),
      // map((response: GetOrderEntryReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
      map((response: Array<OrderEntryReplyDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const orderEntryListDTO = (response as Array<OrderEntryReplyDTO>);

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const productListDTO = response.items;

          const orderEntryList = this.getOrderEntryListFromDTO(orderEntryListDTO);

          return orderEntryList;
        } else {
          throw response;
        }
    }));
  }

  getOrderEntryListFromDTO(dto: Array<OrderEntryReplyDTO>): Array<ListItem> {
    let orderList: Array<ListItem> = [];

    dto.forEach((orderDto: OrderEntryReplyDTO) => {
      orderList.push({
        id: orderDto.id!,
        info: this.getInfoFromOrderDTO(orderDto)
      });
    });

    return orderList;
  }

  getInfoFromOrderDTO(orderDto: OrderEntryReplyDTO): Array<Info> {
    let infoList: Array<Info> = new Array<Info>(7);

    for(const key in orderDto) {
      if(orderDto.hasOwnProperty(key)) {
        switch(key) {
          case 'status':
            const statusText: string = orderDto[key] === OrderEntryStatusEnum.pendingPayment ?
              this.labelPipe.transform(this.labelService.orderEntryStatusPending) : orderDto[key] === OrderEntryStatusEnum.completed ?
              this.labelPipe.transform(this.labelService.orderEntryStatusCompleted) : this.labelPipe.transform(this.labelService.orderEntryStatusFailed);

            infoList[3] = {
              type: ListInfoType.status,
              text: statusText,
              status: orderDto[key],
              sizes: this.sizesArray[3]
            };
            break;

          case 'created':
            infoList[4] = {
              type: ListInfoType.date,
              text: orderDto[key],
              sizes: this.sizesArray[4]
            };
            break;

          case 'productOrderItems':
            infoList[0] = {
              type: ListInfoType.name,
              text: orderDto.productOrderItems[0].product.productSpecification.name,
              sizes: this.sizesArray[0]
            };

            infoList[1] = {
              type: ListInfoType.text,
              text: orderDto.productOrderItems[0].product.productSpecification.serial,
              sizes: this.sizesArray[1]
            };

            infoList[2] = {
              type: ListInfoType.text,
              text: orderDto.productOrderItems[0].product.productSpecification.partnerName,
              sizes: this.sizesArray[2]
            };

            if (orderDto['status'] === OrderEntryStatusEnum.completed) {
              infoList[6] = {
                type: ListInfoType.action,
                action: orderDto.productOrderItems[0].product.productSpecification.id,
                sizes: this.sizesArray[6]
              };
            }
            break;

          case 'totalValue':
            infoList[5] = {
              type: ListInfoType.value,
              value: orderDto.totalValue,
              sizes: this.sizesArray[5]
            };
            break;
        }
      }
    }

/*     created: orderDto.created,
    status: OrderEntryStatusEnum.completed,
    payment: this.getPaymentFromDTO(orderDto.payment),
    productOrderItems: orderDto.productOrderItems,
    relatedParties: this.getRelatedPartiesFromDTO(orderDto.relatedParties) */

    return infoList;
  }

  getOrderEntryFromDTO(dto: OrderEntryReplyDTO): OrderEntry {
    const orderEntry: OrderEntry = {
      id: dto.id,
      created: dto.created,
      status: dto.status,
      payment: this.getPaymentFromDTO(dto.payment),
      productOrderItems: dto.productOrderItems,
      relatedParties: dto.relatedParties ? this.getRelatedPartiesFromDTO(dto.relatedParties) : []
    };

    return orderEntry;
  }

  getPaymentFromDTO(dto: PaymentDTO): Payment{
    const payment: Payment = {
      characteristicObject: dto.characteristic,
      mediumType: PaymentTypeEnum[dto.mediumType as PaymentType],
    };

    return payment;
  }

  getRelatedPartiesFromDTO(partiesDto: Array<RelatedPartyDTO>): Array<RelatedParty> {
    const relatedParties: Array<RelatedParty> = [];

    partiesDto.forEach(dto => {
      relatedParties.push({
        type: RelatedPartyTypesEnum[dto.type as RelatedPartyTypes]
      });
    });

    return relatedParties;
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
