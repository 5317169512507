<ng-container *ngIf="fCName == null; else formInput">
  <div class="input-container w-100">
    <label *ngIf="label" [ngClass]="{'mb-1': !labelMargin}" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
    <div class="position-relative w-100">
      <input type="text" class="w-100" [ngClass]="{'ps-5': alignLeft, 'disabled': isDisabled}" [ngStyle]="{'border-color': showError ? 'var(--error-color)' : 'var(--border-color)'}"
        placeholder="{{ placeholderLabel? placeholderLabel : ''}}" disabled="{{isDisabled}}" [debounceTime]="debounceTime"
        [(ngModel)]="inputValue" (onDebounce)="onValueChange($event)" (keydown)="checkKeyPressed($event)" (blur)="onFocusOut()">

      <ng-container *ngIf="hasSuffix">
        <div class="suffix" [ngClass]="{'alignLeft': alignLeft, 'active': suffixToggle && !isSuffixIcon}" (click)="suffixToggled()">
          <i class="suffix-image" [ngClass]="suffixClassName" *ngIf="isSuffixIcon; else textSuffix"></i>

          <ng-template #textSuffix>
            <span>{{ suffixToggle ? inactiveSuffixLabel : activeSuffixLabel }}</span>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- ######################## FORM ######################## -->
<ng-template #formInput>
  <form [formGroup]="form">
    <fieldset class="input-container position-relative w-100" [disabled]="isDisabled">
      <label *ngIf="label" [ngClass]="{
        'error': form.controls[fCName].touched && !form.controls[fCName].valid,
        'mb-1': !labelMargin
      }"
      [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>

      <div class="position-relative w-100">
        <input type="text" class="w-100"
          [type]="!isPassword ? 'text' : hideValue ? 'password' : 'text'"
          [ngClass]="{
            'error': form.controls[fCName].touched && !form.controls[fCName].valid,
            'password': isPassword, 'ps-5': alignLeft, 'disabled': isDisabled
          }"
          formControlName="{{fCName}}"
          placeholder="{{ placeholderLabel? placeholderLabel : ''}}"
          (keydown)="checkKeyPressed($event)">

        <ng-container *ngIf="hasSuffix">
          <div class="suffix" [ngClass]="{'alignLeft': alignLeft, 'active': suffixToggle && !isSuffixIcon}" [ngStyle]="{'left': alignLeft ? '5px' : ''}" (click)="suffixToggled()">
            <i class="suffix-image" [ngClass]="suffixClassName" *ngIf="isSuffixIcon; else textSuffix"></i>

            <ng-template #textSuffix>
              <span>{{ suffixToggle ? inactiveSuffixLabel : activeSuffixLabel }}</span>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </fieldset>
  </form>
</ng-template>
