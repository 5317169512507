<div class="generic-list-item-wrapper">

  <div class="generic-list-item-container w-100 h-100" [ngClass]="{'static-first-container': staticFirstElement && i === 0 }" *ngFor="let info of item.info; let i = index">
    <div *ngIf="staticFirstElement && i === 0" class="h-100 generic-list-item static-first-element" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
      <ng-container *ngTemplateOutlet="infoContent"></ng-container>
    </div>

    <div class="h-100 generic-list-item" [ngSwitch]="info ? info.type : 'empty'">

      <ng-container *ngTemplateOutlet="infoContent"></ng-container>

      <ng-template #infoContent>
        <div *ngSwitchCase="itemInfoType.id" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          <p>{{info.text}}</p>
        </div>

        <div *ngSwitchCase="itemInfoType.name" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          <p>{{info.text}}</p>
        </div>

        <div *ngSwitchCase="itemInfoType.text" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          <p>{{info.text}}</p>
        </div>

        <div *ngSwitchCase="itemInfoType.entity" class=" flex-wrap  entity" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          <img [src]="info.entity!.imageUrl">
          <p>{{info.entity!.name}}</p>
        </div>

        <div *ngSwitchCase="itemInfoType.status" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }"
          [ngClass]="{'pending': (info.status | lowercase) === itemStatus.pendingPayment, 'completed': (info.status | lowercase) === itemStatus.completed, 'failed': (info.status | lowercase) === itemStatus.failed }">

          <ng-container [ngSwitch]="(info.status | lowercase)">

            <p *ngSwitchCase="itemStatus.pendingPayment">
              {{info.text}}
            </p>

            <p *ngSwitchCase="itemStatus.completed">
              {{info.text}}
            </p>

            <p *ngSwitchCase="itemStatus.failed">
              {{info.text}}
            </p>

          </ng-container>
        </div>

        <div *ngSwitchCase="itemInfoType.date" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          {{ info.text | date: 'dd MMMM yyyy, HH:MM':'pt'}}
        </div>

        <div *ngSwitchCase="itemInfoType.value"
          [ngClass]="{'green': info.value! >= 0, 'red': info.value! < 0}"
          [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          {{ (info.value! >= 0 ? '+' : '' ) + info.value! | number }}p
        </div>

        <div *ngSwitchCase="itemInfoType.action" class="action" [ngStyle]="{'width': (generalService.isMobile | async) ? info.sizes!.sizeSmall + 'vw' : info.sizes!.size + '%' }">
          <img *ngIf="info.action; else actionButton" [src]="'assets/imgs/transaction-action-icon.svg'" (click)="actionClicked(info.action)">
          <ng-template #actionButton>
            <button (click)="actionClicked(item.id)">
              {{info.text}}
            </button>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>
