import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { DebounceModule } from 'src/app/_directives/debounce-module/debounce-module.module';
import { LibraryService } from '../../__services/library.service';
import { BaseComponentModule } from '../../_base-component/base-component.module';
import { NumericInputComponent } from './numeric-input/numeric-input.component';
import { RangeInputComponent } from './range-input/range-input.component';



@NgModule({
  declarations: [NumericInputComponent, RangeInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    HttpClientModule,
    BaseComponentModule,
    DebounceModule
  ],
  exports: [NumericInputComponent, RangeInputComponent],
  providers: [LibraryService]
})
export class NumericInputModule { }
