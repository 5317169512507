import { Component, ElementRef, Input } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { PlaceholderStyle } from '../base-component.model';

@Component({
  selector: 'app-base-component',
  template: '',
  styleUrls: ['./base.component.sass']
})
export class BaseComponent {

  @Input() brandColor: string;
  @Input() darkBrandColor: string;
  @Input() lightBrandColor: string;
  @Input() fontColor: string;
  @Input() errorColor: string;
  @Input() borderColor: string;
  @Input() placeholderStyle: PlaceholderStyle;
  @Input() backgroundColor: string;

  @Input() fontSize: string;
  @Input() fontStyle: string;
  @Input() fontWeight: string;
  @Input() label: string;
  @Input() labelFontSize: string;
  @Input() labelFontWeight: string;
  @Input() inputHeight: string;
  @Input() borderRadius: string;
  @Input() borderWidth: string;

  @Input() toggleOnFtColor: string;

  @Input() searchPlaceholderLabel: string;

  @Input() isMobile: boolean = false;

  constructor(public host: ElementRef, public libraryService: LibraryService) {
    this.setCSSVars();
  }

  public asyncFunction(delayedFn: () => any, timeout: number = 0): any {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), timeout)).then(() => {
        delayedFn();
    })})();
  }

  public setCSSVars(): void {
    const computedStyle = getComputedStyle(this.host.nativeElement);

    if (this.brandColor || this.lightBrandColor || this.fontColor || this.errorColor || this.fontSize || this.fontWeight || this.borderRadius || this.fontStyle
        || this.borderColor || this.borderWidth || this.inputHeight || this.labelFontWeight || this.backgroundColor || this.toggleOnFtColor) {
      //set variables

      if (this.brandColor)
        this.host.nativeElement.style.setProperty('--brand-color', this.libraryService.convertToHexColor(this.host, this.brandColor));
      if (this.darkBrandColor)
        this.host.nativeElement.style.setProperty('--brand-darker', this.libraryService.convertToHexColor(this.host, this.darkBrandColor));
      if (this.lightBrandColor)
        this.host.nativeElement.style.setProperty('--brand-lighter', this.libraryService.convertToHexColor(this.host, this.lightBrandColor));
      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.libraryService.convertToHexColor(this.host, this.fontColor));
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--error-color', this.libraryService.convertToHexColor(this.host, this.errorColor));
      if (this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);
      if (this.fontStyle)
        this.host.nativeElement.style.setProperty('--font-style', this.fontStyle);
      if (this.fontWeight)
        this.host.nativeElement.style.setProperty('--font-weight', this.fontWeight);
      if (this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-radius', this.borderRadius);
      if (this.borderColor)
        this.host.nativeElement.style.setProperty('--border-color', this.libraryService.convertToHexColor(this.host,this.borderColor));
      if (this.borderWidth)
        this.host.nativeElement.style.setProperty('--border-width', this.borderWidth);
      if (this.inputHeight)
        this.host.nativeElement.style.setProperty('--input-height', this.inputHeight);
      if (this.labelFontSize)
        this.host.nativeElement.style.setProperty('--label-font-size', this.labelFontSize);
      if (this.labelFontWeight)
        this.host.nativeElement.style.setProperty('--label-font-weight', this.labelFontWeight);
      if (this.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.libraryService.convertToHexColor(this.host, this.backgroundColor));
      if (this.toggleOnFtColor)
        this.host.nativeElement.style.setProperty('--active-font-color', this.libraryService.convertToHexColor(this.host, this.toggleOnFtColor));
    }
  }
}
