<div class="onboarding-container" [@slideIn]="onBoardingSlideAnimationState">
  <div class="header-container">
    <img [src]="brandInfo.brandImageUrl" [alt]="brandInfo.clientName" class="logo">
  </div>

  <div class="onboarding-step-container custom-scroll" [ngStyle]="{ 'background-image': 'url(assets/imgs/environments/' + environment.tenantName + '/onboarding/background-pattern.png)'}">
    <div class="step-card" [@fadeIn]="onBoardingStepFadeAnimationState" *ngIf="stepInfo">
      <p class="subtitle" *ngIf="stepInfo.subtitle">{{ stepInfo.subtitle }}</p>
      <p class="title" *ngIf="stepInfo.title">{{ stepInfo.title }}</p>

      <ng-container [ngSwitch]="stepInfo.type">
        <!-- ################### SIMPLE STEP ################### -->
        <ng-container *ngSwitchCase="onboardingStepType.CONFIRMATION">
          <img [src]="$any(stepInfo.content).mainImageUrl" class="main-img" *ngIf="$any(stepInfo.content).mainImageUrl">
          <p class="main-img-title mt-2 mt-5" *ngIf="$any(stepInfo.content).mainImageTitle"><!-- {{ (generalService.userDetails | async)?.category }} -->{{ $any(stepInfo.content).mainImageTitle }}</p>

          <p class="simple-description" *ngIf="$any(stepInfo.content).simpleDescription">{{ $any(stepInfo.content).simpleDescription }}</p>
        </ng-container>

        <!-- ################### OPTION STEP ################### -->
        <ng-container *ngSwitchCase="onboardingStepType.MULTIPLE_CHOICE">
          <div class="options-container" *ngIf="$any(stepInfo.content).options">
            <div class="option" *ngFor="let option of $any(stepInfo.content).options"
              (click)="optionSelected(option)"
              [ngClass]="{ selected: option.selected }"
              [ngStyle]="{ 'background-image': 'url(' + (option.imgUrl ? option.imgUrl : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-square.svg') + ')' }">

              <i class="selected-icon bi bi-check-circle-fill" *ngIf="option.selected"></i>
              <div class="option-title" *ngIf="option.title">
                <span>{{ option.title }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- ################### GOAL INFO STEP ################### -->
        <ng-container *ngSwitchCase="onboardingStepType.INFORMATIVE">
          <div class="banner-container" *ngIf="$any(stepInfo.content).banner">
            <div class="banner-info">
              <p class="banner-title" *ngIf="$any(stepInfo.content).banner.title">{{ $any(stepInfo.content).banner.title }}</p>
              <p class="banner-description" *ngIf="$any(stepInfo.content).banner.description">{{ $any(stepInfo.content).banner.description }}</p>
            </div>
            <div class="banner-img-container" *ngIf="$any(stepInfo.content).banner.imgTitle || $any(stepInfo.content).banner.imgUrl"
              [ngStyle]="{
                'background-image': 'url(' + ($any(stepInfo.content).banner.imgUrl !== '' ? $any(stepInfo.content).banner.imgUrl : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg') + ')'
              }">

              <div class="img-title" *ngIf="$any(stepInfo.content).banner.imgTitle">
                <span>{{ $any(stepInfo.content).banner.imgTitle }}</span>
              </div>
            </div>
          </div>

          <div class="activities-section" *ngIf="$any(stepInfo.content).itemsList && $any(stepInfo.content).itemsList.length > 0">
            <p class="activities-section-title" *ngIf="$any(stepInfo.content).activitiesSectionTitle">{{ $any(stepInfo.content).activitiesSectionTitle }}</p>

            <div class="activities-list">
              <div class="activity-info" *ngFor="let activity of $any(stepInfo.content).itemsList">
                <img [src]="activity.imgUrl ? activity.imgUrl : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'">

                <p class="activity-description" *ngIf="activity.description">{{ activity.description }}</p>

                <div class="activity-prize-value">
                  {{ labelsService.onboardingActivityPrizeLabel | label: { points: activity.points } }}
                </div>
              </div>
            </div>
          </div>

          <p class="final-title" *ngIf="$any(stepInfo.content).finalTitle">{{ $any(stepInfo.content).finalTitle }}</p>
        </ng-container>
      </ng-container>

    </div>
  </div>

  <div class="footer-container">
    <div class="progress-steps-container">
      <app-circular-steps
        [activeStepColor]="'var(--main-brand-color)'"
        [nextStepsColor]="'#d9d9d9'"
        [fontSize]="'var(--small-section-description-font-size)'"
        [currentStep]="currentStep"
        [totalSteps]="totalSteps"
        [showLabel]="!(generalService.isMobile | async)">
      </app-circular-steps>
    </div>

    <div class="actions-container">
      <app-generic-button *ngIf="currentStep > 0" [borderRadius]="'50px'"
        [buttonName]="labelsService.onboardingPreviousStepBtnLabel | label"
        [backgroundColor]="'#424242'"
        [brandColor]="'#424242'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
        [buttonSpacing]="'calc(var(' + ((generalService.isMobile | async)? '--smallest-font-size' : '--small-font-size') + ')) calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ') * 3)'"
        [fontWeight]="'700'" [buttonSize]="!(generalService.isMobile | async)? 'min(160px, 17.5vw)' : 'min(125px, 35vw)'" (click)="prevButtonPressed()">
      </app-generic-button>

      <app-generic-button [ngClass]="{ 'ms-3' : currentStep > 0 }" [borderRadius]="'50px'"
        [buttonName]="(currentStep < totalSteps - 1 ? labelsService.onboardingNextStepBtnLabel : labelsService.onboardingFinalizeBtnLabel) | label"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
        [buttonSpacing]="'calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')) calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ') * 3)'"
        [fontWeight]="'700'" [buttonSize]="!(generalService.isMobile | async) ? 'min(160px, 17.5vw)' : 'min(125px, 35vw)'"
        [isDisabled]="(stepInfo && $any(stepInfo.content).options && $any(stepInfo.content).options.length > 0 && !selectedOption) ? true: false"  [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight"
        (click)="nextButtonPressed()">
      </app-generic-button>
    </div>
  </div>
</div>
