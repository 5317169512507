import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass']
})
export class ToolTipComponent implements OnInit {

  @Input() backgroundColor: string;
  @Input() fontColor: string;
  @Input() borderColor: string;

  @Input() fontSize: string;
  @Input() fontWeight: number;
  @Input() borderRadius: string;
  @Input() borderWidth: string;

  @Input() borderStyle: string;

  @Input() alignClass: string;
  @Input() iconClass: string = 'bi-info-circle';
  @Input() textContent: string;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if(this.backgroundColor || this.fontColor || this.borderColor || this.fontSize || this.fontWeight || this.borderRadius || this.borderWidth || this.borderStyle) {

      if(this.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.backgroundColor);

      if(this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.fontColor);

      if(this.borderColor)
        this.host.nativeElement.style.setProperty('--border-color', this.borderColor);

      if(this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);

      if(this.fontWeight)
        this.host.nativeElement.style.setProperty('--font-weight', this.fontWeight);

      if(this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-radius', this.borderRadius);

      if(this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-width', this.borderWidth);

      if(this.borderStyle)
        this.host.nativeElement.style.setProperty('--border-style', this.borderStyle);
    }
  }
}
