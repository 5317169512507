<div class="chart-container">
  <div #chartHeader class="chart-header">
    <span>{{ chartContainer.title }}</span>

    <span class="total" *ngIf="chartContainer.chartTabs.length <= 1; else tabs">
      {{ (chartContainer.totalLabel ? chartContainer.totalLabel + ' ' : '') + total }}
    </span>
    <ng-template #tabs>
      <div class="tabs-container">
        <div class="tab" *ngFor="let tab of chartContainer.chartTabs; let index = index"
          [ngClass]="{ 'selected' : index === selectedTab }" (click)="selectTab(index)">
          {{ tab.tabName }}
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container [ngSwitch]="chartContainer.type">
    <!-- VERTICAL BARS -->
    <div class="chart" [ngStyle]="{ 'height': 'calc(100% - ' + chartHeader.offsetHeight + 'px - 2vw)' }" *ngSwitchCase="chartTypes.VERTICAL_BARS">
      <ngx-charts-bar-vertical
        [results]="chartContainer.chartTabs[selectedTab].data"
        [customColors]="chartContainer.chartTabs[selectedTab].colors"
        [legend]="false"
        [showGridLines]="false"
        [xAxis]="true"
        [yAxis]="true"
        >
      </ngx-charts-bar-vertical>
    </div>

    <!-- DOUGHNUT PIE -->
    <div class="chart" [ngStyle]="{ 'height': 'calc(100% - ' + (chartHeader.offsetHeight + ((generalService.isMobile | async) ? 25 : 0)) + 'px - 2vw)' }" *ngSwitchCase="chartTypes.PIE">
      <ngx-charts-pie-chart
        [results]="chartContainer.chartTabs[selectedTab].data"
        [customColors]="chartContainer.chartTabs[selectedTab].colors"
        [legendPosition]="(generalService.isMobile | async) ? legendPosition.Below : legendPosition.Right"
        [legend]="true"
        [legendTitle]="''"
        [doughnut]="true"
        [arcWidth]="0.6"
        >
      </ngx-charts-pie-chart>
    </div>

    <!-- ADVANCED PIE -->
    <div class="chart" [ngStyle]="{ 'height': 'calc(100% - ' + (chartHeader.offsetHeight + ((generalService.isMobile | async) ? 25 : 0)) + 'px - 2vw)' }" *ngSwitchCase="chartTypes.ADVANCED_PIE">
      <ngx-charts-advanced-pie-chart #chart
        [results]="chartContainer.chartTabs[selectedTab].data"
        [gradient]="true"
        [customColors]="chartContainer.chartTabs[selectedTab].colors"
        >
      </ngx-charts-advanced-pie-chart>
    </div>

    <!-- PIE GRID -->
    <div class="chart" [ngStyle]="{ 'height': 'calc(100% - ' + (chartHeader.offsetHeight + ((generalService.isMobile | async) ? 25 : 0)) + 'px - 2vw)' }" *ngSwitchCase="chartTypes.PIE_GRID">
      <ngx-charts-pie-grid
        [results]="chartContainer.chartTabs[selectedTab].data"
        [customColors]="chartContainer.chartTabs[selectedTab].colors"
        >
      </ngx-charts-pie-grid>
    </div>
  </ng-container>
</div>
