import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ContentType, MediumType, UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { FirebaseAnalyticsService } from 'src/app/_services/firebase/analytics/firebase-analytics.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { InfoCard } from '../../_shared-components/info-card-module/info-card.model';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.sass'],
  animations: [
    trigger('Fade', [
      state(
        'out',
        style({
          opacity: '0'
        })
      ),
      state(
        'in',
        style({
          opacity: '1',
        })
      ),
      transition('out <=> in', animate('300ms ease-in-out')),
    ])
  ]
})
export class MyAccountComponent implements OnInit, AfterViewInit {

  @ViewChild('detailsComponent') detailsComponent: AccountDetailsComponent;

  public userDetails: UserDetails;

  public isDetailsOpen: boolean = false;
  public iseditModeOn: boolean = false;

  public animationState: string = 'in';

  public updatedDetails: FormGroup;

  public resetEdit: boolean = false;

  public menuCards: Array<InfoCard> = [
  {
    icon: 'assets/mock/account-info-icon.svg',
    title: this.labelPipe.transform(this.labelService.myAccountInformationTitle),
    description: this.labelPipe.transform(this.labelService.myAccountInformationDescription),
    hasUrl: false
  },
  {
    icon: 'assets/mock/collection-icon.svg',
    title: this.labelPipe.transform(this.labelService.myAccountCollectionTitle),
    description: this.labelPipe.transform(this.labelService.myAccountCollectionDescription),
    hasUrl: true,
    url: '/my-collections'
  }
  /* {
    icon: 'assets/mock/donation-icon.svg',
    title: 'My donations',
    description: 'See and manage donations.',
    hasUrl: true,
    url: ''
  } */];

  public environment = environment;

  constructor(
    public generalService: GeneralService,
    private keycloakService: KeycloakService,
    public labelService: LabelsService,
    private labelPipe: LabelPipe,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private translateService: TranslateService
    ) {

    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    if (!this.generalService.userDetails$.value) {
      this.generalService.getUserDetails().subscribe(resp => {
        this.userDetails = resp
      });
    } else {
      this.userDetails = this.generalService.userDetails$.value;
    }
  }

  ngOnInit(): void {
    this.generalService.asyncFunction(() => {this.animationState = 'out';});
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('profile');
  }

  public logOut(): void {
    this.firebaseAnalyticsService.logEvents('logout-btn-clicked');
    this.generalService.navigateTo('/landing-page');

    this.generalService.userLogout(window.location.origin);
  }

  public redirectToPage(url: string): void {
    this.generalService.navigateTo(`my-account/${url}`)
  }

  public openDetails(event: boolean):void {
    this.generalService.asyncFunction(() => {
      this.isDetailsOpen = event;
      this.generalService.asyncFunction(() => {
        this.animationState = 'in'
      });
    }, 300);
  }

  public closeDetails(event: boolean): void {
    this.generalService.asyncFunction(() => {
      this.isDetailsOpen = event;
      this.generalService.asyncFunction(() => {
        this.animationState = 'out';
      });
    }, 300);
  }

  public toggleEdit(): void {
    this.iseditModeOn = !this.iseditModeOn;
  }

  public cancelEdit(): void {
    this.iseditModeOn = false;
    this.resetEdit = !this.resetEdit;
  }

  public saveInformation(): void {
    this.detailsComponent.saveDetails();
    if(this.detailsComponent.accountDetailsFormContainer.status === 'VALID') {
      const details: UserDetails = {
        tenantId: this.userDetails.tenantId,
        firstName: this.updatedDetails.get('accountDetailsForm')!.get('firstname')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('firstname')!.value : '',
        lastName: this.updatedDetails.get('accountDetailsForm')!.get('lastname')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('lastname')!.value : '',
        birthdate: this.updatedDetails.get('accountDetailsForm')!.get('birthdate')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('birthdate')!.value : '',
        fiscalNumber: this.updatedDetails.get('accountDetailsForm')!.get('fiscalnumber')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('fiscalnumber')!.value : '',
        emailAddress: {
          mediumType: ('emailAddress' as MediumType.emailAddress),
          preferred: true,
          emailAddress: {
            emailAddress: this.updatedDetails.get('accountDetailsForm')!.get('emailaddress')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('emailaddress')!.value : ''
          }
        },
        fiscalAddress: {
          mediumType: ('fiscalAddress' as MediumType.fiscalAddress),
          preferred: false,
          fiscalAddress: {
            addressLine1: this.updatedDetails.get('accountDetailsForm')!.get('addressline1')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('addressline1')!.value : '',
            addressLine2: this.updatedDetails.get('accountDetailsForm')!.get('addressline2')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('addressline2')!.value : '',
            city: this.updatedDetails.get('accountDetailsForm')!.get('city')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('city')!.value : '',
            postalCode: this.updatedDetails.get('accountDetailsForm')!.get('postalcode')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('postalcode')!.value : '',
            country: this.updatedDetails.get('accountDetailsForm')!.get('country')!.valid ? this.updatedDetails.get('accountDetailsForm')!.get('country')!.value : '',
          }
        },
        images: { // TODO
          coverImage: {
            content: '',
            href: '',
            type: ContentType.IMAGE
          },
          profilePictureImage: {
            content: '',
            href: '',
            type: ContentType.IMAGE
          }
        }
      };

      Object.assign(this.userDetails, details);
      this.generalService.updateUserDetails(this.userDetails).subscribe();

      this.iseditModeOn = false;
    }
  }

  public updateProfilePicture(): void {
    // TODO call image cropper to crop image and updateUserDetails to update profile picture
  }

  public updateBannerPicture(): void {
    // TODO call image cropper to crop image and updateUserDetails to update banner picture
  }
}
