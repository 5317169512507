<div class="transactions-container">
  <!-- <div class="transaction-charts-container mb-5" *ngIf="charts">
    <div class="transaction-charts-labels-container mb-5">
      <div class="title">
        <p>{{ labelService.transactionsDashboardTitle | label }}</p>
      </div>
      <div class="description">
        <p>{{ labelService.transactionDashboardDescription | label }}</p>
      </div>
    </div>

    <app-dashboard-container [charts]="charts">
    </app-dashboard-container>
  </div> -->

  <div class="transaction-list-wrapper">
    <div class="transaction-list-labels-container">
      <div class="title">
        <p>{{ labelService.transactionsListTitle | label }}</p>
      </div>
      <div class="description">
        <p>{{ labelService.transactionListDescription | label }}</p>
      </div>
    </div>

    <div class="mt-5" class="transaction-list-container" *ngIf="transactionsList.length > 0 || isListLoading; else noItemsFound">
      <app-list-header *ngIf="headerListItems.length > 0 && !(generalService.isMobile | async)!" [fullSize]="!(generalService.isMobile | async)!"
        [fontSize]="'var(--header-font-size)'" [fontColor]="environment.brandStyleVariables.mainBorderColor" [headerList]="headerListItems"
        [showArrows]="false"></app-list-header>

      <div class="transaction-list"
        infiniteScroll
        [infiniteScrollDistance]="listPercentageFromBottom"
        [infiniteScrollThrottle]="50"
        [immediateCheck]="true"
        [scrollWindow]="false"
        [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
        (scrolled)="onScroll()"
        #transactionListContainer>
        <ng-container *ngIf="transactionsList.length > 0 || isListLoading">
          <ng-container *ngFor="let item of transactionsList">
            <app-list-item-expandable [fontSize]="'var(--header-font-size)'" [expandableFontSize]="'var(--small-font-size)'" [item]="item" [expandableItem]="item.infoExpandable!"
             [expandableHeader]="expandableListHeaderItems" [headerFontWeight]="'600'" [backgroundOpacity]="'1'" [fullSize]="!(generalService.isMobile | async)!"
             [isMobile]="(generalService.isMobile | async)!"[expandableBackgroundOpacity]="'0%'" [backgroundOpacity]="'10%'" [stateActiveColor]="'#5ED788'" [statePendingColor]="'#ff3e3e'" [stateDisabledColor]="'#FFB84D'"
             [borderRadius]="'var(--smallest-font-size)'" [showId]="false">
            </app-list-item-expandable>
            <!-- <app-generic-list-item [staticFirstElement]="true" [item]="item" (actionEvent)="openTransactionDetails($event)"></app-generic-list-item> -->
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isListLoading">
          <!-- <ng-container *ngFor="let item of loadingListItems">
            <app-loading-list-item class="loading-item">
              <ng-template>
                 <app-product-loading-content></app-product-loading-content>
              </ng-template>
            </app-loading-list-item>
          </ng-container> -->
        </ng-container>
      </div>
    </div>
    <ng-template #noItemsFound>
      <div class="not-items-found">
        <app-no-items [image]="'assets/imgs/environments/' + environment.tenantName + '/empty-list-image.png'" [title]="labelService.transactionListNoItems | label"></app-no-items>
      </div>
    </ng-template>
  </div>
</div>
