import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { LibraryService } from '../../__services/library.service';
import { SnackbarBaseComponent } from '../../_extended-components/snackbar-base-component/snackbar-base.component';
import { CustomSnackBarData } from './snackbar.model';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    '../../_extended-components/snackbar-base-component/snackbar-base.component.sass',
    './snackbar.component.sass'
  ]
})
export class SnackbarComponent extends SnackbarBaseComponent {

  showPrefixIcon: boolean = false;
  prefixIconUrl: string;

  message: string;
  showCloseIcon: boolean;

  showAction: boolean = false;
  actionText: string;

  snackbarClass: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackBarData,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    override host: ElementRef,
    override libraryService: LibraryService) {
    super(host, libraryService);
    super.setCSSVars();
    this.snackbarClass = data.snackbarClass;

    this.showPrefixIcon = data.prefixIconUrl != null && data.prefixIconUrl !== '';
    this.prefixIconUrl = data.prefixIconUrl;

    this.message = data.message;
    this.showCloseIcon = data.showCloseIcon;
    if (data.action) {
      this.showAction = true;
      this.actionText = data.action;
    }
  }

  public closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  public ActionClicked(): void {
    // TODO
    this.closeSnackbar();
  }

}
