<div class="backdrop-container" [@BackdropFadeIn]="(generalService.isMobile | async) ? mobileAnimationState : animationState"></div>

<div [@ZoomIn]="animationState" [@SlideIn]="mobileAnimationState" class="popup-container">
  <div class="close-button" (click)="closePopUp()">
    <i class="bi bi-x"></i>
  </div>

  <div class="title">
    <span>{{ labelService.activityAddInputBtnLabel | label }}</span>

    <div class="state-container" *ngIf="formInputItemStatus.CREATING" [ngClass]="{
      'yellow': form.status === formInputItemStatus.PENDING,
      'green': form.status === formInputItemStatus.COMPLETED,
      'red': form.status === formInputItemStatus.REJECTED
    }">
      <ng-container [ngSwitch]="form.status">
        <span *ngSwitchCase="formInputItemStatus.PENDING">{{ labelService.activityTypeInputStatePendingLabel | label }}</span>
        <span *ngSwitchCase="formInputItemStatus.COMPLETED">{{ labelService.activityTypeInputStateCompletedLabel | label }}</span>
        <span *ngSwitchCase="formInputItemStatus.REJECTED">{{ labelService.activityTypeInputStateRejectedLabel | label }}</span>
      </ng-container>
    </div>
  </div>

  <form class="form-container custom-scroll" [formGroup]="dynamicFormGroup" (ngSubmit)="saveForm()" *ngIf="isLoaded && editing">
    <ng-container *ngFor="let input of form.inputs">
      <div class="input-container" [ngClass]="{
        'half-width': input.size === widthOptions.HALF && !(generalService.isMobile | async),
        'full-width': (!input.size || input.size === widthOptions.FULL) || (generalService.isMobile | async)
      }">
        <ng-container [ngSwitch]="input.type">
          <app-single-line-text-input *ngSwitchCase="inputType.SINGLE_LINE"
            [label]="input.label + (input.required? '*' : '')"
            [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
            [placeholderLabel]="input.placeholder"
            [placeholderStyle]="{
              color: '#8692A6',
              fontSize: 'var(--smaller-font-size)',
              fontWeight: '500'
            }"
            [inputHeight]="'calc(var(--small-font-size) * 4)'"
            [fontSize]="'var(--small-font-size)'"
            [backgroundColor]="'white'"
            [borderColor]="'#8692A6'"
            [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
            [brandColor]="'var(--main-brand-color)'" [debounceTime]="300"
            [inputValue]="$any(input.value)"
            [fGName]="'form'" [fCName]="input.order + ''"
            [isDisabled]="!editing"
            (valueChanged)="input.value = $event">
          </app-single-line-text-input>

          <app-textarea-input *ngSwitchCase="inputType.TEXT_AREA"
            [shouldGrow]="false" [rowSize]="3" [charLength]="input.maxLength ? input.maxLength : 99999"
            [inputPadding]="'var(--smaller-font-size) calc(var(--smaller-font-size) * 2)'"
            [showCharacterCount]="input.maxLength ? true: false" [maxCharactersLabel]="labelService.activityTypeInputPopupMaxCharsLabel | label"
            [label]="input.label + (input.required? '*' : '')"
            [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
            [placeholder]="input.placeholder"
            [placeholderStyle]="{
              color: '#8692A6',
              fontSize: 'var(--smaller-font-size)',
              fontWeight: '500'
            }"
            [inputHeight]="'calc(var(--small-font-size) * 4)'"
            [fontSize]="'var(--small-font-size)'"
            [backgroundColor]="'white'"
            [borderColor]="'#8692A6'"
            [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
            [brandColor]="'var(--main-brand-color)'"
            [inputValue]="$any(input.value)"
            [fGName]="'form'" [fCName]="input.order + ''"
            (valueChanged)="input.value = $event">
          </app-textarea-input>

          <app-numeric-input *ngSwitchCase="inputType.NUMBER"
            [allowNegative]="false" [allowDecimals]="false"
            [minValue]="0" [maxValue]="input.maxLength ? input.maxLength : 99999999"
            [label]="input.label + (input.required? '*' : '')"
            [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
            [placeholderLabel]="input.placeholder"
            [placeholderStyle]="{
              color: '#8692A6',
              fontSize: 'var(--smaller-font-size)',
              fontWeight: '500'
            }"
            [inputHeight]="'calc(var(--small-font-size) * 4)'"
            [fontSize]="'var(--small-font-size)'"
            [backgroundColor]="'white'"
            [borderColor]="'#8692A6'"
            [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
            [brandColor]="'var(--main-brand-color)'"
            [inputValue]="$any(input.value)"
            [fGName]="'form'" [fCName]="input.order + ''"
            (valueChanged)="input.value = $event">
          </app-numeric-input>

          <app-single-line-text-input *ngSwitchCase="inputType.EMAIL"
            [label]="input.label + (input.required? '*' : '')"
            [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
            [placeholderLabel]="input.placeholder"
            [placeholderStyle]="{
              color: '#8692A6',
              fontSize: 'var(--smaller-font-size)',
              fontWeight: '500'
            }"
            [inputHeight]="'calc(var(--small-font-size) * 4)'"
            [fontSize]="'var(--small-font-size)'"
            [backgroundColor]="'white'"
            [borderColor]="'#8692A6'"
            [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
            [brandColor]="'var(--main-brand-color)'" [debounceTime]="300"
            [inputValue]="$any(input.value)"
            [fGName]="'form'" [fCName]="input.order + ''"
            (valueChanged)="input.value = $event">
          </app-single-line-text-input>

          <app-generic-phone-input *ngSwitchCase="inputType.PHONE_NUMBER"
            [allowDropdown]="true"
            [label]="input.label + (input.required? '*' : '')"
            [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
            [placeholderLabel]="input.placeholder"
            [placeholderStyle]="{
              color: '#8692A6',
              fontSize: 'var(--smaller-font-size)',
              fontWeight: '500'
            }"
            [inputHeight]="'calc(var(--small-font-size) * 4)'"
            [fontSize]="'var(--small-font-size)'"
            [backgroundColor]="'white'"
            [borderColor]="'#8692A6'"
            [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
            [brandColor]="'var(--main-brand-color)'"
            [inputValue]="$any(input.value)"
            [fGName]="'form'" [fGChildName]="input.order + ''" [fCName]="'phoneInput'"
            [dropdownBorderRadius]="'0.3vw'" [searchPlaceholderLabel]="labelService.phoneCountrySearchPlaceholder | label">
          </app-generic-phone-input>

          <div *ngSwitchCase="inputType.CHECKBOX" class="d-flex input-checkbox-container">
            <label>{{input.label + (input.required? '*' : '')}}</label>
            <div class="checkbox-wrapper w-100 d-flex">
              <ng-container *ngFor="let option of input.options!; let i = index">
                <app-checkbox
                  [fontSize]="'var(--small-font-size)'"
                  [fontWeight]="'700'"
                  [fontColor]="'var(--user-font-color)'"
                  [borderColor]="'#8692A6'"
                  [brandColor]="'var(--main-brand-color)'"
                  [label]="option.value"
                  [flexDirection]="'row-reverse'"
                  [gapSize]="'0.65vw'"
                  [checked]="option.isChecked"
                  (valueChanged)="addCheckboxValueToOptions(option, input, input.options!)">
                </app-checkbox>
              </ng-container>
            </div>
          </div>

        </ng-container>
      </div>
    </ng-container>
  </form>

  <div class="non-editing-input-wrapper" *ngIf="isLoaded && !editing && form.inputs && form.inputs.length > 0">
    <div class="non-editing-input-container" *ngFor="let input of form.inputs"
      [ngClass]="{
        'half-width': input.size === widthOptions.HALF,
        'full-width': !input.size || input.size === widthOptions.FULL
      }">
      <div class="d-flex input-checkbox-container" *ngIf="input.type === inputType.CHECKBOX; else simpleValue">
        <label>{{input.label + (input.required? '*' : '')}}</label>
        <div class="checkbox-wrapper w-100 d-flex">
          <ng-container *ngFor="let option of input.options!; let i = index">
            <app-checkbox
              [fontSize]="'var(--small-font-size)'"
              [fontWeight]="'700'"
              [fontColor]="'var(--user-font-color)'"
              [borderColor]="'#8692A6'"
              [brandColor]="'var(--main-brand-color)'"
              [label]="option.value"
              [flexDirection]="'row-reverse'"
              [gapSize]="'0.65vw'"
              [disabled]="true"
              [checked]="option.isChecked">
            </app-checkbox>
          </ng-container>
        </div>
      </div>

      <ng-template #simpleValue>
        <p class="input-label">
          {{ input.label + (input.required? '*' : '') }}
        </p>

        <p class="input-value">
          {{ input.value ? input.value : '-' }}
        </p>
      </ng-template>
    </div>
  </div>

  <div class="w-100 d-flex justify-content-around buttons-wrapper" [ngClass]="{'mt-3 mb-3': !isMobile}">
    <ng-container *ngIf="form.status === formInputItemStatus.PENDING">
      <app-generic-button
        [buttonName]="labelService.activityDeleteInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="'#424242'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 1.8) calc(var(--main-info-font-size) * 1.4)'"
        [hasIcon]="true" [iconClass]="'bi bi-trash'" [iconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 1.3)'"
        [fontWeight]="'700'"
        (buttonAction)="removeUserInput()">
      </app-generic-button>

      <app-generic-button *ngIf="!editing"
        [buttonName]="labelService.activityEditInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 1.8) calc(var(--main-info-font-size) * 1.4)'"
        [fontWeight]="'700'"
        [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" (buttonAction)="startEditing()">
      </app-generic-button>
    </ng-container>

    <ng-container  *ngIf="form.status === formInputItemStatus.CREATING">
      <app-generic-button
        [buttonName]="labelService.activityAddInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 1.8) calc(var(--main-info-font-size) * 1.4)'"
        [hasIcon]="true" [iconClass]="'bi bi-plus'" [iconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 2.5)'"
        [fontWeight]="'700'"
        [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!dynamicFormGroup.valid" (buttonAction)="saveForm()">
      </app-generic-button>
    </ng-container>

    <ng-container *ngIf="form.status !== formInputItemStatus.CREATING && editing">
      <app-generic-button
        [buttonName]="labelService.activitySaveInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 1.8) calc(var(--main-info-font-size) * 1.4)'"
        [fontWeight]="'700'"
        [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!dynamicFormGroup.valid" (buttonAction)="saveForm()">
      </app-generic-button>
    </ng-container>

  </div>
</div>
