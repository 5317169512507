import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './textarea-input.component.sass'
  ]
})
export class TextareaInputComponent extends InputBaseComponent implements OnInit {

  @Input() charLength: number;
  @Input() rowSize: number;

  @Input() shouldGrow: boolean;
  @Input() showCharacterCount: boolean;
  @Input() placeholder: string;
  @Input() maxCharactersLabel: string;

  @Input() debounceTime: number;

  @Input() showError: boolean;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    super.setCSSVars();
    super.setSpecificCSSVars();
  }
}
