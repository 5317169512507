import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'src/app/_generic-components-lib/accordion-module/accordion.module';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { GenericPhoneInputModule } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { TextareaInputModule } from 'src/app/_generic-components-lib/inputs/textarea-input-module/textarea-input-module.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';

@NgModule({
  declarations: [
    GeneralInfoComponent,
    ContactUsFormComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ButtonsModule,
    SingleLineTextInputModule,
    PageBannerModule,
    LabelPipeModule,
    TextareaInputModule,
    GenericPhoneInputModule,
    AccordionModule,
    SmallPageBannerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    GeneralInfoComponent,
    ContactUsFormComponent
  ],
  providers: [
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class ContactUsModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { SmallPageBannerModule } from '../_shared-components/small-page-banner-module/small-page-banner.module';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
