import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { DateInputModule } from 'src/app/_generic-components-lib/inputs/date-input-module/date-input-module.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { GenericListHeaderModule } from '../_shared-components/generic-list-header-module/generic-list-header.module';
import { GenericListItemModule } from '../_shared-components/generic-list-item-module/generic-list-item.module';
import { InfoCardModule } from '../_shared-components/info-card-module/info-card.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { MyAccountComponent } from './my-account/my-account.component';

@NgModule({
  declarations: [
    MyAccountComponent,
    AccountDetailsComponent,
    MyCollectionsComponent,
    CollectionContentComponent,
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    InfoCardModule,
    SingleLineTextInputModule,
    ReactiveFormsModule,
    LabelPipeModule,
    GenericListHeaderModule,
    GenericListItemModule,
    InfiniteScrollModule,
    NoItemsModule,
    DateInputModule,
    ListItemCardsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    MyAccountComponent,
    AccountDetailsComponent
  ],
  providers: [
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class ProfileModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { CollectionContentComponent } from './my-collections/collection-content/collection-content.component';
import { MyCollectionsComponent } from './my-collections/my-collections.component';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
