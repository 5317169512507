<div class="bg-loading" [ngClass]="{ 'is-mobile': generalService.isMobile | async }">
  <div class="spinner" *ngIf="!(environment.customLoading); else customLoading">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>

  <ng-template #customLoading>
    <ng-container [ngSwitch]="environment.customLoadingAnimationType">
      <!-- Rive -->
      <ng-container *ngSwitchCase="'rive'">
        <canvas #canvasRive>
        </canvas>
      </ng-container>

      <!-- Lottie -->
      <ng-container *ngSwitchCase="'lottie'">
        <ng-lottie #lottieAnimation
          [width]="'100%'"
          [height]="'100%'"
          [options]="lottieOptions"
          (animationCreated)="lottieAnimationItem = $event;">
        </ng-lottie>
      </ng-container>

      <!-- GIF -->
      <ng-container *ngSwitchCase="'gif'">
        <img [src]="'assets/imgs/environments/' + environment.tenantName + '/custom-loading-animation.gif'" alt="loading...">
      </ng-container>
    </ng-container>
  </ng-template>
</div>




