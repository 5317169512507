import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryService } from '../__services/library.service';
import { ListHeaderModule } from '../list-header-module/list-header.module';
import { ListItemExpandableComponent } from './list-item-expandable/list-item-expandable.component';
import { ListItemComponent } from './list-item/list-item.component';



@NgModule({
  declarations: [
    ListItemComponent,
    ListItemExpandableComponent
  ],
  imports: [
    CommonModule,
    ListHeaderModule
  ],
  exports: [
    ListItemComponent,
    ListItemExpandableComponent
  ],
  providers: [
    LibraryService
  ]
})
export class ListItemModule { }
