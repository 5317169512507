import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DebounceModule } from 'src/app/_directives/debounce-module/debounce-module.module';
import { LibraryService } from '../../__services/library.service';
import { BaseComponentModule } from '../../_base-component/base-component.module';



@NgModule({
  declarations: [TextareaInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DebounceModule,
    BaseComponentModule,
    HttpClientModule,
  ],
  exports: [
    TextareaInputComponent
  ],
  providers: [
    LibraryService
  ]
})
export class TextareaInputModule { }
