import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { FirebaseAnalyticsService } from 'src/app/_services/firebase/analytics/firebase-analytics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-side-menu',
  templateUrl: './mobile-side-menu.component.html',
  styleUrls: ['./mobile-side-menu.component.sass'],
  animations: [
    trigger('MenuSlide', [
      state('hide', style({
        transform: 'translateX(-100%)',
      })),
      state('show', style({
        transform: 'translateX(0)'
      })),
      transition('hide <=> show',  animate('600ms ease-in')),
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('600ms cubic-bezier(0.73, 0.03, 0.74, 0.05)')),
    ]),
  ]
})
export class MobileSideMenuComponent implements OnInit, OnChanges {

  @Input() close: boolean;

  @Input() brandColor: string;

  @Output() isClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationState: string = 'hide';

  public currentUrl: string;
  public isRouteActive: boolean;

  public environment = environment;

  constructor(
    public generalService: GeneralService,
    public labelService: LabelsService,
    private host: ElementRef,
    public router: Router,
    private firebaseAnalyticsService: FirebaseAnalyticsService
    ) {
      this.currentUrl = router.url.split('?')[0];
      this.isRouteActive = this.router.url.includes('/my-account');
    }

  ngOnInit(): void {
    this.setCSSVars();
    this.generalService.asyncFunction(() => {this.animationState = 'show'}, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['close']) {
      if (changes['close'].currentValue) {
        this.animationState = 'hide';
        this.generalService.asyncFunction(() => this.isClosed.emit(true), 600);
      }
    }
  }

  public goTo(url?: string, external: boolean = false): void {
    if(url) {
      this.generalService.navigateTo(url, external);
    }
    this.generalService.autoScrollTop('page-router-container');
    this.isClosed.emit(true);
  }

  public goToLogout(): void {
    this.firebaseAnalyticsService.logEvents('logout-btn-clicked');
    localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
    this.generalService.keycloakService.logout(window.location.origin);
  }

  public goToLogin(): void {
    this.firebaseAnalyticsService.logEvents('login-btn-clicked');

    history.pushState({page: 1}, 'back'); // force entrance in history of current page to allow navigation back from login page

    this.generalService.keycloakService.login({
      // force go to login page even if it could login without that flow
      prompt: 'login',

      // if current page is '' (landing-page) and there is a 'home' page, after login it should redirect to it,
      // else stay in the same page
      redirectUri:
        window.location.pathname === '' && this.generalService.menuList.some(m => m.viewUrl === '/home') ?
        (window.location.origin + '/home') : window.location.href
    });
  }

  setCSSVars(): void {
    if(this.brandColor) {
      this.host.nativeElement.style.setProperty('--menu-brand-color', this.brandColor)
    }
  }
}
