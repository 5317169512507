<div class="backdrop-container" [@BackdropFadeIn]="animationState" (click)="closeClickOutside ? closePopUp() : null"></div>

<div [@ZoomIn]="animationState" class="popup-container card-radius">

  <div class="close-button" *ngIf="enableXButton" (click)="closePopUp()">
    <i class="bi bi-x"></i>
  </div>

  <span class="pre-img-title mb-4" *ngIf="preImgTitle"
    [ngStyle]="{
      'text-align': preImgTitle.style?.textAlign,
      'font-size': preImgTitle.style?.fontSize,
      'font-weight': preImgTitle.style?.fontWeight,
      'font-style': preImgTitle.style?.fontStyle,
      'color': preImgTitle.style?.fontColor,
      'margin-top': preImgTitle.style?.marginTop,
      'margin-bottom': preImgTitle.style?.marginBottom,
      'text-transform': preImgTitle.style?.textTransform
    }">
    {{ preImgTitle.text }}
  </span>

  <img [src]="image" alt="{{ preImgTitle != null ? preImgTitle.text : title != null ? title.text : ''}}" *ngIf="image">

  <span class="title mt-4" *ngIf="title"
  [ngClass]="{'mt-4': !title.style?.marginTop }"
  [ngStyle]="{
    'text-align': title.style?.textAlign,
    'font-size': title.style?.fontSize,
    'font-weight': title.style?.fontWeight,
    'font-style': title.style?.fontStyle,
    'color': title.style?.fontColor,
    'margin-top': title.style?.marginTop,
    'margin-bottom': title.style?.marginBottom,
    'text-transform': title.style?.textTransform
  }">{{ title.text }}</span>

  <div class="w-100 description-container">
    <ng-container *ngFor="let description of descriptions">
      <ng-container *ngIf="description.innerTexts; else simpleText">
          <p class="description"
            [ngClass]="{'mt-2': !description.style?.marginTop, 'mb-4': !description.style?.marginBottom }"
            [ngStyle]="{
              'text-align': description.style?.textAlign,
              'font-size': description.style?.fontSize,
              'font-weight': description.style?.fontWeight,
              'font-style': description.style?.fontStyle,
              'color': description.style?.fontColor,
              'margin-top': description.style?.marginTop,
              'margin-bottom': description.style?.marginBottom,
              'text-transform': description.style?.textTransform
            }">
            {{ description.text }}
            <ng-container *ngFor="let innerText of description.innerTexts">
              <a [href]="innerText.action!.url" role="button" [ngStyle]="{
                'font-size': innerText.style?.fontSize,
                'font-weight': innerText.style?.fontWeight,
                'color': innerText.style?.fontColor
                }"*ngIf="innerText.action?.hasUrl; else textDescription">
                {{innerText.text}}
              </a>

              <ng-template #textDescription>
                <span [ngStyle]="{
                  'text-align': innerText.style?.textAlign,
                  'font-size': innerText.style?.fontSize,
                  'font-weight': innerText.style?.fontWeight,
                  'font-style': innerText.style?.fontStyle,
                  'color': innerText.style?.fontColor,
                  'margin-top': innerText.style?.marginTop,
                  'margin-bottom': innerText.style?.marginBottom,
                  'text-transform': innerText.style?.textTransform,
                  'cursor': innerText.action?.isClickable ? 'button' : ''
                }" (click)="innerText.action?.isClickable ? navigateTo() : ''">{{innerText.text}}</span>
              </ng-template>

            </ng-container>
          </p>
      </ng-container>

      <ng-template #simpleText>
        <p class="description w-100"
        [ngClass]="{'mt-2': !description.style?.marginTop, 'mb-4': !description.style?.marginBottom}"
        [ngStyle]="{
          'text-align': description.style?.textAlign,
          'font-size': description.style?.fontSize,
          'font-weight': description.style?.fontWeight,
          'font-style': description.style?.fontStyle,
          'color': description.style?.fontColor,
          'margin-top': description.style?.marginTop,
          'margin-bottom': description.style?.marginBottom,
          'text-transform': description.style?.textTransform
        }">{{description.text}}</p>
      </ng-template>
    </ng-container>
  </div>

  <div class="bottom-image-container mb-4" *ngIf="bottomImage">
    <img [src]="bottomImage">
    <span>{{bottomDescription}}</span>
  </div>

  <div class="button-area-wrapper w-100" [ngClass]="{'flex-column': buttonsVertical}">
    <ng-container *ngFor="let btn of buttons">
      <div class="w-100 mt-2 button" (click)="btnAction(btn)"
        [ngStyle]="{
          'background-color': btn.style?.backgroundColor,
          'color': btn.style?.fontColor,
          'font-size': btn.style?.fontSize,
          'font-weight': btn.style?.fontWeight,
          'font-style': btn.style?.fontStyle,
          'text-transform': btn.style?.textTransform,
          'border-radius': btn.style?.borderRadius
        }"
        [ngClass]="{'generic-btn-shadow': btn.hasShadow, 'generic-btn': !btn.hasShadow}">
        {{ btn.text }}
      </div>
    </ng-container>
  </div>
</div>
