export interface OrderEntry {
  id: string;
  status: OrderEntryStatusEnum;
  created: string;
  productOrderItems: Array<ProductOrderItem>;
  relatedParties: Array<RelatedParty>;
  payment: Payment;
}

export interface OrderEntryDTO {
  id?: string;
  productOrderItems: Array<ProductOrderItemDTO>;
  relatedParties?: Array<RelatedPartyDTO>;
  payment: PaymentDTO;
  totalValue?: number;
}

export interface OrderEntryReplyDTO extends OrderEntryDTO {
  id: string;
  status: OrderEntryStatusEnum;
  created: string;
}

export interface OrderEntryListDTO {
  id: string;
  status: OrderEntryStatusEnum;
  created: string;
  ordersList: Array<OrderEntry>
}

export interface ProductOrderItem {
  id?: string;
  quantity: number;
  action: string;
  status?: string;
  product: {
    productSpecification: {
      id: string;
    };
  }
}

export interface ProductOrderItemDTO {
  id?: string;
  quantity: number;
  action: string;
  status?: string;
  product: {
    productSpecification: {
      id: string;
      name?: string,
      serial?: string,
      partnerName?: string,
    };
  }
}

export interface Payment {
  mediumType: PaymentTypeEnum,
  characteristicObject?: PaymentCharacteristic;
}

export interface PaymentDTO {
  mediumType: string,
  characteristic?: PaymentCharacteristic;
}

export interface PaymentCharacteristic {
  phoneNumber : string;
}

export interface PaymentCharacteristicDTO {
  phoneNumber : string;
}

export interface RelatedParty {
  id?: string;
  type: RelatedPartyTypes;
}

export interface RelatedPartyDTO {
  id?: string;
  type: string;
}

export enum OrderEntryStatusEnum {
  acknowledged = 'acknowledged',
  inProgress = 'inProgress',
  pendingPayment = 'pendingPayment',
  completed = 'completed',
  failed = 'failed',
  rejected = 'rejected'
}

export type OrderEntryStatus = keyof typeof OrderEntryStatusEnum;

export enum RelatedPartyTypesEnum {
  WALLET = 'WALLET',
  BUYER = 'BUYER',

  // TODO add more types
}

export type RelatedPartyTypes = keyof typeof RelatedPartyTypesEnum;

export enum PaymentTypeEnum {
  MBWAY = 'MBWAY',
  GAMIFICATION_COIN = 'GAMIFICATION_COIN'
  // TODO add more payment types
}

export type PaymentType = keyof typeof PaymentTypeEnum;
