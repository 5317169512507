<div class="navbar">
  <div class="navigation-container">
    <div class="nav-brand"><img [src]="brandInfo.brandImageUrl" alt="" (click)="goTo('/')" class="logo"></div>

    <ng-container *ngFor="let menu of generalService.menuList">
      <a class="navigation-item" [ngClass]="{'is-active': (generalService.instituionActive | async) && menu.viewUrl === '/institutions'}" [routerLink]="menu.viewUrl" [routerLinkActive]="'is-active'" (click)="goTo()" *ngIf="!menu.isHidden">
        <span>{{menu.name}}</span>
      </a>
    </ng-container>
  </div>
  <div class="user-info-wrapper" *ngIf="generalService.useKeycloak || generalService.useWallet; else simpleButton">
    <ng-container *ngIf="generalService.useKeycloak; else noLogin">
      <ng-container *ngIf="generalService.isUserLoggedIn | async; else login">
        <div class="user-info-container">

          <div class="notifications-icon">
            <!-- TODO Notifications -->
          </div>

          <div class="wallet-icon">
            <!-- TODO Wallet Connection -->
          </div>

          <!-- User info -->
          <div class="user-info" >
            <div class="user-info-detail" (click)="openUserProfileDropdown()">
              <div class="user-profile-image-container" [ngClass]="{'active': isRouteActive}">
                <img class="w-100" [src]="(generalService.userDetails | async)?.images?.profilePictureImage?.href" *ngIf="(generalService.userDetails | async)?.images?.profilePictureImage && (generalService.userDetails | async)?.images?.profilePictureImage?.href; else defaultImage">
                <ng-template #defaultImage>
                  <img class="user-profile-image" [src]="'assets/imgs/environments/' + environment.tenantName + '/default-images/default-profile-image.svg'">
                </ng-template>
              </div>

              <span class="user-name">
                {{ ((generalService.userDetails | async)?.firstName ? (generalService.userDetails | async)?.firstName : '') + ((generalService.userDetails | async)?.lastName ? ' ' + (generalService.userDetails | async)?.lastName : '') }}
              </span>

              <div class="arrow-container">
                <div class="arrow-icon"></div>
              </div>
            </div>

            <ng-container *ngIf="userProfileDropdownOpened">
              <app-generic-dropdown class="user-profile-dropdown-container" [dropdownOptions]="userProfileDropdownOptions" [checkIcon]="false" [borderWidth]="'0'" [borderColor]="'#E4E8EE'" [fontColor]="'var(--main-font-color)'"
                [fontSize]="'16px'" [lightBrandColor]="'var(--main-brand-color-lighter)'"
                (optionSelected)="userProfileDropdownOptionSelected($event)" (closeDropdown)="userProfileDropdownOpened = false"></app-generic-dropdown>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #login>
        <app-generic-button [buttonName]="labelService.loginButton | label"
          [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
          [fontSize]="'var(--small-font-size)'" [fontWeight]="'800'" [borderRadius]="'calc(var(--small-font-size) * 3)'" [buttonSpacing]="'0.55vw 1.5vw'" (click)="goToLogin()"></app-generic-button>
      </ng-template>
    </ng-container>
    <ng-template #noLogin>
      <app-generic-button [hasPrefixIcon]="true" [iconClass]="'bi bi-wallet'" [defaultIconColor]="'#ffffff'"
        [buttonName]="(walletService.isWalletConnected ? labelService.walletButton : labelService.loginButton) | label"
        [backgroundColor]="walletService.isWalletConnected ? '#5ED788' : 'var(--main-brand-color)'"
        [brandColor]="walletService.isWalletConnected ? '#5ED788' : 'var(--main-brand-color)'" [fontSize]="'var(--small-font-size)'"
        [borderRadius]="'calc(var(--small-font-size) * 3)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [buttonSpacing]="'0.55vw 1.5vw'" [fontWeight]="'800'" (click)="openWalletSideBar()">
      </app-generic-button>
    </ng-template>
  </div>

  <ng-template #simpleButton>
    <app-generic-button [buttonName]="labelService.demoButton | label" [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [brandColor]="environment.brandStyleVariables.mainBrandColor"
      [fontSize]="'var(--small-font-size)'" [fontWeight]="'500'" [borderRadius]="'calc(var(--small-font-size) * 3)'"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [buttonSpacing]="'0.65vw 2.3vw'" (click)="goTo('contact-us'); this.generalService.autoScrollTop('contact-us-form-container', true)">
    </app-generic-button>
  </ng-template>
</div>

<div class="mobile-navbar">
  <div class="hamburguer-menu" [ngClass]="{'close-menu': isSideMenuOpen}" (click)="toggleSideMenu()">
    <span class="hamburguer-line"></span>
    <div class="hamburguer-cross">
      <span class="cross-line"></span>
    </div>
  </div>
  <img class="brand-icon" [src]="brandInfo.brandImageUrl" (click)="goTo('/'); setSideMenuState(false)">

  <div class="side-menu-container" *ngIf="isSideMenuOpen">
    <app-mobile-side-menu [close]="!toggleMenuState" (isClosed)="setSideMenuState(false)"></app-mobile-side-menu>
  </div>
</div>

<div class="side-bar-container" *ngIf="isSideBarOpen">
  <app-wallet-sidebar [profilePicture]="'assets/imgs/wallet-profile-placeholder.png'" [close]="!toggleSideBarState" (isClosed)="setSideBarState(false); toggleSideBarState = $event"></app-wallet-sidebar>
</div>
