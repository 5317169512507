<div class="list-item-content-container w-100 ps-3 pe-3 mt-auto">
  <div class="points-container" *ngIf="prices && prices.length > 0">
    {{ 'marketplace_listitem_price' | label: { price: prices[0].amount, unit: getCurrencySymbol(currency.type, 'narrow') | label } }}
  </div>

  <div class="quantity-container right-top-column" *ngIf="quantity && !isHistoryItem">
    <ng-container *ngIf="quantityWarningNumber; else normal">
      <span class="quantity-waning-msg" [ngStyle]="{ 'color': isBlocked ? 'var(--main-font-color)' : 'var(--msg-color)'}"
        *ngIf="quantity <= quantityWarningNumber">{{ quantity | i18nPlural: generalService.getPluralKey('product_quantity_warning') | translate: {n: quantity} }}</span>
      <span class="quantity-normal-msg"
        *ngIf="quantity > quantityWarningNumber">{{ quantity | i18nPlural: generalService.getPluralKey('product_quantity') | translate: {n: quantity} }}</span>
    </ng-container>

    <ng-template #normal>
      <span class="quantity-normal-msg" *ngIf="quantity">
        {{ quantity | i18nPlural: generalService.getPluralKey('product_quantity') | translate: {n: quantity} }}
      </span>
    </ng-template>
  </div>

  <div class="history-item-quantity-container" *ngIf="quantity && isHistoryItem">
    {{ (quantity > 1 ? labelsService.productHistoryListQuantityPluralLabel : labelsService.productHistoryListQuantityLabel) | label : { quantity: quantity } }}
  </div>

<!--     <div class="lowest-price-message" *ngIf="lowestPriceValue">
    <span class="lowest-price-msg">{{ 'marketplace_listitem_lowestask' | label: { price: lowestPriceValue, unit: '€' } }}</span>
  </div> -->

  <div class="left-bottom-column">
    <img [src]="'assets/imgs/lock-icon.png'" class="me-2" *ngIf="isBlocked">
    <div class="serials-container ms-1" *ngIf="serial">
      <span>{{ 'marketplace_listitem_serial' | label }} {{ serial }}</span>
    </div>
  </div>

  <div class="date-container right-bottom-column" *ngIf="endDate">
    <ng-container>
      <img class="warning me-2" *ngIf="dateWarning" [src]="'assets/imgs/red-warning-icon.svg'">
      <span *ngIf="endDate" [ngClass]="{'warning-color': dateWarning}">{{ 'marketplace_listitem_date' | label: { date: endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang } }}</span>
    </ng-container>
  </div>
</div>
