import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Description, GenericPopUp, PopUpButton, Title } from '../_generic-components-lib/popups/popups.model';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  popUpsToShow: Array<GenericPopUp> = [];

  hasPopups$: Subject<boolean> = new Subject<boolean>()
  hasPopups: Observable<boolean> = this.hasPopups$.asObservable();

  constructor() { }

  public setPopupToOpen(preImageTitle: Title, image: string, title: Title, descriptions: Array<Description>, buttons: Array<PopUpButton>, closeClickOutside: boolean = false, enableXButton: boolean = true, buttonsVertical: boolean = false): void {
    this.popUpsToShow.push({
      preImgTitle: preImageTitle,
      image: image,
      title: title,
      descriptions: descriptions,
      buttons: buttons,
      closeClickOutside: closeClickOutside,
      enableXButton: enableXButton,
      buttonsVertical: buttonsVertical
    });

    this.setHasPopups();
  }

  public removePopup(): void {
    this.popUpsToShow.shift();
    this.setHasPopups();
  }

  private setHasPopups(): void {
    this.hasPopups$.next(this.popUpsToShow.length > 0);
  }


  public getTitle(title: string, fontColor?: string): Title {
    return {
      text: title,
      style: {
        fontColor: fontColor ? fontColor : getComputedStyle(document.documentElement).getPropertyValue('--main-font-color'),
        fontSize: getComputedStyle(document.documentElement).getPropertyValue('--header-font-size'),
        fontWeight: 'bold'
      }
    }
  }

  public getSimpleDescription(description: string, fontColor?: string): Description {
    return {
      text: description,
      style: {
        fontColor: fontColor ? fontColor : getComputedStyle(document.documentElement).getPropertyValue('--main-font-color'),
        fontSize: getComputedStyle(document.documentElement).getPropertyValue('--main-info-font-size')
      }
    };
  }
}
