<div class="descriptions-container">
  <div class="desc-item" [ngClass]="{'justify-content-center text-center': (!descItem.title && !descItem.subtitle) || !descItem.imageUrl && !descItem.animation}" *ngFor="let descItem of content.descriptionList">
    <div class="text-container" *ngIf="descItem.title || descItem.subtitle">
      <p class="desc-title" *ngIf="descItem.title">{{descItem.title}}</p>
      <p class="desc-subtitle" *ngIf="descItem.subtitle">{{descItem.subtitle}}</p>
    </div>
    <ng-container [ngSwitch]="descItem.animation.type" *ngIf="descItem.animation; else simpleImg">
      <!-- Lottie -->
      <ng-container *ngSwitchCase="'lottie'">
        <ng-lottie
          [width]="(generalService.isMobile | async) ? '40vw' : '20vw'"
          [height]="(generalService.isMobile | async) ? '40vw' : '20vw'"
          [options]="{
            autoplay: true,
            loop: true,
            path: descItem.animation.url
          }">
        </ng-lottie>
      </ng-container>

      <!-- Rive -->
      <div class="rive-animation-container" *ngSwitchCase="'rive'"
        [ngStyle]="{
          'width': (generalService.isMobile | async) ? '40vw' : '20vw',
          'height': (generalService.isMobile | async) ? '40vw' : '20vw'
        }" >
        <app-rive-animation-component
          [loop]="true"
          [animationFileUrl]="descItem.animation.url"></app-rive-animation-component>
      </div>
    </ng-container>

    <!-- Simple Image -->
    <ng-template #simpleImg>
      <img *ngIf="descItem.imageUrl" [src]="descItem.imageUrl">
    </ng-template>
  </div>
</div>
