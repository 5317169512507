import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { OnboardingStepInfo, OnboardingStepInfoDTO, OnboardingStepLightInfo, OnboardingStepLightInfoDTO, OnboardingSubmitContent } from '../onboarding.model';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public submitStep(body: OnboardingSubmitContent): Observable<string> {
    return this.apiService.post(`tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/onboarding`, body, '1.0', true, environment.useMockedData.onboardingSubmitStep).pipe(
      catchError(
        (error: HttpErrorResponse) => {

            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(!response || this.generalService.isValidServerReply(response)) {
          return 'Success';
        } else {
          throw response;
        }
      })
    )
  }

  public getOnboardingList(): Observable<Array<OnboardingStepLightInfo>> {
    return this.apiService.get(`tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/onboarding`, {}, '1.0', true, environment.useMockedData.onboardingGetList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<OnboardingStepLightInfoDTO> | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {

          const info = this.getStepListFromDTO((response as Array<OnboardingStepLightInfoDTO>));
          return info;
        } else {
          throw response;
        }
      }));
  }

  public getStepListFromDTO(dtoList: Array<OnboardingStepLightInfoDTO>): Array<OnboardingStepLightInfo> {
    const list: Array<OnboardingStepLightInfo> = [];

    dtoList.forEach(dto => {
      list.push(this.getStepFromDTO(dto));
    });

    return list;
  }

  public getOnboardingStep(stepId: string): Observable<OnboardingStepInfo> {
    return this.apiService.get(`tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/onboarding/${stepId}`, { }, '1.0', true, environment.useMockedData.onboardingGetStep).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: OnboardingStepInfoDTO | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {
          // TODO const info = this.getStepFromDTO(response as OnboardingStepInfoDTO>);
          const info = this.getStepFromDTO((response as OnboardingStepInfoDTO));

          return info;
        } else {
          throw response;
        }
      }));
  }

  private getStepFromDTO(stepDTO: OnboardingStepInfoDTO): OnboardingStepInfo {
    const stepInfo: OnboardingStepInfo = {
      onboardingId: stepDTO.onboardingId,
      type: stepDTO.type,
      logicType: stepDTO.logicType,
      order: stepDTO.order,
      subtitle: stepDTO.subtitle,
      title: stepDTO.title,
      content: stepDTO.content
    };

    return stepInfo;
  }
}
