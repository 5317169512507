import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OnboardingStepInfoDTO, OnboardingStepLogicType, OnboardingStepType } from 'src/app/pages/onboarding/onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class MockOnboardingService {

  constructor() { }

  public getOnboardingStep(): Observable<OnboardingStepInfoDTO> {
    /* return of({
      subtitle: 'COMPLETA O TEU REGISTO',
      title: 'Confirmas que esta é a tua função na Your?',

      type: OnboardingStepType.SIMPLE,
      content: {
        mainImageUrl: 'https://cdn-icons-png.flaticon.com/512/10161/10161084.png',
        mainImageTitle: 'Contabilista',
        simpleDescription: 'Caso detecte alguma irregularidade, por favor, não hesite em contactar a nossa equipa de suporte para obter assistência imediata: supportyourwinyouwin@outlook.com'
      }
    }); */

    /* return of({
      subtitle: 'COMPLETA O TEU REGISTO',
      title: 'Qual é o teu sonho?',
      type: OnboardingStepType.OPTION,
      content: {
        options: [
          {
            img: 'https://astelus.com/wp-content/viajes/guia-turistica-de-londres-inglaterra.jpg',
            title: 'Viagem a Londres - 5 dias para 2 pessoas (Tudo incluído).'
          },
          {
            img: 'https://www.apple.com/newsroom/images/product/iphone/geo/Apple-iPhone-14-iPhone-14-Plus-hero-220907-geo.jpg.og.jpg?202305251129',
            title: 'iPhone 14 128Gb',
          },
          {
            img: 'https://www.turbo.pt/wp-content/uploads/2022/06/Mercedes-AMG-GLE-Edition-55_003.jpg',
            title: 'Automóvel Mercedes-Benz GLE.',
          },
          {
            img: 'https://images.impresa.pt/sicnot/2022-08-05-Meo-Sudoeste-d1fee13a-1',
            title: 'Bilhete Geral MEO Sudoeste 2023.',
          },
          {
            img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
            title: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído).',
          },
          {
            img: 'https://www.razaoautomovel.com/wp-content/uploads/2021/12/smart_concept_1_2-1_925x520_acf_cropped-925x520.jpg',
            title: 'Automóvel SUV elétrico Smart #1.',
          }
        ]
      }
    }); */

    /* return of({
      subtitle: 'O TEU SONHO',
      title: 'Para atingires o teu sonho, precisas de juntar XXX Pinkcoins.',
      type: OnboardingStepType.GOAL_INFO,
      content: {
        banner: {
          title: 'Como?',
          description: 'Participa no crescimento da Your, vendendo serviços adicionais aos clientes que hoje já geres, ou trazendo novos clientes!',
          img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
          imgTitle: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído). '
        },
        activitiesSectionTitle: 'Exemplos de missões disponíveis',
        activitiesExamples: [{
          img: 'https://blog.hygia.com.br/wp-content/uploads/2021/02/compliance-na-gestao-da-saude-1.jpg',
          description: 'Vender serviços de segurança e saúde no trabalho para os colaboradores da EDP.',
          points: 500
        },
        {
          img: 'https://static.cobrefacil.com.br/assets/base/4fa/7c6/253/departamento-financeiro.jpg',
          description: 'Vender serviços de contabilidade para o departamento financeiro.',
          points: 9500
        },
        {
          img: 'https://www.redebrasilatual.com.br/wp-content/uploads/2020/07/acidente27.jpeg',
          description: 'Vender seguro de acidentes de trabalho para trabalhadores.',
          points: 500
        }],
        finalTitle: 'Estás pronto para começar?'
      }
    }); */

    return of(
      {
        onboardingId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
        order: 0,
        subtitle: 'COMPLETA O TEU REGISTO',
        title: 'Confirmas que esta é a tua função na Your?',

        type: OnboardingStepType.CONFIRMATION,
        logicType: OnboardingStepLogicType.JOB_TITLE,
        content: {
          mainImageUrl: 'https://gamifyou-assets-dev.s3.eu-west-1.amazonaws.com/YOUR/your/temp/contabilista.png',
          mainImageTitle: 'Contabilista',
          simpleDescription: 'Caso detectes alguma irregularidade, por favor, não hesites em contactar a nossa equipa de suporte para obter assistência imediata: support@gamifyou.com'
        }
      },
      /* {
        onboardingId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
        order: 1,
        subtitle: 'COMPLETA O TEU REGISTO',
        title: 'Qual é o teu sonho?',
        type: OnboardingStepType.MULTIPLE_CHOICE,
        logicType: OnboardingStepLogicType.GOAL_DEFINITION,
        content: {
          options: [
            {
              imgUrl: 'https://astelus.com/wp-content/viajes/guia-turistica-de-londres-inglaterra.jpg',
              title: 'Viagem a Londres - 5 dias para 2 pessoas (Tudo incluído).'
            },
            {
              imgUrl: 'https://www.apple.com/newsroom/images/product/iphone/geo/Apple-iPhone-14-iPhone-14-Plus-hero-220907-geo.jpg.og.jpg?202305251129',
              title: 'iPhone 14 128Gb',
            },
            {
              imgUrl: 'https://www.turbo.pt/wp-content/uploads/2022/06/Mercedes-AMG-GLE-Edition-55_003.jpg',
              title: 'Automóvel Mercedes-Benz GLE.',
            },
            {
              imgUrl: 'https://images.impresa.pt/sicnot/2022-08-05-Meo-Sudoeste-d1fee13a-1',
              title: 'Bilhete Geral MEO Sudoeste 2023.',
            },
            {
              imgUrl: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
              title: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído).',
            },
            {
              imgUrl: 'https://www.razaoautomovel.com/wp-content/uploads/2021/12/smart_concept_1_2-1_925x520_acf_cropped-925x520.jpg',
              title: 'Automóvel SUV elétrico Smart #1.',
            }
          ]
        }
      },
      {
        onboardingId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
        order: 2,
        subtitle: 'O TEU SONHO',
        title: 'Para atingires o teu sonho, precisas de juntar XXX Pinkcoins.',
        type: OnboardingStepType.INFORMATIVE,
        logicType: OnboardingStepLogicType.ACTIVITIES,
        content: {
          banner: {
            title: 'Como?',
            description: 'Participa no crescimento da Your, vendendo serviços adicionais aos clientes que hoje já geres, ou trazendo novos clientes!',
            img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
            imgTitle: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído). '
          },
          activitiesSectionTitle: 'Exemplos de missões disponíveis',
          activitiesExamples: [{
            img: 'https://blog.hygia.com.br/wp-content/uploads/2021/02/compliance-na-gestao-da-saude-1.jpg',
            description: 'Vender serviços de segurança e saúde no trabalho para os colaboradores da EDP.',
            points: 500
          },
          {
            img: 'https://static.cobrefacil.com.br/assets/base/4fa/7c6/253/departamento-financeiro.jpg',
            description: 'Vender serviços de contabilidade para o departamento financeiro.',
            points: 9500
          },
          {
            img: 'https://www.redebrasilatual.com.br/wp-content/uploads/2020/07/acidente27.jpeg',
            description: 'Vender seguro de acidentes de trabalho para trabalhadores.',
            points: 500
          }],
          finalTitle: 'Estás pronto para começar?'
        }
      } */
    );
  }

  public getOnboardingList(): Observable<Array<OnboardingStepInfoDTO>> {
    /* return of({
      subtitle: 'COMPLETA O TEU REGISTO',
      title: 'Confirmas que esta é a tua função na Your?',

      type: OnboardingStepType.SIMPLE,
      content: {
        mainImageUrl: 'https://cdn-icons-png.flaticon.com/512/10161/10161084.png',
        mainImageTitle: 'Contabilista',
        simpleDescription: 'Caso detecte alguma irregularidade, por favor, não hesite em contactar a nossa equipa de suporte para obter assistência imediata: supportyourwinyouwin@outlook.com'
      }
    }); */

    /* return of({
      subtitle: 'COMPLETA O TEU REGISTO',
      title: 'Qual é o teu sonho?',
      type: OnboardingStepType.OPTION,
      content: {
        options: [
          {
            img: 'https://astelus.com/wp-content/viajes/guia-turistica-de-londres-inglaterra.jpg',
            title: 'Viagem a Londres - 5 dias para 2 pessoas (Tudo incluído).'
          },
          {
            img: 'https://www.apple.com/newsroom/images/product/iphone/geo/Apple-iPhone-14-iPhone-14-Plus-hero-220907-geo.jpg.og.jpg?202305251129',
            title: 'iPhone 14 128Gb',
          },
          {
            img: 'https://www.turbo.pt/wp-content/uploads/2022/06/Mercedes-AMG-GLE-Edition-55_003.jpg',
            title: 'Automóvel Mercedes-Benz GLE.',
          },
          {
            img: 'https://images.impresa.pt/sicnot/2022-08-05-Meo-Sudoeste-d1fee13a-1',
            title: 'Bilhete Geral MEO Sudoeste 2023.',
          },
          {
            img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
            title: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído).',
          },
          {
            img: 'https://www.razaoautomovel.com/wp-content/uploads/2021/12/smart_concept_1_2-1_925x520_acf_cropped-925x520.jpg',
            title: 'Automóvel SUV elétrico Smart #1.',
          }
        ]
      }
    }); */

    /* return of({
      subtitle: 'O TEU SONHO',
      title: 'Para atingires o teu sonho, precisas de juntar XXX Pinkcoins.',
      type: OnboardingStepType.GOAL_INFO,
      content: {
        banner: {
          title: 'Como?',
          description: 'Participa no crescimento da Your, vendendo serviços adicionais aos clientes que hoje já geres, ou trazendo novos clientes!',
          img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
          imgTitle: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído). '
        },
        activitiesSectionTitle: 'Exemplos de missões disponíveis',
        activitiesExamples: [{
          img: 'https://blog.hygia.com.br/wp-content/uploads/2021/02/compliance-na-gestao-da-saude-1.jpg',
          description: 'Vender serviços de segurança e saúde no trabalho para os colaboradores da EDP.',
          points: 500
        },
        {
          img: 'https://static.cobrefacil.com.br/assets/base/4fa/7c6/253/departamento-financeiro.jpg',
          description: 'Vender serviços de contabilidade para o departamento financeiro.',
          points: 9500
        },
        {
          img: 'https://www.redebrasilatual.com.br/wp-content/uploads/2020/07/acidente27.jpeg',
          description: 'Vender seguro de acidentes de trabalho para trabalhadores.',
          points: 500
        }],
        finalTitle: 'Estás pronto para começar?'
      }
    }); */

    return of(
      [{
        onboardingId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
        order: 0,
        subtitle: 'COMPLETA O TEU REGISTO',
        title: 'Confirmas que esta é a tua função na Your?',

        type: OnboardingStepType.CONFIRMATION,
        logicType: OnboardingStepLogicType.JOB_TITLE,
        content: {
          mainImageUrl: 'https://gamifyou-assets-dev.s3.eu-west-1.amazonaws.com/YOUR/your/temp/contabilista.png',
          mainImageTitle: 'Contabilista',
          simpleDescription: 'Caso detectes alguma irregularidade, por favor, não hesites em contactar a nossa equipa de suporte para obter assistência imediata: support@gamifyou.com'
        }
      }]
    );
  }

  public submitStep(): Observable<string> {
    return of('');
  }
}
