import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './numeric-input.component.sass',
  ]
})
export class NumericInputComponent extends InputBaseComponent implements OnInit {

  @Input() minValue: number;
  @Input() maxValue: number;

  @Input() allowNegative: boolean;
  @Input() allowDecimals: boolean;

  showError: boolean;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

      super.setCSSVars();
      super.setSpecificCSSVars();
  }

  /**
   * Blocks non-numeric input based on a pattern.
   * @param event The keyboard event object.
   * @param allowNegative Flag indicating whether negative numbers are allowed.
   * @param allowDecimals Flag indicating whether decimal numbers are allowed.
   * @param minValue The minimum allowed value.
   * @param maxValue The maximum allowed value.
   * @returns A boolean indicating whether the input is valid.
 */
  public blockNonNumbers(event: KeyboardEvent, allowNegative: boolean = false, allowDecimals: boolean = false, minValue: number = -9999999, maxValue: number = 9999999): boolean {
    const key = event.key;
    const currentTarget = (event.target as HTMLInputElement);
    const currentValue: string = currentTarget.value;
    const selectionStart: number = currentTarget.selectionStart!;
    const selectionEnd: number = currentTarget.selectionEnd!;

    // Allows the key combination of CTRL + A to select all the text
    if(event.ctrlKey || event.key === 'a') {
      return true;
    }

    // Declaration of the start of the regex pattern
    let pattern = '^(';

    // If allow negative is true we make the pattern start with the negative sign, if not we remove it
    if (allowNegative) {
      pattern += '\-?[0-9]*';
    } else {
      pattern += '[0-9]*';
    }

    /*
      If allowDecimals is true a regex for decimal places is created
      maxDigits will allow us to define how many digits we want to have after the decimal places this will ensure that we are able to match our max value properly
    */
    if (allowDecimals) {
      pattern += `(?:[.,][0-9]*)?`
    }

    // Closing of the regex pattern
    pattern += ')$';

    const validInputPattern = new RegExp(pattern);

    // Check if the input value is within the specified range
    const newValue = currentValue.slice(0, selectionStart) + key + currentValue.slice(selectionEnd);

    // Convert the value to a float
    const numericValue = parseFloat(newValue);


    // Allow entering the negative sign at the start
    if (newValue === '-' && allowNegative) {
      return true;
    }


    // Allow Backspace key when text is selected or no text is selected
    if (key === 'Backspace' && (selectionStart !== selectionEnd || selectionStart > 0)) {
      return true;
    }

    // Validate the numeric value against the specified range
    const isValid = !isNaN(numericValue) && numericValue >= minValue && numericValue <= maxValue;

    // Prevent any other input by canceling the event
    if (!isValid) {
      event.preventDefault();
    }

    // Will return true or false depending on the patternTest and if we are typing numeric values.
    return validInputPattern.test(newValue) && isValid;
  }

  /**
   * Removes invalid characters from the input value based on a pattern.
   * @param input The event object associated with the input event.
   * @param maxValue The maximum allowed value.
   * @param allowNegative Flag indicating whether negative numbers are allowed.
   * @param allowDecimals Flag indicating whether decimal numbers are allowed.
 */
  public removeFromInput(input: Event, allowNegative: boolean = false, allowDecimals: boolean = false, maxValue: number = 9999999): void {
    // Declaration of the start of the regex pattern
    let pattern = '^(';

    // If allow negative is true we make the pattern start with the negative sign, if not we remove it
    if (allowNegative) {
      pattern += '\-?[0-9]*';
    } else {
      pattern += '[0-9]*';
    }

    /*
      If allowDecimals is true a regex for decimal places is created
      maxDigits will allow us to define how many digits we want to have after the decimal places this will ensure that we are able to match our max value properly
    */
    if (allowDecimals) {
      const maxDigits = Math.floor(Math.log10(Math.abs(maxValue)));
      pattern += `(?:[.,][0-9]{0\,${maxDigits}})?`
    }

    // Closing of the regex pattern
    pattern += ')$';

    // Declaration of the input itself since we can't access target directly
    const inputTarget = (input.target as HTMLInputElement);
    // current value of the input
    const inputValue = inputTarget.value;

    // Generation of the regexPattern using the string created before.
    const validInputPattern = new RegExp(pattern);

    /*
      This part will split the current values into a new array,
      after we declare a new empty array to store all the valid values
      we then loop through the values we have and match/test them agains our pattern
      if they match the pattern we add them to the validValues array
      when the loop finishes we join all the values and update the input value with the validValues removing anything the user tries to add
      this way the input only accept digits, decimal places and negative signs.
    */
    const values = inputValue.split('');
    const validValues = [];

    for (const value of values) {
      if (validInputPattern.test(value)) {
        validValues.push(value);
      }
    }

    const validString = validValues.join('');
    inputTarget.value = validString;
  }
}
