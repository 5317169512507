import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { ListBaseComponent } from '../../_extended-components/list-base-component/list-base.component';
import { ActionId, ItemState, ListItem } from '../list-item.model';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    '../../_extended-components/list-base-component/list-base.component.sass',
    './list-item.component.sass'
  ]
})
export class ListItemComponent extends ListBaseComponent implements OnInit, AfterViewInit {

  @Input() item: ListItem;

  @Input() fullSize: boolean;
  @Input() itemMargin: string = '10px 10px 10px 13px';

  @Output() itemEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() actionEvent: EventEmitter<number> = new EventEmitter<number>();

  itemState = ItemState; //This enum allows the verification of the State if it's active, archived etc.
  ActionId = ActionId; // This enum allows the verification of the multi function button type if it's a toggle for example.

  actionActive: boolean = false;
  enableClick: boolean = true;

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngAfterViewInit(): void {
    this.enableDragOrClick();
  }

  // Click event to handle the emit of the item.
  itemClicked(): void {
    if(!this.item.notClickable) {
      if(this.enableClick) {
        this.item.selected = true;
        this.itemEvent.emit(this.item.id);
      }
    }
  }

  //Click event for the multi function button
  actionClicked(actionId: number): void {
    if (actionId !== null) {
      if(actionId === ActionId.visibility) {
        this.actionActive = !this.actionActive;
      }
      this.actionEvent.emit(actionId);
    }
  }

  //This function will allow each list item to have its content selectable without firing the click event.
  enableDragOrClick(): void {
    const element = this.host.nativeElement.firstChild;
    const delta = 6;
    let startX: number;
    let startY: number;

    element.addEventListener('mousedown', (event: MouseEvent) => {
      startX = event.pageX;
      startY = event.pageY;
    });

    element.addEventListener('mouseup', (event: MouseEvent) => {
      const diffX = Math.abs(event.pageX - startX);
      const diffY = Math.abs(event.pageY - startY);

      if (diffX < delta && diffY < delta) {
        this.enableClick = true;
      } else {
        this.enableClick = false;
      }
    });
  }
}
