import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/_generic-components-lib/inputs/dropdowns-module/dropdown.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { Chip } from '../../_shared-components/chips-dropdown-module/chips-dropdown.model';
import { Category } from '../../_shared-components/list-item-cards/cardItem.model';
import { PartnersService } from '../_services/partners.service';
import { Partner, PartnerFilterGroup, PartnerFilterItem } from '../partners.model';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.sass'],
  animations: [
    trigger('Slide', [
      state('hide', style({
        height: '0px',
      })),
      state('show', style({
       height: '*',
       overflow: 'visible'
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ])
  ]
})
export class PartnersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('partnerContainerList', { static: false }) public partnerListContainerRef: ElementRef;

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;
  public isMobile: boolean = false;
  public isButtonMobileActive: boolean;

  public searchString: string;
  public partnerSearch: string;
  public animationState: string;

  public listPercentageFromBottom: number = 0;

  private queryParameters: Params = {};

  public currentUrl: string;

  private destroy: Subject<boolean> = new Subject<boolean>()

  public categorySelectedOptions: Array<Chip>;
  public selectedFilters: Array<PartnerFilterGroup> = [];
  public partnerList: Array<Partner> = [];
  public categoryList: Array<Category> = [];
  public loadingListItems: Array<number> = [1, 2, 3, 4, 5, 6];
  public districtList: Array<DropdownOption> = [];
  public loggedIn: boolean;

  public environment = environment;

  constructor(
    private generalService: GeneralService,
    public partnersService: PartnersService,
    public mainComponent: AppComponent,
    private keycloakService: KeycloakService,
    public labelService: LabelsService,
    private route: ActivatedRoute,
    private translateService: TranslateService
    ) {
      const sessionLanguage = localStorage.getItem('SessionLanguageCode');

      this.translateService.currentLang = environment.defaultLanguage;
      this.translateService.setDefaultLang(environment.defaultLanguage);
      this.translateService.use(environment.defaultLanguage);
    }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(async isLoggedIn => this.loggedIn = isLoggedIn);
    this.route.url.pipe(takeUntil(this.destroy)).subscribe((resp: Array<UrlSegment>) => {this.currentUrl = resp[0].path;});
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParameters = params
    });

    this.generalService.isMobile.pipe(takeUntil(this.destroy)).subscribe((resp: boolean) => {this.isMobile = resp});

    this.generalService.asyncFunction(() => {this.animationState = 'hide'}, 0);

    this.searchString = this.queryParameters['search'];

    this.isListLoading = true;
    this.getItems(1, this.queryParameters);
    this.partnersService.getEndOfScrollReached().subscribe(() => {
      if (!this.isListLoading) {
        this.isListLoading = true;
        this.getMoreItems(this.queryParameters);
      }
    });

    /* this.partnersService.getCategories().subscribe((resp: Array<Category>) => {
      this.categoryList.push(...resp);
      this.selectedFilters.push({
        queryParameter: 'categories',
        items: [...resp]
      }); */
      this.updateFilters(this.queryParameters);
      this.isLoaded = true;
    /* }); */
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('institutions');
  }

  public search(): void {
    this.updateQueryParameters();
  }

  public toggleFilters(): void {
    if(this.isMobile) {
      this.isButtonMobileActive = !this.isButtonMobileActive;
    }

    this.generalService.asyncFunction(() => {this.animationState = this.animationState === 'hide' ? 'show' : 'hide';}, 300);
  }

  public submitFilter(): void {
    this.getItems(1, this.queryParameters);
  }

  public onScroll(): void {
    if(this.partnersService.infiniteScrollActive) {
      this.partnersService.getEndOfScrollReached().next(true);
    }
  }

     /**
   * This function receives the queryParameters and will push a set amount of items into the loadingList array,
   * this array will show up on the List of institutions to show the user something is loading
   * after it will fetch a new page from the getPartnersList function and show them.
   *
   * @param params - Parameter of type Params this is all the queryParameters in the url.
   */
     private getMoreItems(params: Params = {}): void {
      if(this.partnersService.totalPages > this.partnersService.currentPageIndex) {
        this.partnersService.currentPageIndex++;
        this.getItems(this.partnersService.currentPageIndex, params);
      }
    }

    private getItems(page: number, params: Params = {}):void {
      if(!this.isListLoading) {
        this.isListLoading = true;
      }

      this.partnersService.getPartnersList(page, params).subscribe({
        next: list => {
          if(this.partnersService.currentPageIndex > 1 && this.partnerList) {
            this.partnerList = this.partnerList.concat(list);
          } else {
            this.partnerList = list;
          }

          this.generalService.asyncFunction(() => {
            this.getListPercentageFromBottom();
          }, 0);
          this.isListLoading = false;
        },
        error: error => {
          this.isListLoading = false;
        }
      });
    }

   /**
   * This function receives the queryParameters and loop through all, while looping it will hold the group of filters
   * and if the group exists it will trigger the updateFilter function that will update all the filtes.
   *
   * @param params - Parameter of type Params this is all the queryParameters in the url.
   */
  public updateFilters(params: Params): void {
    Object.keys(params).forEach((key: string) => {
      let group = this.selectedFilters.find(filter => filter.queryParameter === key);

      if(group){
        this.updateFilter(group, params, key);
      }
    });
  }

   /**
   * This function will run everytime we select a category from the filter, the filter will emit an array of selected categories.
   * we wipe the selectedFilters Array that we will use to hold all the categories selected
   * we then fill the selectedFilters and also run the updateQueryParameters function
   * which will update the queryParameters on the url and get from BE a new list of items that correspond to the filters.
   *
   * @param categories - Parameter of type Array<Category> this is the array of selectedCategories.
   */
  public getSelectedCategories(categories: Array<Category>): void {
    this.selectedFilters = [];

    this.selectedFilters.push({
      queryParameter: 'categories',
      items: categories
    });

    this.updateQueryParameters();
  }

   /**
   * This function will create an array from the queryParameters and will set the selected categories based on the queryParameters.
   *
   * @param group - Parameter of type PartnerFilterGroup this is the array of selectedFilters (search is not included)
   * @param params - QueryParameters on the url.
   * @param key - key of the query paramter to search for.
   */
  private updateFilter(group: PartnerFilterGroup, params: Params, key: string): void {
    let values = params[key].split(',');

    values.forEach((value: string) => {
      const groupItem = group.items.find((p: PartnerFilterItem) => p.id === value);

      if(groupItem) {
        groupItem.isSelected = true;
        this.categoryList.find(category => category.id === groupItem.id)!.isSelected = true;
      }
    });
  }

   /**
   * This function will update the queryParameters on the url and call the the list of institutions based on the parameters.
   */
  private updateQueryParameters(): void {
    let queryParams: Params = {};
    this.selectedFilters.forEach(category => {
      if(category) {
        queryParams['categories'] = category.items.map(item => item.id).toString();
      }
    });

    this.updateSearchParam(queryParams);

    this.getMoreItems(queryParams);
    this.generalService.navigateToWithQueryParams('/institutions', queryParams);
  }


   /**
   * Will add or remove the search queryParameter from the url based on the search.
   *
   * @param params - QueryParameters on the url
   */
  private updateSearchParam(params: Params): void {

    if('search' in this.queryParameters) {
      params['search'] = this.queryParameters['search'];
    }

    if(this.searchString) {
      params['search'] = this.searchString;
    } else {
      delete params['search'];
    }
  }

  private getListPercentageFromBottom(): void {

    let listPos = this.partnerListContainerRef.nativeElement.offsetTop;
    let listHeight = this.partnerListContainerRef.nativeElement.clientHeight;
    let totalPageHeight = this.mainComponent.scrollableContainerRef.nativeElement.scrollHeight; // page height with scroll

    let percentageFromBottom = (Math.abs(listPos) + listHeight) / totalPageHeight * 100;

    this.listPercentageFromBottom = (100 - percentageFromBottom) / 10 - 0.1; // We divide by 10 to get the distance of the list from the bottom of page with a small margin of 0.5 which can be adjusted.
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
