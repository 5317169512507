<div class="steps-label" *ngIf="showLabel">{{ labelsService.onboardingStepsLabel | label: { currentStep: currentStep + 1, totalSteps: totalSteps } }}</div>

<div class="steps-container">
  <ng-container *ngFor="let step of stepsList; let index = index;">
    <span class="pre-step-line" *ngIf="index > 0" [ngClass]="{'active': index <= currentStep }"></span>

    <div class="step" [ngClass]="{'active': index <= currentStep }">
      {{ index + 1 }}
    </div>
  </ng-container>
</div>
