import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryService } from '../__services/library.service';
import { BaseComponent } from './base-component/base.component';



@NgModule({
  declarations: [
    BaseComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [LibraryService]
})
export class BaseComponentModule { }
