import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() imageUrl: string;
  @Input() imageDefaultColor: string;
  @Input() imageDisabledColor: string;
  @Input() currentPageIndex: number;
  @Input() maxPageSize: number;
  @Input() morePagesAvailable: boolean;

  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  isDisabledRight: boolean = false;
  isDisabledLeft: boolean = false;

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    this.isDisabledLeft = this.currentPageIndex === 1;
    this.isDisabledRight = !this.morePagesAvailable;

    this.setCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['currentPageIndex']) {
      this.isDisabledLeft = changes['currentPageIndex'].currentValue === 1;
      this.isDisabledRight = !this.morePagesAvailable;
    }
  }

  movePage(page: number): void {
    if(page > 0) {
      page = this.currentPageIndex += 1;
    } else {
      page = this.currentPageIndex -= 1;
    }

    this.changePage.emit(page);
  }

  setCSSVars(): void {
    if(this.imageUrl || this.imageDefaultColor) {
      if(this.imageUrl){
        this.host.nativeElement.style.setProperty('--image-url', `url(${this.imageUrl})`);
      }

      if(this.imageDefaultColor) {
        this.host.nativeElement.style.setProperty('--image-default-color', this.imageDefaultColor);
      }

      if(this.imageDisabledColor) {
        this.host.nativeElement.style.setProperty('--image-disabled-color', this.imageDisabledColor);
      }
    }
  }
}
