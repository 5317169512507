import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LanguagesService } from 'src/app/_services/_languages/languages.service';
import { environment } from 'src/environments/environment';
import { BlogService } from '../_services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { Article } from '../blog.model';
@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.sass']
})
export class BlogContentComponent implements OnInit {

  public environment = environment;
  private articleId: string;

  public article: Article;

  constructor(
    public languageService: LanguagesService,
    private translateService: TranslateService,
    public labelsService: LabelsService,
    private blogService: BlogService,
    private route: ActivatedRoute,
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = this.environment.defaultLanguage;
    this.translateService.setDefaultLang(this.environment.defaultLanguage);
    this.translateService.use(this.environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.articleId = params['id'];
      this.blogService.getArticleDetail(this.articleId).pipe(take(1)).subscribe(resp => {
        this.article = resp;
      });
    });
  }

}
