<ng-container *ngIf="fCName == null; else formInput">
  <div class="input-container w-100">
    <label *ngIf="label" [ngClass]="{'mb-1': !labelMargin}" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
    <div class="input-wrapper position-relative w-100">
      <input type="text" class="w-100 ps-3 pe-3" [ngClass]="{'ps-5': alignLeft}"
        placeholder="{{ placeholderLabel? (placeholderLabel ) : ''}}" [debounceTime]="debounceTime"
        [(ngModel)]="inputValue" (onDebounce)="onValueChange($event)" (keydown)="checkKeyPressed($event)">

      <ng-container *ngIf="hasSuffix">
        <div class="suffix" [ngClass]="{'alignLeft': alignLeft, 'active': isSuffixActive && !isSuffixIcon}" (click)="suffixToggled()">
          <i class="suffix-image" [ngClass]="suffixClassName" *ngIf="isSuffixIcon; else textSuffix"></i>

          <ng-template #textSuffix>
            <span>{{(isSuffixActive ? inactiveSuffixLabel : activeSuffixLabel) }}</span>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- ######################## FORM ######################## -->
<ng-template #formInput>
  <form [formGroup]="form">
    <div class="input-container position-relative w-100">
      <label *ngIf="label" [ngClass]="{
        'error': form.controls[fCName].touched && !form.controls[fCName].valid,
        'mb-1': !labelMargin
      }"
      [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>

      <div class="input-wrapper position-relative w-100">
        <input type="text" class="w-100 ps-3 pe-3"
          [type]="!isPassword ? 'text' : hideValue ? 'password' : 'text'"
          [ngClass]="{
            'error': form.controls[fCName].touched && !form.controls[fCName].valid,
            'password': isPassword, 'ps-5': alignLeft
          }"
          formControlName="{{fCName}}"
          placeholder="{{ placeholderLabel? (placeholderLabel ) : ''}}"
          (keydown)="checkKeyPressed($event)" >

        <ng-container *ngIf="hasSuffix">
          <div class="suffix" [ngClass]="{'alignLeft': alignLeft, 'active': isSuffixActive && !isSuffixIcon}" [ngStyle]="{'left': alignLeft ? '5px' : ''}" (click)="suffixToggled()">
            <i class="suffix-image" [ngClass]="suffixClassName" *ngIf="isSuffixIcon; else textSuffix"></i>

            <ng-template #textSuffix>
              <span>{{(isSuffixActive ? inactiveSuffixLabel : activeSuffixLabel) }}</span>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>
