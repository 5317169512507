<div class="product-info mt-4 mb-5">
  <div class="product-info-title-container">
    <span>{{ product.name }}</span>
  </div>

  <div class="product-info-table">
    <div class="collection-container" *ngIf="product.collection">
      <p class="info-table-header">{{labelService.productDetailsCollectionTitle | label}}</p>

      <p *ngIf="product.collection" class="collection-name">{{ product.collection.name }}</p>


      <!-- <div class="available-collectibles-container"> //TODO uncomment when needed
        <div class="label">{{ labelService.productDetailsCollectableTitle | label }}</div>
        <div class="value">{{ product.quantity + ' ' + (labelService.productDetailsSale | label) }}</div>
      </div> -->
    </div>
    <div class="collectible-detail-container">
      <p class="info-table-header">{{labelService.productDetailsInfoTitle | label}}</p>

      <div class="serial-number-container">
        <div class="label">{{ labelService.productDetailsSerial | label }}</div>
        <div class="value">{{ product.serial}}</div>
      </div>

      <div class="quantity-container" *ngIf="product.quantity && product.quantity > 0">
        <div class="value">
         {{ (product.quantity | i18nPlural: generalService.getPluralKey('product_quantity')) | translate: {n: product.quantity} }}
        </div>
      </div>

      <div class="date-container" *ngIf="product.endDate">
        {{ (labelService.productDetailsDate | label) + (product.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase)}}
      </div>
    </div>
    <div class="partner-container" *ngIf="partner">
      <p class="info-table-header">{{labelService.productDetailsInstitution | label}}</p>

      <div class="partner-info-container">
        <img [src]="partner.image" [alt]="partner.name" class="partner-img">
        <div class="partner-name">{{ partner.name }}</div>
      </div>
    </div>
  </div>

  <div class="product-price-details-container mt-3 mb-3" *ngIf="product.prices.length > 1">
    <p class="price-header">{{labelService.productDetailsPrice | label}}</p>

    <div class="price-container">
      <ng-container *ngIf="product.prices">
        <ng-container *ngFor="let currency of product.prices">
          <span class="product-value" *ngIf="currency.selected">
            <span>{{ currency.amount }}</span>
            <ng-container [ngSwitch]="currency.unit">
              <span class="currency-symbol" *ngSwitchCase="'EUR'">
                €
              </span>
              <div class="ethereum currency-symbol" *ngSwitchCase="'ETH'">
              </div>
              <span class="currency-name" *ngSwitchDefault>
                &nbsp;{{currency.unit}}
              </span>
            </ng-container>
          </span>
        </ng-container>
      </ng-container>

      <div class="currencies-container" *ngIf="product.prices && product.prices.length > 1">
        <div class="currency-container" *ngFor="let currency of product.prices" (click)="selectCurrencyToShow(currency)" [ngClass]="{'selected': currency.selected}">
          <!-- currency button -->
          <ng-container [ngSwitch]="currency.unit">
            <ng-container *ngSwitchCase="'EUR'">
              €
            </ng-container>
            <div class="ethereum crypto-icon" *ngSwitchCase="'ETH'">
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="product-info-text w-100" *ngIf="product.description">
    <span class="title">{{labelService.productDetailsDescription | label}}</span>
    <div class="description">{{ product.description }}</div>
  </div>

  <div class="product-properties-wrapper w-100 mt-4" *ngIf="product.traits && product.traits.length > 0">
    <p class="title">{{labelService.productDetailsProperties | label}}</p>

    <div class="properties-list-container">
      <div class="property-container" *ngFor="let property of product.traits">
        <span class="property-name">{{ property.name }}</span>
        <br>
        <span class="property-value">{{ property.value }}</span>
      </div>
    </div>
  </div>

  <!-- <div class="product-tags w-100 mt-4" *ngIf="product.categories">
    <p class="title">Tags</p>
    <span class="tags-container" *ngFor="let tag of product.categories">
      {{ tag.name }}
    </span>
  </div> -->
</div>

<div class="product-info-button-container" *ngIf="product.quantity! > 0">
  <div class="purchase-button button-shadow" role="button" (click)="goToPaymentPage()">{{labelService.productDetailsBuyButton | label}}</div>
</div>
