import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, map, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CarouselContent, ContactDTO, FiscalAddress, GenericErrorReply, MediumType } from '../../../../_services/_general-service/general-service.model';
import { GeneralService } from '../../../../_services/_general-service/general.service';
import { ApiService } from '../../../../_services/api.service';
import { Collection, CollectionDTO, ContentType, ContentTypeEnum, ImageContent, ImageContentDTO, Product, ProductDTO, ProductPartner, ProductPartnerDTO, ProductType, ProductTypeEnum } from './products.service.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  endOfScrollReached$ = new Subject<boolean>();
  infiniteScrollActive:boolean = false;

  currentPageIndex: number = 0;
  pageSize: number = 16;
  totalPages: number = 1;

  productInfo: Product;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService) { }

  public getProductList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<Product>> {
    const currentParams: Params = {
      ...params,
      pageSize: pageSize ? pageSize : this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`product-catalog/public/product`, currentParams, '1.0', false, environment.useMockedData.marketplaceProductsGetList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      // map((response: GetProductListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
        map((response: Array<ProductDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetProductListReply;
          response = (response ? response  : []) as Array<ProductDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const productListDTO = response.items;
          const productListDTO = response;
          const productList = this.getProductListFromDTO(productListDTO);
          return productList;
        } else {
          throw response;
        }
    }));
  }

  public getProductListFromDTO(dto: Array<ProductDTO>): Array<Product> {
    let productList: Array<Product> = [];

    dto.forEach((productDto: ProductDTO) => {
      productList.push({
        id: productDto.productId,
        tenantId: productDto.tenantId,
        name: productDto.name,
        description: productDto.description,
        images: productDto.images ? this.getProductImageContentFromDTO(productDto.images) : [],
        type: ProductTypeEnum[productDto.type as ProductType],
        endDate: productDto.endDate,
        serial: productDto.serial,
        prices: productDto.prices,
        partner: productDto.partner,
        lowestPrice: productDto.lowestPrice,
        quantity: productDto.quantity,
        quantityReserved: productDto.quantityReserved,
        totalPurchased: productDto.totalPurchased,
        purchaseLimit: productDto.purchaseLimit,
        categories: productDto.categories ? productDto.categories : [],
        traits: productDto.traits,
        collection: productDto.collection ? this.getCollectionFromDTO(productDto.collection) : undefined,
        isBlocked: productDto.isBlocked
      });
    });

    return productList;
  }

  private getProductImageContentFromDTO(imagesDto: Array<ImageContentDTO>): Array<ImageContent> {
    let images: Array<ImageContent> = [];

    imagesDto.forEach(dto => {
      images.push({
        href: dto.href,
        content: dto.content ? dto.content : '',
        type: ContentTypeEnum[dto.type as ContentType],
      });
    });

    return images;
  }

  public getProductDetail(productId: string): Observable<Product> {
    return this.apiService.get(`product-catalog/public/product/${productId}`, {}, '1.0', true, environment.useMockedData.marketplaceProductsGet, productId).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: ProductDTO | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const productDetailDTO = (response as ProductDTO);
          const productDetail = this.getProductDetailFromDTO(productDetailDTO);

          productDetail.prices[0].selected = true;

          return productDetail;
        } else {
          throw response;
        }
      }
    ));
  }

  public getRelatedProductList(params: Params): Observable<Array<Product>> {
    const parameters = {
      ...params,
      pageSize: 4,
      page: 1,
    };
    return this.apiService.get(`product-catalog/public/product`, parameters, '1.0', false, environment.useMockedData.marketplaceProductsGetRelatedList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      // map((response: GetProductListReply | GenericErrorReply) => { // TODO uncomment all comments when pagination is implemented
      map((response: Array<ProductDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // const productListDTO = (response as GetProductListReply);
          const productListDTO = (response as Array<ProductDTO>);
          // const productList = this.getProductListFromDTO(productListDTO.items);
          const productList = this.getProductListFromDTO(productListDTO);

          return productList;
        } else {
          throw response;
        }
    }));
  }

  private getProductDetailFromDTO(dto: ProductDTO): Product {
    let productDetail: Product;

    productDetail = {
      id: dto.productId,
      tenantId: dto.tenantId,
      name: dto.name,
      description: dto.description,
      endDate: dto.endDate,
      images: dto.images ? this.getProductImageContentFromDTO(dto.images) : [],
      serial: dto.serial,
      type: ProductTypeEnum[dto.type as ProductType],
      prices: dto.prices,
      lowestPrice: dto.lowestPrice,
      quantity: dto.quantity,
      quantityReserved: dto.quantityReserved,
      totalPurchased: dto.totalPurchased,
      purchaseLimit: dto.purchaseLimit,
      categories: dto.categories ? dto.categories : [],
      traits: dto.traits,
      collection: dto.collection ? this.getCollectionFromDTO(dto.collection) : undefined,
      partner: dto.partner ? this.getPartnerFromDTO(dto.partner) : undefined,
      locations: dto.locations ? dto.locations : [], // this.getAddressesFromDTO(dto.locations) : [],
      redeemSteps: dto.redeemSteps
    }

    return productDetail;
  }

  private getCollectionFromDTO(dto: CollectionDTO): Collection {
    return {
      id: dto.id,
      name: dto.name
    }
  }

  private getPartnerFromDTO(dto: ProductPartnerDTO): ProductPartner {
    return {
      id: dto.id,
      name: dto.name,
      image: dto.image
    };
  }

  private getAddressesFromDTO(dto: Array<ContactDTO>) : Array<FiscalAddress> {
    const fiscalAddresses: Array<FiscalAddress> = [];

    dto.forEach(contact => {
      if (contact.mediumType === MediumType.fiscalAddress) {
        fiscalAddresses.push(contact.characteristic as FiscalAddress);
      }
    });

    return fiscalAddresses;
  }

  public carouselContentFromProducts(products: Array<Product>): Array<CarouselContent> {
    let carouselContent: Array<CarouselContent> = [];

    products.forEach(product => {
      carouselContent.push({
        id: product.id,
        title: product.name,
        imageUrl: product.images && product.images.length > 0 ? product.images[0].href : `assets/imgs/environments/${environment.tenantName}/default-images/default-image-small-rectangle.svg`,
        description: product.description,
        iconUrl: 'assets/imgs/arrow-right-btn.svg'
      });
    });

    return carouselContent;
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
