import { Injectable } from '@angular/core';
import { OurProductsInfo } from 'src/app/pages/our-products/our-products.model';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MockOurProductsService {

  constructor() { }

  getProductsList(): Observable<OurProductsInfo> {
    return of({
      introBanners: [{
        titleAboveBanner: undefined,

        bannerImageUrl: 'assets/mock/environments/Gamifyou/our-products/_Intro_Banners/main_banner_image_1.png', // TODO
        bannerTitleImageUrl: 'assets/mock/environments/Gamifyou/our-products/_Intro_Banners/main_logo.png', // TODO
        bannerTitle: 'Our product',
        bannerSubTitle: undefined,
        bannerDescriptionHTML: `<p>GamifYou is a data and analytics platform that helps companies improve engagement with their customers. Through advanced techniques (AI, gamification) we generate smart insights.</p>`
                             + `<p>Discover the biggest benefits that gamification can bring to increase engagement with your audience!</p>`,
      }, {
        titleAboveBanner: 'A gamification engine adaptable to your own business.',

        bannerImageUrl: 'assets/mock/environments/Gamifyou/our-products/_Intro_Banners/main_banner_image_2.png', // TODO
        bannerTitleImageUrl: undefined,
        bannerTitle: undefined,
        bannerSubTitle: 'Our gamification engine is a versatile tool that can be customized.',
        bannerDescriptionHTML: `<p>GamifYou enables the integration of game-like elements into non-gaming contexts to increase engagement, motivation and participation. We use game mechanics and dynamics, such as points, badges, leaderboards, challenges and quests to make non-game activities more enjoyable and rewarding.</p>`
                             + `<p>Overall, by leveraging game-like elements, organizations can create more enjoyable and rewarding experiences for their users and drive desired behaviours.</p>`,
      }],
      products: [
        {
          id: '1',
          productName: 'CityPoints Cascais',
          mainColor: '#0AADAF',

          // known for banner
          backgroundColor: '#0AADAF',
          squareImageUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/Square_Logo.png',
          mobileImagePreviewUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/mobile_preview.png',

          // product description banner
          mainProductLogoUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/CityPoints_Logo.png',
          mainProductImageUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/main_product_image.png',
          mainProductTitle: 'The Gamification of the City',
          mainProductDescriptionHTML: 'City Points Cascais won the Global Champion for Best Government and Citizen Engagement Mobile App',
          productCertificationsImageUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/CityPoints_Cascais_Certs.png',

          // product preview banner
          productPreviewBannerImageUrl: 'assets/mock/environments/Gamifyou/our-products/City_Points_Cascais/product_preview_banner.png'
        },
        {
          id: '2',
          productName: 'MBWay Challenge',
          mainColor: '#D60510',

          // known for banner
          backgroundColor: '#C8C8C8',
          squareImageUrl: 'assets/mock/environments/Gamifyou/our-products/MBWay_Challenge/Square_Logo.png',
          mobileImagePreviewUrl: 'assets/mock/environments/Gamifyou/our-products/MBWay_Challenge/mobile_preview.png',

          // product description banner
          mainProductLogoUrl: 'assets/mock/environments/Gamifyou/our-products/MBWay_Challenge/MBWay_Challenge_Logo.png',
          mainProductImageUrl: 'assets/mock/environments/Gamifyou/our-products/MBWay_Challenge/main_product_image.png',
          mainProductTitle: 'The 1.5 Million User’s App',
          mainProductDescriptionHTML: `<span style="color: #D60510; font-weight: bold">CHALLENGE</span> was created to help SIBS (biggest forward payment solution in Portugal) increase the engagement and to spread the MB WAY App. Users earn points for using MB Way and for inviting his friends to the platform.`
                                    + `<p>Key Features</p>`
                                    + `<ul>`
                                      + `<li>Scratch-and-win;</li>`
                                      + `<li>Member get member (Referral program);</li>`
                                      + `<li>Campaign;</li>`
                                      + `<li>Share Prizes;</li>`
                                    + `</ul>`,
          productCertificationsImageUrl: '',

          // product preview banner
          productPreviewBannerImageUrl: 'assets/mock/environments/Gamifyou/our-products/MBWay_Challenge/product_preview_banner.png'
        },
        {
          id: '3',
          productName: 'Millennium Estoril Open',
          mainColor: '#EFDF00',

          // known for banner
          backgroundColor: '#EFDF00',
          squareImageUrl: 'assets/mock/environments/Gamifyou/our-products/Millennium_Estoril_Open/Square_Logo.png',
          mobileImagePreviewUrl: 'assets/mock/environments/Gamifyou/our-products/Millennium_Estoril_Open/mobile_preview.png',

          // product description banner
          mainProductLogoUrl: 'assets/mock/environments/Gamifyou/our-products/Millennium_Estoril_Open/Millennium_Estoril_Open_Logo.png',
          mainProductImageUrl: 'assets/mock/environments/Gamifyou/our-products/Millennium_Estoril_Open/main_product_image.png',
          mainProductTitle: 'The Tournament\'s Official App',
          mainProductDescriptionHTML: `GamifYou Engine is being used as a platform that connects <span style="color: #BB2649; font-weight: bold">Millennium Estoril Open</span> to Tennis lovers.`
                                    + `<p>Key Features</p>`
                                    + `<ul>`
                                      + `<li>Player Details;</li>`
                                      + `<li>Living Score;</li>`
                                      + `<li>Engage with tennis audience by using, gamification specifically designed for Millennium Estoril Open through Challenges, Activities, Surveys and Quizzes;</li>`
                                      + `<li>Attendees earn points. They can use them with your initiatives or with your sponsors;</li>`
                                    + `</ul>`,
          productCertificationsImageUrl: '',

          // product preview banner
          productPreviewBannerImageUrl: 'assets/mock/environments/Gamifyou/our-products/Millennium_Estoril_Open/product_preview_banner.png'
        }
      ]
    });
  }


}
