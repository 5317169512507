import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.sass']
})
export class InfoCardComponent implements OnInit {

  @Input() brandColor: string;

  @Input() cardTitle: string;
  @Input() cardDescription: string;
  @Input() cardIcon: string;
  @Input() hasUrl: boolean;

  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  public buttonPress(): void {
    this.buttonClicked.emit(true);
  }

  private setCSSVars(): void {
    if(this.brandColor || this.cardIcon) {
      if(this.brandColor) {
        this.host.nativeElement.style.setProperty('--brand-color', this.brandColor);
      }

      if(this.cardIcon) {
        this.host.nativeElement.style.setProperty('--image-url', `url(${this.cardIcon})`);
      }
    }
  }
}
