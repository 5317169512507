import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageBannerComponent } from './page-banner/page-banner.component';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';



@NgModule({
  declarations: [PageBannerComponent],
  imports: [
    CommonModule,
    LabelPipeModule
  ],
  exports: [PageBannerComponent]
})
export class PageBannerModule { }
