import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { DropdownOption } from '../dropdown.model';

@Component({
  selector: 'app-generic-dropdown',
  templateUrl: './generic-dropdown.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    './generic-dropdown.component.sass'
  ],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class GenericDropdownComponent extends DropdownBaseComponent implements OnInit, OnChanges {

  @Input() activeText: boolean = false;

  @Input() retrieveId: boolean = true; // This will allow for the option Id or name to be emitted based on the value of this

  dropdownOpen: boolean = false;
  showDropdownAbove: boolean = false; // TODO automatic turn on

  selectedOption?: DropdownOption;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    super.setCSSVars();
    super.setSpecificCSSVars();

    this.dropdownOpen = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['optionToSelect']?.previousValue !== changes['optionToSelect']?.currentValue && changes['optionToSelect']?.previousValue !== undefined) {
      if(this.optionToSelect) {
        this.toggleOptionSelected(this.optionToSelect.id);
      } else {
        this.clearSelection();
      }
    }
  }

  toggleOptionSelected(optionId: number | string): void {
    this.dropdownOptions.forEach(option => {
      if(option.id === optionId) {
        option.selected = true;
        this.selectedOption = option;
        if(this.form) {

          this.form.get(this.fCName)!.setValue(this.retrieveId ? option.id + '' : option.name);
        }
      } else {
        option.selected = false;
      }
    });

    this.optionSelected.emit(this.selectedOption);
  }

  clickOutside(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = false;

    (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 150)).then(() => {
            this.closeDropdown.emit(true);
        });
    })();
  }

  clearSelection(): void {
    this.dropdownOptions.forEach((option: DropdownOption) => {
      option.selected = false;
    });
    this.selectedOption = undefined;
  }
}
