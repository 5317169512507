<!-- <div class="d-flex justify-content-between align-items-center w-100 ps-3 pe-3 mt-2 mb-2">
  <div class="points-container left-top-column"></div>

  <div class="quantity-container right-top-column"></div>
</div> -->

<!-- <div class="d-flex justify-content-between w-100 ps-3 pe-3 mt-2">
  <div class="d-flex justify-content-between align-items-baseline">
    <div class="category-container me-1"></div>
  </div>

  <div class="date-container"></div>
</div>
-->
<div class="w-100">
  <div class="prize-container left-top-column"></div>

  <div class="quantity-container right-top-column"></div>

  <div class="partner-container left-bottom-column"></div>

  <div class="date-container right-bottom-column"></div>
</div>
