<div class="featured-title-wrapper">
  <div id="featured-title-scroll-container" #ScrollAnimationContainer>
    <div id="featured-title-container" #FeaturedTitleContainer>
      <div class="title-container">
        {{content.title}}
        <img [src]="content.imageUrl" class="sufix-icon-container" *ngIf="content.imageUrl">
      </div>
    </div>
  </div>
</div>
