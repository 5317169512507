import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialPopupComponent } from './tutorial-popup/tutorial-popup.component';
import { LottieModule } from 'ngx-lottie';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';



@NgModule({
  declarations: [
    TutorialPopupComponent
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    LottieModule,
    LabelPipeModule
  ],
  exports: [
    TutorialPopupComponent
  ]
})
export class TutorialModule { }
