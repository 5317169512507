import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ContentType } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { ActivityListItem, ActivityTypes } from '../../activities.model';

@Component({
  selector: 'app-activity-list-item',
  templateUrl: './activity-list-item.component.html',
  styleUrls: ['./activity-list-item.component.sass'],
})
export class ActivityListItemComponent implements OnInit {

  @Input() activity: ActivityListItem;
  @Input() showProgress: boolean;

  public getCurrencySymbol = getCurrencySymbol;
  public environment = environment;

  public ActivityTypes = ActivityTypes;

  constructor(
    public generalService: GeneralService,
    public translateService: TranslateService,
    public labelsService: LabelsService,
    private labelPipe: LabelPipe
    ) {
    }

  ngOnInit(): void {
    this.handleActivityType();
  }


  private handleActivityType(): void {
    switch (this.activity.type) {
      case ActivityTypes.REGULAR:
        break;

      case ActivityTypes.INPUT:
        this.activity.partner = {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: this.labelPipe.transform('activity-type-' + this.activity.type.toLocaleLowerCase()),
          images: {
            profilePictureImage: {
              href: `assets/imgs/environments/${environment.tenantName}/activities/${this.activity.type.toLocaleLowerCase()}-activity-type-secondary-icon.png`,
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          },
        };
        break;

      case ActivityTypes.MULTI_STEP:
        break;
    }
  }
}
