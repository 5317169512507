<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="'36vh'" [imageSize]="'35%'">
  <!-- [backgroundImageUrl]="'assets/mock/contact-us/contact-us-hero.png'" [title]="labelService.contactusBannerTitle | label" [description]="labelService.contactusBannerDescription | label" -->
</app-page-banner>

<div class="content-wrapper">
  <div class="menu-area-container" *ngIf="subMenuList && subMenuList.length > 0">
    <ng-container *ngFor="let menu of subMenuList">
      <a class="contact-us-menu" [routerLink]="currentMenuUrl + menu.viewUrl" [routerLinkActive]="'active'" (click)="menuClicked()">
        <span>{{menu.name}}</span>
      </a>
    </ng-container>
  </div>

  <router-outlet></router-outlet>
</div>

<div class="address-banner" *ngIf="!(generalService.isMobile | async) && brandInfo && (brandInfo.brandHQAddress || brandInfo.brandHQMapImageUrl)">
  <div class="address-info-container" *ngIf="brandInfo.brandHQAddress">
    <div class="address-title">{{ labelService.contactUsHeadquartersAddressTitle | label }}</div> <!-- TODO -->

    <div class="address-info">
      <p *ngIf="brandInfo.brandHQAddress.addressLine1">{{ brandInfo.brandHQAddress.addressLine1 }}</p>
      <p *ngIf="brandInfo.brandHQAddress.addressLine2">{{ brandInfo.brandHQAddress.addressLine2 }}</p>
      <p *ngIf="brandInfo.brandHQAddress.postalCode">
        {{
          brandInfo.brandHQAddress.postalCode
          + ( brandInfo.brandHQAddress.city ? ', ' + brandInfo.brandHQAddress.city : '' )
          + ( brandInfo.brandHQAddress.country ? ', ' + brandInfo.brandHQAddress.country : '' )
        }}
      </p>

      <p *ngIf="brandInfo.phoneContact">{{ brandInfo.phoneContact }}</p>
      <p *ngIf="brandInfo.emailContact">{{ brandInfo.emailContact }}</p>
    </div>
  </div>

  <img class="map-container" [src]="brandInfo.brandHQMapImageUrl" alt="headquarters location map" *ngIf="brandInfo.brandHQMapImageUrl">
</div>

