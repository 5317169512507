<div class="carousel-container">
  <div class="carousel-main-container swiper-container" *ngIf="content.smallCarouselContent && content.mainCarouselContent.length > 0">
    <swiper #swiperRef class="carousel-swiper"
      [slidesPerView]="'auto'"
      [spaceBetween]="30"
      [loop]="content.mainCarouselContent.length > 1 ? true : false"
      [loopedSlides]="content.mainCarouselContent.length > 1 ? 2 : 0"
      [navigation]="false"
      [pagination]="false"
      [centeredSlides]="true"
      [breakpoints]="{
        '320': {
          spaceBetween: 18
        },
        '760': {
          spaceBetween: 30
        },
        '1200': {
          initialSlide: 1
        }
      }"
      [autoplay]="{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        stopOnLastSlide: false
      }"
      >

      <ng-template swiperSlide class="carousel-item-container" *ngFor="let mainContentItem of content.mainCarouselContent; let index = index">
          <div class="carousel-item-wrapper">
            <img [src]="mainContentItem.imageUrl">
            <div class="carousel-item-info-container">
              <div class="carousel-item-info">
                <div class="item-type" *ngIf="mainContentItem.type">
                  <ng-container [ngSwitch]="mainContentItem.type">
                      <p *ngSwitchCase="CarouselType.COLLECTIONS" class="swiper-no-swiping">{{'Collections' | translate}}</p>
                      <p *ngSwitchCase="CarouselType.NEWS" class="swiper-no-swiping">{{'News' | translate}}</p>
                      <p *ngSwitchCase="CarouselType.NFT" class="swiper-no-swiping">{{'NFT' | translate}}</p>
                      <p *ngSwitchCase="CarouselType.BLOG" class="swiper-no-swiping">{{'Blog' | translate}}</p>
                  </ng-container>
                </div>
                <div class="carousel-item-title swiper-no-swiping">
                  <p>{{mainContentItem.title}}</p>
                </div>
              </div>
              <div class="carousel-item-button-container">
                <div class="carousel-item-button" (click)="carouselSlideAction(mainContentItem.type!, mainContentItem.id)">
                  <img [src]="mainContentItem.iconUrl">
                </div>
              </div>
            </div>
            <div class="carousel-top-right-icon-container" *ngIf="mainContentItem.badgeIconUrl">
              <img [src]="mainContentItem.badgeIconUrl">
            </div>
          </div>
      </ng-template>
    </swiper>
  </div>

  <div class="carousel-small-container swiper-container" *ngIf="content.smallCarouselContent && content.smallCarouselContent.length > 0 && environment.tenantName === 'GAMIFYOU'">
    <swiper #swiperRef class="carousel-swiper"
      [slidesPerView]="'auto'"
      [spaceBetween]="30"
      [navigation]="false"
      [pagination]="false"
      [centeredSlides]="true"
      [loop]="content.smallCarouselContent.length > 3 ? true : false"
      [loopedSlides]="content.smallCarouselContent.length > 3 ? 2 : 0"
      [speed]="500"
      [grabCursor]="true"
      [breakpoints]="{
        '320': {
          spaceBetween: 10
        },
        '760': {
          spaceBetween: 30
        },
        '1920': {
          initialSlide: 0
        }
      }"
      >

      <ng-template swiperSlide class="carousel-item-container" *ngFor="let smallContentItem of content.smallCarouselContent; let index = index">
          <div class="carousel-item-wrapper">
            <img [src]="smallContentItem.imageUrl">
            <div class="carousel-item-info-container">
              <div class="carousel-item-info">
                <div class="carousel-item-title swiper-no-swiping">
                  <p>{{smallContentItem.title}}</p>
                </div>
              </div>
              <div class="carousel-item-button-container">
                <div class="carousel-item-button" (click)="carouselSlideAction(smallContentItem.type!, smallContentItem.id)">
                  <img [src]="smallContentItem.iconUrl">
                </div>
              </div>
            </div>
          </div>
      </ng-template>
    </swiper>
  </div>
</div>
