<form class="account-details-form" [formGroup]="accountDetailsFormContainer" (ngSubmit)="saveDetails()">
  <div class="details-column">
    <div class="account-details-control">
      <label *ngIf="!editMode">{{labelService.myAccountDetailsFirstName | label}}*</label>
      <p *ngIf="!editMode">{{ userDetails.firstName }}</p>

      <app-single-line-text-input
        *ngIf="editMode"
        [label]="(labelService.myAccountDetailsFirstName | label)+'*'"
        [fGName]="'accountDetailsForm'"
        [fCName]="'firstname'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.firstName!">
      </app-single-line-text-input>

      <span class="input-error"
          *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('firstname')?.touched
          && (accountDetailsFormContainer.get('accountDetailsForm')?.get('firstname')?.hasError('required')
          || accountDetailsFormContainer.get('accountDetailsForm')?.get('firstname')?.hasError('minlength')))">
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
      </span>
      <span class="input-error"
          *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('firstname')?.touched
          && accountDetailsFormContainer.get('accountDetailsForm')?.get('firstname')?.hasError('pattern'))">
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorTextField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{labelService.myAccountDetailsLastName | label}}*</label>
      <p *ngIf="!editMode">{{ userDetails.lastName }}</p>

      <app-single-line-text-input
        *ngIf="editMode"
        [label]="(labelService.myAccountDetailsLastName | label)+'*'"
        [fGName]="'accountDetailsForm'"
        [fCName]="'lastname'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.lastName!">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('lastname')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('lastname')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('lastname')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('lastname')?.touched
        && accountDetailsFormContainer.get('accountDetailsForm')?.get('lastname')?.hasError('pattern'))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorTextField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsBirthdate | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.birthdate ? (userDetails.birthdate | date: 'dd/MM/yyyy': 'pt') : '-' }}</p>

      <app-date-input class="mt-3" *ngIf="editMode"
        [customIconClass]="'assets/imgs/calendar.svg'"
        [label]="(labelService.myAccountDetailsBirthdate | label)"
        [placeholder]="(labelService.myAccountDetailsBirthdateLabel | label)"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [fGName]="'accountDetailsForm'"
        [fCName]="'birthdate'"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [savedDate]="userDetails.birthdate"
        [minDate]="minDate"
        [mobile]="(generalService.isMobile | async)!"
        [forcedLocaleFormat]="'pt'">
      </app-date-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('birthdate')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('birthdate')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('birthdate')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsEmail | label }}*</label>
      <p *ngIf="!editMode">{{ $any(userDetails.emailAddress?.emailAddress)?.emailAddress ? $any(userDetails.emailAddress?.emailAddress)?.emailAddress : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="(labelService.myAccountDetailsEmail | label)+'*'"
        [fGName]="'accountDetailsForm'"
        [fCName]="'emailaddress'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="$any(userDetails.emailAddress?.emailAddress)?.emailAddress ? $any(userDetails.emailAddress?.emailAddress)?.emailAddress : labelService.myAccountDetailsEmailLabel | label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('emailaddress')?.touched
        && accountDetailsFormContainer.get('accountDetailsForm')?.get('emailaddress')?.hasError('required'))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('emailaddress')?.touched
        && accountDetailsFormContainer.get('accountDetailsForm')?.get('emailaddress')?.hasError('pattern'))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsEmailErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{labelService.myAccountDetailsFiscalNumber | label}}*</label>
      <p *ngIf="!editMode">{{userDetails.fiscalNumber! ? userDetails.fiscalNumber! : '-'}}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="(labelService.myAccountDetailsFiscalNumber | label)+'*'"
        [fGName]="'accountDetailsForm'"
        [fCName]="'fiscalnumber'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalNumber! ? userDetails.fiscalNumber! : labelService.myAccountDetailsFiscalNumberLabel | label">
      </app-single-line-text-input>


      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('fiscalnumber')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('fiscalnumber')?.hasError('required')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('fiscalnumber')?.touched
        && accountDetailsFormContainer.get('accountDetailsForm')?.get('fiscalnumber')?.hasError('minlength')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('fiscalnumber')?.hasError('maxlength'))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorTextField | label}}
      </span>
    </div>

  </div>

  <div class="details-column">
    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsAddressLine1Label | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.fiscalAddress && $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="labelService.myAccountDetailsAddressLine1Label | label"
        [fGName]="'accountDetailsForm'"
        [fCName]="'addressline1'"
        [inputHeight]="'64'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 : labelService.myAccountDetailsAddressLine1Label | label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline1')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline1')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline1')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsAddressLine2Label | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.fiscalAddress && $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="labelService.myAccountDetailsAddressLine2Label | label"
        [fGName]="'accountDetailsForm'"
        [fCName]="'addressline2'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 : labelService.myAccountDetailsAddressLine2Label | label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline2')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline2')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('addressline2')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsFiscalPostalCode | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.postalCode : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="labelService.myAccountDetailsFiscalPostalCode | label"
        [fGName]="'accountDetailsForm'"
        [fCName]="'postalcode'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.postalCode : labelService.myAccountDetailsFiscalPostalCodeLabel | label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('postalcode')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('postalcode')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('postalcode')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsFiscalCity | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.city : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="labelService.myAccountDetailsFiscalCity | label"
        [fGName]="'accountDetailsForm'"
        [fCName]="'city'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.city : this.labelService.myAccountDetailsFiscalCityLabel| label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.hasError('minlength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

    <div class="account-details-control">
      <label *ngIf="!editMode">{{ labelService.myAccountDetailsFiscalCountry | label }}</label>
      <p *ngIf="!editMode">{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.country : '-' }}</p>
      <app-single-line-text-input
        *ngIf="editMode"
        [label]="labelService.myAccountDetailsFiscalCountry | label"
        [fGName]="'accountDetailsForm'"
        [fCName]="'country'"
        [inputHeight]="'64px'"
        [fontSize]="'var(--main-info-font-size)'"
        [roundBorders]="true"
        [borderRadius]="'var(--main-info-font-size) * 3'"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.country : labelService.myAccountDetailsFiscalCoutryLabel | label">
      </app-single-line-text-input>

      <span class="input-error"
        *ngIf="(accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.touched
        && (accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.hasError('required')
        || accountDetailsFormContainer.get('accountDetailsForm')?.get('city')?.hasError('minLength')))">
        <img src="assets/imgs/error-icon.svg">
        {{labelService.myAccountDetailsErrorField | label}}
      </span>
    </div>

  </div>

</form>
