import { ImageContent, ImageContentDTO } from "src/app/pages/marketplace/_services/_products/products.service.model";

export interface ProductHistoryItemDTO {
  productId: string;
  name: string;
  quantity: number;
  expirationDate: string;
  images: Array<ImageContentDTO>;
}

export interface ProductHistoryItem {
  productId: string;
  name: string;
  quantity: number;
  expirationDate: string;
  images: Array<ImageContent>;
}

export interface ProductCodeDTO {
  productInstanceId: string;
  state: ProductCodeStatus;
  code: string;
  purchaseDate: string;
  expirationDate: string;
  redeemDate: string;
}

export interface ProductCode {
  productInstanceId: string;
  state: ProductCodeStatus;
  code: string;
  purchaseDate: string;
  expirationDate: string;
  redeemDate: string;
}

export enum ProductCodeStatus {
  PURCHASED = 'PURCHASED',
  EXPIRED = 'EXPIRED',
  REDEEMED = 'REDEEMED'
}
