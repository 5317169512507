<div class="side-menu-container" [@MenuSlide]="animationState">
  <div class="user-info-container" *ngIf="generalService.isUserLoggedIn | async"> <!-- (click)="goTo('my-account')"> -->
    <div class="user-info-wrapper">
      <div class="user-info-image-container" [ngClass]="{'active': isRouteActive}">
        <img [src]="(generalService.userDetails | async)?.images!.profilePictureImage?.href ? (generalService.userDetails | async)?.images!.profilePictureImage!.href : 'assets/imgs/environments/'+ environment.tenantName +'/default-images/default-profile-image.svg'" alt="user-profile-picture">
      </div>
      <div class="user-info-name-container">
        <p>{{(generalService.userDetails | async)?.firstName}} {{(generalService.userDetails | async)?.lastName}}</p>
      </div>
    </div>
    <!-- <div class="user-btn-container">
      <span></span>
    </div> -->
  </div>

  <div class="menu-list-container">
    <ng-container *ngFor="let menu of generalService.menuList">
      <a class="menu-container" [ngClass]="{'is-active': (generalService.instituionActive | async) && menu.viewUrl === '/institutions'}" [routerLink]="menu.viewUrl" [routerLinkActive]="'is-active'" (click)="goTo()" *ngIf="!menu.isHidden">
        <p class="menu-name">{{menu.name}}</p>
        <!-- <div class="menu-arrow-container">
          <span class="menu-arrow"></span>
        </div> -->
      </a>
    </ng-container>
  </div>

  <div class="user-interaction-container" *ngIf="!(generalService.isUserLoggedIn | async) && environment.useKeycloak">
    <app-generic-button [buttonName]="labelService.loginButton | label" [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [borderRadius]="'calc(var(--small-font-size) * 3)'" [buttonSpacing]="'3.55vw 1.5vw'" [fontWeight]="'800'" [fontSize]="'var(--smallest-font-size)'"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor" (buttonAction)="goToLogin()">
    </app-generic-button>
  </div>
  <div class="user-interaction-container" *ngIf="(generalService.isUserLoggedIn | async) && environment.useKeycloak">
    <app-generic-button [buttonName]="labelService.logoutButton | label" [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [borderRadius]="'calc(var(--small-font-size) * 3)'" [buttonSpacing]="'3.55vw 1.5vw'" [fontWeight]="'800'" [fontSize]="'var(--smallest-font-size)'"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor" (buttonAction)="goToLogout()">
    </app-generic-button>
  </div>
</div>

<div class="backdrop-container" [@BackdropFadeIn]="animationState"></div>
