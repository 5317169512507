import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { ChipsDropdownModule } from '../_shared-components/chips-dropdown-module/chips-dropdown.module';
import { FilterListModule } from '../_shared-components/filters-list-module/filter-list.module';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { LoadingListModule } from '../_shared-components/loading-list-module/loading-list.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { PaginationModule } from '../_shared-components/pagination-module/pagination.module';
import { DropdownsModule } from 'src/app/_generic-components-lib/inputs/dropdowns-module/dropdowns.module';
import { RegisterBannerModule } from '../_shared-components/register-banner-module/register-banner.module';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { PartnerNotFoundComponent } from './partner-not-found/partner-not-found.component';
import { PartnersComponent } from './partners-list/partners.component';

@NgModule({
  declarations: [
    PartnersComponent,
    PartnerDetailsComponent,
    PartnerNotFoundComponent,
  ],
  imports: [
    CommonModule,
    SingleLineTextInputModule,
    PageBannerModule,
    ButtonsModule,
    ChipsDropdownModule,
    DropdownsModule,
    InfiniteScrollModule,
    RegisterBannerModule,
    ListItemCardsModule,
    LoadingListModule,
    LabelPipeModule,
    FilterListModule,
    NoItemsModule,
    PaginationModule,
    MarketPlaceModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    PartnersComponent,
    PartnerDetailsComponent,
    PartnerNotFoundComponent
  ],
  providers: [
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class PartnersModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MarketPlaceModule } from '../marketplace/marketplace.module';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
