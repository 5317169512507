import { Injectable } from '@angular/core';
import { GoalOptionDTO } from 'src/app/pages/user-goal-module/user-goal.model';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MockUserGoalService {

  constructor() { }

  public getUserGoalOptionDTOsList(): Observable<Array<GoalOptionDTO>> {
    return of([
      {
        itemId: '1',
        img: 'https://astelus.com/wp-content/viajes/guia-turistica-de-londres-inglaterra.jpg',
        title: 'Viagem a Londres - 5 dias para 2 pessoas (Tudo incluído).'
      },
      {
        itemId: '1',
        img: 'https://www.apple.com/newsroom/images/product/iphone/geo/Apple-iPhone-14-iPhone-14-Plus-hero-220907-geo.jpg.og.jpg?202305251129',
        title: 'iPhone 14 128Gb',
      },
      {
        itemId: '1',
        img: 'https://www.turbo.pt/wp-content/uploads/2022/06/Mercedes-AMG-GLE-Edition-55_003.jpg',
        title: 'Automóvel Mercedes-Benz GLE.',
      },
      {
        itemId: '1',
        img: 'https://images.impresa.pt/sicnot/2022-08-05-Meo-Sudoeste-d1fee13a-1',
        title: 'Bilhete Geral MEO Sudoeste 2023.',
      },
      {
        itemId: '1',
        img: 'https://fashionbubbles.com/wp-content/uploads/2020/12/ilhas-maldivas.png',
        title: 'Viagem às Maldivas - 7 dias para 2 pessoas (Tudo incluído).',
      },
      {
        itemId: '1',
        img: 'https://www.razaoautomovel.com/wp-content/uploads/2021/12/smart_concept_1_2-1_925x520_acf_cropped-925x520.jpg',
        title: 'Automóvel SUV elétrico Smart #1.',
      }
    ]);
  }
}
