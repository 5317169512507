import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  selector: 'app-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './range-input.component.sass',
  ]
})
export class RangeInputComponent extends InputBaseComponent implements OnInit {
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() currentMinValue : number;
  @Input() currentMaxValue: number;
  @Input() step: number = 1;

  @Output() valuesChanged: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

  showError: boolean;

  minSelected: number | null;
  maxSelected: number | null;

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    this.minSelected = this.currentMinValue ? this.currentMinValue : this.minValue;
    this.maxSelected = this.currentMaxValue ? this.currentMaxValue : this.maxValue;

    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  minValueChanged(minSelected: number): void {
    if (minSelected >= this.maxSelected!) {
      this.minSelected = minSelected;
      (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
          this.minSelected = this.maxSelected! - this.step;
          this.valuesChanged.emit([this.minSelected!, this.maxSelected!]);
        });
      })();
    }
  }

  maxValueChanged(maxSelected: number): void {
    if (maxSelected <= this.minSelected!) {
      this.maxSelected = maxSelected;
      (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
          this.maxSelected = this.minSelected! + this.step;
          this.valuesChanged.emit([this.minSelected!, this.maxSelected!]);
        });
      })();
    }
  }
}
