import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { AccordionBaseComponent } from '../../_extended-components/accordion-base-component/accordion-base.component';
import { Accordion } from '../accordion.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    '../../_extended-components/accordion-base-component/accordion-base.component.sass',
    './accordion.component.sass'
  ],
  animations: [
    trigger('slideAnimation', [
      state('hide', style({
        height: '0px'
      })),
      state('show', style({
        height: '*'
      })),
      transition('show <=> hide', animate('300ms ease-in-out'))
    ])
  ]
})
export class AccordionComponent extends AccordionBaseComponent implements OnInit {

  @Input() accordions: Array<Accordion> = [];

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public toggleAccordion(accordionItem: Accordion): void {
    super.asyncFunction(() => {
      this.accordions.forEach(item => {
        if(item != accordionItem) {
          item.animationState = 'hide';
        }
      })
      accordionItem.animationState = accordionItem.animationState === 'hide' ? 'show' : 'hide';
    }, 300)
  }
}
