import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserGoalPopupComponent } from './edit-user-goal-popup/edit-user-goal-popup.component';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';



@NgModule({
  declarations: [
    EditUserGoalPopupComponent
  ],
  imports: [
    CommonModule,
    LabelPipeModule,
    ButtonsModule
  ],
  exports: [
    EditUserGoalPopupComponent
  ]
})
export class UserGoalModule { }
