<div class="partner-not-found-container">

  <div class="partner-not-found-image">
    <img [src]="image" [alt]="imageAlt">
  </div>

  <div class="partner-not-found-title">
    {{ title }}
  </div>

  <div class="partner-not-found-description">
    {{ description }}
  </div>
</div>
