import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LanguageDTO } from 'src/app/_services/_general-service/general-service.model';

@Injectable({
  providedIn: 'root'
})
export class MockLanguagesService {

  constructor() { }

  getLanguages(): Observable<Array<LanguageDTO>> {
    return of([
      {
        languageId: 'b3350614-88cc-47ae-9e9a-0f898b7a8a59',
        code: 'EN',
        name: 'English',
        image: null,
        isDefault: false
      },
      {
        languageId: '82d3616f-cfe2-417d-b342-a30d6da87492',
        code: 'PT',
        name: 'Português',
        image: null,
        isDefault: true
      }
  ])
  }
}
