import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { OurProductsService } from '../_services/our-products.service';
import { OurProductsIntroBanner, ProductInfo } from '../our-products.model';


@Component({
  selector: 'app-our-products-container',
  templateUrl: './our-products-container.component.html',
  styleUrls: ['./our-products-container.component.sass']
})
export class OurProductsContainerComponent implements OnInit {

  public environment = environment;
  public isLoaded: boolean = false;

  public introBanners: Array<OurProductsIntroBanner>;
  public productsList: Array<ProductInfo>;

  constructor(
    public labelService: LabelsService,
    public ourProductsService: OurProductsService,
    private translateService: TranslateService,
    public generalService: GeneralService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.ourProductsService.getProductsList().subscribe(response => {
      this.introBanners = response.introBanners;
      this.productsList = response.products;
      this.isLoaded = true;
    });
  }

}
