import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest, filter, take, takeUntil } from 'rxjs';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { FilterChip, FilterGroup, FilterItem, FilterTypeEnum, SelectedFilterGroup } from '../../_shared-components/filters-list-module/filters-list/filter.model';
import { FiltersListService } from '../../_shared-components/filters-list-module/filters-list/filters-list.service';
import { ProductsService } from '../../marketplace/_services/_products/products.service';
import { Product } from '../../marketplace/_services/_products/products.service.model';
import { MarketplaceService } from '../../marketplace/_services/marketplace.service';
import { TierItem } from '../../marketplace/marketplace-list-container/tiers-filter/tier-filter.model';
import { PartnersService } from '../_services/partners.service';
import { Partner } from '../partners.model';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.sass']
})
export class PartnerDetailsComponent implements OnInit, OnDestroy {

  public filterList: Array<FilterGroup> = [];
  public loadingListItems: Array<number> = [1, 2, 3, 4, 5, 6];
  public partnerCollections: Array<Product> = [];
  public selectedFilters: Array<SelectedFilterGroup> = [];
  public tierList: Array<TierItem> = [];

  public filterChips: Array<FilterChip> = [];
  public productList: Array<Product> = [];
  public productListToShow: Array<Product> = [];

  public queryParameters: Params = {};

  public activeFilterCount: number = 0;
  public pageIndex: number = 1;
  public availablePages: number = 1;
  public itemsPerPage: number = 10;

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;
  public showFilters: boolean = false;

  public partner: Partner;
  private partnerId: string;

  public searchString: string;

  public environment = environment;

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public labelsService: LabelsService,
    private router: ActivatedRoute,
    private route: Router,
    private partnerService: PartnersService,
    public generalService: GeneralService,
    private productService: ProductsService,
    private marketplaceService: MarketplaceService,
    private filtersService: FiltersListService,
    public labelService: LabelsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.route.events.pipe(
      takeUntil(this.destroy),
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    ).subscribe(() => {
      this.generalService.institutionActive$.next(false);
    });

    this.generalService.institutionActive$.next(true);

    this.router.queryParams.pipe(take(1)).subscribe((params) => {
      this.router.params.pipe(take(1)).subscribe((parameters) => {
        this.partnerId = parameters['partnerId']

        this.partnerService.getPartnerDetails(this.partnerId).subscribe(partner => {
          this.partner = partner;

          this.queryParameters = {
            ...params
          };

          this.isListLoading = true

          this.searchString = this.queryParameters['search'];

          // amount of items that will be shown when list is loading
          this.loadingListItems = [1, 2, 3, 4, 5, 6];
          /* this.getFilters(this.queryParameters).subscribe(() => {
            this.filterList.forEach(group => {
              this.selectedFilters.push({
                queryParameter: group.queryParameter,
                items: []
              });
            }); */
            this.filtersService.selectedFilters = this.selectedFilters;
            this.updateFilters(this.queryParameters);
            this.isLoaded = true;
          /* }); */
        }, err => {
          this.generalService.navigateTo('/404');
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('partner-details-' + this.partnerId);
  }

  private getFilters(param: Params): Observable<boolean> {
    const main$: Subject<boolean> = new Subject<boolean>();
    const filters$: Subject<boolean> = new Subject<boolean>();

    this.marketplaceService.getFilters(param).subscribe(resp => {
      this.filterList = resp;

      filters$.next(true);
    });

    combineLatest([filters$.asObservable()]).subscribe(
      ([ filters]) => {
        main$.next(filters);
    });

    return main$.asObservable();
  }

  search(): void {
    this.updateQueryParameters();
  }

  /**
   * Whenever there is an update on selected filters (either filter was deselected, all filters cleared, or a new filter selected) update all lists
   */
  public filtersUpdated(): void {
    this.activeFilterCount = 0;

    this.selectedFilters.forEach(group => {
      this.activeFilterCount += group.items.length;

      // add chips when a new filter is selected
      group.items.forEach(item => {
        if (this.filterChips.find(chip => (group.queryParameter === chip.queryParameter && chip.value === item.value)) === undefined) {
          this.filterChips.push({
            queryParameter: group.queryParameter,
            name: item.name,
            value: item.value
          });
        }
      });
    });

    let chipsToDelete: Array<FilterChip> = [];

    // remove chip when a filter is removed
    this.filterChips.forEach(chip => {
      let chipFound = false;

      this.selectedFilters.forEach(group => {
        if (chip.queryParameter === group.queryParameter && group.items.find(item => item.value === chip.value) !== undefined) {
          chipFound = true;
        }
      });

      if (!chipFound) {
        chipsToDelete.push(chip);
      }
    });

    chipsToDelete.forEach(chip => this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1));

    this.updateQueryParameters();
  }

  public productItemClicked(productId: string): void {
    this.generalService.navigateTo(`marketplace/product/${productId}`);

    this.generalService.autoScrollTop('page-router-container');
  }

  /**
   * Deletes a chip from selected filters chip list
   *
   * @param chip - FilterChip
   */
  public deleteChip(chip: FilterChip): void {
    // delete chip from list
    this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1);

    // remove filter from selected filters
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (this.selectedFilters[i].queryParameter === chip.queryParameter) {
        this.selectedFilters[i].items.splice(this.selectedFilters[i].items.findIndex(c => c.value === chip.value), 1);
        this.activeFilterCount--;
      }
    }

    // toggle off filter
    this.filterList.forEach(group => {
      if (group.queryParameter === chip.queryParameter) {
        switch (group.type) {
          case FilterTypeEnum.Checkbox:
            (group.values.find(v => (v as FilterItem).id === chip.value) as FilterItem).selected = false;
            break;

          case FilterTypeEnum.Radio:
            (group.values.find(v => (v as RadioButton).value === chip.value) as RadioButton).selected = false;
            break;

          case FilterTypeEnum.Range:
            // TODO
            break;
          default:
            break;
        }
      }
    });
  }

  /**
   * Updates each filter based on the query parameters
   *
   * @param params - QueryParameters on the URL
   *
   * We loop all the queryParameters on the url and obtain the key of each query param and save it on a variable
   * then we check if each variable is valid, if so we run the update filter function.
   */
  private updateFilters(params: Params): void {
    Object.keys(params).forEach((key: string) => {
      let group = this.filterList.find(group => group.queryParameter === key);

      if(group) {
        this.updateFilter(group, params, key);
      }
    });
    this.filtersUpdated();
  }

  /**
   * Updates each filter based on the query parameters
   *
   * @param group - FilterGroup this can be a RadioButtonGroup or CheckBoxGroup
   * @param params - QueryParameters that exist on the url
   * @param key - name of the key of each param.
   *
   * The UpdateFilter function first create a new array by splitting all the parameters after that we loop each value
   * and check if they belong to a groupItem (RadioButtonGroup) if not they are a CheckBoxGroup in the end of each we set it
   * to true and run the change detection for each group
   */
  private updateFilter(group: FilterGroup, params: Params, key: string): void {
    let values = params[key].split(',');

    let groupItem;
    values.forEach((value: string) => {
      switch (group.type) {
        case FilterTypeEnum.Checkbox:
          groupItem = (group.values.find(v => (v as FilterItem).id === +value) as FilterItem);

          if(groupItem) {
            groupItem.selected = true;
            this.filtersService.filterSelected(group, groupItem, true);
          }
          break;

        case FilterTypeEnum.Radio:
          groupItem = (group.values.find(v => (v as RadioButton).value === +value) as RadioButton);

          if(groupItem) {
            groupItem.selected = true;
            this.filtersService.radioBtnfilterChanged(group, +value);
          }
          break;

        case FilterTypeEnum.Range:
          // TODO
          break;

        default:
          break;
      }
    });
  }

  private updateQueryParameters(): void {
    let queryParams: Params = {};
    this.selectedFilters.forEach(group => {
      if(group.items.length > 0) {
        queryParams[group.queryParameter] = group.items.map(item => item.value).toString();
      }
    });

    this.updateSearchParam(queryParams);
    if(Object.entries(queryParams).length > 0) {
      this.queryParameters = queryParams;
    }

    this.changePage(1, true);
    this.generalService.navigateToWithQueryParams(`/${this.partnerId}`, this.queryParameters);
  }

  public changePage(index: number, filtersUpdated: boolean = false): void {
    this.pageIndex = index;
    const params: Params = {
      ...this.queryParameters,
      partnerId: this.partnerId
    }

    // Calculate how many pages we have loaded so far
    const totalPagesLoaded = Math.ceil(this.productList.length / this.productService.pageSize);

    if(totalPagesLoaded < index || filtersUpdated) {
      this.isListLoading = true;
      this.productService.getProductList(this.pageIndex, params, this.itemsPerPage).subscribe({
        next: (resp: Array<Product>) => {
          if(index > 1) {
            this.productList = this.productList.concat(resp);
          } else {
            this.productList = resp;
          }
          this.productListToShow = this.productList.slice((this.pageIndex-1) * this.productService.pageSize, this.pageIndex * this.productService.pageSize);
          this.isListLoading = false;
        },
        error: error => {
          this.isListLoading = false;
        }
      });
    } else {
      this.productListToShow = this.productList.slice((this.pageIndex-1) * this.productService.pageSize, this.pageIndex * this.productService.pageSize);
    }
  }

  private updateSearchParam(params: Params): void {
    if ('search' in this.queryParameters) {
      params['search'] = this.queryParameters['search'];
    }

    if (this.searchString){
      params['search'] = this.searchString;
    } else {
      delete params['search'];
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
