<div class="filters-list-container p-3">
  <div class="title-container">
    <span>{{filtersTitle}}</span>

    <div class="close-btn-container" *ngIf="isMobile" (click)="closeFilter()">
      <div class="close-icon">
        <span class="cross-line"></span>
      </div>
    </div>

    <div class="clear-filters-container" *ngIf="!isMobile && activeFilterCount > 0" (click)="clearFilters()">
      {{ clearFiltersLabel }}
    </div>
  </div>

  <div class="clear-filters-container" *ngIf="isMobile && activeFilterCount > 0" (click)="clearFilters()">
    {{ clearFiltersLabel }}
  </div>

  <ng-container *ngFor="let group of filterList; let index = index">
    <div class="filter-group-container mt-3" [ngClass]="{'pb-5': index === filterList.length - 1}">
      <div class="group-title-container" (click)="toggleFilterGroup(group)">
        <span class="group-title">{{group.name}}</span>
        <div class="arrow-container">
          <div class="arrow-icon" [ngClass]="{'active': group.isOpen}"></div>
        </div>
      </div>

      <div class="group-filters-container" [ngClass]="{'active': group.isOpen, 'animation-started': group.animationStarted}">
        <ng-container [ngSwitch]="group.type">
          <!-- CHECKBOXES -->
          <ng-container *ngSwitchCase="filterTypes.Checkbox">
            <div class="filter-item-container" *ngFor="let filter of group.values" >
              <div class="filter-input-container">
                <app-checkbox [label]="$any(filter).description" [checked]="$any(filter).selected" [justifyContent]="'space-between'"
                  [fontSize]="'15px'" [inputSize]="'20px'" (valueChanged)="isFiltersCheckboxUpdated(group, $any(filter), $event)"></app-checkbox>
              </div>
            </div>
          </ng-container>
          <!-- RADIO BTNS -->
          <ng-container *ngSwitchCase="filterTypes.Radio">
            <div class="filter-item-container">
              <div class="filter-input-container">
                <app-radio-group [radioButtons]="$any(group).values" [vertical]="true" [labelFirst]="true" [fontSize]="'15px'" [verticalMargin]="'3px'"
                  [justifyContent]="'space-between'" [brandColor]="environment.brandStyleVariables.mainBrandColor" (valueChanged)="isFiltersRadioUpdated(group, $event)"></app-radio-group>
              </div>
            </div>
          </ng-container>
          <!-- RANGE -->
          <ng-container *ngSwitchCase="filterTypes.Range">
            <!-- TODO -->
          </ng-container>
        </ng-container>

      </div>
    </div>
  </ng-container>
</div>
