import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { take } from 'rxjs';
import { AnimationTypes, BannerTypes, CarouselBanner, VideoBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../marketplace/_services/_products/products.service';
import { PartnersService } from '../partners/_services/partners.service';
import { Partner } from '../partners/partners.model';
import { LandingPageService } from './landing-page.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

  public bannerTypes = BannerTypes;

  public mobileVideo: boolean;
  public isLoaded: boolean = false;

  public carouselContent: CarouselBanner = {
    backgroundColor: '',
    mainCarouselContent: [],
    smallCarouselContent: [],
  };

  public options: AnimationOptions;
  public optionsMobile: AnimationOptions;

  public animationTypes = AnimationTypes;

  public environment = environment;

  constructor(
    public generalService: GeneralService,
    private productsService: ProductsService,
    private partnersService: PartnersService,
    public landingPageService: LandingPageService,
    private translateService: TranslateService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  ngOnInit(): void {
    this.generalService.getLandingPageBanners().pipe(take(1)).subscribe(() => {
      for(const banner of this.generalService.banners) {
        switch(banner.type) {
          case BannerTypes.CAROUSEL:
            if(this.environment.tenantName === 'GIVIN') {
              this.partnersService.getPartnersList(1).subscribe((partners: Array<Partner>) => {
                this.carouselContent.mainCarouselContent.push(...this.partnersService.carouselContentFromPartners(partners));
                banner.content = this.carouselContent;
              });
            } else {
              this.carouselContent = banner.content as CarouselBanner;
            }
            break;

          case BannerTypes.VIDEO:
            const videoContent = banner.content as VideoBanner;
            this.options = {
              autoplay: true,
              loop: videoContent.animationDesktop?.loop,
              path: videoContent.animationDesktop?.url
            };

            this.optionsMobile = {
              autoplay: true,
              loop: videoContent.animationMobile?.loop,
              path: videoContent.animationDesktop?.url
            };
            break;
        }
      }

      this.isLoaded = true;
    });
  }

  ngAfterViewInit(): void {
    const scrollContainer = this.generalService.scrollableContainerRef.nativeElement;

    scrollContainer.addEventListener('gesturechange', () => {}, { passive: true });

    this.generalService.scrollEventListener();
  }

  private checkScreenSize(): void {
    this.mobileVideo = window.innerWidth <= window.innerHeight;
  }
}
