<div class="small-banner-wrapper">
  <div class="small-banner-header">
    <div class="title-container">
      <p>{{title}}</p>
    </div>
    <div class="description-container">
      <p>{{description}}</p>
    </div>
  </div>

  <div class="small-banner-container">
    <img [src]="imageSrc" [alt]="title">
  </div>
</div>
