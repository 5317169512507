import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TierItemDTO } from 'src/app/pages/marketplace/marketplace.model';
import { ProductDTO } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { FilterGroupDTO } from 'src/app/pages/_shared-components/filters-list-module/filters-list/filter.model';
import { environment } from 'src/environments/environment';
import { MediumType } from 'src/app/_services/_general-service/general-service.model';

@Injectable({
  providedIn: 'root',
})
export class MockMarketplaceService {
  constructor() {}

  getTiers(): Observable<Array<TierItemDTO>> {
    return of([
        {
          id: 1,
          title: 'Common',
        },
        {
          id: 2,
          imageUrl: 'assets/mock/tier2-background-image.png',
          title: 'Special',
        },
        {
          id: 3,
          imageUrl: 'assets/mock/tier4-background-image.png',
          title: 'Rare',
        },
        {
          id: 4,
          imageUrl: 'assets/mock/tier3-background-image.png',
          title: 'Legendary',
        },
      ]
    );
  }

  getFilters(): Observable<Array<FilterGroupDTO>> {
    return of([
      {
        queryParameter: 'status',
        name: 'Status',
        exclusive: false,
        type: 'Checkbox',
        values: [
          {
            id: 1,
            description: 'Buy now',
          },
          {
            id: 2,
            description: 'On auction',
          },
          {
            id: 3,
            description: 'New',
          },
          {
            id: 4,
            description: 'Has offers',
          },
          {
            id: 5,
            description: 'Buy with card',
          },
        ],
      },
      {
        queryParameter: 'price',
        name: 'Price',
        type: 'Radio',
        exclusive: true,
        values: [
          {
            id: 1,
            description: 'Under €100',
          },
          {
            id: 2,
            description: '€100 - €300',
          },
          {
            id: 3,
            description: '€301 - €500',
          },
          {
            id: 4,
            description: 'Above €500',
          },
        ],
      },
    ]);
  }

  getProductList(): Observable<Array<ProductDTO>> {
    if (environment.tenantName === 'YOUR') {
      return of([
        {
          productId: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
          tenantId: 'YOUR',
          name: 'Bilhete Geral NOS Alive 2023.',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
          type: 'VOUCHER',
          endDate: "2024-12-31T00:00:00Z",
          images: [
            {
              "href": "https://infocul.pt/wp-content/uploads/2022/11/nos-alive-2023-scaled.jpeg",
              "type": "Image"
            }
          ],
          prices: [
            { amount: 350, unit: 'pinkcoins' },
          ],
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "YOUR",
          "name": "Vender serviços de contabilidade para o departamento financeiro.",
          "description": "Vender serviços de contabilidade para o departamento financeiro.",
          "type": "NFT",
          "quantity": 5,
          "endDate": "2024-12-31T00:00:00Z",
          "images": [{ "href":"assets/mock/products-mock/CARITAS_PORTUGUESA_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 150, "unit": "pinkcoins" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ]
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "YOUR",
          "name": "Vender serviços de outsourcing de contabilidade para o departamento financeiro.",
          "description": "Vender serviços de outsourcing de contabilidade para o departamento financeiro.",
          "type": "NFT",
          "endDate": "2024-12-31T00:00:00Z",
          "quantity": 1,
          "images": [{ "href":"assets/mock/products-mock/FUNDACAO_AIS_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 500, "unit": "pinkcoins" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ],
          isBlocked: true
        },
      ]);
    }

    return of([
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "GIVIN",
          "name": "NFT Multinho - AMI",
          "description": "O NFT Multinho - AMI surge de uma ligação entre o NFT Solidário e a instituição AMI. Este projeto visa apoiar a instituição parceira AMI, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
          "type": "NFT",
          "endDate": "2024-12-31T00:00:00Z",
          "images": [{ "href":"assets/mock/products-mock/AMI_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 5000, "unit": "EUR" },
          ],
          "quantity": 1,
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ]
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "GIVIN",
          "name": "NFT Multinho - Cáritas Portuguesa",
          "description": "O NFT Cáritas Portuguesa - AMI surge de uma ligação entre o NFT Solidário e a Cáritas Portuguesa. Este projeto visa apoiar a instituição parceira Cáritas Portuguesa, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
          "type": "NFT",
          "quantity": 1,
          "endDate": "2024-12-31T00:00:00Z",
          "images": [{ "href":"assets/mock/products-mock/CARITAS_PORTUGUESA_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 5000, "unit": "EUR" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ]
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "GIVIN",
          "name": "NFT Multinho - Fundação AIS",
          "description": "O NFT Fundação AIS - AMI surge de uma ligação entre o NFT Solidário e a Fundação AIS. Este projeto visa apoiar a instituição parceira Fundação AIS, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
          "type": "NFT",
          "endDate": "2024-12-31T00:00:00Z",
          "quantity": 1,
          "images": [{ "href":"assets/mock/products-mock/FUNDACAO_AIS_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 5000, "unit": "EUR" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ]
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "GIVIN",
          "name": "NFT Multinho - Liga Portuguesa Contra o Cancro",
          "description": "O NFT Multinho - Liga Portuguesa Contra o Cancro surge de uma ligação entre o NFT Solidário e a Liga Portuguesa Contra o Cancro. Este projeto visa apoiar a instituição parceira Liga Portuguesa Contra o Cancro, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
          "type": "NFT",
          "quantity": 1,
          "endDate": "2024-12-31T00:00:00Z",
          "images": [{ "href":"assets/mock/products-mock/LIGA_PORTUGUESA_CONTRA_O_CANCRO_NFT.png", "type": 'Image' }],
          "prices": [
            { "amount": 5000, "unit": "EUR" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ]
        },
        {
          "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
          "tenantId": "GIVIN",
          "name": "NFT Multinho - Amnistia Internacional",
          "description": "O NFT Multinho - Amnistia Internacional surge de uma ligação entre o NFT Solidário e a Amnistia Internacional. Este projeto visa apoiar a instituição parceira Amnistia Internacional, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
          "type": "NFT",
          "quantity": 1,
          "endDate": "2024-12-31T00:00:00Z",
          "images": [
            {
              "href": "assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png",
              "type": "Image"
            },
            {
              "href": "assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.mp4",
              "type": "Video"
            }
          ],
          "prices": [
            { "amount": 5000, "unit": "EUR" },
          ],
          "traits": [
            {
              "name": "Background",
              "value": "White"
            },
            {
              "name": "Object",
              "value": "Animation"
            }
          ],
          "partner": {
            "id": "amnistia-internacional",
            "name": "AMNISTIA INTERNACIONAL"
          }
        },
      ]);
  }

  getProductDetails(): Observable<ProductDTO> {
    if (environment.tenantName === 'YOUR') {
      return of(
        {
          productId: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
          tenantId: 'YOUR',
          name: 'Bilhete Geral NOS Alive 2023.',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
          type: 'VOUCHER',
          endDate: "2024-12-31T01:01:00Z",
          images: [
            {
              "href": "https://infocul.pt/wp-content/uploads/2022/11/nos-alive-2023-scaled.jpeg",
              "type": "Image"
            },
            {
              "href": "https://eventosemportugal.pt/wp-content/uploads/2022/12/nos-alive-cartaz-2023.jpg",
              "type": "Image"
            }
          ],
          prices: [
            { amount: 350, unit: 'pinkcoins' },
          ],
          quantity: 5,
          partner: {
            id: 'edp',
            name: 'EDP SA',
            image: 'https://cdn.cookielaw.org/logos/cff2f252-ce7c-42a7-81d6-dad2054003d2/d012ff80-be06-43a0-840b-23d0fe85062c/378b7f5a-e886-46cc-8eb7-0ae2ffffa3f6/logoedpb_new_dark.png',
          },
          addresses: [{
            mediumType: MediumType.fiscalAddress,
            characteristic: {
              addressLine1: 'Passeio Marítimo de Algés',
              addressLine2: '',
              postalCode: '',
              city: 'Algés',
              country: 'Portugal',
              gpsCoords: {
                x: 38.69854217108374,
                y: -9.234042903573437
              },
            }
          },
          {
            mediumType: MediumType.fiscalAddress,
            characteristic: {
              addressLine1: 'Avenida 24 de Julho',
              addressLine2: '12',
              postalCode: '',
              city: 'Lisboa',
              country: 'Portugal',
              gpsCoords: {
                x: 37.04486999575262,
                y: -7.973170051642344
              },
            }
          }]
        }
      );
    }

    return of(
      {
        productId: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
        tenantId: 'GIVIN',
        name: 'NFT Multinho - Amnistia Internacional',
        description: 'O NFT Multinho - Amnistia Internacional surge de uma ligação entre o NFT Solidário e a Amnistia Internacional. Este projeto visa apoiar a instituição parceira Amnistia Internacional, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.',
        type: 'NFT',
        endDate: "2024-12-31T00:00:00Z",
        serial: "#1",
        images: [
          {
            "href": "assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png",
            "type": "Image"
          },
          {
            "href": "assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.mp4",
            "type": "Video"
          }
        ],
        prices: [
          { amount: 5000, unit: 'EUR' },
        ],
        quantity: 1,
        traits: [
          {
            "name": "Background",
            "value": "White"
          },
          {
            "name": "Object",
            "value": "Animation"
          }
        ],
        partner: {
          id: 'amnistia-internacional',
          name: 'AMNISTIA INTERNACIONAL',
          image: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
        },
        "collection": {
          "id": "b618d679-2316-4b5e-808a-ee99f4d4c323",
          "name": "NFT Multinho"
        }
      }
    );
  }

  // getProductList(): Observable<Array<ProductDTO>> {
  //   return of([
  //     {
  //       id: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
  //       tenantId: 'ARTENTIK',
  //       name: 'St. Francis Xavier Calming a Storm at Sea.',
  //       description: 'An Awesome Description 1',
  //       type: 'NFT',
  //       endDate: "2023-10-10T00:00:00Z",
  //       imageUrl: ['/assets/mock/mock-nft2.jpg'],
  //       serial: '#488',
  //       prices: [
  //         { amount: 115, unit: 'ETH' },
  //         { amount: 99, unit: 'EUR' },
  //       ],
  //       characteristics: [],
  //       quantity: 2,
  //       categories: [
  //         {
  //           id: '29f8afc2-a364-48a9-8b7a-ea33ce106174',
  //           name: 'Artifact',
  //         }
  //       ],
  //       collection: {
  //         id: '31bb4267-f9e3-42ad-8a83-f753a7fe759a',
  //         name: 'A new Awesome Collection',
  //       },
  //     },
  //     {
  //       id: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
  //       tenantId: 'ARTENTIK',
  //       name: 'Receiving the body of St Francis Xavier in St Paul`s Church in Goa.',
  //       description: 'An Awesome Description 1',
  //       type: 'NFT',
  //       endDate: "2023-10-10T00:00:00Z",
  //       imageUrl: ['/assets/mock/mock-nft2.jpg'],
  //       serial: '#488',
  //       prices: [
  //         { amount: 115, unit: 'ETH' },
  //         { amount: 99, unit: 'EUR' },
  //       ],
  //       characteristics: [],
  //       quantity: 10,
  //       categories: [
  //         {
  //           id: '29f8afc2-a364-48a9-8b7a-ea33ce106174',
  //           name: 'Artifact',
  //         }
  //       ],
  //       collection: {
  //         id: '31bb4267-f9e3-42ad-8a83-f753a7fe759a',
  //         name: 'A new Awesome Collection',
  //       },
  //     },
  //     {
  //       id: '3c1c5467-4411-4052-bed1-eaeae911d2e0',
  //       tenantId: 'ARTENTIK',
  //       name: 'Reliquary - Happy Fathers Day.',
  //       description: 'An Awesome Description 1',
  //       type: 'NFT',
  //       endDate: "2023-10-10T00:00:00Z",
  //       imageUrl: ['/assets/mock/mock-nft2.jpg'],
  //       serial: '#488',
  //       prices: [
  //         { amount: 115, unit: 'ETH' },
  //         { amount: 99, unit: 'EUR' },
  //       ],
  //       characteristics: [],
  //       quantity: 4,
  //       categories: [
  //         {
  //           id: '29f8afc2-a364-48a9-8b7a-ea33ce106174',
  //           name: 'Relic',
  //         }
  //       ],
  //       collection: {
  //         id: '31bb4267-f9e3-42ad-8a83-f753a7fe759a',
  //         name: 'A new Awesome Collection',
  //       },
  //     },
  //   ])
  // }
}
