<div class="info-card-wrapper" (click)="buttonPress()">
  <div class="info-card-container">
    <div class="info-card-icon"></div>
    <div class="info-card">
      <div class="info-card-title">
        {{cardTitle}}
      </div>
      <div class="info-card-desc">
        {{cardDescription}}
      </div>
    </div>
  </div>
</div>
