import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { ActivityInfo, ActivityTypes } from '../../activities.model';
import { LoggedUserService } from 'src/app/_services/_logged_user_service/logged-user.service';


@Component({
  selector: 'app-activity-details-card',
  templateUrl: './activity-details-card.component.html',
  styleUrls: ['./activity-details-card.component.sass']
})
export class ActivityDetailsCardComponent implements OnInit, OnChanges {

  @Input() activity: ActivityInfo;
  @Output() triggerGetActivityInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

  public getCurrencySymbol = getCurrencySymbol;
  public ActivityTypes = ActivityTypes;

  public environment = environment;

  public userGrantedPermission: boolean = false;

  constructor(
    public labelService: LabelsService,
    public translateService: TranslateService,
    private loggedUserService: LoggedUserService
  ) {
    this.checkUserDistance();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activity']) {
      this.checkUserDistance();
    }
  }

  public getActivityInfo(triggerValue: boolean): void {
    this.triggerGetActivityInfo.emit(triggerValue);
  }

  private checkUserDistance(): void {
    if (this.activity && this.activity.addresses && this.activity.addresses.some(a => a.gpsCoords != null)) {
      this.loggedUserService.getCurrentPosition(
        (position: GeolocationPosition) => {
          // Handle successful geolocation updates
          console.log('Geolocation updated:', position);

          this.userGrantedPermission = true;
          this.updateUserDistance(position);
        },
        (error: GeolocationPositionError) => {
          // Handle geolocation errors
          console.error('Geolocation error:', error);
        }
      );
    }
  }

  private updateUserDistance(position: GeolocationPosition): void {
    this.activity.addresses?.forEach(address => {
      address.distance = google.maps.geometry.spherical.computeDistanceBetween({lat: position.coords.latitude, lng: position.coords.longitude}, {lat: address.gpsCoords!.x, lng: address.gpsCoords!.y})
    });
  }

}
