import { Component, OnInit } from '@angular/core';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {

  public environment = environment;

  constructor(public labelService: LabelsService) { }

  ngOnInit(): void {
  }

}
