<div class="article-item-content mt-auto">
  <div class="description-container">
    <p>{{ articleDescription }}</p>
  </div>

  <div class="article-publish-date-container">
    <p class="publish-date-label">{{ labelsService.newsArticleDateLabel | label }}</p>

    <span class="publish-date">{{ publishDate | date: 'MMMM dd, YYYY': 'pt' }}</span>
  </div>
</div>
