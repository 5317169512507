<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? '36vh' : '65vh'"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

<div class="activity-sub-tasks-page-wrapper card-radius" *ngIf="isLoaded" [@FadeIn]="animationState">
  <div class="activity-info-container mb-3">
    <div class="activity-info">
      <p class="tasks-progress">
        {{ activityInfo.totalCompletedSubActivities + '/' + activityInfo.totalSubActivities + ' ' + ( labelsService.activityMultiStepTaskName | label)}}
      </p>

      <p class="activity-name" *ngIf="activityInfo.name">{{ activityInfo.name }}</p>
      <p class="activity-date" *ngIf="activityInfo.endDate">
        {{ labelsService.activityListItemDateLabel | label: { date: activityInfo.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
      </p>
    </div>

    <img [src]="activityInfo.images && activityInfo.images.length > 0 ? activityInfo.images[0].href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'">
  </div>

  <p class="sub-task-list-title" *ngIf="labelsService.activityMultiStepTitle | label">
    {{ labelsService.activityMultiStepTitle | label }}
  </p>

  <div class="sub-tasks-list">
    <div class="sub-task-info-container" *ngFor="let subTask of activityInfo.subActivities; let index = index"
      [ngClass]="{ 'completed': subTask.isCompleted }">
      <p class="task-index">
        {{ ( labelsService.activityMultiStepTaskItemIndex | label: {index: index + 1}) }}
      </p>

      <p class="sub-task-title" *ngIf="subTask.name">
        {{ subTask.name }}
      </p>

      <p class="sub-task-description" *ngIf="subTask.description">
        {{ subTask.description }}
      </p>

      <p class="prize-container mt-3" *ngIf="subTask.rewards[0].amount != null">
        <span *ngIf="subTask.isCompleted; else notCompleted">{{ subTask.rewards[0].amount + ' ' + getCurrencySymbol('pinkcoins', 'narrow') }}</span>
        <ng-template #notCompleted>
          {{ labelsService.activityListItemPrizeLabel | label: { amount: subTask.rewards[0].amount, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
        </ng-template>
      </p>

      <p class="sub-task-completed" *ngIf="subTask.isCompleted">
        <ng-container *ngIf="subTask.isCompleted">
          <i class="bi bi-check-circle"></i> <span class="ms-2"> {{ labelsService.activityMultiStepCompletedSubTaskLabel | label }}</span>
        </ng-container>
      </p>

      <p class="date-container mt-4 mb-2" *ngIf="activityInfo.endDate && !subTask.isCompleted">
        <ng-container *ngIf="!subTask.isCompleted">
          <!-- TODO {{ labelsService.activityListItemDateLabel | label: { date: subTask.endDate | date: 'dd MMMM, YYYY': translateService.currentLang: translateService.currentLang } }} -->
          {{ labelsService.activityListItemDateLabel | label: { date: activityInfo.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
        </ng-container>
      </p>
    </div>
  </div>
</div>
