<div class="custom-date-container position-relative w-100" #dateInputContainer *ngIf="!mobile; else mobileUI ">
  <label *ngIf="label !== null">{{ label }}</label>

  <mat-form-field class="custom-formfield custom-date-formfield mt-1 w-100" appearance="fill" (click)="rangePicker.open()">
    <mat-date-range-input #formInput [formGroup]="form" [rangePicker]="rangePicker" [disabled]="isDisabled">
      <input matStartDate formControlName="start" readonly autocomplete="off"
        placeholder="{{ placeholderStart }}" (dateChange)="onDateChange($event.value, false)"
        [min]="minDate"
        [max]="maxDate"
      >
      <input matEndDate formControlName="end" readonly autocomplete="off"
        placeholder="{{ placeholderEnd }}" (dateChange)="onDateChange($event.value, true)"
        [min]="minDate"
        [max]="maxDate"
      >
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix matRippleDisabled [for]="rangePicker" [disabled]="isDisabled" class="me-3">
      <mat-icon matDatepickerToggleIcon svgIcon="customIcon" *ngIf="customIcon">
        <i class="bi bi-calendar4"></i>
      </mat-icon>
    </mat-datepicker-toggle>

    <mat-date-range-picker #rangePicker [disabled]="isDisabled" [calendarHeaderComponent]="customHeader"></mat-date-range-picker>
  </mat-form-field>
</div>

<ng-template #mobileUI>
  <div class="custom-date-container position-relative w-100">
    <label *ngIf="label !== null">{{ label }}</label>

    <mat-form-field class="custom-formfield custom-date-formfield mt-1 w-100" appearance="fill" (click)="rangeMobilePicker.open()">
      <mat-date-range-input #formInput [formGroup]="form" [rangePicker]="rangeMobilePicker" [disabled]="isDisabled">
        <input matStartDate formControlName="start" readonly autocomplete="off"
          placeholder="{{ placeholderStart }}" (dateChange)="onDateChange($event.value, false)"
          [min]="minDate"
          [max]="maxDate"
        >
        <input matEndDate formControlName="end" readonly autocomplete="off"
          placeholder="{{ placeholderEnd }}" (dateChange)="onDateChange($event.value, true)"
          [min]="minDate"
          [max]="maxDate"
        >
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix matRippleDisabled [for]="rangeMobilePicker" [disabled]="isDisabled" class="me-3">
        <mat-icon matDatepickerToggleIcon svgIcon="customIcon" *ngIf="customIcon">
          <i class="bi bi-calendar4"></i>
        </mat-icon>
      </mat-datepicker-toggle>

      <mat-date-range-picker #rangeMobilePicker [disabled]="isDisabled" [calendarHeaderComponent]="customHeader" touchUi></mat-date-range-picker>
    </mat-form-field>
  </div>
</ng-template>
