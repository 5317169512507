<div class="backdrop-container" [@BackdropFadeIn]="animationState" (click)="closeClickOutside ? closePopUp() : null"></div>

<div [@ZoomIn]="animationState" class="popup-container card-radius">
  <div class="close-button" *ngIf="enableXButton" (click)="closePopUp()">
    <i class="bi bi-x"></i>
  </div>

  <p class="title">
    {{ labelService.purchaseProductPopupTitle | label }}
  </p>
  <p class="item-name mb-2">
    {{ product.name }}
  </p>
  <p class="item-exire-date mt-0">
    {{ (labelService.productDetailsDate | label) + (product.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase)}}
  </p>


  <div class="quantity-input-wrapper">
    <p class="title mb-2">
      {{ labelService.purchaseProductPopupQuantityLabel | label }}
    </p>

    <div class="quantity-input-container mb-3">
      <div class="decrease-quantity-input input-icon minus-icon" (click)="changeQuantity(-1)">
        <i class="bi bi-dash"></i>
      </div>
      <div class="quantity">
        {{ selectedQuantity }}
      </div>
      <div class="increase-quantity-input input-icon plus-icon" (click)="changeQuantity(1)">
        <i class="bi bi-plus"></i>
      </div>
    </div>

    <div class="product-price">
      {{ labelService.marketplaceListItemPrice | label: { price: selectedQuantity * product.prices[0].amount, unit: getCurrencySymbol(currency.type, 'narrow') | label } | lowercase }}
    </div>

    <p class="amount-to-spend mt-2">
      {{ labelService.purchaseProductPopupAvailableCurrencyInWallet | label : { quantity: generalService.userDefaultWallet.currentValue, currency: (getCurrencySymbol(currency.name, 'narrow') | label | lowercase) } }}
    </p>

    <p class="error-message" *ngIf="showErrorMessage">
      {{ errorMessageToShow }}
    </p>
  </div>

  <div class="w-100">
    <app-generic-button class="confirm-button"
      [buttonName]="labelService.purchaseProductPopupConfirmButtonLabel | label"
      [borderRadius]="'60px'"
      [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [brandColor]="environment.brandStyleVariables.mainBrandColor"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
      [fontSize]="'var(--small-font-size)'"
      [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLighter"
      [isDisabled]="showErrorMessage"
      [buttonSize]="'100%'"
      (click)="purchaseConfirm()">
    </app-generic-button>
  </div>
</div>
