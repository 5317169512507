import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DebounceModule } from 'src/app/_directives/debounce-module/debounce-module.module';
import { FilterPipeModule } from 'src/app/_shared-modules/filter-pipe-module/filter-pipe.module';
import { LibraryService } from '../../__services/library.service';
import { CheckboxInputModule } from '../checkbox-input-module/checkbox-input.module';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { GenericDropdownComponent } from './generic-dropdown/generic-dropdown.component';
import { LanguageMultiDropdownComponent } from './language-multi-dropdown/language-multi-dropdown.component';
@NgModule({
  declarations: [GenericDropdownComponent, DropdownInputComponent, DropdownSearchComponent, CountryDropdownComponent, LanguageMultiDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DebounceModule,
    FilterPipeModule,
    CheckboxInputModule
  ],
  exports: [GenericDropdownComponent, DropdownInputComponent, DropdownSearchComponent, CountryDropdownComponent, LanguageMultiDropdownComponent],
  providers: [LibraryService]
})
export class DropdownsModule { }

