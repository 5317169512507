import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  selector: 'app-single-line-input-with-btn',
  templateUrl: './single-line-input-with-btn.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './single-line-input-with-btn.component.sass'
  ]
})
export class SingleLineInputWithBtnComponent extends InputBaseComponent implements OnInit, OnChanges {

  @Input() isPassword: boolean = false;
  @Input() hasSuffix: boolean = false;
  @Input() isSuffixIcon: boolean; // Determines if suffix is icon or text
  @Input() alignLeft: boolean;

  @Input() inactiveSuffixLabel: string;
  @Input() activeSuffixLabel: string;
  @Input() iconBackgroundColor: string;
  @Input() iconButtonWidth: string;

  @Input() activeSuffix: string;
  @Input() inactiveSuffix: string;
  @Input() isSuffixActive: boolean;

  @Input() debounceTime: number;

  hideValue: boolean = false;

  suffixClassName: string;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

 ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    super.setCSSVars();
    this.setCSSVars();
    super.setSpecificCSSVars();

    this.hideValue = this.isPassword;

    this.updateIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateIcon();

    if(changes['iconButtonWidth'])
      this.host.nativeElement.style.setProperty('--icon-button-width', changes['iconButtonWidth'].currentValue);
  }

  //update icon class name with what comes from @input
  updateIcon(): string {
    return this.suffixClassName = this.isSuffixActive ? this.activeSuffix : this.inactiveSuffix
  }

  suffixToggled(): void {
    if(this.isPassword)
      this.hideValue = !this.hideValue;

    this.suffixClicked.emit(this.isSuffixActive);
  }

  override setCSSVars(): void {
      //set variables
      if(this.iconBackgroundColor)
        this.host.nativeElement.style.setProperty('--icon-background-color', this.libraryService.convertToHexColor(this.host, this.iconBackgroundColor));
      if(this.iconButtonWidth)
        this.host.nativeElement.style.setProperty('--icon-button-width', this.iconButtonWidth);
  }

}
