<ng-container *ngIf="fCName == null; else formInput">
  <div class="input-container w-100">
      <label *ngIf="label" [ngClass]="{'mb-1': !labelMargin}" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
      <div class="inner-container position-relative w-100 d-flex align-items-center" [ngClass]="{'error': hasError}">
        <div class="dropdown-button" [attr.role]="allowDropdown ? 'button' : ''" (click)="allowDropdown ? openDropdown() : ''">
          <div class="country-flag" [ngClass]="selectedCountry.flagClass || ''"></div>
          <i class="bi" *ngIf="allowDropdown" [ngClass]="{'bi-chevron-down': !dropdownOpen, 'bi-chevron-up': dropdownOpen}"></i>
        </div>
        <input type="tel" class="w-100 ps-1 pe-3" [placeholder]="placeholderLabel" [(ngModel)]="inputValue" (ngModelChange)="validatePhoneNumber(selectedCountry.iso2, inputValue)">
      </div>
      <div class="position-relative w-100">
        <ng-container *ngIf="dropdownOpen && allowDropdown">
          <app-country-dropdown [countryList]="allCountries" [brandColor]="brandColor" [borderRadius]="borderRadius" [noResultsLabel]="noResultsLabel" [placeholder]="searchPlaceholderLabel" [lightBrandColor]="'#D9D9D94D'" [borderColor]="'white'"
          [errorColor]="errorColor" [fontColor]="fontColor" [fontSize]="fontSize" (countrySelected)="emitOptionSelected($event)" (closeDropdown)="dropdownOpen = false"></app-country-dropdown>
        </ng-container>
      </div>
  </div>
</ng-container>

<ng-template #formInput>
  <form [formGroup]="form">
    <div class="input-container w-100">
      <label *ngIf="label" [ngClass]="{
        'error': form.controls[fCName].touched && !form.controls[fCName].valid,
        'mb-1': !labelMargin
      }"
      [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
      <div class="inner-container position-relative w-100 d-flex align-items-center" [ngClass]="{'error': form.controls[fCName].touched && !form.controls[fCName].valid }">
        <div class="dropdown-button" [attr.role]="allowDropdown ? 'button' : ''" (click)="allowDropdown ? openDropdown() : ''">
          <div class="country-flag" [ngClass]="selectedCountry.flagClass || ''"></div>
          <i class="bi" *ngIf="allowDropdown" [ngClass]="{'bi-chevron-down': !dropdownOpen, 'bi-chevron-up': dropdownOpen}"></i>
        </div>
        <input type="tel" class="w-100 ps-1 pe-3" [placeholder]="placeholderLabel" formControlName="{{fCName}}">
        <input type="hidden" formControlName="countryCode">
      </div>
      <div class="position-relative w-100">
        <ng-container *ngIf="dropdownOpen && allowDropdown">
          <app-country-dropdown [countryList]="allCountries" [brandColor]="brandColor" [borderRadius]="dropdownBorderRadius ? dropdownBorderRadius : borderRadius" [noResultsLabel]="noResultsLabel" [placeholder]="searchPlaceholderLabel" [lightBrandColor]="'#D9D9D94D'" [borderColor]="'white'"
            [errorColor]="errorColor" [fontColor]="fontColor" [fontSize]="fontSize" (countrySelected)="emitOptionSelected($event)" (closeDropdown)="dropdownOpen = false"></app-country-dropdown>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>
