import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-not-found',
  templateUrl: './partner-not-found.component.html',
  styleUrls: ['./partner-not-found.component.sass']
})
export class PartnerNotFoundComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() imageAlt: string;

  constructor() { }

  ngOnInit(): void {
  }

}
