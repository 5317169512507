import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { GoalOption, GoalOptionDTO } from '../user-goal.model';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { ProductDTO } from '../../marketplace/_services/_products/products.service.model';
import { ImageType } from '../../tutorial/tutorial.model';

@Injectable({
  providedIn: 'root'
})
export class UserGoalService {

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getUserGoalOptionDTOsList(): Observable<Array<GoalOption>> {
    return this.apiService.get(`product-catalog/public/product`, {}, '1.0', true, environment.useMockedData.marketplaceProductsGetList, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<ProductDTO> | GenericErrorReply ) => {
        if(this.generalService.isValidServerReply(response)) {
          response = (response as Array<ProductDTO>);

          const userGoalOptionDTOsList = this.getUserGoalOptionDTOsFromDTO(response);
          return userGoalOptionDTOsList;
        } else {
          throw response;
        }
    }));
  }

  private getUserGoalOptionDTOsFromDTO(productsDTO: Array<ProductDTO>): Array<GoalOption> {
    const userGoalOptions: Array<GoalOption> = [];

    productsDTO.forEach(dto => {
      const foundImage = dto.images?.find(img => img.type === ImageType.IMAGE)?.href;

      userGoalOptions.push({
        itemId: dto.productId,
        img: foundImage ? foundImage : `assets/imgs/environments/${environment.tenantName}/default-images/default-image-small-rectangle.svg`,
        title: dto.name,
        selected: false
      });
    });

    return userGoalOptions;
  }

  /* public getUserGoalOptionDTOsList(): Observable<Array<GoalOption>> {
    return this.apiService.get('userGoal/GetOptionsList', {}, '1.0', true, environment.useMockedData.userGoalGetOptionsList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<GoalOptionDTO> | GenericErrorReply ) => {
        if(this.generalService.isValidServerReply(response)) {
          const userGoalOptionDTOsListDTO = (response as Array<GoalOptionDTO>);
          const userGoalOptionDTOsList = this.getUserGoalOptionDTOsFromDTO(userGoalOptionDTOsListDTO);
          return userGoalOptionDTOsList;
        } else {
          throw response;
        }
    }));
  }

  private getUserGoalOptionDTOsFromDTO(goalsDTO: Array<GoalOptionDTO>): Array<GoalOption> {
    const userGoalOptions: Array<GoalOption> = [];

    goalsDTO.forEach(dto => {
      userGoalOptions.push({
        img: dto.img,
        title: dto.title,
        selected: false
      });
    });

    return userGoalOptions;
  } */
}
