import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, Subject, catchError, map } from 'rxjs';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { FilterGroup, FilterGroupDTO, FilterItem, FilterItemDTO, FilterType, FilterTypeEnum } from '../../_shared-components/filters-list-module/filters-list/filter.model';
import { Article, ArticleDTO } from '../blog.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  endOfScrollReached$ = new Subject<boolean>();
  infiniteScrollActive:boolean = false;

  currentPageIndex: number = 0;
  pageSize: number = 16;
  totalPages: number = 1;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getArticleList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<Article>> {
    /* const currentParams: Params = {
      ...params,
      pageSize: pageSize ? pageSize : this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`blog/public/article`, currentParams, '1.0', false, true, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      // map((response: GetArticleListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
        map((response: Array<ArticleDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetArticleListReply;
          response = response as Array<ArticleDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const articleListDTO = response.items;
          const articleListDTO = response;
          const articleList = this.getArticleListFromDTO(articleListDTO);
          return articleList;
        } else {
          throw response;
        }
    })); */

    return this.apiService.getFromFile('https://gamifyou-assets-prod.s3.eu-west-1.amazonaws.com/blog-articles-list.json', true).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
        map((response: Array<ArticleDTO> | GenericErrorReply) => {
          if(this.generalService.isValidServerReply(response)) {
            // response = response as GetArticleListReply;
            response = response as Array<ArticleDTO>;

            this.currentPageIndex = pageIndex;
            // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
            this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
            // const articleListDTO = response.items;
            const articleListDTO = response;
            const articleList = this.getArticleListFromDTO(articleListDTO);
            return articleList;
          } else {
            throw response;
          }
      }));
  }

  private getArticleListFromDTO(dto: Array<ArticleDTO>): Array<Article> {
    let articleList: Array<Article> = [];

    dto.forEach((articleDto: ArticleDTO) => {
      articleList.push(this.getArticleDetailFromDTO(articleDto));
    });

    return articleList;
  }

  public getArticleDetail(articleId: string): Observable<Article> {
    /* return this.apiService.get(`blog/public/article/${articleId}`, {}, '1.0', true, true, articleId, 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: ArticleDTO | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const articleDetailDTO = (response as ArticleDTO);
          const articleDetail = this.getArticleDetailFromDTO(articleDetailDTO);

          return articleDetail;
        } else {
          throw response;
        }
    })); */

    return this.apiService.getFromFile('https://gamifyou-assets-prod.s3.eu-west-1.amazonaws.com/blog-articles-list.json', true).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
        map((response: Array<ArticleDTO> | GenericErrorReply) => {
          if(this.generalService.isValidServerReply(response)) {
            response = response as Array<ArticleDTO>;

            const articleListDTO = response;
            const articleList = this.getArticleListFromDTO(articleListDTO);

            const article = articleList.find(article => article.id === articleId);

            if (!article) {
              throw response;
            }

            return article;
          } else {
            throw response;
          }
      }));
  }

  private getArticleDetailFromDTO(dto: ArticleDTO): Article {
    let articleDetail: Article;

    articleDetail = {
      id: dto.id,
      title: dto.title,
      description: dto.description,
      image: dto.image,
      publishDate: dto.publishDate,
      articleContent: dto.articleContent
    }

    return articleDetail;
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }


  public getFilters(params: Params = {}): Observable<Array<FilterGroup>> {
    return this.apiService.get('blog/public/article/article-filter', params, '1.0', true, environment.useMockedData.blogGetFilters, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<FilterGroupDTO> | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {
          const filtersReply = response as Array<FilterGroupDTO>;
          const filtersList: Array<FilterGroup> = this.getFiltersListFromDTO(filtersReply);

          return filtersList;
        } else {
          throw response;
        }
      }));
  }

  private getFiltersListFromDTO(filtersReply: Array<FilterGroupDTO>): Array<FilterGroup>{
    const filtersList: Array<FilterGroup> = [];

    filtersReply.forEach(dto => {
      filtersList.push({
        queryParameter: dto.queryParameter,
        name: dto.name,
        type: FilterTypeEnum[dto.type as FilterType],
        values: this.getFilterItemFromDTO(dto.values, FilterTypeEnum[dto.type as FilterType]),
        isOpen: true
      });
    });

    return filtersList;
  }

  private getFilterItemFromDTO(dtoList: Array<FilterItemDTO>, filterType: FilterType = FilterTypeEnum.Checkbox): Array<FilterItem | RadioButton> {
    const filterList: Array<FilterItem | RadioButton> = [];

    dtoList.forEach(dto => {
      switch (filterType) {
        case FilterTypeEnum.Checkbox:
          filterList.push({
            id: dto.id,
            description: dto.description,
            selected: false
          });
          break;

        case FilterTypeEnum.Radio:
          filterList.push({
            label: dto.description,
            value: dto.id,
            selected: false
          });
          break;

        case FilterTypeEnum.Range:
          // TODO
          break;

        default:
          break;
      }
    });

    return filterList;
  }
}
