<div class="product-detail-container">
  <div class="product-detail-scrollable-container" #scrollableContainer>
    <div class="product-description-container">
      <p class="product-title" *ngIf="content.title">{{ content.title }}</p>
      <p class="product-text" *ngIf="content.text">{{ content.text }}</p>
      <p class="product-subtitle" *ngIf="content.subtitle">{{ content.subtitle }}</p>
      <ul class="product-solution-list" *ngIf="content.bulletPoints">
        <ng-container *ngFor="let item of content.bulletPoints">
          <li class="product-solution-item">{{ item }}</li>
        </ng-container>
      </ul>
      <div class="product-incentive" *ngIf="content.incentive">
        <p class="product-title" *ngIf="content.incentive.title">{{ content.incentive.title }}</p>
        <p class="product-subtitle" *ngIf="content.incentive.subtitle">{{ content.incentive.subtitle }}</p>
        <p class="product-text" *ngIf="content.incentive.description">{{ content.incentive.description }}</p>
      </div>
    </div>

    <div class="product-image-container" #ImagesContainerRef>
      <div class="product-image-wrapper">
        <img class="product-main-image" id="product-details-main-image" #MainImage [src]="content.imageUrl" *ngIf="content.imageUrl"> <!-- Main Image -->
        <img class="product-left-icon" id="product-details-left-icon" #LeftIcon [src]="content.iconUrls[0]" *ngIf="content.iconUrls && content.iconUrls.length > 0"> <!-- First Icon -->
        <img class="product-right-icon" id="product-details-right-icon" #RightIcon [src]="content.iconUrls[1]" *ngIf="content.iconUrls && content.iconUrls.length > 1"> <!-- Second Icon -->
        <img class="product-bottom-left" id="product-details-bottom-left-icon" #LeftBottomIcon [src]="content.iconUrls[2]" *ngIf="content.iconUrls && content.iconUrls.length > 2"> <!-- Third Icon -->
      </div>
    </div>
  </div>
</div>
