import { Component, ElementRef } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-snackbar-base-component',
  template: '',
  styleUrls: ['./snackbar-base.component.sass']
})
export class SnackbarBaseComponent extends BaseComponent {

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

}
