import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';
import { DropdownOption } from '../../inputs/dropdowns-module/dropdown.model';

@Component({
  selector: 'app-dropdown-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    './dropdown-base.component.sass',
  ]
})
export class DropdownBaseComponent extends BaseComponent {

  @Input() dropdownHeight: string;
  @Input() labelWeight: number;

  @Input() checkIcon: boolean;

  @Input() inputPlaceholder: string;
  @Input() noResultsLabel: string = 'No results found';

  @Input() fCName: string; // FormControlName
  @Input() fGName: string; // FormGroupName

  @Input() dropdownOptions: Array<DropdownOption>;
  @Input() optionToSelect?: DropdownOption;

  @Output() optionSelected: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() closeDropdown: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disabled: boolean;

  public inputValue?: string = '';

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
    super.setCSSVars();
  }

  setSpecificCSSVars(): void {
    if (this.dropdownHeight || this.labelWeight) {

      //set variables
      if (this.labelWeight)
        this.host.nativeElement.style.setProperty('--placeholder-weight', this.labelWeight);
      if (this.dropdownHeight)
        this.host.nativeElement.style.setProperty('--dropdown-height', this.dropdownHeight);
    }
  }
}
