import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericPopupComponent } from '../generic-popup/generic-popup.component';

@Component({
  selector: 'app-generic-popup-from-bottom',
  templateUrl: './generic-popup-from-bottom.component.html',
  styleUrls: ['./generic-popup-from-bottom.component.sass'],
  animations: [
    trigger('Slide', [
      state('down', style({
        opacity: '0',
        transform: 'translateY(100%)'
      })),
      state('up', style({
        opacity: '1',
        transform: 'translateY(0%)',
      })),
      transition('up => down',  animate('300ms ease-in-out')),
      transition('down => up',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('down', style({
        opacity: '0',
      })),
      state('up', style({
        opacity: '0.32',
      })),
      transition('up <=> down',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class GenericPopupFromBottomComponent extends GenericPopupComponent implements OnInit, AfterViewInit {

  @Input() walletName: string;
  @Input() walletLabel: string;

  override animationState: string;

  constructor(
    private routerOverride: Router,
    private hostOverride: ElementRef,
    ) {
    super(routerOverride, hostOverride);
  }

  override ngOnInit(): void {

  }

  override ngAfterViewInit(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        this.animationState = 'up';
    })})();
  }

  override closePopUp(): void {
    this.animationState = 'down';
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 300)).then(() => {
        this.popUpClosed.emit(true);
    })})();
  }
}
