import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, map, Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';
import { CarouselContent, GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { Category, CategoryDTO } from '../../_shared-components/list-item-cards/cardItem.model';
import { Partner, PartnerDTO } from '../partners.model';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  endOfScrollReached$ = new Subject<boolean>();
  infiniteScrollActive:boolean = false;

  currentPageIndex: number = 0;
  pageSize: number = 16;
  totalPages: number = 1;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getPartnersList(pageIndex: number, params: Params = {}): Observable<Array<Partner>> {
    const currentParams: Params = {
      ...params,
      pageSize: this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`partner-management/public/partner`, currentParams, '1.0', false, environment.useMockedData.partnersGetList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      // map((response: GetPartnersListReply | GenericErrorReply) => { // TODO uncomment all comments when pagination is implemented
      map((response: Array<PartnerDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetPartnersListReply;
          response = response as Array<PartnerDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const partnerDTO = response.items;
          const partnerDTO = response;
          const partnerList = this.getPartnersFromDTO(partnerDTO);

          return partnerList;
        } else {
          throw response;
        }
    }));
  }

  public getPartnersFromDTO(dto: Array<PartnerDTO>): Array<Partner> {
    let partnersList: Array<Partner> = [];

    dto.forEach((partnerDTO: PartnerDTO) => {
      partnersList.push({
        id: partnerDTO.id,
        tenantId: partnerDTO.tenantId,
        name: partnerDTO.name,
        description: partnerDTO.description,
        url: partnerDTO.url,
        categories: partnerDTO.categories ? this.getCategoriesFromDTO(partnerDTO.categories) : [],
        images: partnerDTO.images,
      });
    });

    return partnersList;
  }

  public getCategories(): Observable<Array<Category>> {
    return this.apiService.get(`partner-management/public/partner-category`, {}, '1.0', true, environment.useMockedData.partnersGetCategories).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<CategoryDTO>) => {
        if(this.generalService.isValidServerReply(response)) {
          const categoriesDTO = (response as Array<CategoryDTO>);
          const categoriesList = this.getCategoriesFromDTO(categoriesDTO);

          return categoriesList;
        } else {
          throw response;
        }
    }));
  }

  private getCategoriesFromDTO(dto: Array<CategoryDTO>): Array<Category> {
    let categoryList: Array<Category> = [];
    dto.forEach((categoryDTO: CategoryDTO) => {
      categoryList.push({
        id: categoryDTO.id,
        name: categoryDTO.name,
        isSelected: false
      });
    });

    return categoryList;
  }

  public getPartnerDetails(partnerName: string): Observable<Partner> {
    return this.apiService.get(`partner-management/public/partner/${partnerName}`, {}, '1.0', true, environment.useMockedData.partnersGetDetails, partnerName).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: PartnerDTO | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const partnerDTO = (response as PartnerDTO);
          const partner = this.getParterDetailFromDTO(partnerDTO);

          return partner;
        } else {
          throw response;
        }
    }));
  }

  private getParterDetailFromDTO(dto: PartnerDTO): Partner {
    let partnerDetail: Partner;

    partnerDetail = {
      id: dto.id,
      tenantId: dto.tenantId,
      name: dto.name,
      description: dto.description,
      url: dto.url,
      images: dto.images,
      contacts: dto.contacts,
      labels: dto.labels,
      categories: dto.categories
    }

    return partnerDetail;
  }

  public carouselContentFromPartners(partners: Array<Partner>): Array<CarouselContent> {
    let carouselContent: Array<CarouselContent> = [];

    partners.forEach(partner => {
      carouselContent.push({
        id: partner.id,
        title: partner.name,
        imageUrl: partner.images && partner.images.coverImage.href ? partner.images.coverImage.href : `assets/imgs/environments/${environment.tenantName}/default-images/default-image-small-rectangle.svg`,
        description: partner.description,
        iconUrl: 'assets/imgs/arrow-right-btn.svg',
        badgeIconUrl: partner.images && partner.images.profilePictureImage.href ? partner.images.profilePictureImage.href : undefined
      });
    });

    return carouselContent;
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }

  public donate(): void{
    //TODO Donate action when the user clicks the donate now button.
  }
}
