import * as lpn from 'google-libphonenumber';

export const phoneNumberValidator = (control: any) => {
  if (!control) {
    return null;
  }

  const phoneUtil = lpn.PhoneNumberUtil.getInstance();
  let countryCode: string;
  let phoneNumber: string;

  if (typeof control === 'string') {
    //ngModel
    [countryCode, phoneNumber] = control.split(',');
  } else {
    // FormControl
    phoneNumber = control.parent ? control.parent.get('phoneInput')?.value : null;
    countryCode = control.parent ? control.parent.get('countryCode')?.value.replace('+', '') : null;

    if (phoneNumber) {
      let parsedNumber;

      try {
        parsedNumber = phoneUtil.parseAndKeepRawInput('+' + countryCode + phoneNumber);
      } catch (error) {
        // Return null if there is an error parsing the number
        return { validatePhoneNumber: { valid: false } };
      }

      countryCode = phoneUtil.getRegionCodeForNumber(parsedNumber) ? phoneUtil.getRegionCodeForNumber(parsedNumber) + '' : 'PT' ;
    }
  }

  if (!countryCode || !phoneNumber) {
    return null;
  }

  let parsedNumber;

  try {
    parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
  } catch (error) {
    // Return null if there is an error parsing the number
    return { validatePhoneNumber: { valid: false } };
  }

  const nationalSignificantNumber = phoneUtil.getNationalSignificantNumber(parsedNumber);

  // Check if input starts with the selected country code, and remove it if it does
  if (nationalSignificantNumber.startsWith(countryCode)) {
    phoneNumber = nationalSignificantNumber.slice(countryCode.length);
  }

  try {
    const isValidNumber = phoneUtil.isValidNumberForRegion(parsedNumber, countryCode);

    if (isValidNumber) {
      return null;
    }
  } catch (error) {
    if ((error as Error).name === 'PhoneNumberError') {
      return { validatePhoneNumber: { valid: false } };
    } else {
      // Re-throw the error if it's not a PhoneNumberError
      throw error;
    }
  }

  return { validatePhoneNumber: { valid: false } };
};
