import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { ListHeaderModule } from 'src/app/_generic-components-lib/list-header-module/list-header.module';
import { ListItemModule } from 'src/app/_generic-components-lib/list-item-module/list-item-module.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DashboardModule } from '../_shared-components/dashboard-module/dashboard.module';
import { GenericListHeaderModule } from '../_shared-components/generic-list-header-module/generic-list-header.module';
import { GenericListItemModule } from '../_shared-components/generic-list-item-module/generic-list-item.module';
import { ItemImageSliderModule } from '../_shared-components/item-image-slider-module/item-image-slider.module';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { LoadingListModule } from '../_shared-components/loading-list-module/loading-list.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { MarketPlaceModule } from '../marketplace/marketplace.module';
import { ActivitiesHistoryListContainerComponent } from './history-container/activities-history-list-container/activities-history-list-container.component';
import { HistoryContainerComponent } from './history-container/history-container.component';
import { HistoryProductDetailsComponent } from './history-container/product-history-list-container/history-product-details/history-product-details.component';
import { ProductCodeListItemComponent } from './history-container/product-history-list-container/product-code-list-item/product-code-list-item.component';
import { ProductHistoryListContainerComponent } from './history-container/product-history-list-container/product-history-list-container.component';
import { TransactionsHistoryListContainerComponent } from './history-container/transactions-history-list-container/transactions-history-list-container.component';
import { ActivitiesModule } from '../activities/activities.module';


@NgModule({
  declarations: [
    HistoryContainerComponent,
    ProductHistoryListContainerComponent,
    ActivitiesHistoryListContainerComponent,
    TransactionsHistoryListContainerComponent,
    HistoryProductDetailsComponent,
    ProductCodeListItemComponent
  ],
  imports: [
    CommonModule,
    PageBannerModule,
    AppRoutingModule,
    ListItemModule,
    ListHeaderModule,
    NoItemsModule,
    LabelPipeModule,
    GenericListItemModule,
    GenericListHeaderModule,
    InfiniteScrollModule,
    SingleLineTextInputModule,
    ListItemCardsModule,
    MarketPlaceModule,
    ActivitiesModule,
    ItemImageSliderModule,
    LoadingListModule,
    DashboardModule
  ]
})
export class HistoryModule { }
