<!-- ##################### Product Description Banner ##################### -->
<div class="product-description-banner mt-5" [ngStyle]="{'flex-flow' : flexFlow}">
  <div class="product-info-container" [ngStyle]="{'width': productInfo.mainProductImageUrl ? '45%' : '100%'}">
    <img class="main-product-logo" [src]="productInfo.mainProductLogoUrl" *ngIf="productInfo.mainProductLogoUrl">

    <p class="product-title mt-5">{{ productInfo.mainProductTitle }}</p>
    <div class="product-description" [innerHTML]="productInfo.mainProductDescriptionHTML | safehtml" *ngIf="productInfo.mainProductDescriptionHTML"></div>

    <img class="product-certifications mt-5" [src]="productInfo.productCertificationsImageUrl" *ngIf="productInfo.productCertificationsImageUrl">
  </div>

  <img class="main-product-image" [src]="productInfo.mainProductImageUrl" *ngIf="productInfo.mainProductImageUrl">
</div>

<!-- ##################### Product Preview Banner ##################### -->
<img class="product-preview-banner" [src]="productInfo.productPreviewBannerImageUrl" *ngIf="productInfo.productPreviewBannerImageUrl">
