import { Injectable } from '@angular/core';
import { AboutUsInfo, AboutUsInfoDTO } from '../about-us.model';
import { Observable, catchError, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  constructor(
    private generalService: GeneralService,
    private apiService: ApiService
  ) { }

  public getAboutUsInfo(): Observable<AboutUsInfo> {
    return this.apiService.get(`about-us/public`, {}, '1.0', true, environment.useMockedData.aboutUsGetInfo, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }),
        map((response: AboutUsInfoDTO | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          response = response as AboutUsInfoDTO;

          const aboutUsInfoDTO = response;
          const aboutUsInfo = this.getInfoFromDTO(aboutUsInfoDTO);

          return aboutUsInfo;
        } else {
          throw response;
        }
    }));
  }

  private getInfoFromDTO(dto: AboutUsInfoDTO): AboutUsInfo {
    let info: AboutUsInfo;

    info = {
      aboutUsDescription: dto.aboutUsDescription,
      animation: dto.animation,
      animationMobile: dto.animationMobile,
      showOurTeam: dto.showOurTeam,
      ourTeamImageUrl: dto.ourTeamImageUrl
    };

    return info;
  }
}
