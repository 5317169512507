import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-items',
  templateUrl: './no-items.component.html',
  styleUrls: ['./no-items.component.sass']
})
export class NoItemsComponent implements OnInit {

  @Input() titleColor: string;
  @Input() descriptionColor: string;
  @Input() titleFontSize: string;
  @Input() titleFontWeight: string;
  @Input() descriptionFontSize: string;

  @Input() image: string;
  @Input() imageAlt: string;
  @Input() title: string;
  @Input() description: string;

  public environment = environment;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if(this.titleColor || this.descriptionColor || this.titleFontSize || this.descriptionFontSize || this.titleFontWeight) {
      if(this.titleColor) {
        this.host.nativeElement.style.setProperty('--title-color', this.titleColor)
      }

      if(this.descriptionColor) {
        this.host.nativeElement.style.setProperty('--description-color', this.descriptionColor);
      }

      if(this.titleFontSize) {
        this.host.nativeElement.style.setProperty('--title-font-size', this.titleFontSize);
      }

      if(this.descriptionFontSize) {
        this.host.nativeElement.style.setProperty('--description-font-size', this.descriptionFontSize);
      }

      if(this.titleFontWeight) {
        this.host.nativeElement.style.setProperty('--title-font-weight', this.titleFontWeight);
      }
    }
  }
}
