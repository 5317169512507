<div class="institution-details-wrapper">
  <ng-container *ngIf="isLoaded && partner">
    <app-page-banner
      [title]="partner.name"
      [description]="partner.description"
      [backgroundImageUrl]="partner.images.coverImage.href"
      [useGradient]="true"
      >
    </app-page-banner>

    <div class="institution-details-info-container">
      <!-- <div class="info-header">
        <p>{{ labelsService.partnersDetailsTitle | label }}</p>
        <span>{{ labelsService.partnersDetailsDescription | label }}</span>
      </div> -->

      <div class="info-section-wrapper" *ngIf="partner.labels && partner.labels.length > 0">
        <div class="info-section-top-container">
          <div class="info-section-left-wrapper">
            <div class="info-section-left-container">
              <p class="info-section-title">{{ labelsService.partnersDetailsSubTitle | label }}</p>

              <p class="info-section-desc" *ngIf="partner.labels.length > 0">
                {{ partner.labels[0].value }}
              </p>

              <!-- <app-generic-button
                [borderRadius]="'30'"
                [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
                [brandColor]="'var(--main-brand-color)'"
                [buttonName]="'Donate now'"
                [hasIcon]="true"
                [iconUrl]="'assets/mock/ActionIcon.svg'"
                [defaultIconColor]="'#ffffff'"
                [fontColor]="'#ffffff'"
                [largeButton]="false">
              </app-generic-button> -->
            </div>
          </div>

          <div class="info-section-right-wrapper">
            <div class="info-section-right-container">
              <img [src]="partner.images && partner.images.profilePictureImage.href ? partner.images.profilePictureImage.href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-square.svg'" alt="institution-logo">
            </div>
          </div>
        </div>

        <div class="info-section-bottom-container">
          <div class="info-section-left-wrapper">
            <div class="info-section-left-container">
              <span *ngIf="partner.labels.length > 1">{{ partner.labels[1].value }}</span>
              <p *ngIf="partner.labels.length > 2">{{ partner.labels[2].value }}</p>
            </div>
          </div>

          <div class="info-section-right-wrapper">
            <div class="info-section-right-container">
              <p *ngIf="partner.labels.length > 3" [innerHTML]="partner.labels[3].value"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section collections-container" *ngIf="isLoaded && ((productListToShow.length > 0 || queryParameters['search'] != null) || isListLoading)">
      <p class="section-title">{{ labelsService.partnersDetailsCollectionTitle | label }}</p>
      <p class="section-description">{{ labelsService.partnersDetailsCollectionDescription | label: { 'partner': partner.name } }}</p>

      <div class="collections-filters-header-container">
        <div class="search-container">
          <app-single-line-text-input
            [placeholderLabel]="labelService.partnerDetailsFilterSearchPlaceholder | label" [inputHeight]="'48px'" [fontSize]="'var(--main-info-font-size)'"
            [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [borderColor]="'transparent'"
            [prefixMargin]="'20px'" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
            [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true" [debounceTime]="0"
            (suffixClicked)="search()" (actionOnEnter)="search()" (valueChanged)="searchString = $event">
          </app-single-line-text-input>
        <!--
          <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)"
            [buttonText]="(generalService.isMobile | async)? '' : showFilters ? 'Close filters' : 'Open filters'" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
            [brandColor]="environment.brandStyleVariables.brandBackgroundColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainErrorColor"
            [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
            [imageUrl]="'assets/imgs/filter-icon.svg'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
            [isActive]="showFilters"
            (buttonActive)="showFilters = $event"></app-toggle-button>-->
        </div>
      </div>

      <div class="collections-mobile-filters-container custom-scroll" [ngClass]="{'active' : (generalService.isMobile | async) && showFilters}">
        <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)!" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
      </div>

      <div class="collections-list-wrapper">
        <div class="collections-list-container">
          <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
            <div class="chip" *ngFor="let chip of filterChips">
              <span>{{chip.name}}</span>
              <div class="delete-icon-container" (click)="deleteChip(chip)">
                <div class="close-icon">
                  <span class="cross-line"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="product-list-container w-100">
            <div class="product-list-wrapper" *ngIf="productListToShow.length > 0 || isListLoading; else noItemsFound">
               <!-- //TODO implement [quantityWarningNumber] and [quantityWarningColor] when Marketplace product quantity is Higher than one and we want to have a warning like quantity -->
              <ng-container *ngFor="let productCard of productListToShow; let index = index">
                <ng-container *ngIf="productCard.partner?.id">
                  <app-list-item-card class="product-list-card" [image]="productCard.images ? productCard.images[0].href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'"
                    [title]="productCard.name" (click)="productItemClicked(productCard.id)">
                    <ng-template>
                      <div class="w-100">
                        <app-product-content [prices]="productCard.prices!" [lowestPriceValue]="productCard.lowestPrice!" [quantity]="productCard.quantity!"
                          [category]="productCard.categories!" [serial]="productCard.serial!"></app-product-content>
                      </div>
                    </ng-template>
                  </app-list-item-card>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isListLoading">
                <ng-container *ngFor="let item of loadingListItems">
                  <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}"></app-loading-list-item>
                </ng-container>
              </ng-container>
            </div>
<!--             <div class="product-list-pagination-wrapper">
              <app-pagination [imageUrl]="'assets/imgs/pagination-arrow-icon.svg'" [imageDefaultColor]="environment.brandStyleVariables.mainBrandColor"
                [imageDisabledColor]="environment.brandStyleVariables.imageDisabledColor" [currentPageIndex]="pageIndex" [maxPageSize]="itemsPerPage" [morePagesAvailable]="pageIndex < availablePages" (changePage)="changePage($event)">
            </app-pagination>
            </div> -->
            <ng-template #noItemsFound>
              <div class="no-items-found">
                <app-no-items [image]="'assets/imgs/environments/' + environment.tenantName + '/empty-list-image.png'" [title]="labelService.partnersDetailsNoItems | label"></app-no-items>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="collections-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
          <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)!" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template *ngIf="isLoaded && !partner">
    <app-partner-not-found
     [title]="labelService.partnerDetailsNoDetailsTitle | label"
     [description]="labelService.partnerDetailsNoDetailsDesc | label"
     [image]="'assets/imgs/environments/' + environment.tenantName + '/empty-list-image.png'">
    </app-partner-not-found>
  </ng-template>
</div>
