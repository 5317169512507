import { TransactionStatus } from "../../history-module/_services/transactions/transactions.model";
import { OrderEntryStatusEnum } from "../../marketplace/_services/_order-entry/order-entry.model";

export interface ListItem {
  id: string;
  info: Array<Info>;
}

export interface Info {
  text?: string;
  entity?: Entity;
  value?: number;
  type: ListInfoType
  action?: string;
  sizes?: Sizes;
  status?: OrderEntryStatusEnum | TransactionStatus;
}

export enum ListInfoType {
  id,
  name,
  text,
  entity,
  status,
  date,
  value,
  action
}
export interface Sizes {
  size: number;
  sizeSmall?: number;
}

export interface Money {
  amount: number;
  unit: string;
}

export interface Actions {
  imageUrl: string;
  tooltipText?: string;
  actionId: ActionId;
  disabled?: boolean;
}

export interface Entity {
  name: string;
  imageUrl: string;
}

export enum ActionId {
  read
}

export enum ItemState {
  pending, // yellow //pendente
  completed, // green // finalizado
  failed, // red // erro
}
