<app-page-banner [title]="labelService.newsBannerTitle | label" [backgroundImageUrl]="'assets/mock/environments/Gamifyou/blog/unsplash_ln5drpv_ImI.png'"
  [imageUrl]="'assets/mock/environments/Gamifyou/blog/gamifas_pc.png'" [containerHeight]="'36vh'" [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [alignImageBottom]="true" [imageSize]="'35%'">
</app-page-banner>

<div class="section blog-container" *ngIf="isLoaded">
  <p class="section-title">{{labelService.newsListTitle | label}}</p>
  <p class="section-description">{{labelService.newsListDescription | label}}</p>

<!-- <div class="marketplace-filters-header-container" *ngIf="filterList && filterList.length > 0"> -->
  <!-- <div class="blog-filters-header-container">
    <div class="search-container">
      <app-single-line-text-input
        [placeholderLabel]="labelService.newsListSearchPlaceholder | label" [inputHeightPx]="48" [fontSize]="'var(--main-info-font-size)'"
        [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [roundBorders]="true" [roundedBorderPx]="30" [borderColor]="'transparent'"
        [prefixMarginPx]="20" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
        [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true"
        (suffixClicked)="search()" (actionOnEnter)="search()" (valueChanged)="searchString = $event">
      </app-single-line-text-input>

      <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)" [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [toggleHeight]="40" [toggleWidth]="40"
        [buttonText]="(generalService.isMobile | async)? '' : showFilters ? (labelService.newsListFilterButtonCloseLabel | label) : (labelService.newsListFilterButtonLabel | label)" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainFontColor"
        [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [iconClass]="'bi bi-funnel'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [isActive]="showFilters" (buttonActive)="showFilters = $event">
      </app-toggle-button>
    </div>
  </div>

  <div class="blog-mobile-filters-container custom-scroll" [ngClass]="{'active' : (generalService.isMobile | async) && showFilters}">
    <app-filters-list [filterList]="filterList" [filtersTitle]="labelService.newsListFiltersTitle | label" [clearFiltersLabel]="labelService.newsListFiltersClearLabel | label" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
  </div> -->

  <div class="blog-list-wrapper" [ngStyle]="{'gap': showFilters && !(generalService.isMobile | async) ? '40px' : '0' }">
    <div class="blog-list-container">
      <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
        <div class="chip" *ngFor="let chip of filterChips">
          <span>{{chip.name}}</span>
          <div class="delete-icon-container" (click)="deleteChip(chip)">
            <div class="close-icon">
              <span class="cross-line"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="article-list-container w-100"
        infiniteScroll
        [infiniteScrollDistance]="listPercentageFromBottom"
        [infiniteScrollThrottle]="50"
        [immediateCheck]="true"
        [scrollWindow]="false"
        [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
        (scrolled)="onScroll()"
        #articleContainerList>
        <div class="article-list-wrapper" *ngIf="articleList.length > 0 || isListLoading; else noItemsFound">
          <ng-container *ngFor="let articleCard of articleList">
            <app-list-item-card class="article-list-card" [isImageVertical]="true" [image]="articleCard.image.profilePictureImage  ? articleCard.image.profilePictureImage.href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'"
              [title]="articleCard.title" (click)="articleItemClicked(articleCard.id)">
              <ng-template>
                <app-blog-list-item [articleDescription]="articleCard.description" [publishDate]="articleCard.publishDate"></app-blog-list-item>
              </ng-template>
            </app-list-item-card>
          </ng-container>

          <ng-container *ngIf="isListLoading">
            <ng-container *ngFor="let item of loadingListItems">
              <app-loading-list-item class="loading-item" [isImageVertical]="true" [ngClass]="{'opacity-0': !isListLoading}">
                <ng-template>
                  <app-blog-loading-content></app-blog-loading-content>
                </ng-template>
              </app-loading-list-item>
            </ng-container>
          </ng-container>
        </div>
        <ng-template #noItemsFound>
          <div class="no-items-found">
            <app-no-items [image]="'assets/imgs/environments/' + environment.tenantName + '/empty-list-image.png'" [title]="labelService.markplaceListNoItems | label"></app-no-items>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="blog-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
      <app-filters-list [filtersTitle]="labelService.newsListFiltersTitle | label" [clearFiltersLabel]="labelService.newsListFiltersClearLabel | label" [filterList]="filterList" [isMobile]="(generalService.isMobile | async)!" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
    </div>
  </div>
</div>
