import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { environment } from 'src/environments/environment';
import { FilterListModule } from '../_shared-components/filters-list-module/filter-list.module';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { LoadingListModule } from '../_shared-components/loading-list-module/loading-list.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { BlogContentComponent } from './blog-content/blog-content.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogLoadingContentComponent } from './blog-loading-content/blog-loading-content.component';
import { BlogListItemComponent } from './blog-list-item/blog-list-item.component';
import { SafeHtmlPipeModule } from 'src/app/_shared-modules/safe-html-pipe-module/safe-html-pipe.module';


@NgModule({
  declarations: [
    BlogListComponent,
    BlogContentComponent,
    BlogLoadingContentComponent,
    BlogListItemComponent
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    PageBannerModule,
    LabelPipeModule,
    FilterListModule,
    SingleLineTextInputModule,
    LoadingListModule,
    InfiniteScrollModule,
    ListItemCardsModule,
    SafeHtmlPipeModule,
    NoItemsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  exports: [
    BlogListComponent,
    BlogContentComponent
  ]
})
export class BlogModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
