import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { OurProductsIntroBanner } from '../our-products.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-intro-banner',
  templateUrl: './intro-banner.component.html',
  styleUrls: ['./intro-banner.component.sass']
})
export class IntroBannerComponent implements OnInit {

  @Input() bannerInfo: OurProductsIntroBanner;
  @Input() flexFlow: string;

  constructor(
    private host: ElementRef
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    this.host.nativeElement.style.setProperty('--subtitle-color', environment.brandStyleVariables.pseudoElementHighlightColor);
    this.host.nativeElement.style.setProperty('--pseudo-element-color', environment.brandStyleVariables.pseudoElementHighlightColor);
  }

}
