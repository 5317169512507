<div class="list-item-container w-100 pb-3" [ngClass]="{'is-blocked': isBlocked, 'vertical': isImageVertical, 'highlighted': highlighted}">
  <div class="main-img-container w-100" [ngClass]="{'mb-2': !isImageVertical}">
    <div class="item-type-container" *ngIf="itemTypeImage">
      <img [src]="itemTypeImage" alt="{{ itemType | translate}}">
    </div>
    <img class="w-100 main-image" [src]="image ? image : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'" alt="{{title}}">
  </div>

  <div class="title-container w-100">
    <p class="item-title" [ngClass]="{'ps-3 pe-3 mt-2 mb-2': !isImageVertical}">{{ title }}</p>
  </div>

  <ng-container *ngTemplateOutlet="tmplsArray[0]"></ng-container>
</div>
