import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject } from 'rxjs';
import { Params } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityListItem, ActivityListItemDTO } from 'src/app/pages/activities/activities.model';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { ActivitiesService } from 'src/app/pages/activities/_services/activities/activities.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesHistoryService {

  endOfScrollReached$ = new Subject<boolean>();
  infiniteScrollActive:boolean = false;

  currentPageIndex: number = 0;
  pageSize: number = 16;
  totalPages: number = 1;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private activitiesService: ActivitiesService
  ) { }

  public getActivityHistoryList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<ActivityListItem>> {
    const currentParams: Params = {
      ...params,
      pageSize: pageSize ? pageSize : this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`activity-management/public/activity/history`, currentParams, '1.0', false, environment.useMockedData.activitiesGetList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      // map((response: GetActivityListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
        map((response: Array<ActivityListItemDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetActivityListReply;
          response = (response ? response  : []) as Array<ActivityListItemDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const activityListDTO = response.items;
          const activityListDTO = response;
          const activityList = this.activitiesService.getActivityListFromDTO(activityListDTO);
          return activityList;
        } else {
          throw response;
        }
    }));
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
