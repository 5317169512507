import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceDirective } from './debounce-directive/debounce-directive.service';

@NgModule({
  declarations: [
    DebounceDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DebounceDirective,
  ]
})
export class DebounceModule { }
