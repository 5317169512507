import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LanguagesService } from 'src/app/_services/_languages/languages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-list-item',
  templateUrl: './blog-list-item.component.html',
  styleUrls: ['./blog-list-item.component.sass']
})
export class BlogListItemComponent implements OnInit {
  @Input() articleDescription: string;
  @Input() publishDate: string;

  public environment = environment;

  constructor(
    public languageService: LanguagesService,
    private translateService: TranslateService,
    public labelsService: LabelsService,
    public generalService: GeneralService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = this.environment.defaultLanguage;
    this.translateService.setDefaultLang(this.environment.defaultLanguage);
    this.translateService.use(this.environment.defaultLanguage);
  }

  ngOnInit(): void {
  }
}
