import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { ToggleButtonComponent } from '../buttons-module/toggle-button/toggle-button.component';
import { GenericButtonComponent } from './generic-button/generic-button.component';
import { GenericIconButtonComponent } from './generic-icon-button/generic-icon-button.component';

@NgModule({
  declarations: [
    ToggleButtonComponent,
    GenericButtonComponent,
    GenericIconButtonComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ToggleButtonComponent,
    GenericButtonComponent,
    GenericIconButtonComponent
  ],
  providers: [
    HttpClient,
    LibraryService
  ]
})
export class ButtonsModule { }
