import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-loading-content',
  templateUrl: './product-loading-content.component.html',
  styleUrls: ['./product-loading-content.component.sass']
})
export class ProductLoadingContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
