import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { OrderEntryStatusEnum } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.model';
import { ListInfoType, ListItem } from '../generic-list-item.model';

@Component({
  selector: 'app-generic-list-item',
  templateUrl: './generic-list-item.component.html',
  styleUrls: ['./generic-list-item.component.sass']
})
export class GenericListItemComponent implements OnInit {

  @Input() fontColor: string;
  @Input() brandColor: string;
  @Input() errorColor: string;
  @Input() statePendingColor: string;
  @Input() stateCompletedColor: string;
  @Input() stateFailedColor: string;

  @Input() fontSize: string;

  @Input() item: ListItem;

  @Input() useSize: boolean;

  @Input() staticFirstElement: boolean;

  @Output() itemEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() actionEvent: EventEmitter<string> = new EventEmitter<string>();

  itemStatus = OrderEntryStatusEnum;
  itemInfoType = ListInfoType;

  actionActive: boolean = false;
  enableClick: boolean = true;

  constructor(
    private host: ElementRef,
    public generalService: GeneralService
    ) { }

  ngOnInit(): void {
  }


  //Click event for the multi function button
  actionClicked(actionId: string): void {
    this.actionEvent.emit(actionId);
  }

  setCSSVars(): void {
    if (this.fontColor || this.brandColor || this.errorColor || this.statePendingColor || this.stateCompletedColor || this.stateFailedColor || this.fontSize) {

      //set variables
      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.fontColor);
      if (this.brandColor)
        this.host.nativeElement.style.setProperty('--brand-color', this.brandColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--error-color', this.errorColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--state-pending-color', this.statePendingColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--state-completed-color', this.stateCompletedColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--state-failed-color', this.stateFailedColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);
    }
  }
}
