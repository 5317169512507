import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';
import { CustomSnackBarData, SnackBarStatusType } from './snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  /**
   * @param message main message to show
   * @param action text to appear in a button that closes the snack bar
   * @param type shows a custom image depending on the status
   * @param durationMillis auto hide the snack bar after x mili seconds
   */
   openSnackBar(message: string, action: string = '', type: SnackBarStatusType = SnackBarStatusType.none, durationMillis: number = 5000, verticalPosition: MatSnackBarVerticalPosition = 'bottom', horizontalPosition: MatSnackBarHorizontalPosition = 'center', prefixUrl: string = ''): MatSnackBarRef<SnackbarComponent> {

    let panelClass: string = '';
    let prefixIconUrl: string = '';

    switch (type) {
      case SnackBarStatusType.success:
        panelClass = 'snack-bar-success';
        break;
      case SnackBarStatusType.error:
        panelClass ='snack-bar-error';
        prefixIconUrl = 'assets/imgs/error-icon.svg';
        break;
      case SnackBarStatusType.warning:
        panelClass ='snack-bar-warning';
        prefixIconUrl = 'assets/imgs/warning-icon.svg';
        break;
      case SnackBarStatusType.info:
        panelClass ='snack-bar-info';
        prefixIconUrl = prefixUrl;
        break;
      default:
        break;
    }

    const data: CustomSnackBarData = {
      snackbarClass: panelClass,
      prefixIconUrl: prefixIconUrl,
      message: message,
      showCloseIcon: true,
      action: action
    };

    return this.snackBar.openFromComponent(SnackbarComponent, {
      data,
      duration: durationMillis,
      verticalPosition,
      horizontalPosition
    });
  }

}
