import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './radio-group.component.sass'
  ]
})
export class RadioGroupComponent extends ButtonBaseComponent implements OnInit, OnChanges {

  @Input() vertical: boolean;
  @Input() labelFirst: boolean;
  @Input() savedValue: number | null;

  @Input() radioButtons: Array<RadioButton>;

  selectedValue: number | undefined;

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['radioButtons']) {
      this.selectedValue = this.radioButtons.find(b => b.selected)?.value;
    }

    if(changes['savedValue'] && changes['savedValue'].currentValue != null) {
      this.selectedValue = this.savedValue!;
    }
  }

  public onValueChange(input: number): void {
    this.valueChanged.emit(input);
  }
}

export interface RadioButton {
  label: string;
  value: number;
  selected?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
}
