import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { HtmlContents } from '../terms.model';
import { TermsAndConditionsService } from '../_services/terms-and-conditions.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.sass'],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy, AfterViewInit {

  termsContentArr: Array<HtmlContents>;

  tabsBodyHtml: Array<string> = [];

  destroy: Subject<boolean> = new Subject<boolean>();

  startingTab: number;

  constructor(
    public termsService: TermsAndConditionsService,
    private router: Router,
    private location: Location,
    private generalService: GeneralService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.termsService.getTerms().pipe(takeUntil(this.destroy)).subscribe((resp: Array<HtmlContents>) => {
      this.termsContentArr = resp;
      this.termsService.initTabs(resp);
      resp.forEach((tab, index) => {
        if(tab.url === this.router.url){
          this.startingTab = index;
          tab.html = tab.html.replaceAll('$selfUrl$', this.router.url);
        }

        this.tabsBodyHtml.push(tab.html);
      });
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('terms-and-conditions');
  }

  public navigateTo(tabId: string): void {
    const tabIndex = this.termsContentArr.findIndex(term => term.id === tabId);
    const url: string = this.termsContentArr[tabIndex].url;

    this.tabsBodyHtml[tabIndex] = this.tabsBodyHtml[tabIndex].replaceAll('$selfUrl$', url);
    this.location.go(url);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
