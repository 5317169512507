export interface TutorialStepDTO {
  subtitle: string;
  title: string;
  description: string;
  image: ImageContent;
}

export interface TutorialStep {
  subtitle: string;
  title: string;
  description: string;
  image: ImageContent;
  selectedStep: boolean;
}

export interface ImageContent {
  type?: ImageType;
  url: string;
}

export enum ImageType {
  IMAGE = 'IMAGE',
  LOTTIE = 'LOTTIE',
  RIVE = 'RIVE'
}
