import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './checkbox.component.sass'
  ]
})
export class CheckboxComponent extends ButtonBaseComponent implements OnInit {

  @Input() gapSize: string;

  @Input() flexDirection: string;

  @Input() disabled: boolean;
  @Input() checked: boolean;

  @Input() fCName: string;
  @Input() fGName: string;

  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    this.setCSSVars();
    super.setSpecificCSSVars();
  }

  public onValueChange(): void {
    if(!this.disabled)
      this.valueChanged.emit(!this.checked);
  }
}
