import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { DropdownOption } from '../dropdown.model';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/dropdown-base-component/dropdown-base.component.sass',
    './dropdown-search.component.sass',
    '../generic-dropdown/generic-dropdown.component.sass'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class DropdownSearchComponent extends DropdownBaseComponent implements OnInit, OnChanges {

  @Input() hasSearchIcon: boolean;

  @Input() activeText: boolean = false;
  @Input() searchPlaceholder: string;

  @Input() currentPageSize: number;
  @Input() pageIncrement: number;

  @Output() searchedValue: EventEmitter<string> = new EventEmitter<string>();

  public searched: boolean;
  public hover: boolean;
  public dropdownOpen: boolean = false;
  public searchFocused: boolean = false;

  public searchValue: string = '';

  public selectedOption?: DropdownOption;
  public searchOptions: Array<DropdownOption>;

  public form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    if(this.optionToSelect) {
      this.selectedOption = this.optionToSelect;
      this.searchValue = this.optionToSelect.name;
    }

    this.setCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['optionToSelect']?.previousValue !== changes['optionToSelect']?.currentValue) {
      if(this.optionToSelect) {
        this.toggleOptionSelected(this.optionToSelect.id);
      } else {
        this.clearSelection();
      }
    }
  }

  forceFocus(): void {
    if (!this.disabled) {
      const container: any = document.getElementById('dropdownContainer')!;
      if(container !== null) {
      setTimeout(() => {
          container.parentElement.focus()
        }, 200);
      }
    }
  }

  search(): void {
    if(!this.disabled) {
      this.currentPageSize = this.pageIncrement;
      this.searched = this.form ? (this.form.get(this.fCName)?.value != '' ? true : false) : (this.searchValue != '' ? true : false);
      this.searchedValue = this.form ? this.form.get(this.fCName)?.value : this.searchValue;
      this.searchFocused = false;
      (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 150)).then(() => {
            this.dropdownOpen = false;
        });
    })();
    }
  }

  clearSelection(): void {
    this.dropdownOptions.forEach((option: DropdownOption) => {
      option.selected = false;
    });
    this.selectedOption = undefined;
  }

  checkEnter(event: KeyboardEvent): void {
    if(event.key === 'Enter') {
      this.search();
    }
  }

  toggleOptionSelected(optionId: string | number): void {
    this.dropdownOptions.forEach(option => {
      if(option.id === optionId) {
        option.selected = true;
        this.selectedOption = option;
        if(this.form) {
          this.form.get(this.fCName)!.setValue(option.name);
        }
      } else {
        option.selected = false;
      }
    });

    this.searchFocused = false;
    this.dropdownOpen = false;
    if(!this.form)
      this.searchValue = this.selectedOption!.name;

    this.currentPageSize = this.pageIncrement;
    this.optionSelected.emit(this.selectedOption);
  }

  public clickOutside(event: any) {
    this.currentPageSize = this.pageIncrement;

    event.preventDefault();
    event.stopPropagation();
    this.searchFocused = false;

    (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 150)).then(() => {
            this.dropdownOpen = false;
        });
    })();
  }
}
