<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link *ngFor="let link of NavMenuItems" [routerLink]="link.url" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive" [routerLinkActiveOptions]="{exact: true}" (click)="emitClickedTab(link.id)">
    <i *ngIf="link.hasRouteIcon" class="{{link.routeIcon}}"></i>
    <span>{{link.name}}</span>
  </a>
</nav>
<div class="page-router-container">
  <mat-tab-nav-panel #tabPanel [@FadeIn]="globalAnimationState">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
