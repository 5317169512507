
<div class="sub-tasks-progress-container d-flex flex-column mb-3">
  <p class="title mb-2" *ngIf="labelsService.activityMultiStepTitle | label">{{ labelsService.activityMultiStepTitle | label }}</p>
  <p class="subtitle mb-4" *ngIf="labelsService.activityMultiStepSubtitle | label">{{ labelsService.activityMultiStepSubtitle | label }}</p>

  <div class="progress-wrapper">
    <div class="info-container">
      <p class="tasks-progress">
        {{ completedTasks + '/' + totalTasks + ' ' + ( labelsService.activityMultiStepTaskName | label)}}
      </p>

      <p class="progress-bar-title" *ngIf="labelsService.activityMultiStepProgressBarTitle | label">
        {{ labelsService.activityMultiStepProgressBarTitle | label }}
      </p>

      <div class="progress-bar-container">
        <ng-progress
          #completedTasksProgressBar
          [id]="activity.activityId"
          [direction]="'ltr+'"
          [speed]="800"
          [debounceTime]="0"
          [meteor]="false"
          [spinner]="false"
          [fixed]="false"
          [min]="0"
          [max]="100"
          [ease]="'linear'"
          [color]="'#5ED788'">
        </ng-progress>

        <span> {{ activity.totalCompletedSubActivityPercentage }}%</span>
      </div>
    </div>

    <div class="animation-container" *ngIf="!isMobile" [ngSwitch]="environment.animations.activityMultiStep.type">
      <!-- Rive -->
      <ng-container *ngSwitchCase="'RIVE'">
        <canvas #canvasRive class="w-100">
        </canvas>
      </ng-container>

      <!-- Lottie -->
      <ng-container *ngSwitchCase="'LOTTIE'">
        <ng-lottie #lottieAnimation
          class="w-100"
          [width]="'100%'"
          [height]="'100%'"
          [options]="lottieOptions"
          (animationCreated)="lottieAnimationItem = $event;">
        </ng-lottie>
      </ng-container>

      <!-- GIF -->
      <ng-container *ngSwitchCase="'GIF'">
        <img class="w-100" [src]="environment.animations.activityMultiStep.url" alt="loading...">
      </ng-container>

      <!-- Img -->
      <ng-container *ngSwitchCase="'IMAGE'">
        <img class="w-100" [src]="environment.animations.activityMultiStep.url" alt="">
      </ng-container>
    </div>

  </div>

  <div class="check-tasks-button align-self-end">
    <app-generic-button [buttonName]="completedTasks > 0 ? (labelsService.activityMultiStepCheckTasksBtn | label) : (labelsService.activityMultiStepStartTasksBtn | label)" [borderRadius]="'50px'"
      [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
      [buttonSpacing]="'calc(var(--main-info-font-size) * 1.1) calc(var(--main-info-font-size) * 3)'"
      [fontWeight]="'700'" (click)="checkTasks()">
    </app-generic-button>
  </div>
</div>
