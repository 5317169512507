<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="'36vh'"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

<div class="home-page-container" *ngIf="isLoaded">
  <!-- ####### User Goal Info ####### -->
  <div class="section user-goal-container">
    <p class="w-100 section-title" *ngIf="labelService.homePageUserGoalTitle | label">{{ labelService.homePageUserGoalTitle | label }}</p>
    <!-- ### User progress card ### -->
    <div class="goal-progress-card">
      <div class="progress-bar-wrapper">
        <div class="progress-bar-container">
          <div class="start-progress-circle">
            <i class="bi bi-check-lg"></i>
          </div>
          <span class="user-balance" *ngIf="generalService.userDefaultWallet">{{ generalService.userDefaultWallet.currentValue }}</span>
          <ng-progress
            #progressBar
            [direction]="'ltr+'"
            [speed]="800"
            [debounceTime]="0"
            [meteor]="false"
            [spinner]="false"
            [fixed]="false"
            [min]="0"
            [max]="100"
            [ease]="'linear'"
            [color]="'var(--main-brand-color)'"
            (completed)="progressAnimationCompleted()">
          </ng-progress>
          <div class="end-progress-circle">
            <i class="bi bi-record2"></i>
          </div>

          <div class="user-goal-value-container" *ngIf="userHasGoal">
            <p class="goal-progress-lable" *ngIf="labelService.homePageUserGoalProgressLabel | label">{{ labelService.homePageUserGoalProgressLabel | label }}</p>
            <div class="user-goal-value">{{ this.userGoal.prices[0].amount }}</div>
          </div>
        </div>
      </div>

      <!-- Rive -->
      <div class="rive-animation-container">
        <app-rive-animation-component
          [loop]="true"
          [animationFileUrl]="'assets/imgs/environments/' + environment.tenantName + '/home/goal-progress-animation.riv'"></app-rive-animation-component>
      </div>
    </div>

    <!-- ### Balance to win card ### -->
    <div class="available-balance-to-win-card">
      <div class="text-container">
        <p class="card-title">{{ labelService.homePageAvailableToWinCardTitle | label }}</p>

        <div class="winning-potencials">
          <ng-container *ngIf="!potencialRewardsLoading && potencialRewards; else loading">
            <div class="activities-potencial-winnings">
              <div>
                <p class="potencial-prize-label" *ngIf="labelService.homePageAvailableToWinTargetLabel | label">{{ labelService.homePageAvailableToWinTargetLabel | label }}</p>
                <p class="target-name" *ngIf="labelService.homePageAvailableToWinFirstTargetName | label">{{ labelService.homePageAvailableToWinFirstTargetName | label }}</p>
              </div>
              <div>
                <p class="potencial-prize-value">{{ potencialRewards.potentialPartnerRewards }}</p>
                <p class="potencial-prize-currency" *ngIf="labelService.homePageAvailableToWinCurrencyName | label">{{ labelService.homePageAvailableToWinCurrencyName | label }}</p>
              </div>
            </div>

            <div class="input-activities-potencial-winnings">
              <div>
                <p class="potencial-prize-label" *ngIf="labelService.homePageAvailableToWinTargetLabel | label">{{ labelService.homePageAvailableToWinTargetLabel | label }}</p>
                <p class="target-name" *ngIf="labelService.homePageAvailableToWinSecondTargetName | label">{{ labelService.homePageAvailableToWinSecondTargetName | label }}</p>
              </div>
              <div>
                <p class="potencial-prize-value">{{ potencialRewards.potentialUserInputRewards }}</p>
                <p class="potencial-prize-currency" *ngIf="labelService.homePageAvailableToWinCurrencyName | label">{{ labelService.homePageAvailableToWinCurrencyName | label }}</p>
              </div>
            </div>
          </ng-container>
          <ng-template #loading>
            <app-main-loading class="loading"></app-main-loading>
          </ng-template>
        </div>
      </div>
      <img [src]="'assets/imgs/environments/' + environment.tenantName + '/home/available-to-win-card-img.png'">
    </div>

    <!-- ### Goal info card ### -->
    <ng-container *ngIf="userHasGoal; else emptyGoal">
      <div class="goal-info-card" [ngStyle]="{ 'background-image': 'url(' + userGoalBackgroundImg + ')'}">
        <div class="info-container">

          <app-generic-icon-button class="edit-goal-btn" [iconClass]="'bi bi-pencil'" [iconColor]="'var(--main-brand-color)'" [hoveredIconColor]="'var(--main-brand-color)'"
            [iconSize]="'var(--header-font-size)'" [buttonBackgroundColor]="environment.brandStyleVariables.mainBackgroundColor"
            [hoveredBackgroundColor]="environment.brandStyleVariables.mainBackgroundColor" [buttonSize]="'calc(var(--small-section-description-font-size) * 2)'"
            (buttonAction)="promptEditGoal()"></app-generic-icon-button>

          <p class="info-label">{{ labelService.homePageUserGoalCardTitle | label }}</p>

          <div class="goal-info">
            <p class="goal-value">{{ 'marketplace_listitem_price' | label: { price: this.userGoal.prices[0].amount, unit: getCurrencySymbol(this.userGoal.prices[0].unit, 'narrow') | label } }}</p>

            <div class="goal-title-redirect">
              <span class="goal-title">{{ this.userGoal.name }}</span>

              <div class="goal-redirect-btn" (click)="redirectToGoal()">
                <i class="bi bi-arrow-right-short"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #emptyGoal>
      <div class="goal-info-card empty-goal">
        <div class="info-container">
          <p class="info-label">{{ labelService.homePageUserGoalCardTitle | label }}</p>

          <p class="add-goal-text-line">{{ labelService.homePageUserGoalCardEmptyTitle | label }}</p>
          <p class="add-goal-text-line">{{ labelService.homePageUserGoalCardEmptySubtitle | label }}</p>

          <div class="goal-info">

            <div class="goal-title-redirect">
              <span class="goal-title" [ngStyle]="{ 'color': 'var(--main-brand-color)' }">{{ labelService.homePageUserGoalCardEmptyButtonLabel | label }}</span>

              <div class="goal-redirect-btn" (click)="promptEditGoal()">
                <i class="bi bi-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- ####### Activities List ####### -->
  <div class="activities-list-wrapper section">
    <p class="section-title" *ngIf="labelService.homePageActivityListTitle | label">{{ labelService.homePageActivityListTitle | label }}</p>
    <p class="section-subtitle" *ngIf="labelService.homePageActivityListDescription | label">{{ labelService.homePageActivityListDescription | label }}</p>

    <div class="activities-list-container" *ngIf="activitiesList.length > 0; else noItemsFound">
      <div class="activity-list-header">
        <app-list-header *ngIf="activitiesHeaderList.length > 0"
          [fullSize]="!(generalService.isMobile | async)" [fontSize]="'var(--main-info-font-size)'" [fontColor]="environment.brandStyleVariables.mainBorderColor"
          [headerList]="activitiesHeaderList" [showArrows]="false" [clickable]="false"></app-list-header>
      </div>

      <div class="activity-list" #activityListContainer>
        <ng-container *ngIf="activitiesList.length > 0">
          <ng-container *ngFor="let item of activitiesList">
            <app-list-item-expandable [fontSize]="'var(--header-font-size)'" [expandableFontSize]="'var(--small-font-size)'" [item]="item" [expandableItem]="item.infoExpandable!"
             [expandableHeader]="homeService.expandableHeaders" [headerFontWeight]="'600'" [backgroundOpacity]="'1'" [fullSize]="!(generalService.isMobile | async)!" [showId]="false"
             [isMobile]="(generalService.isMobile | async)!" [expandableBackgroundOpacity]="'0%'" [backgroundOpacity]="'10%'" [stateActiveColor]="'var(--main-brand-color)'"
             (actionEvent)="listItemActionClicked($event, item.id)">
            </app-list-item-expandable>

            <!-- <app-list-item [item]="item" [itemMargin]="'1vw 0'" [fullSize]="!(generalService.isMobile | async)" ></app-list-item> -->
          </ng-container>
        </ng-container>
      </div>

      <p class="show-full-activity-list" (click)="showMoreActivities()">
        {{ labelService.homePageActivityListShowMoreLabel | label }}
      </p>
    </div>

    <ng-template #noItemsFound>
      <div class="not-items-found mt-5 mb-5">
        <app-no-items [image]="'assets/imgs/environments/'+ environment.tenantName + '/empty-list-image.png'" [title]="labelService.homePageEmptyActivitiesListTitle | label"
          [titleColor]="'#424242'" [titleFontSize]="'var(--intermediate-title-font-size)'" [titleFontWeight]="'600'"></app-no-items>
      </div>
    </ng-template>
  </div>
</div>

<app-edit-user-goal-popup class="edit-user-goal-popup-container" *ngIf="showEditUserGoalPopup"
  [isAddingGoal]="!userHasGoal" (popUpClosed)="showEditUserGoalPopup = false;" (newGoalSelected)="goalSelected($event)"></app-edit-user-goal-popup>
