import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipeModule } from 'src/app/_shared-modules/safe-html-pipe-module/safe-html-pipe.module';
import { LibraryService } from '../../__services/library.service';
import { GenericTabsComponent } from './generic-tabs/generic-tabs.component';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';

@NgModule({
  declarations: [
    GenericTabsComponent,
    NavigationTabsComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    SafeHtmlPipeModule
  ],
  exports: [
    GenericTabsComponent, NavigationTabsComponent
  ],
  providers: [LibraryService]
})
export class GenericTabsModule { }
