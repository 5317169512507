import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../../_services/_products/products.service';
import { Product, ProductPartner } from '../../_services/_products/products.service.model';

import { getCurrencySymbol } from '@angular/common';
import { Currency } from 'src/app/_services/_general-service/general-service.model';
import { LoggedUserService } from 'src/app/_services/_logged_user_service/logged-user.service';
import { PaymentTypeEnum } from '../../_services/_order-entry/order-entry.model';

@Component({
  selector: 'app-voucher-details',
  templateUrl: './voucher-details.component.html',
  styleUrls: ['./voucher-details.component.sass'],
})
export class VoucherDetailsComponent implements OnInit {

  @Input() product: Product;
  @Input() partner: ProductPartner | undefined;
  @Input() isBlocked: boolean;
  @Input() isHistory: boolean;

  public environment = environment;
  public getCurrencySymbol = getCurrencySymbol;

  public userGrantedPermission: boolean = false;
  public userDistance: number;

  public showPurchasePopup: boolean = false;

  public currency: Currency;
  public selectedPaymentType: PaymentTypeEnum;

  constructor(
    public labelService: LabelsService,
    public generalService: GeneralService,
    public translateService: TranslateService,
    private popupService: PopupService,
    private labelPipe: LabelPipe,
    private productService: ProductsService,
    private loggedUserService: LoggedUserService,
    private host: ElementRef
  ) { }

  ngOnInit(): void {
    this.setCssVars();
    this.checkUserDistance();

    const currencyAux: Currency | undefined = this.generalService.generalInfo.brandingInfo.currencies?.find(c => c.type === this.product.prices[0].unit);

    this.currency = currencyAux ? currencyAux : {
      type: this.product.prices[0].unit,
      name: this.product.prices[0].unit,
      requiresWallet: true,
      paymentTypes: []
    };
  }

  public redirectToMission(): void {
    this.generalService.navigateTo('/activities');
  }

  public openPurchasePopup(): void {
    if(this.generalService.isUserLoggedIn$.value) {
      // switch case to change action based on payment type
      const selectedCurrency =
        this.generalService.generalInfo.brandingInfo.currencies?.find(c => c.type === this.product.prices[0].unit);

      if (selectedCurrency) {
        this.selectedPaymentType = selectedCurrency?.paymentTypes[0];

        switch(this.selectedPaymentType) {
          case PaymentTypeEnum.GAMIFICATION_COIN:
            this.showPurchasePopup = true;
            break;

          default:
            // TODO
            break;
        }
      } else {
        // TODO Error case
      }

      // this.goToPaymentPage();

    } else {
      this.popupService.setPopupToOpen(
        {
          text: ''
        },
        `assets/imgs/environments/${environment.tenantName}/popup-login-profile-image.png`,
        this.popupService.getTitle(this.labelPipe.transform(this.labelService.popupWelcomeTitle).replace('{{client}}', environment.tenantName)),
        [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.popupWelcomeDescription))],
        [{
          text: this.labelPipe.transform(this.labelService.popupWelcomeButtonLogin),
          isCloseBtn: false,
          actionText: 'login',
          style: {
            backgroundColor: environment.brandStyleVariables.mainBrandColor,
            fontColor: environment.brandStyleVariables.mainBackgroundColor
          }
        }/*, {
          text: this.labelPipe.transform(this.labelService.popupWelcomeButtonCancel),
          isCloseBtn: true,
          actionText: '',
          backgroundColor: '#ff3e3e',
          textColor: '#ffffff'
        } */],
        true
      );
    }
  }

  private goToPaymentPage(): void {
    this.productService.productInfo = this.product;

    let queryParams: Params = {};
    queryParams['quantity'] = '1';

    this.generalService.navigateToWithQueryParams(`marketplace/product/${this.product.id}/payment-page`, queryParams);

    this.generalService.autoScrollTop('page-router-container');
  }

  private checkUserDistance(): void {
    if (this.product.locations && this.product.locations.some(location => location.gpsCoords != null)) {
      this.loggedUserService.getCurrentPosition(
        (position: GeolocationPosition) => {
          // Handle successful geolocation updates
          console.log('Geolocation updated:', position);

          this.userGrantedPermission = true;
          this.updateUserDistance(position);
        },
        (error: GeolocationPositionError) => {
          // Handle geolocation errors
          console.error('Geolocation error:', error);
        }
      );
    }
  }

  private updateUserDistance(position: GeolocationPosition): void {
    this.product.locations?.forEach(location => {
      location.distance = google.maps.geometry.spherical.computeDistanceBetween({lat: position.coords.latitude, lng: position.coords.longitude}, {lat: location.gpsCoords!.x, lng: location.gpsCoords!.y})
    });
  }

  private setCssVars(): void {
    this.host.nativeElement.style.setProperty('--top-pseudo-element', `url('../../../../../assets/imgs/environments/${environment.tenantName}/products/top-pseudo-element.svg')`);
    this.host.nativeElement.style.setProperty('--bottom-pseudo-element', `url('../../../../../assets/imgs/environments/${environment.tenantName}/products/bottom-pseudo-element.svg')`);
  }
}
