<div class="footer-container">
  <!-- ########################## Brand Info ########################## -->
  <div class="brand-info-container">
    <img [src]="brandInfo.brandImageUrl" class="brand-image">
    <p class="brand-description mt-3" *ngIf="labelsService.footerBrandDescription | label">
      {{ labelsService.footerBrandDescription | label }}
    </p>

    <div class="contacts-container mt-5">
      <!-- <a class="contact-container" [href]="'tel:'+brandInfo.phoneContact">
        <div class="icon-container" [ngStyle]="{'background-color': 'var(--footer-button-background-color)'}">
          <img [src]="'assets/imgs/phone-icon.svg'">
        </div>

        <div class="contact-info ms-2">
          <span class="contact-label" *ngIf="labelsService.footerContactsPhoneTitle | label">{{ labelsService.footerContactsPhoneTitle | label }}</span>
          <span class="contact">{{ brandInfo.phoneContact }}</span>
        </div>
      </a> -->

      <a class="contact-container" [href]="'mailto:'+brandInfo.emailContact">
        <div class="icon-container" [ngStyle]="{'background-color': 'var(--footer-button-background-color)'}">
          <img [src]="'assets/imgs/email-icon.svg'">
        </div>

        <div class="contact-info ms-2">
          <span class="contact-label" *ngIf="labelsService.footerContactsEmailTitle | label">{{ labelsService.footerContactsEmailTitle | label }}</span>
          <span class="contact">{{ brandInfo.emailContact }}</span>
        </div>
      </a>

    </div>

    <div class="navigation-container mt-5">
      <ng-container *ngFor="let item of urlList">
        <a [routerLink]="item.url" class="me-3" *ngIf="item.enabled">
          {{ item.name | label }}
        </a>
      </ng-container>
    </div>
  </div>

  <!-- ########################## Newsletter ########################## -->
  <div class="newsletter-container">
    <p class="newsletter-title" *ngIf="labelsService.footerNewsletterTitle | label">{{ labelsService.footerNewsletterTitle | label }}</p>

    <p class="newsletter-description mt-2" *ngIf="labelsService.footerNewsletterDescription | label">
      {{ labelsService.footerNewsletterDescription | label }}
    </p>

    <div class="newsletter-subscription-form mt-3">
      <img class="email-icon" src="assets/imgs/email-icon.svg">
      <input type="text" id="newsletter-input" [placeholder]="labelsService.footerNewsletterPlaceholder | label" [(ngModel)]="newsletterEmail">
      <app-generic-button [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [borderRadius]="'50px'" [fontWeight]="'500'"
        [buttonName]="labelsService.footerNewsletterSubmitBtn | label" [fontSize]="'var(--small-font-size)'" [buttonSize]="'123px'" [buttonSpacing]="'max(0.6vw, 9.45px) max(1.5vw, 30px)'" (click)="submitNewsletter()"></app-generic-button>
    </div>

    <div class="social-media-list mt-4" *ngIf="brandInfo.socialMediaList && brandInfo.socialMediaList.length > 0">
      <div class="social-media-item me-3" (click)="navigateOutside(sm.redirectUrl)" *ngFor="let sm of brandInfo.socialMediaList">
        <div class="social-media-icon" [ngStyle]="{'mask-image' : 'url(' + sm.iconUrl + ')', '-webkit-mask-image': 'url(' + sm.iconUrl + ')' }"></div>
      </div>
    </div>

    <div class="copyrights-container mt-4" *ngIf="labelsService.footerAllRightsReserved | label">
      {{ labelsService.footerAllRightsReserved | label }}
    </div>
  </div>

  <!-- ########################## Navigation & Copy Rights ########################## -->
  <div class="navigation-copyrights-container mt-3">
    <div class="navigation-container mt-3">
      <ng-container *ngFor="let item of urlList">
        <a [href]="item.url" class="me-3" *ngIf="item.enabled">
          {{ item.name | label }}
        </a>
      </ng-container>
    </div>

    <div class="copyrights-container mt-4" *ngIf="labelsService.footerAllRightsReserved | label">
      {{ labelsService.footerAllRightsReserved | label }}
    </div>
  </div>

  <div class="app-version-container" *ngIf="environment.showVersion">
    {{appVersion}}v
  </div>
</div>
