<ng-container *ngIf="fCName == null; else formInput">
  <div class="input-container">
    <label *ngIf="label" [ngClass]="{'mb-1': !labelMargin}" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
    <div class="position-relative mt-1 w-100">
      <input type="text" class="w-100" [(ngModel)]="inputValue" [ngClass]="{'error': showError}" [placeholder]="placeholderLabel"
      (keydown)="blockNonNumbers($event, allowNegative, allowDecimals, minValue, maxValue)"
      (input)="removeFromInput($event, allowNegative, allowDecimals, maxValue)" (onDebounce)="onValueChange($event)">
    </div>
  </div>
</ng-container>


<ng-template #formInput>
  <form [formGroup]="form">
    <div class="input-container">
      <label *ngIf="label !== ''" [ngClass]="{
        'error': form.controls[fCName].touched && !form.controls[fCName].valid,
        'mb-1': !labelMargin
      }"
      [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>

      <div class="position-relative w-100">
        <input type="text" class="w-100" [ngClass]="{'error': form.controls[fCName].touched && !form.controls[fCName].valid}"
         formControlName="{{fCName}}" [ngClass]="{'error': showError}" [placeholder]="placeholderLabel"
         (keydown)="blockNonNumbers($event, allowNegative, allowDecimals, minValue, maxValue)"
         (input)="removeFromInput($event, allowNegative, allowDecimals, maxValue)">
      </div>
    </div>
  </form>
</ng-template>
