import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { GenericPopupFromBottomComponent } from './generic-popup-from-bottom/generic-popup-from-bottom.component';
import { GenericPopupComponent } from './generic-popup/generic-popup.component';


@NgModule({
  declarations: [
    GenericPopupComponent,
    GenericPopupFromBottomComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenericPopupComponent,
    GenericPopupFromBottomComponent
  ],
  providers: [LibraryService]
})
export class GenericPopupModule { }
