import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilterGroupDTO } from 'src/app/pages/_shared-components/filters-list-module/filters-list/filter.model';
import { ArticleDTO } from 'src/app/pages/blog/blog.model';

@Injectable({
  providedIn: 'root',
})
export class MockBlogService {
  constructor() {}

  getNewsList(): Observable<Array<ArticleDTO>> {
    return of([
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'Emotion & Gamification',
        description:
          'We all already know how gamification can be useful for teams, customers and citizens, but how it actually works when we talk about',
        publishDate: '2022-07-22T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'How Gamification makes you happy.',
        description:
          'We live in a world flooded with apps, and those who offer exciting features have an edge.',
        publishDate: '2022-06-30T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'Benefits of Gamification in Logistics.',
        description:
          'No one can deny that gamification is a growing trend in businesses, and the logistics sector is no exception. Gamification in logistics is a tool that helps employees do their jobs more efficiently by involving them in fun processes that boost their motivation and competitiveness.',
        publishDate: '2022-06-28T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: '5 Gamification Trends you can’t lose for 2022.',
        description:
          'New year, new resolutions, and also new gamification trends you can’t lose.',
        publishDate: '2022-01-21T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'How Gamification can improve Mental Health.',
        description:
          'Since 2020, when Covid-19 started, the world has been forced to embrace the various possibilities of 21st-century technology. We started to work from home, and that become our new normal, but for some people, this change wasn´t that easy.',
        publishDate: '2021-12-03T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'How Gamification is helping HR Teams.',
        description:
          'Marketers use gamification for different purposes, such as driving engagement, promoting users, increasing user lifetime value, and much more. Regarding the Human Resources field,',
        publishDate: '2021-10-28T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'How Gamification is helping the Health Industry.',
        description:
          'For the almost last 2 years, the world has changed and the way we live, act and manage our lives changed with it.\nWe faced a pandemic, we changed the way we work, the way we act with other people and we got closer to the digital world. We got closer to technology and of course to Gamification.',
        publishDate: '2021-10-18T00:00:00+0000',
        articleContent: ''
      },
      {
        id: '2987da29-0c53-44dd-ba0c-c9892259fc00',
        image: {
          coverImage: {
            href: ''
          },
          profilePictureImage: {
            href: 'assets/mock/environments/Gamifyou/blog/article_thumbnail.png'
          },
        },
        title: 'How Gamification and IoT work Together.',
        description:
          'Have you ever heard the term Gamification in IoT before? IoT stands for Internet of Things.\nIoT means taking all the connectable devices in the world and connecting them to the internet. IoT is at the centre of the digital transformation revolution that is changing the shape of business, enterprise, and people’s lives.',
        publishDate: '2021-09-27T00:00:00+0000',
        articleContent: ''
      },
    ]);
  }

  getFiltersList(): Observable<Array<FilterGroupDTO>> {
    return of([
      {
        queryParameter: 'publishing',
        name: 'Published date',
        type: 'Radio',
        exclusive: true,
        values: [
          {
            id: 1,
            description: 'Oldest to Newest',
          },
          {
            id: 2,
            description: 'Newest to Oldest',
          },
        ],
      },
    ]);
  }
}
