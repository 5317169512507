import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { CustomDateAdapter } from '../custom-date-adapter';
import { DateInputComponent } from '../date-input/date-input.component';

@Component({
  selector: 'app-date-input-range',
  templateUrl: './date-input-range.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    '../date-input/date-input.component.sass',
    './date-input-range.component.sass'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DateInputRangeComponent extends DateInputComponent {

  @Input() placeholderStart: string;
  @Input() placeholderEnd: string;
  @Input() savedDateRange: rangeOutput;

  @Output() rangeChanged: EventEmitter<rangeOutput> = new EventEmitter<rangeOutput>();

  selectedRange: rangeOutput = {start : null, end: null};

  constructor(
    public override rootFormGroup: FormGroupDirective,
    public override matIconRegistry: MatIconRegistry,
    public override domSanitizer: DomSanitizer,
    public override _adapter: CustomDateAdapter,
    public override fb: UntypedFormBuilder,
    public override host: ElementRef,
    public override libraryService: LibraryService,
    @Inject(MAT_DATE_LOCALE) public override _locale: string
    ) {
    super(rootFormGroup, matIconRegistry, domSanitizer, _adapter, fb, host, libraryService,  _locale);
  }

  override ngOnInit() {
    super.setCSSVars();
    super.setSpecificCSSVars();
    super.ngOnInit();

    if (this.fGName) {
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;
    } else {
      this.form = this.fb.group({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
      });
    }

    if (this.savedDateRange) {
      this.form.get('start')!.setValue(this.savedDateRange.start);
      this.form.get('end')!.setValue(this.savedDateRange.end);
    }
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (this.inputLoaded) {
      if (changes['minDate']) {
        const previousMinDate = (new Date(changes['minDate'].previousValue)).getTime();
        const currentDate = (new Date(this.formInput.value)).getTime();

        if (previousMinDate > currentDate) {
          this.formInput.value = undefined;
        }
      }

      if (changes['maxDate']) {
        const previousMaxDate = (new Date(changes['maxDate'].previousValue)).getTime();
        const currentDate = (new Date(this.formInput.value)).getTime();

        if (previousMaxDate < currentDate) {
          this.formInput.value = undefined;
        }
      }
    }
  }

  onDateChange(date: Date, isEndDate: boolean = false) {
    if (isEndDate && date) {
      this.selectedRange.end = date;
      this.rangeChanged.emit(this.selectedRange);
    } else if (date) {
      this.selectedRange.start = date;
    }
  }
}

export interface rangeOutput {
  start: Date | null;
  end: Date | null;
}
