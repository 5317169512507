<div (click)="clickOutside($event);" class="outside-dropdown"></div>

<div class="dropdown aux" id="dropdownContainer"></div>
<ng-container *ngIf="fCName == null; else formInput">
  <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'"
    [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">

    <ng-container *ngIf="languageOptions.length !== 0; else noResults">
      <div *ngFor="let language of languageOptions">
        <div *ngIf="language.name!.toLowerCase()" class="option-item">
          <app-checkbox class="w-100" [brandColor]="brandColor" [label]="language.name" [justifyContent]="'space-between'" [fontSize]="fontSize" [checked]="language.isSelected!" [disabled]="language.isDefault!" (valueChanged)="toggleOptionSelected(language, $event)"></app-checkbox>
        </div>
      </div>
    </ng-container>

    <ng-template #noResults>
      <div class="option-item">
        <span class="title">{{'NoResults' }}</span>
      </div>
    </ng-template>
  </div>
</ng-container>


<ng-template #formInput>
  <form [formGroup]="form">
    <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'"
      [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">
      <ng-container *ngIf="languageOptions.length !== 0; else noResults">
        <div *ngFor="let language of languageOptions">
          <div *ngIf="language.name!.toLowerCase()" class="option-item">
            <app-checkbox class="w-100" [brandColor]="brandColor" [label]="language.name" [justifyContent]="'space-between'" [fontSize]="fontSize" [checked]="language.isSelected!" [disabled]="language.isDefault!" (valueChanged)="toggleOptionSelected(language, $event)"></app-checkbox>
          </div>
        </div>
      </ng-container>

      <ng-template #noResults>
        <div class="option-item">
          <span class="title">{{ 'NoResults' }}</span>
        </div>
      </ng-template>
    </div>
  </form>
</ng-template>


