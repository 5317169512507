<div class="tier-list">
  <div class="tier-item" *ngFor="let tier of tierList" (click)="tierAction(tier)">
    <div class="tier-card">
      <div class="card-background-container">
        <img class="image-background" [src]="tier.imageUrl" [alt]="tier.title" *ngIf="tier.imageUrl">
      </div>

      <p class="tier-title">{{tier.title}}</p>
      <p class="tier-description">Click here</p>
    </div>
  </div>
</div>
