import { FiscalAddress, ImageContent, ImageContentDTO } from "src/app/_services/_general-service/general-service.model";
import { Partner } from "../partners/partners.model";
import { DynamicInput } from "./activity-details-container/activity-details-card/activity-types/input/activity-type-input.model";

export interface ActivityInfo {
  activityId: string;
  type: ActivityTypes;
  name: string;
  description: string;
  images: Array<ImageContent>;
  endDate: string;
  partner?: Partner;
  rewards: Array<Reward>;
  addresses: Array<FiscalAddress>;
  totalReward: number;
  canWinRewards: boolean;

  // Activity Input Type
  inputs?: Array<DynamicInput>;
  userInputs?: Array<FormInputListItem>;

  // Multi Step Type
  subActivities?: Array<subActivity>;
  totalCompletedSubActivities?: number;
  totalCompletedSubActivityPercentage?: number;
  totalSubActivities?: number;
  totalSubActivityReward?: number;
}

export interface ActivityListItem {
  activityId: string;
  type: ActivityTypes;
  name: string;
  description: string;
  images: Array<ImageContent>;
  endDate: string;
  partner?: Partner;
  rewards: Array<Reward>;
  totalReward: number;

  // Multi Step Type
  totalCompletedSubActivities?: number;
  totalSubActivities?: number;
  totalCompletedSubActivityPercentage?: number;
  totalSubActivityReward?: number;
}

export interface ActivityInfoDTO {
  activityId: string;
  type: ActivityTypes;
  name: string;
  description: string;
  images: Array<ImageContentDTO>;
  endDate: string;
  partner?: Partner;
  rewards: Array<Reward>;
  codes?: Array<Code>;
  totalReward: number;
  canWinRewards: boolean;

  // Activity Input Type
  inputs?: Array<DynamicInput>;
  userInputs?: Array<FormInputListItem>;

  // Multi Step Type
  subActivities?: Array<subActivity>;
  totalCompletedSubActivities?: number;
  totalCompletedSubActivityPercentage?: number;
  totalSubActivities?: number;
  totalSubActivityReward?: number;
}

export interface ActivityListItemDTO {
  activityId: string;
  type: ActivityTypes;
  name: string;
  description: string;
  images: Array<ImageContentDTO>;
  endDate: string;
  partner?: Partner;
  rewards: Array<Reward>;
  totalReward: number;

  // Multi Step Type
  totalCompletedSubActivities?: number;
  totalCompletedSubActivityPercentage?: number;
  totalSubActivities?: number;
  totalSubActivityReward?: number;
}

export interface FormInputListItem {
  userInputId: string | null;
  status: FormInputItemStatus;
  inputs: Array<DynamicInput>;
}

export interface Reward {
  amount: number;
  unit: string;
}

export interface Code {
  address: FiscalAddress;
}

export interface subActivity {
  activityId: string;
  description: string;
  name: string;
  rewards: Array<Reward>;
  isCompleted: boolean;
}

export enum ActivityTypes {
  REGULAR = 'REGULAR',
  MULTI_STEP = 'MULTI_STEP',
  INPUT = 'INPUT'
}


export enum FormInputItemStatus {
  CREATING = 'CREATING',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}
