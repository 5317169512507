<app-small-page-banner [title]="labelService.contactusGeneralInfoBannerTitle | label"
  [description]="labelService.contactusGeneralInfoBannerDescription | label" [imageSrc]="'assets/mock/contact-us/general-info-small-banner.png'"
  [titleFontSizePx]="'var(--page-title-font-size)'" [descFontSizePx]="'var(--section-description-font-size)'">
</app-small-page-banner>

<div class="faq-container">
  <div class="faq-title">{{labelService.contactusGeneralInfoFAQTitle | label}}</div>
  <app-accordion [accordions]="accordions" [fontSize]="'var(--section-description-font-size)'"
    [numberFontSize]="'var(--page-title-font-size)'" [titleFontSize]="'var(--large-section-title-font-size)'">
  </app-accordion>
</div>

<div class="form-area-container">
  <div class="form-container form-shadow">
    <p class="form-title">{{labelService.contactusGeneralInfoFormTitle | label}}</p>
    <p class="form-description">{{labelService.contactusGeneralInfoFormDesc | label}}</p>
    <form [formGroup]="contactusFormContainer" (ngSubmit)="submitForm()">
      <div class="contact-us-form-control">
        <app-single-line-text-input
          [label]="labelService.contactusGeneralInfoNameTitle | label"
          [fGName]="'generalInfoForm'"
          [fCName]="'generalInfoName'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactusGeneralInfoNamePlaceholder | label">
        </app-single-line-text-input>

        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoName')?.touched
            && (contactusFormContainer.get('generalInfoForm')?.get('generalInfoName')?.hasError('required')
            || contactusFormContainer.get('generalInfoForm')?.get('generalInfoName')?.hasError('minlength')))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
        </span>
        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoName')?.touched
            && contactusFormContainer.get('generalInfoForm')?.get('generalInfoName')?.hasError('pattern'))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      </div>

      <div class="contact-us-form-control">
        <app-generic-phone-input
          [selectedCountryISO]="CountryISO.Portugal"
          [label]="labelService.contactusGeneralInfoPhoneTitle | label" [placeholderLabel]="labelService.contactusGeneralInfoPhonePlaceholder | label"
          [inputHeight]="'64px'" [fontSize]="'var(--main-info-font-size)'" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [fontColor]="environment.brandStyleVariables.mainFontColor"
          [errorColor]="environment.brandStyleVariables.mainErrorColor" [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [roundBorders]="true" [fCName]="'phoneInput'" [fGName]="'generalInfoForm'">
        </app-generic-phone-input>

        <span class="input-error"
          *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('phoneInput')?.touched
          && (contactusFormContainer.get('generalInfoForm')?.get('phoneInput')?.hasError('required')))">
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>

        <span class="input-error"
          *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('phoneInput')?.touched
          && contactusFormContainer.get('generalInfoForm')?.get('phoneInput')?.hasError('validatePhoneNumber'))">
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      </div>

      <div class="contact-us-form-control">
        <app-single-line-text-input
          [label]="labelService.contactusGeneralInfoEmailTitle | label"
          [fGName]="'generalInfoForm'"
          [fCName]="'generalInfoEmail'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactusGeneralInfoEmailPlaceholder | label">
        </app-single-line-text-input>

        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoEmail')?.touched
            && (contactusFormContainer.get('generalInfoForm')?.get('generalInfoEmail')?.hasError('required')
            || contactusFormContainer.get('generalInfoForm')?.get('generalInfoEmail')?.hasError('minlength')))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
        </span>
        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoEmail')?.touched
            && contactusFormContainer.get('generalInfoForm')?.get('generalInfoEmail')?.hasError('pattern'))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      </div>

      <div class="contact-us-form-control">
        <app-textarea-input
        [label]="labelService.contactusGeneralInfoMessageTitle | label"
        [fGName]="'generalInfoForm'"
        [fCName]="'generalInfoMessage'"
        [labelMargin]="'1rem'"
        [borderRadius]="'33px'"
        [shouldGrow]="true"
        [charLength]="150"
        [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [placeholder]="labelService.contactusGeneralInfoMessagePlaceholder | label">
        </app-textarea-input>

        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoMessage')?.touched
            && (contactusFormContainer.get('generalInfoForm')?.get('generalInfoMessage')?.hasError('required')
            || contactusFormContainer.get('generalInfoForm')?.get('generalInfoMessage')?.hasError('minlength')))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
        </span>
        <span class="input-error"
            *ngIf="(contactusFormContainer.get('generalInfoForm')?.get('generalInfoMessage')?.touched
            && contactusFormContainer.get('generalInfoForm')?.get('generalInfoMessage')?.hasError('pattern'))">
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      </div>

      <app-generic-button [buttonName]="labelService.contactusGeneralInfoSubmitButton | label" [minHeight]="'64px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
        [buttonSize]="'244px'" [borderRadius]="'66px'" [fontSize]="'var(--small-font-size)'" [fontWeight]="'700'"
        [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!contactusFormContainer.valid"
        submit>
      </app-generic-button>
    </form>
  </div>

  <div class="form-image">
    <img [src]="'assets/mock/contact-us/contact-us-general-form-nft.png'" alt="form-nft">
  </div>
</div>

