export interface ListItem {
  id: string;
  info: Array<Info>;
  infoExpandable?: Array<ExpandableListItem>;
  selected?: boolean;
  notClickable?: boolean;
  expand?: boolean;
  itemType?: string;
}

export interface ExpandableListItem extends ListItem {
  action?: Actions;
}

export interface Type {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Info {
  name?: string;
  type?: Type;
  thumbnailUrl?: string;
  date?: string;
  partner?: string;
  amount?: number;
  progressBar?: ProgressBarInfo;
  description?: string;
  modifiedBy?: string;
  activeActivities?: number;
  hasBadge?: boolean;
  badge?: string;
  points?: number;
  state?: State;
  stateIndicator?: State;
  actions?: Array<Actions>;

  sizes?: Sizes;
  styles?: Styles;
}

export interface ProgressBarInfo {
  total: number;
  currentValue: number;
}

export interface State {
  label: string;
  class: string;
}

export interface Actions {
  bootstrapIconClass?: string;
  imageUrl?: string;
  tooltipText: string;
  actionId: ActionId;
  backgroundColor?: string;
  borderRadius?: string;
  disabled?: boolean;
  buttonHeight?: string;
  buttonWidth?: string;
  textValue?: string;
  fontColor?: string;
  fontSize?: string;
  fontWeight?: string;
}

export interface Sizes {
  size: number;
  sizeSmall?: number;
}

export interface Styles {
  fontSize?: string;
  fontColor?: string;
  fontWeight?: string;
  textAlign?: string;
  padding?: string;
  whiteSpace?: string;
  maxAmountTextLines?: number;
  justify?: string;
}

export enum ActionId {
  publish,
  unpublish,
  qrcode,
  visibility,
  redeem,
  delete,
  goTo,

  edit,
  show
}

export enum ItemState {
  noState,

  // General
  pending, // yellow
  active, // green
  disabled, // red
  hidden, // TODO grey?

  // Vouchers
  outofstock, // red

  // Users
  confirmed, // green
  blacklisted, // red

  // CategoryBadges
  configured, // green
  notConfigured, // red

  // Suggestions
  accepted,

  archived
}
