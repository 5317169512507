<div class="snackbar-container snack-bar-status" [ngClass]="snackbarClass">
  <img class="prefix-icon" [src]="prefixIconUrl" *ngIf="showPrefixIcon">

  <span class="message">
    {{message}}
  </span>

  <div *ngIf="showAction" class="action-button" (click)="ActionClicked()">
    {{actionText}}
  </div>

  <div *ngIf="showCloseIcon" class="close-icon-container" (click)="closeSnackbar()">
    <div class="close-icon">
      <span class="cross-line"></span>
    </div>
  </div>
</div>
