import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ToolTipModule } from '../../tooltip-module/tooltip.module';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibraryService } from '../../__services/library.service';
import { RadioGroupComponent } from './radio-group/radio-group.component';

@NgModule({
  declarations: [
    RadioGroupComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToolTipModule,
  ],
  exports: [
    RadioGroupComponent
  ],
  providers: [LibraryService]
})
export class RadioButtonsInputModule { }

