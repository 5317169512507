import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibraryService } from '../../__services/library.service';
import { DropdownsModule } from '../dropdowns-module/dropdowns.module';
import { CountryCode } from './country-code-data';
import { GenericPhoneInputComponent } from './generic-phone-input/generic-phone-input.component';


@NgModule({
  declarations: [
    GenericPhoneInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownsModule,
    HttpClientModule,
  ],
  exports: [
    GenericPhoneInputComponent
  ],
  providers: [
    CountryCode,
    FormGroupDirective,
    LibraryService
  ]
})
export class GenericPhoneInputModule { }
