import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipsDropdownComponent } from './chips-dropdown/chips-dropdown.component';



@NgModule({
  declarations: [
    ChipsDropdownComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ChipsDropdownComponent
  ]
})
export class ChipsDropdownModule { }
