import myLocaleEN from '@angular/common/locales/en';
import myLocalePT from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AuthGuardKeycloak } from './_guards/AuthKeycloak.guard';
import { ApiService } from './_services/api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './pages/_shared-components/navbar/navbar.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from './_services/custom-translate-loader.service';
import { ActionIncentiveComponent } from './pages/landing-page/parallax/action-incentive/action-incentive.component';
import { CarouselComponent } from './pages/landing-page/parallax/carousel/carousel.component';
import { DescriptionListComponent } from './pages/landing-page/parallax/description-list/description-list.component';
import { FeaturedTitleComponent } from './pages/landing-page/parallax/featured-title/featured-title.component';
import { ImageCardComponent } from './pages/landing-page/parallax/image-card/image-card.component';
import { PartnerListComponent } from './pages/landing-page/parallax/partner-list/partner-list.component';
import { ProductDetailComponent } from './pages/landing-page/parallax/product-detail/product-detail.component';

import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from "@auth0/angular-jwt";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';
import { DeepCopy } from './_directives/deep-copy/deep-copy.service';
import { ButtonsModule } from './_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { CheckboxInputModule } from './_generic-components-lib/inputs/checkbox-input-module/checkbox-input.module';
import { DropdownsModule } from './_generic-components-lib/inputs/dropdowns-module/dropdowns.module';
import { RadioButtonsInputModule } from './_generic-components-lib/inputs/radio-button-input-module/radio-button-input.module';
import { SingleLineTextInputModule } from './_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { GenericPopupModule } from './_generic-components-lib/popups/generic-popup-module/generic-popup.module';
import { SnackbarsModule } from './_generic-components-lib/snackbars-module/snackbars-module.module';
import { AuthGuard } from './_guards/auth.guard';
import { LabelPipeModule } from './_shared-modules/label-pipe-module/label-pipe-.module';
import { LabelPipe } from './_shared-modules/label-pipe-module/label.pipe';
import { ChipsDropdownModule } from './pages/_shared-components/chips-dropdown-module/chips-dropdown.module';
import { FooterComponent } from './pages/_shared-components/footer/footer.component';
import { InfoCardModule } from './pages/_shared-components/info-card-module/info-card.module';
import { ItemImageSliderModule } from './pages/_shared-components/item-image-slider-module/item-image-slider.module';
import { ListItemCardsModule } from './pages/_shared-components/list-item-cards/list-item-cards.module';
import { MobileSideMenuComponent } from './pages/_shared-components/mobile-side-menu/mobile-side-menu.component';
import { PageBannerModule } from './pages/_shared-components/page-banner-module/page-banner.module';
import { WalletConnectModuleModule } from './pages/_shared-components/wallet-connect-module/wallet-connect-module.module';
import { BlogModule } from './pages/blog/blog.module';
import { ContactUsModule } from './pages/contact-us/contact-us.module';
import { ImageOverflowTiersFilterComponent } from './pages/marketplace/marketplace-list-container/image-overflow-tiers-filter/image-overflow-tiers-filter.component';
import { MarketPlaceModule, playerFactory } from './pages/marketplace/marketplace.module';
import { PageNotFoundModule } from './pages/page-not-found-module/page-not-found.module';
import { PartnersModule } from './pages/partners/partners.module';
import { ProfileModule } from './pages/profile/profile.module';
import { TermsAndConditionsModule } from './pages/terms-and-conditions/terms-and-conditions.module';
import { TutorialModule } from './pages/tutorial/tutorial.module';
import { OurProductsModule } from './pages/our-products/our-products.module';
import { AboutUsModule } from './pages/about-us/about-us.module';
import { OnboardingModule } from './pages/onboarding/onboarding.module';
import { RiveAnimationModule } from './pages/_shared-components/rive-animation-module/rive-animation.module';
import { HomeModule } from './pages/home-module/home.module';
import { HistoryModule } from './pages/history-module/history.module';
import { ActivitiesModule } from './pages/activities/activities.module';
import { LoadingModule } from './_loading/loading.module';

registerLocaleData(myLocalePT);
registerLocaleData(myLocaleEN);

// Keycloak initialize function
function initializeKeycloak(keycloak: KeycloakService) {

  return async () => {
    try {
      keycloak.keycloakEvents$.subscribe({
        next: (e) => {
          if (e.type == KeycloakEventType.OnTokenExpired) {
            console.log('token Expired, trying to revalidate..');
            keycloak.updateToken(30).then(resp => {
              console.log('Token revalidation:', resp ? 'success revalidating!' : 'failed revalidation');

              if (!resp) {
                console.log('clearing token');
                localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
                keycloak.clearToken();
                window.location.reload();
              }
            }).catch(err => {
              console.log('Failed to refresh the token - ', err);
            });
          }
        }
      });

      await keycloak.init({
        config: environment.keycloakConfig,
        loadUserProfileAtStartUp: true,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/keycloak/silent-check-sso.html',
          enableLogging: true
        }
      });

      if (keycloak.getKeycloakInstance().token) {
        localStorage.setItem(environment.keycloakConfig.clientId + '-jwt', keycloak.getKeycloakInstance().token!);
      }


    } catch (error) {
      console.log(error);
    }
  }
}

let providers: Array<Provider> = [
  ApiService,
  environment.useKeycloak ? AuthGuardKeycloak : AuthGuard,
  DeepCopy,
  LabelPipe
];

if (environment.useKeycloak) {
  providers.push([
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    NavbarComponent,
    DescriptionListComponent,
    CarouselComponent,
    ActionIncentiveComponent,
    ProductDetailComponent,
    ImageCardComponent,
    FeaturedTitleComponent,
    PartnerListComponent,
    FooterComponent,
    MobileSideMenuComponent,
    ImageOverflowTiersFilterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SwiperModule,
    ButtonsModule,
    RadioButtonsInputModule,
    CheckboxInputModule,
    SingleLineTextInputModule,
    SnackbarsModule,
    GenericPopupModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    WalletConnectModuleModule,
    LabelPipeModule,
    ListItemCardsModule,
    PartnersModule,
    PageBannerModule,
    ChipsDropdownModule,
    DropdownsModule,
    MarketPlaceModule,
    AboutUsModule,
    PageNotFoundModule,
    ItemImageSliderModule,
    InfoCardModule,
    ProfileModule,
    TermsAndConditionsModule,
    ContactUsModule,
    TutorialModule,
    BlogModule,
    OurProductsModule,
    OnboardingModule,
    ActivitiesModule,
    HistoryModule,
    RiveAnimationModule,
    LottieModule.forRoot({ player: playerFactory }),
    HomeModule,
    LoadingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem(environment.keycloakConfig.clientId + '-jwt')
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}
