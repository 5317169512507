<div class="list-item-container"
  [ngStyle]="{ 'margin': itemMargin }"
  [ngClass]="{ 'selected': item.selected, 'not-clickable' : item.notClickable}"
  (click)="itemClicked()">

  <div class="item-info h-100 d-flex align-items-center" *ngFor="let info of item.info; let i = index;"
    [ngStyle]="{
      'width': (fullSize ? info.sizes!.size : info.sizes!.sizeSmall) + '%',
      'color': info.styles?.fontColor ? info.styles!.fontColor : 'var(--font-color)',
      'font-weight': info.styles?.fontWeight ? info.styles!.fontWeight : 'normal',
      'font-size': info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)',
      'text-align': info.styles?.textAlign ? info.styles?.textAlign : 'center',
      'padding': info.styles?.padding ? info.styles?.padding : 'none'
    }">
    <ng-container *ngIf="fullSize || info.sizes!.sizeSmall">

      <div *ngIf="info.name" class="h-100 w-100 d-flex align-items-center name-info-container" [ngStyle]="{'padding-left' : (item.selected ? '0px' : '4px')}">
        <!-- Selected Border -->
        <div *ngIf="item.selected" class="selected-item-line"></div>

        <!-- Item Thumbnail Image -->
        <div *ngIf="info.thumbnailUrl" class="ps-3 pe-3">
          <img [src]="info.thumbnailUrl" class="image-container">
        </div>

        <!-- Item Type Icon -->
        <div *ngIf="info.type?.imageUrl" class="pe-1">
          <img [src]="info.type?.imageUrl" class="sec-image-container">
        </div>

        <!-- Item Name -->
        <div class="name-container" [ngStyle]="{
          'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
          '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
          '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
          'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
          'overflow': 'hidden',
          'text-overflow': 'ellipsis',
          'line-height': 1.1
        }">
          {{ info.name }}
        </div>
      </div>

      <!-- Item Text Info -->
      <div *ngIf="info.description" class="h-100 w-100 d-flex align-items-center justify-content-center text-container" [ngStyle]="{
        'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
        '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
        '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
        'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
        'line-height': 1.1
      }">
        <div>{{info.description}}</div>
      </div>

      <!-- Item Dates (already formated) -->
      <div *ngIf="info.date" class="h-100 w-100 d-flex align-items-center justify-content-center date-container" [ngStyle]="{
        'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
        '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
        '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
        'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
        'line-height': 1.1
      }">
        <span>{{info.date}}</span>
      </div>

      <div *ngIf="info.progressBar" class="h-100 w-100 d-flex flex-column align-items-center progress-bar-container">
        <div class="progress-title">
          <span class="current-stock">{{info.progressBar.currentValue}}</span> / <span class="total-items">{{info.progressBar.total}}</span>
        </div>
        <div class="progress w-75">
          <div class="progress-bar" role="progressbar" [ngClass]="{'danger': ((info.progressBar.currentValue/info.progressBar.total)*100) >= 80}" [ngStyle]="{'width': (info.progressBar.currentValue-0)*100/(info.progressBar.total-0) + '%' }" [attr.aria-valuenow]="info.progressBar.currentValue" aria-valuemin="0" [attr.aria-valuemax]="info.progressBar.total"></div>
        </div>
      </div>

      <!-- Item State (based on state enum) -->
      <div *ngIf="info.state" class="w-100 d-flex align-items-center justify-content-center state-container" [ngClass]="info.state.class">
        <span>{{info.state.label}}</span>
      </div>

      <div *ngIf="info.actions" class="w-100 d-flex align-items-center justify-content-end multi-function-btns-container">
        <div class="action-btn me-2" *ngFor="let action of info.actions; let index = index" [ngClass]="{'eyeBtn': action.actionId === ActionId.visibility}"
        (click)="actionClicked(action.actionId); action ? $event.stopPropagation() : ''">

          <ng-container *ngIf="action.imageUrl; else bootstrapIcon">
            <div class="action-icon-wrapper" [ngClass]="{'iconActive': actionActive}">
              <div [ngStyle]="{'mask-image' : 'url(' + action.imageUrl + ')', '-webkit-mask-image': 'url(' + action.imageUrl + ')', 'background-color': !actionActive ? 'var(--font-color)' : 'var(--brand-color)' }" class="default-img"></div>
              <!-- Action Icon Tooltip -->
              <div class="custom-tooltip" *ngIf="action.tooltipText">
                <div class="tooltip-text">{{ action.tooltipText }}</div>
                <div class="arrow"></div>
              </div>
            </div>
          </ng-container>

          <ng-template #bootstrapIcon>
            <div class="action-icon-wrapper d-flex align-items-center justify-content-center" [ngStyle]="{
              'background-color': action.backgroundColor? action.backgroundColor : 'transparent',
              'border-radius': action.borderRadius? action.borderRadius : '0',
              'font-size': info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)',
              'width': action.buttonWidth ? action.buttonWidth : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)',
              'height': action.buttonHeight ? action.buttonHeight : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)'
            }">
              <i class="bi" [ngClass]="action.bootstrapIconClass"></i>
            </div>
          </ng-template>
        </div>
      </div>

    </ng-container>
  </div>
</div>
