<mat-tab-group class="h-100" [ngClass]="{'enable-line': hasUnderLineActive}" #tabGroup [(selectedIndex)]="defaultTab" (selectedTabChange)="emitClickedTab($event.index)">
  <mat-tab *ngFor="let tab of tabsList; let index = index" [bodyClass]="tabsBody.length > 0 ? 'small-space' : ''">
    <ng-template mat-tab-label>
        <i *ngIf="tab.hasIcon" class="{{tab.icon}}"></i>
        <span>{{tab.name}}</span>
    </ng-template>

    <div id="tab-body-content" *ngIf="tabsBody.length > 0; else contentC" [innerHTML]="tabsBody[index] | safehtml"></div>
    <ng-template #contentC>
      <ng-content *ngTemplateOutlet="tmplsArray[index]"></ng-content>
    </ng-template>
  </mat-tab>
</mat-tab-group>
