<div class="collections-wrapper">
  <div class="collections-banner">
    <img [src]="userDetails.images!.coverImage && userDetails.images!.coverImage.href ? userDetails.images!.coverImage.href : 'assets/mock/my-account-banner.png'">
  </div>

  <div class="collections-menu-container">
    <div class="collections-menu-item active">
      <p>{{labelService.productHistoryTitle | label}}</p>
    </div>
    <div class="collections-menu-item" (click)="openTransactions()">
      <p>{{labelService.transactionsTitle | label}}</p>
    </div>
  </div>

  <div class="section collections-container" *ngIf="isLoaded">
    <p class="section-title">{{labelService.productHistoryListTitle | label}}</p>

  <!-- <div class="collections-filters-header-container" *ngIf="filterList && filterList.length > 0"> -->
    <div class="collections-filters-header-container">
      <div class="search-container">
        <app-single-line-text-input
          [placeholderLabel]="labelService.productHistorySearchPlaceholder | label" [inputHeight]="'48px'" [fontSize]="'var(--main-info-font-size)'"
          [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [borderColor]="'transparent'"
          [prefixMargin]="'20px'" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
          [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true" [debounceTime]="0"
          (suffixClicked)="search()" (actionOnEnter)="search()" (valueChanged)="searchString = $event">
        </app-single-line-text-input>

  <!--       <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)"
          [buttonText]="(generalService.isMobile | async)? '' : showFilters ? (labelService.marketplaceFiltersButtonCloseLabel | label) : (labelService.marketplaceFiltersButtonLabel | label)" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
          [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainErrorColor"
          [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
          [imageUrl]="'assets/imgs/filter-icon.svg'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
          [isActive]="showFilters"
          (buttonActive)="showFilters = $event"></app-toggle-button> -->
      </div>
    </div>
    <!--
    <div class="collections-mobile-filters-container custom-scroll" [ngClass]="{'active' : (generalService.isMobile | async) && showFilters}">
      <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
    </div> -->

    <div class="collections-list-wrapper">
      <div class="collections-list-container">
  <!--       <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
          <div class="chip" *ngFor="let chip of filterChips">
            <span>{{chip.name}}</span>
            <div class="delete-icon-container" (click)="deleteChip(chip)">
              <div class="close-icon">
                <span class="cross-line"></span>
              </div>
            </div>
          </div>
        </div> -->

        <div class="product-list-container w-100"
          infiniteScroll
          [infiniteScrollDistance]="listPercentageFromBottom"
          [infiniteScrollThrottle]="50"
          [immediateCheck]="true"
          [scrollWindow]="false"
          [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
          (scrolled)="onScroll()"
          #productContainerList>
          <div class="product-list-wrapper" *ngIf="collectionList.length > 0 || isListLoading; else noItemsFound">
            <ng-container *ngFor="let collectionCard of collectionList">
              <app-list-item-card class="product-list-card" [image]="collectionCard.images && collectionCard.images.length > 0 ? collectionCard.images[0].href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'"
                [title]="collectionCard.name" [itemType]="collectionCard.type" [itemTypeImage]="collectionCard.type === 'NFT' ? 'assets/imgs/environments/' + environment.tenantName + '/donation-icon.png' : ''" (click)="collectionItemClicked(collectionCard.id)">
                <ng-template>
                  <div class="w-100">
                    <app-collection-content [prices]="collectionCard.prices" [purchaseDate]="collectionCard.purchaseDate!"
                      [category]="collectionCard.categories!" [serial]="collectionCard.serial!" [partner]="collectionCard.partner!">
                    </app-collection-content>
                  </div>
                </ng-template>
              </app-list-item-card>
            </ng-container>

            <ng-container *ngIf="isListLoading">
              <ng-container *ngFor="let item of loadingListItems">
                <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}"></app-loading-list-item>
              </ng-container>
            </ng-container>
          </div>
          <ng-template #noItemsFound>
            <div class="no-items-found">
              <app-no-items [image]="'assets/imgs/environments/' + environment.tenantName + '/empty-list-image.png'" [title]="labelService.productHistoryListNoItems | label"></app-no-items>
            </div>
          </ng-template>
        </div>
      </div>

  <!--     <div class="marketplace-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
        <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
      </div> -->
    </div>
  </div>
</div>
