<div class="product-page-wrapper">
  <app-page-banner
    [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
    [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
    [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
    [useGradient]="mainBannerInfo.useGradient"
    [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
    [smallBanner]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? false : true"
    [textContainerMaxWidth]="'70%'"
    [imageSize]="'35%'">
  </app-page-banner>

  <p class="product-info-title mt-4">{{labelService.productDetailsScreenTitle | label}}</p>

  <div class="product-info-wrapper mb-4"  *ngIf="isLoaded" [@FadeIn]="animationState">

    <div class="product-info-container card-radius">

      <app-item-image-slider
        [itemTypeImage]="'assets/imgs/environments/'+ environment.tenantName +'/icons/product-bought-icon.svg'"
        [imageList]="product.images ? product.images : []"
        [paginationBulletColor]="environment.brandStyleVariables.pseudoElementHighlightColor">
      </app-item-image-slider>

      <ng-container [ngSwitch]="product.type">
        <div *ngSwitchCase="ProductTypeEnum.VOUCHER">
          <app-voucher-details [product]="product" [partner]="partner" [isHistory]="true"></app-voucher-details>
        </div>

        <div *ngSwitchCase="ProductTypeEnum.NFT">
          <app-nft-details [product]="product" [partner]="partner"></app-nft-details>
        </div>
      </ng-container>
    </div>

    <div class="product-codes-list-wrapper">
      <p class="product-codes-list-title">{{labelService.productHistoryDetailsCodeListTitle | label}}</p>

      <div class="product-codes-list-container">
        <ng-container *ngFor="let productCode of productCodeList">
          <app-product-code-list-item [productCode]="productCode"></app-product-code-list-item>
        </ng-container>

        <ng-container *ngIf="isListLoading">
          <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}" *ngFor="let item of loadingListItems"></app-loading-list-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>
