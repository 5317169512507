import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { RiveAnimationModule } from '../_shared-components/rive-animation-module/rive-animation.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { NgProgressModule } from 'ngx-progressbar';
import { UserGoalModule } from '../user-goal-module/user-goal.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { ListItemModule } from 'src/app/_generic-components-lib/list-item-module/list-item-module.module';
import { ListHeaderModule } from 'src/app/_generic-components-lib/list-header-module/list-header.module';
import { LoadingModule } from 'src/app/_loading/loading.module';



@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    ButtonsModule,
    PageBannerModule,
    RiveAnimationModule,
    UserGoalModule,
    LabelPipeModule,
    NgProgressModule,
    ListItemModule,
    ListHeaderModule,
    NoItemsModule,
    LoadingModule
  ],
  exports: [
    HomeComponent
  ]
})
export class HomeModule { }
