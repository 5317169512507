import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { Chip } from '../chips-dropdown.model';

@Component({
  selector: 'app-chips-dropdown',
  templateUrl: './chips-dropdown.component.html',
  styleUrls: ['./chips-dropdown.component.sass'],
  animations: [
    trigger('Slide', [
      state('hide', style({
        height: '0px',
      })),
      state('show', style({
       height: '*'
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ])
  ]
})
export class ChipsDropdownComponent implements OnInit, OnChanges {

  @Input() chipsList: Array<Chip>;

  // @Input() chipsList: Array<Chip> = [
  //   { id: "0", name: "Children and young people" },
  //   { id: "1", name: "Elderly" },
  //   { id: "0", name: "Women" },
  //   { id: "2", name: "Homeless people" },
  //   { id: "3", name: "Religious" },
  //   { id: "4", name: "Environmental" },
  //   { id: "5", name: "Animals" },
  //   { id: "6", name: "Immigrants" },
  //   { id: "7", name: "People with disabilities" },
  //   { id: "8", name: "Social reinsertion" },
  // ];

  @Input() arrowColor: string;
  @Input() arrowIconUrl: string;

  @Input() defaultLabel: string;

  @Output() selectedFilterOptions: EventEmitter<Array<Chip>> = new EventEmitter<Array<Chip>>();

  isListOpen: boolean = false;

  animationState: string;

  auxiliarList: Array<Chip> = [];

  constructor(
    private host: ElementRef,
    private generalService: GeneralService
    ) { }

  ngOnInit(): void {
    this.setCSSVars();

    this.generalService.asyncFunction(() => {this.animationState = 'hide'}, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['chipsList']) {
      this.auxiliarList = this.chipsList.filter(c => c.isSelected);
    }
  }

  private setCSSVars(): void {
    if(this.arrowColor || this.arrowIconUrl) {
      if(this.arrowIconUrl)
        this.host.nativeElement.style.setProperty('--image-url', `url(${this.arrowIconUrl})`)

      if(this.arrowColor)
        this.host.nativeElement.style.setProperty('--icon-default-color', this.arrowColor);
    }
  }

  clickOutside(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isListOpen = false;
    this.generalService.asyncFunction(() => {this.animationState = this.animationState === 'hide' ? 'show' : 'hide';});
  }

  public toggleList(): void {
    this.isListOpen = !this.isListOpen;
    this.generalService.asyncFunction(() => {this.animationState = this.animationState === 'hide' ? 'show' : 'hide';});
  }

  public selectChip(event: any, chip: Chip): void {
    chip.isSelected = !chip.isSelected;
    const index = this.auxiliarList.indexOf(chip);
    if(index === -1) {
      this.auxiliarList.push(chip);
    } else {
      this.auxiliarList.splice(index, 1);
    }
    this.selectedFilterOptions.emit(this.auxiliarList);

    event.preventDefault();
    event.stopPropagation();
  }

  public deSelectChip(event: any, chip: Chip): void {
    chip.isSelected = false;
    const indexToRemove = this.auxiliarList.indexOf(chip);
    this.auxiliarList.splice(indexToRemove, 1);

    this.selectedFilterOptions.emit(this.auxiliarList);

    event.preventDefault();
    event.stopPropagation();
  }
}
