export interface OnboardingStepLightInfo {
  onboardingId: string;
  type: OnboardingStepType;
  logicType: OnboardingStepLogicType;
  order: number;

  title: string;
  subtitle: string;
  content?: SimpleStep | OptionStep | GoalInfoStep;
}

export interface OnboardingStepLightInfoDTO {
  onboardingId: string;
  type: OnboardingStepType;
  logicType: OnboardingStepLogicType;
  order: number;

  title: string;
  subtitle: string;
  content?: SimpleStep | OptionStep | GoalInfoStep;
}

export interface OnboardingStepInfoDTO {
  onboardingId: string;
  type: OnboardingStepType;
  logicType: OnboardingStepLogicType;
  order: number;

  title: string;
  subtitle: string;
  content?: SimpleStep | OptionStep | GoalInfoStep;
}

export interface OnboardingStepInfo {
  onboardingId: string;
  type: OnboardingStepType;
  logicType: OnboardingStepLogicType;
  order: number;

  title: string;
  subtitle: string;
  content?: SimpleStep | OptionStep | GoalInfoStep;
}

export interface OnboardingSubmitContent {
  onboardingId: string;
  content: GoalDefinitionContent | undefined;
}

export interface GoalDefinitionContent {
  productId: string;
}

export enum OnboardingStepType {
  CONFIRMATION = 'CONFIRMATION',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  INFORMATIVE = 'INFORMATIVE'
}

export enum OnboardingStepLogicType {
  JOB_TITLE = 'JOB_TITLE',
  GOAL_DEFINITION = 'GOAL_DEFINITION',
  ACTIVITIES = 'ACTIVITIES'
}

export interface SimpleStep {
  mainImageUrl?: string;
  mainImageTitle?: string;
  simpleDescription?: string;
}

export interface OptionStep {
  options?: Array<Option>;
}

export interface GoalInfoStep {
  banner?: Banner;

  listSectionTitle?: string;
  itemsList?: Array<ListItem>;
  finalTitle?: string;
}

export interface Option {
  itemId: string;
  imgUrl: string;
  title: string;
  selected?: boolean;
}

export interface ListItem {
  imgUrl: string;
  description: string;
  points: number;
}

export interface Banner {
  imgUrl: string;
  description: string;
  img: string;
  imgTitle: string;
}
