export interface DynamicInput {
  type: InputType;
  order: number;
  label: string;
  placeholder: string;
  required: boolean;
  maxLength?: number;
  value?: string | number | boolean;
  options?: Array<Option>;
  size: WidthOptions;
}

export interface DynamicInputDTO {
  type: InputType;
  order: number;
  label: string;
  placeholder: string;
  required: boolean;
  maxLength?: number;
  value?: string;
  options?: Array<OptionDTO>;
  size: WidthOptions;
}

export interface GenericKeyValue {
  key: string;
  value: string;
}

export interface OptionDTO {
  key: string;
  value: string;
}

export interface Option {
  id: string;
  value: string;
  isChecked: boolean;
}

export enum InputType {
  SINGLE_LINE = 'SINGLE_LINE',
  TEXT_AREA = 'TEXT_AREA',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  RADIO_GROUP = 'RADIO_GROUP',
  CHECKBOX = 'CHECKBOX',
  TOGGLE = 'TOGGLE'
}

export enum WidthOptions {
  HALF = 'HALF',
  FULL = 'FULL'
}
