import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-input-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    './input-base.component.sass',
  ]
})
export class InputBaseComponent extends BaseComponent {

  @Input() cropperPrimaryBackgroundColor: string;
  @Input() cropperBackgroundColor: string;
  @Input() removeBtnColor: string = '#4D4D4D';
  @Input() removeBtnTextColor: string = 'white';
  @Input() barColor: string;
  @Input() toggleBarColor: string;
  @Input() roundedBackground: string;

  @Input() iconFontSize: string;

  @Input() uploadBtnBorderRadius: string;

  @Input() uploadBtnLabel: string = 'Upload';
  @Input() removeBtnLabel: string = 'Remove';
  @Input() aspectRatio: number = 1/1;
  @Input() cropperAspectRatio: number = 1/1;
  @Input() imageBorderRadius: string;
  @Input() popupBorderRadius: string;

  @Input() inputPadding: string;
  @Input() prefixMargin: string;
  @Input() suffixMargin: string;
  @Input() placeholderLabel: string;

  @Input() iconUrl: string;
  @Input() labelMargin: string;

  @Input() roundBorders: boolean;

  @Input() isDisabled: boolean = false;

  @Input() inputValue: string = '';

  @Input() fCName: string; // FormControlName
  @Input() fGName: string; // FormGroupName
  @Input() fGChildName: string; // FormGroupName

  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() actionOnEnter: EventEmitter<string> = new EventEmitter<string>();
  @Output() suffixClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notFocused: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    override host: ElementRef, override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  // action on debounce
  public onValueChange(input: string): void {
    this.valueChanged.emit(input);
  }

  public onFocusOut(): void {
      this.notFocused.emit(true);
  }

  // action on "Enter"
  public checkKeyPressed(keyPressed: KeyboardEvent): void {
    if (keyPressed.key === 'Enter' || keyPressed.key === 'NumpadEnter') {
      this.actionOnEnter.emit();
    }
  }

  public setSpecificCSSVars(): void {
    if (this.inputPadding || this.prefixMargin || this.suffixMargin || this.placeholderStyle || this.cropperPrimaryBackgroundColor || this.cropperBackgroundColor || this.removeBtnColor || this.removeBtnTextColor || this.uploadBtnBorderRadius
      || this.aspectRatio || this.cropperAspectRatio || this.imageBorderRadius || this.popupBorderRadius || this.barColor || this.toggleBarColor || this.iconUrl || this.roundedBackground
      || this.iconFontSize) {

      if(this.inputPadding)
        this.host.nativeElement.style.setProperty('--input-padding', this.inputPadding);
      if(this.prefixMargin)
        this.host.nativeElement.style.setProperty('--prefix-margin', this.prefixMargin);
      if(this.suffixMargin)
        this.host.nativeElement.style.setProperty('--suffix-margin', this.suffixMargin);
      if(this.cropperPrimaryBackgroundColor)
        this.host.nativeElement.style.setProperty('--cropper-primary-background-color', this.libraryService.convertToHexColor(this.host, this.cropperPrimaryBackgroundColor));
      if(this.cropperBackgroundColor)
        this.host.nativeElement.style.setProperty('--cropper-background-color', this.libraryService.convertToHexColor(this.host, this.cropperBackgroundColor));
      if(this.removeBtnTextColor)
        this.host.nativeElement.style.setProperty('--remove-btn-text-color', this.libraryService.convertToHexColor(this.host, this.removeBtnTextColor));
      if(this.removeBtnColor)
        this.host.nativeElement.style.setProperty('--remove-btn-color', this.libraryService.convertToHexColor(this.host,this.removeBtnColor));
      if(this.imageBorderRadius)
        this.host.nativeElement.style.setProperty('--image-border-radius', this.imageBorderRadius);
      if(this.uploadBtnBorderRadius)
        this.host.nativeElement.style.setProperty('--btn-border-radius', this.uploadBtnBorderRadius);
      if(this.aspectRatio)
        this.host.nativeElement.style.setProperty('--aspect-ratio', this.aspectRatio);
      if(this.cropperAspectRatio)
        this.host.nativeElement.style.setProperty('--cropper-aspect-ratio', this.cropperAspectRatio);
      if(this.popupBorderRadius)
        this.host.nativeElement.style.setProperty('--popup-border-radius', this.popupBorderRadius);
      if(this.barColor)
        this.host.nativeElement.style.setProperty('--default-bar-color', this.libraryService.convertToHexColor(this.host, this.barColor));
      if(this.toggleBarColor)
        this.host.nativeElement.style.setProperty('--toggle-bg-color', this.libraryService.convertToHexColor(this.host,this.toggleBarColor));
      if(this.iconUrl)
        this.host.nativeElement.style.setProperty('--icon-url', `url(${this.iconUrl})`);
      if(this.roundedBackground)
        this.host.nativeElement.style.setProperty('--rounded-bg', this.libraryService.convertToHexColor(this.host, this.roundedBackground));
      if(this.iconFontSize)
        this.host.nativeElement.style.setProperty('--icon-font-size', this.iconFontSize);

      if(this.placeholderStyle)
        this.setPlaceholderStyles();
    }
  }

  private setPlaceholderStyles(): void {
    if(this.placeholderStyle.fontSize)
      this.host.nativeElement.style.setProperty('--placeholder-font-size', this.placeholderStyle.fontSize);
    if(this.placeholderStyle.fontStyle)
      this.host.nativeElement.style.setProperty('--placeholder-font-style', this.placeholderStyle.fontStyle);
    if(this.placeholderStyle.fontWeight)
      this.host.nativeElement.style.setProperty('--placeholder-font-weight', this.placeholderStyle.fontWeight);
    if(this.placeholderStyle.color)
      this.host.nativeElement.style.setProperty('--placeholder-color', this.libraryService.convertToHexColor(this.host, this.placeholderStyle.color));
  }
}
