<button class="generic-button" [ngClass]="{'has-icon': hasIcon || hasPrefixIcon, 'border': hasBorder}"
  [disabled]="isDisabled ? 'disabled' : ''" (click)="emitButtonAction()">
  <div class="prefix-icon" [ngClass]="{ 'no-margin' : buttonName === '' }" *ngIf="hasPrefixIcon">
    <i [class]="iconClass"></i>
  </div>
  <span>{{buttonName}}</span>
  <div class="suffix-icon" [ngClass]="{ 'no-margin' : buttonName === '' }" *ngIf="hasIcon">
    <i [class]="iconClass"></i>
  </div>
</button>
