import { Injectable } from '@angular/core';
import { initializeApp } from '@firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  private app: any;
  private analytics: any;

  constructor() {
  }

  initializeFirebaseApp(): void {
    const firebaseConfig = environment.firebaseConfig;

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }

  logEvents(eventName: string, savedDate?: Date): void {
    if (!this.app) {
      this.initializeFirebaseApp();
    }

    // shared method to log the events
    const currentDate = savedDate ? savedDate.toISOString() : (new Date()).toISOString();
    const hoursMins = currentDate.split('T')[1].split(':');

    logEvent(this.analytics, eventName, { date: hoursMins[0] + ':' + hoursMins[1] });
  }
}
