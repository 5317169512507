import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingInfo, Menu, MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.sass']
})
export class ContactUsComponent implements OnInit {

  public subMenuList: Array<Menu>;
  public currentMenuUrl: string;
  public mainBannerInfo: MenuBanner;
  public environment = environment;

  public brandInfo: BrandingInfo;

  constructor(
    public labelService: LabelsService,
    public generalService: GeneralService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/contact-us');
    this.currentMenuUrl = currentMenu?.viewUrl!;
    this.subMenuList = currentMenu?.childs!;
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.brandInfo = this.generalService.generalInfo.brandingInfo;
  }

  public menuClicked(): void {
    this.generalService.autoScrollTop('page-router-container');
  }
}
