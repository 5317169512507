export enum SnackBarStatusType {
  none,
  success,
  error,
  warning,
  info
}

export interface CustomSnackBarData {
  snackbarClass: string;
  prefixIconUrl: string;
  message: string;
  showCloseIcon: boolean;
  action?: string;
}
