import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Description, PopUpButton, Title } from '../../popups.model';

@Component({
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class GenericPopupComponent implements OnInit, AfterViewInit {

  @Input() preImgTitle: Title;
  @Input() image: string;
  @Input() bottomImage: string;
  @Input() bottomDescription: string;
  @Input() title: Title;
  @Input() descriptions: Array<Description>;
  @Input() navigationUrl: string;
  @Input() buttons: Array<PopUpButton>;

  @Input() closeClickOutside: boolean;
  @Input() buttonsVertical: boolean;
  @Input() enableXButton: boolean = true;

  @Output() buttonClicked: EventEmitter<{actionText?: string, actionValue?: string}> = new EventEmitter<{actionText?: string, actionValue?: string}>();
  @Output() popUpClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationState: string = 'hide';

  constructor(
    private router: Router,
    private host:ElementRef
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        this.animationState = 'show';
    })})();
  }

  btnAction(btn: PopUpButton): void {
    if (btn.isCloseBtn) {
      this.closePopUp();
      this.buttonClicked.emit({
        actionText: btn.actionText,
        actionValue: btn.actionValue
      });
    }
    else this.buttonClicked.emit({
      actionText: btn.actionText,
      actionValue: btn.actionValue
    });
  }

  closePopUp(): void {
    this.animationState = 'hide';
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 300)).then(() => {
        this.popUpClosed.emit(true);
    })})();
  }

  navigateTo(): void {
    if(this.navigationUrl.includes('https')) {
      window.open(this.navigationUrl, "_blank");
    } else {
      this.router.navigate([this.navigationUrl]);
    }
  }
}
