import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { Subscription, take } from 'rxjs';
import { phoneNumberValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input/generic-phone-input.validator';
import { CountryISO } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/phone-number.model';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { environment } from 'src/environments/environment';
import { ContactUsService } from '../_services/contact-us.service';
@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.sass']
})
export class ContactUsFormComponent implements OnInit, OnDestroy {

  public environment = environment;

  public contactUsFormContainer: FormGroup;
  public CountryISO = CountryISO;
  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public labelService: LabelsService,
    private popupService: PopupService,
    private contactUsService: ContactUsService
  ) { }

  ngOnInit(): void {
    this.contactUsFormContainer = this.fb.group({
      form: this.fb.group({
        name: new UntypedFormControl('', [
          Validators.pattern("^[A-Za-zÀ-ÿ\\s]*$"),
          Validators.required
        ]),
        phoneInput: new UntypedFormControl('', [
          phoneNumberValidator,
        ]),
        countryCode: new UntypedFormControl('', []),
        email: new UntypedFormControl('', [
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"),
          Validators.required
        ]),
        message: new UntypedFormControl('', [
          Validators.pattern("^(?! )[^\s].*$"),
          Validators.required
        ])
      }),
    });

    if (environment.showPhoneInput) {
      this.contactUsFormContainer.get('form')?.get('phoneInput')?.addValidators(Validators.required);
      this.contactUsFormContainer.get('form')?.get('countryCode')?.addValidators(Validators.required);
    }

    this.subscription = this.contactUsFormContainer.get('form')?.get('phoneInput')?.valueChanges.subscribe((value: string) => {
      const phoneUtil = lpn.PhoneNumberUtil.getInstance();
      const countryCode = this.contactUsFormContainer.get('form')?.get('countryCode')?.value;
      const regionCode = phoneUtil.getCountryCodeForRegion(countryCode);
      const isoCode = phoneUtil.getRegionCodeForCountryCode(phoneUtil.getCountryCodeForRegion(countryCode));
      const phoneNumberLength = phoneUtil.getExampleNumberForType(isoCode, lpn.PhoneNumberType.FIXED_LINE_OR_MOBILE).getNationalNumber()?.toString().length;

      if(countryCode && typeof value === 'string' && value.length === phoneNumberLength && !value.startsWith(`+${regionCode}`)) {
        this.contactUsFormContainer.get('form')?.get('phoneInput')?.patchValue(
          `+${regionCode} ${value}`,
        );
      }
    })!;
  }

  public submitForm(): void {
    this.contactUsFormContainer.markAllAsTouched();
    if(this.contactUsFormContainer.valid) {
      // Add a way to submit the information to BE

      this.contactUsService.submitContactUsForm(this.contactUsFormContainer).pipe(take(1)).subscribe(resp => {

      });

      this.popupService.setPopupToOpen(
        {text:''},
        'assets/imgs/environments/' + environment.tenantName + '/popup-success-image.png',
        { text: 'Thank you'},
        [
          {text: 'Request sent successfully.\nA member of our team will get back to you shortly.'},
        ],
        [{
          text: 'Ok',
          actionText: 'close',
          isCloseBtn: true,
          style: {
            backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
            fontColor: this.environment.brandStyleVariables.mainBackgroundColor,
            marginTop: '17px',
            fontSize: '14px',
            textAlign: 'center',
            fontWeight: '700',
            borderRadius: '66px'
          }
        }]
      );

      this.popupService.hasPopups.subscribe(haspopUp => {
        if(!haspopUp) {
          this.contactUsFormContainer.reset();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
