import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SmallPageBannerComponent } from './small-page-banner/small-page-banner.component';



@NgModule({
  declarations: [
    SmallPageBannerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SmallPageBannerComponent
  ]
})
export class SmallPageBannerModule { }
