import { Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-loading-list-item',
  templateUrl: './loading-list-item.component.html',
  styleUrls: ['./loading-list-item.component.sass']
})
export class LoadingListItemComponent implements OnInit {
  @ContentChildren(TemplateRef) tmpls: QueryList<TemplateRef<any>>;

  @Input() isImageVertical: boolean;

  public tmplsArray: Array<TemplateRef<any>> = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.tmplsArray = this.tmpls.toArray();
  }
}
