import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivityInfoDTO, ActivityTypes } from 'src/app/pages/activities/activities.model';
import { ContentType, GenericErrorReply } from 'src/app/_services/_general-service/general-service.model'

@Injectable({
  providedIn: 'root'
})
export class MockActivitiesService {

  constructor() { }

  public getActivityList(): Observable<Array<ActivityInfoDTO>> {
    return of([
      {
        activityId: '29d8e34c-3818-422d-9d60-94eb9346b48a',
        type: ActivityTypes.REGULAR,
        name: 'Test 1',
        description: '',
        partnerId: '14ec2728-01a0-40aa-a16a-f6ef4d458fb6',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 500,
          unit: 'pinkcoins'
        }],
        totalReward: 500,
        canWinRewards: true,
        partner: {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: 'test partner',
          images: {
            profilePictureImage: {
              href: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          }
        },
        endDate: "2024-12-31T00:00:00Z",
      },
      {
        activityId: 'ac148d62-9867-4873-9c77-939c1b194de6',
        type: ActivityTypes.INPUT,
        name: 'Test 2',
        description: '',
        partnerId: '971165a6-e2c5-4265-87da-3c4a5677eaf7',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 250,
          unit: 'pinkcoins'
        }],
        totalReward: 250,
        canWinRewards: true,
        endDate: "2024-12-31T00:00:00Z"
      },
      {
        activityId: '80889ced-c94e-40cf-b9e0-8e2f4167c91a',
        type: ActivityTypes.MULTI_STEP,
        name: 'Test 3',
        description: '',
        partnerId: 'e5ebdde5-3adf-456d-9eb2-001a217947ec',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 1500,
          unit: 'pinkcoins'
        }],
        totalReward: 1500,
        canWinRewards: true,
        subTasks: ['', '', ''],
        partner: {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: 'test partner',
          images: {
            profilePictureImage: {
              href: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          }
        },
        endDate: "2024-12-31T00:00:00Z"
      }
    ]);
  }

  public getActivityInfo(): Observable<ActivityInfoDTO> {
    return of(
      {
        activityId: '29d8e34c-3818-422d-9d60-94eb9346b48a',
        type: ActivityTypes.REGULAR,
        name: 'Test 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
        partnerId: '14ec2728-01a0-40aa-a16a-f6ef4d458fb6',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 500,
          unit: 'pinkcoins'
        }],
        totalReward: 500,
        canWinRewards: true,
        partner: {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: 'test partner',
          images: {
            profilePictureImage: {
              href: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          }
        },
        endDate: "2024-12-31T00:00:00Z",
      }
    );

    /* return of(
      {
        activityId: '29d8e34c-3818-422d-9d60-94eb9346b48a',
        type: ActivityTypes.INPUT,
        name: 'Test 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
        partnerId: '14ec2728-01a0-40aa-a16a-f6ef4d458fb6',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 500,
          unit: 'pinkcoins'
        }],
        totalReward: 500,
        canWinRewards: true,
        partner: {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: 'test partner',
          images: {
            profilePictureImage: {
              href: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          }
        },
        endDate: "2024-12-31T00:00:00Z",
        codes: null,
        userInputs:[],
        inputs: [
          {
            type: InputType.SINGLE_LINE,
            order: 1,
            label: "Nome da empresa",
            placeholder: "Inserir nome da empresa",
            required: true,
            maxLength: 150,
            size: WidthOptions.HALF
          },
          {
            type: InputType.SINGLE_LINE,
            order: 2,
            label: "Pessoa do contacto",
            placeholder: "Inserir nome da pessoa do contacto",
            required: true,
            maxLength: 150,
            size: WidthOptions.HALF
          },
          {
            type: InputType.EMAIL,
            order: 3,
            label: "Email",
            placeholder: "Inserir email",
            required: true,
            size: WidthOptions.HALF
          },
          {
            type: InputType.PHONE_NUMBER,
            order: 4,
            label: "Telemóvel",
            placeholder: "Inserir telemóvel",
            required: true,
            size: WidthOptions.HALF,
          },
          {
            type: InputType.SINGLE_LINE,
            order: 5,
            label: "NIF",
            placeholder: "Inserir NIF da empresa",
            required: true,
            size: WidthOptions.HALF
          },
          {
            type: InputType.NUMBER,
            order: 6,
            label: "Valor da oportunidade",
            placeholder: "Inserir o valor que pode gerar esta oportunidade",
            required: false,
            size: WidthOptions.HALF
          },
          {
            type: InputType.TEXT_AREA,
            order: 7,
            label: "Observações",
            placeholder: "Descreve a oportunidade",
            required: false,
            maxLength: 1000,
            size: WidthOptions.FULL
          }
        ]
      }
    ); */

    /* return of(
      {
        activityId: '29d8e34c-3818-422d-9d60-94eb9346b48a',
        type: ActivityTypes.MULTI_STEP,
        name: 'Test 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
        partnerId: '14ec2728-01a0-40aa-a16a-f6ef4d458fb6',
        images: [
          {
            href: 'assets/mock/products-mock/AMNISTIA_INTERNACIONAL_NFT.png',
            content: '',
            type: ContentType.IMAGE,
          }
        ],
        rewards: [{
          amount: 500,
          unit: 'pinkcoins'
        }],
        totalReward: 500,
        canWinRewards: true,
        partner: {
          id: '',
          tenantId: '',
          description: '',
          url: '',
          categories: [],
          name: 'test partner',
          images: {
            profilePictureImage: {
              href: 'assets/mock/institutions-mock/aministia_internacional_logo.png',
              type: ContentType.IMAGE
            },
            coverImage: {
              href: '',
              type: ContentType.IMAGE
            }
          }
        },
        endDate: "2024-12-31T00:00:00Z",
        codes: null
      }
    ); */
  }

  public addEditUserInput(): Observable<null | GenericErrorReply> {
    return of(null);
  }

  public removeUserInput(): Observable<null | GenericErrorReply> {
    return of(null);
  }
}
