import { AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { TabsBaseComponent } from '../../../_extended-components/tabs-base-component/tabs-base.component';
import { TabItem } from '../../generic-tabs-model';

@Component({
  selector: 'app-generic-tabs',
  templateUrl: './generic-tabs.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/tabs-base-component/tabs-base.component.sass',
    './generic-tabs.component.sass'
  ]
})
export class GenericTabsComponent extends TabsBaseComponent implements OnInit, AfterContentInit {
  @ContentChildren(TemplateRef) tmpls: QueryList<TemplateRef<any>>;
  @ViewChild('tabGroup', { static: false}) tabGroup: MatTabGroup | undefined;

  @Input() hasUnderLineActive: boolean;

  @Input() tabsBody: Array<string> = [];

  @Input() defaultTab: number = 0;

  @Input() tabsList: Array<TabItem>;

  @Output() clickedTab: EventEmitter<string> = new EventEmitter<string>();

  tmplsArray: Array<TemplateRef<any>> = [];

  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngAfterContentInit(): void {
    if(this.tabsBody.length === 0) {
      this.tmplsArray = this.tmpls.toArray();
    }
  }

  emitClickedTab(event: number): void {
    this.clickedTab.emit(this.tabsList[event].id);
  }
}
