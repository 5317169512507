import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { DropdownOption } from '../dropdown.model';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/dropdown-base-component/dropdown-base.component.sass',
    '../generic-dropdown/generic-dropdown.component.sass',
    './dropdown-input.component.sass'
  ],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class DropdownInputComponent extends DropdownBaseComponent implements OnInit, OnChanges {

  @Input() arrowIconUrl: string;
  @Input() checkIconUrl: string;

  public hover: boolean;
  public inputFocused: boolean = false;

  public selectedOption?: DropdownOption;

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    if(this.optionToSelect) {
      this.selectedOption = this.optionToSelect;
      this.inputValue = this.optionToSelect.name;
    }

    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['optionToSelect']?.currentValue !== changes['optionToSelect']?.previousValue && changes['optionToSelect']?.previousValue !== undefined) {
      this.selectedOption = this.optionToSelect!;
      this.inputValue = this.optionToSelect ? this.optionToSelect.name : '';
    }
  }

  public emitOptionSelected(option: DropdownOption): void {
    this.selectedOption = option;
    this.inputFocused = false;

    this.optionSelected.emit(option);
  }
}
