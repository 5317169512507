<ng-container *ngIf="fCName == null; else formInput">
  <div class="custom-checkbox-container" [ngClass]="{'disabled': disabled}" [ngStyle]="{'justify-content': justifyContent, 'flex-direction': flexDirection, 'gap': gapSize ? gapSize : ''}"
    (click)="$event.stopPropagation(); onValueChange()">
    <span class="checkbox-label" [ngClass]="{'disabled': disabled}">{{ label }}</span>
    <mat-checkbox class="example-margin" [checked]="checked" [disabled]="disabled"></mat-checkbox>
  </div>
</ng-container>

<ng-template #formInput>
  <form [formGroup]="form">
    <div class="custom-checkbox-container" [ngClass]="{'disabled': disabled}" [ngStyle]="{'justify-content': justifyContent, 'flex-direction': flexDirection, 'gap': gapSize ? gapSize : ''}"
      (click)="$event.stopPropagation(); onValueChange()">
      <span class="checkbox-label" [ngClass]="{'disabled': disabled}">{{ label }}</span>
      <mat-checkbox class="example-margin" formControlName="{{fCName}}" [checked]="checked" [disabled]="disabled"></mat-checkbox>
    </div>
  </form>
</ng-template>
