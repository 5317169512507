import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActionId, ItemState } from 'src/app/pages/_shared-components/generic-list-item-module/generic-list-item.model';
import { ProductDTO } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { TransactionHeaderDTO, TransactionResponse } from 'src/app/pages/profile/_services/profile.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MockProfileService {

  constructor() { }

  getUserTransactionListHeaders(): Observable<Array<TransactionHeaderDTO>> {
    return of([
      {
        id: '1',
        name: 'Nome',
        sizes: [],
      },
      {
        id: '2',
        name: 'Estado',
        sizes: [],
      },
      {
        id: '3',
        name: 'Data',
        sizes: [],
      },
      {
        id: '4',
        name: 'Valor',
        sizes: [],
      }
    ]);
  }

  getUserTransactionList(): Observable<TransactionResponse> {
    return of({
      items: [
      {
        id: '0',
        name: 'Coll 5',
        serial: "#5368",
        entity: {
          name: 'Unicef',
          imageUrl: 'assets/mock/unicef.png',
        },
        status: ItemState.completed,
        date: '2022-01-12T00:00:00+0000',
        value: {
          amount: 40.00,
          unit: '€'
         },
        sizes: [],
        action: {
          imageUrl: 'assets/imgs/transaction-action-icon.svg',
          actionId: ActionId.read
        }
      },
      {
        id: '1',
        name: 'Coll 5',
        serial: "#4779",
        entity: {
          name: 'Unicef',
          imageUrl: 'assets/mock/unicef.png',
        },
        status: ItemState.completed,
        date: '2022-01-11T00:00:00+0000',
        value: {
          amount: 40.00,
          unit: '€'
         },
        sizes: [],
        action: {
          imageUrl: 'assets/imgs/transaction-action-icon.svg',
          actionId: ActionId.read
        }
      },
      {
        id: '2',
        name: 'Coll 5',
        serial: "#4879",
        entity: {
          name: 'Unicef',
          imageUrl: 'assets/mock/unicef.png',
        },
        status: ItemState.completed,
        date: '2022-01-10T00:00:00+0000',
        value: {
          amount: 40.00,
          unit: '€'
         },
        sizes: [],
        action: {
          imageUrl: 'assets/imgs/transaction-action-icon.svg',
          actionId: ActionId.read
        }
      },
      {
        id: '3',
        name: 'Coll 5',
        serial: "#7449",
        entity: {
          name: 'Unicef',
          imageUrl: 'assets/mock/unicef.png',
        },
        status: ItemState.completed,
        date: '2022-01-09T00:00:00+0000',
        value: {
          amount: 40.00,
          unit: '€'
         },
        sizes: [],
        action: {
          imageUrl: 'assets/imgs/transaction-action-icon.svg',
          actionId: ActionId.read
        }
      },
      {
        id: '4',
        name: 'Coll 5',
        serial: "#7449",
        entity: {
          name: 'Unicef',
          imageUrl: 'assets/mock/unicef.png',
        },
        status: ItemState.completed,
        date: '2022-01-09T00:00:00+0000',
        value: {
         amount: 40.00,
         unit: '€'
        },
        sizes: [],
        action: {
          imageUrl: 'assets/imgs/transaction-action-icon.svg',
          actionId: ActionId.read
        }
      }
    ],
    totalItems: 5
    });
  }

  getCollectionsList(): Observable<Array<ProductDTO>> {
    if (environment.tenantName === 'YOUR') {
      return of([
        {
          productId: 'a630a5e0-ecd0-4daf-930c-c63bf1529a21',
          tenantId: 'YOUR',
          name: 'Bilhete Geral MEO Sudoeste',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque aliquet id nullam tristique consectetur. Nec curabitur nunc, a vitae. Felis odio sit nibh bibendum donec egestas sit phasellus elit. Ut enim duis eu cras duis risus leo. Amet adipiscing consequat lectus ut dignissim nisl.\nCongue at dictum aenean enim volutpat. Tellus lacinia rutrum lectus amet, auctor et ut odio vitae. Viverra leo cum euismod ultrices dolor in. Purus feugiat mauris venenatis dui pellentesque scelerisque in tellus id. Feugiat magna augue feugiat libero. Mi aliquet id posuere purus sit risus. Sit orci lorem adipiscing ut vestibulum, habitasse aliquet condimentum. Convallis metus pellentesque nibh consectetur luctus volutpat.\nEnim mi dolor viverra amet scelerisque sodales. Senectus suscipit mauris id nullam convallis leo posuere tellus posuere. Dignissim sit elit pretium tristique nunc.',
          type: 'VOUCHER',
          endDate: "2024-11-31T00:00:00Z",
          images: [
            {
              "href": "https://images.impresa.pt/expresso/2022-08-07-_RC05821_ritacarmo.jpg-46d3e97a",
              "type": "IMAGE"
            }
          ],
          prices: [
            { amount: 2, unit: 'Vouchers' },
          ],
        }
      ]);
    }

    return of([
      {
        "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
        "tenantId": "GIVIN",
        "name": "NFT Multinho - AMI",
        "description": "O NFT Multinho - AMI surge de uma ligação entre o NFT Solidário e a instituição AMI. Este projeto visa apoiar a instituição parceira AMI, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
        "type": "NFT",
        "serial": "1/1",
        "endDate": "2024-12-31T00:00:00Z",
        "images": [{ "href":"assets/mock/products-mock/AMI_NFT.png", "type": 'Image' }],
        "prices": [
          { "amount": 5000, "unit": "EUR" },
        ],
        "quantity": 1,
        "purchaseDate": "2023-06-04T00:00:00Z",
        "traits": [
          {
            "name": "Background",
            "value": "White"
          },
          {
            "name": "Object",
            "value": "Animation"
          }
        ],
        "partner": {
          "id": "ami",
          "name": "AMI",
          "image": "https://givin-assets-dev.s3.eu-west-1.amazonaws.com/GIVIN/assets/partners/b10595a2-0db8-4e32-9de3-27da89c3907d_1676481190140.png"
        }
      },
      {
        "productId": "3c1c5467-4411-4052-bed1-eaeae911d2e0",
        "tenantId": "GIVIN",
        "name": "NFT Multinho - Cáritas Portuguesa",
        "description": "O NFT Cáritas Portuguesa - AMI surge de uma ligação entre o NFT Solidário e a Cáritas Portuguesa. Este projeto visa apoiar a instituição parceira Cáritas Portuguesa, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
        "type": "NFT",
        "quantity": 1,
        "serial": "1/1",
        "endDate": "2024-12-31T00:00:00Z",
        "images": [{ "href":"assets/mock/products-mock/CARITAS_PORTUGUESA_NFT.png", "type": 'Image' }],
        "prices": [],
        "purchaseDate": "2023-06-04T00:00:00Z",
        "traits": [
          {
            "name": "Background",
            "value": "White"
          },
          {
            "name": "Object",
            "value": "Animation"
          }
        ],
        "partner": {
          "id": 'caritas',
          "name": "Cáritas Portuguesa",
          "image": "https://givin-assets-dev.s3.eu-west-1.amazonaws.com/GIVIN/assets/partners/bb774e1b-35de-4d88-9023-d5b3f73fc992_1676481249470.png"
        }
      },
      {
        "productId": "696d981a-804c-45c5-9a32-66c1acea71fb",
        "tenantId": "173036a3-2bd6-4d6c-8b45-5584a913d2c3",
        "name": "NFT Multinho - Liga Portuguesa Contra o Cancro",
        "description": "O NFT Multinho - Liga Portuguesa Contra o Cancro surge de uma ligação entre o NFT Solidário e a Liga Portuguesa Contra o Cancro. Este projeto visa apoiar a instituição parceira Liga Portuguesa Contra o Cancro, para que eles consigam continuar o excelente trabalho que têm feito com as pessoas.",
        "type": "NFT",
        "status": "Created",
        "endDate": "2022-12-31T00:00:00Z",
        "images": [
            {
                "type": "Image",
                "href": "https://givin-assets-qa.s3.eu-west-1.amazonaws.com/GIVIN/assets/products/3dcbc995-2d53-4c93-b844-0068f3e29258_1676546520359.png",
            }
        ],
        "serial": "1/1",
        "quantity": 1,
        "prices": [
            {
                "amount": 5000,
                "unit": "EUR"
            }
        ],
        "characteristics": [],
        "traits": [
            {
                "name": "Instituição",
                "value": "Liga Portuguesa Contra o Cancro"
            },
            {
                "name": "Cor",
                "value": "Rosa"
            },
            {
                "name": "Categoria",
                "value": "Saúde"
            },
            {
                "name": "Fundo",
                "value": "Branco"
            },
            {
                "name": "Personagem",
                "value": "Multinho"
            }
        ],
        "categories": [],
        "collection": {
            "id": "9f2fbc46-b94a-4813-8a81-c772766db973",
            "tenantId": "313eb9ab-b086-4107-ae39-e6c90dd7c9df",
            "name": "NFT Multinho",
        },
        "partner": {
            "id": "liga-portuguesa-contra-o-cancro",
            "name": "Liga Portuguesa Contra o Cancro"
        }
      }
    ]);
  }
}
