import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ActivitiesService } from '../_services/activities/activities.service';
import { ActivityInfo, ActivityListItem, ActivityTypes } from '../activities.model';

@Component({
  selector: 'app-activity-details-container',
  templateUrl: './activity-details-container.component.html',
  styleUrls: ['./activity-details-container.component.sass'],
  animations: [
    trigger('FadeIn', [
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      transition('hide <=> show', animate('600ms ease-in-out')),
    ])
  ]
})
export class ActivityDetailsContainerComponent implements OnInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject<boolean>();

  public animationState: string = 'hide';

  public mainBannerInfo: MenuBanner;
  public environment = environment;
  public isLoaded: boolean = false;

  public loadingListItems: Array<number> = [];
  public isListLoading: boolean = false;

  public similarActivitiesList: Array<ActivityListItem> = [];
  public activityId: string;
  public activity: ActivityInfo;

  public ActivityTypes = ActivityTypes;

  constructor(
    public generalService: GeneralService,
    public labelService: LabelsService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    public mainComponent: AppComponent
  ) {
    this.generalService.userDetails.pipe(takeUntil(this.destroy)).subscribe(() => {
      // TODO
    });
  }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/activities');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.route.params.subscribe(params => {
      this.activityId = params['id'];

      this.getActivityDetails();
      this.getSimilarItems(1);
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public getActivityDetails(): void {
    this.activitiesService.getActivityDetails(this.activityId).pipe(take(1)).subscribe({
      next: activityInfo => {
        this.activity = activityInfo;
        this.isLoaded = true;
        this.generalService.asyncFunction(() => { this.animationState = 'show'; });
      },
      error: err => {
        this.generalService.navigateTo('/404');
      }
    });
  }

  private getSimilarItems(page: number, params: Params = {}): void {
    this.loadingListItems = [1, 2, 3, 4];
    this.isListLoading = true;

    this.activitiesService.getActivityList(page, params).subscribe({
      next: list => {
        this.similarActivitiesList = list.filter(item => item.activityId !== this.activityId).slice(0, 5);
        this.isListLoading = false;
      },
      error: error => {
        this.isListLoading = false;
      }
    });
  }

  public activityItemClicked(activityId: string): void {
    this.generalService.navigateTo(`activities/${activityId}`);
    this.generalService.autoScrollTop('page-router-container');
  }
}
