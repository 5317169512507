import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { CardButton, CategoryDTO } from '../cardItem.model';

@Component({
  selector: 'app-list-item-small-card',
  templateUrl: './list-item-small-card.component.html',
  styleUrls: ['./list-item-small-card.component.sass']
})
export class ListItemSmallCardComponent implements OnInit {

  @Input() cardImage: string;
  @Input() cardTitle: string;
  @Input() cardDescription: string;
  @Input() cardTags: Array<CategoryDTO>;
  @Input() cardButtons: Array<CardButton>;

  constructor(
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
  }

  public buttonActionClicked(action: string, actionValue: string = ''): void {
    switch(action) {
      case 'navigate':
        this.generalService.navigateTo(actionValue);
        this.generalService.autoScrollTop('page-router-container');
        break;

      case 'donate':

        break;
    }
  }
}
