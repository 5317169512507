import { CommonModule, I18nPluralPipe } from '@angular/common';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import player from 'lottie-web';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { CheckboxInputModule } from 'src/app/_generic-components-lib/inputs/checkbox-input-module/checkbox-input.module';
import { RadioButtonsInputModule } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-button-input.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { GenericPopupModule } from 'src/app/_generic-components-lib/popups/generic-popup-module/generic-popup.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { environment } from 'src/environments/environment';
import { PageNotFoundModule } from '../page-not-found-module/page-not-found.module';
import { FilterListModule } from '../_shared-components/filters-list-module/filter-list.module';
import { ItemImageSliderModule } from '../_shared-components/item-image-slider-module/item-image-slider.module';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { LoadingListModule } from '../_shared-components/loading-list-module/loading-list.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductPaymentPageComponent } from './product-payment-page/product-payment-page.component';
import { TiersFilterComponent } from './marketplace-list-container/tiers-filter/tiers-filter.component';
import { MarketplaceListContainerComponent } from './marketplace-list-container/marketplace-list-container.component';
import { GenericPhoneInputModule } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input.module';
import { ProductContentComponent } from './marketplace-list-container/product-content/product-content.component';
import { ProductLoadingContentComponent } from './product-loading-content/product-loading-content.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    MarketplaceListContainerComponent,
    ProductDetailsComponent,
    TiersFilterComponent,
    ProductPaymentPageComponent,
    ProductContentComponent,
    ProductLoadingContentComponent,
    MarketplaceListContainerComponent,
    NftDetailsComponent,
    VoucherDetailsComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    LabelPipeModule,
    ListItemCardsModule,
    LoadingListModule,
    InfiniteScrollModule,
    ButtonsModule,
    CheckboxInputModule,
    SingleLineTextInputModule,
    I18nPluralPipe,
    PageBannerModule,
    ItemImageSliderModule,
    CheckboxInputModule,
    RadioButtonsInputModule,
    GenericPopupModule,
    FilterListModule,
    NoItemsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    GenericPhoneInputModule,
    SwiperModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    PurchasePopupModule,
    RiveAnimationModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    MarketplaceListContainerComponent,
    ProductDetailsComponent,
    ProductContentComponent,
    TiersFilterComponent,
    ProductLoadingContentComponent,
    NftDetailsComponent,
    VoucherDetailsComponent
  ],
  providers: [
    HttpClient,
    FormControlDirective,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class MarketPlaceModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { NftDetailsComponent } from './product-details/nft-details/nft-details.component';
import { VoucherDetailsComponent } from './product-details/voucher-details/voucher-details.component';
import { SwiperModule } from 'swiper/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { PurchasePopupModule } from '../_shared-components/purchase-popup-module/purchase-popup.module';
import { RiveAnimationModule } from '../_shared-components/rive-animation-module/rive-animation.module';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = environment.defaultLanguage;
      translate.setDefaultLang(environment.defaultLanguage);
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
