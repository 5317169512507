import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxInputModule } from 'src/app/_generic-components-lib/inputs/checkbox-input-module/checkbox-input.module';
import { RadioButtonsInputModule } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-button-input.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { FiltersListComponent } from './filters-list/filters-list.component';



@NgModule({
  declarations: [
    FiltersListComponent
  ],
  imports: [
    CommonModule,
    RadioButtonsInputModule,
    CheckboxInputModule,
    LabelPipeModule
  ],
  exports: [
    FiltersListComponent
  ]
})
export class FilterListModule { }
