import { Component, ElementRef, Input } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-list-base-component',
  template: '',
  styleUrls: ['./list-base.component.sass']
})
export class ListBaseComponent extends BaseComponent {

  @Input() stateActiveColor: string;
  @Input() statePendingColor: string;
  @Input() stateDisabledColor: string;
  @Input() progressBarColor: string;
  @Input() defaultColor: string;
  @Input() activeColor: string;

  @Input() backgroundOpacity: string = '1A';

  @Input() stateFontSize: string;

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  setSpecificCSSVars(): void {
    if (this.stateActiveColor || this.statePendingColor || this.stateDisabledColor || this.progressBarColor || this.stateFontSize || this.defaultColor || this.activeColor) {

      if (this.stateActiveColor) {
        this.host.nativeElement.style.setProperty('--state-active-color', this.libraryService.convertToHexColor(this.host, this.stateActiveColor));
        this.host.nativeElement.style.setProperty('--state-active-light-color', this.libraryService.convertToHexColor(this.host,this.stateActiveColor, this.backgroundOpacity));
      }
      if (this.statePendingColor) {
        this.host.nativeElement.style.setProperty('--state-pending-color', this.libraryService.convertToHexColor(this.host,this.statePendingColor));
        this.host.nativeElement.style.setProperty('--state-pending-light-color', this.libraryService.convertToHexColor(this.host,this.statePendingColor, this.backgroundOpacity));
      }
      if (this.stateDisabledColor){
        this.host.nativeElement.style.setProperty('--state-disabled-color', this.libraryService.convertToHexColor(this.host, this.stateDisabledColor));
        this.host.nativeElement.style.setProperty('--state-disabled-light-color', this.libraryService.convertToHexColor(this.host,this.stateDisabledColor), this.backgroundOpacity);
      }
      if (this.progressBarColor)
        this.host.nativeElement.style.setProperty('--progress-bar-color', this.libraryService.convertToHexColor(this.host,this.progressBarColor));
      if (this.stateFontSize)
        this.host.nativeElement.style.setProperty('--state-font-size', this.stateFontSize);
      if (this.defaultColor)
        this.host.nativeElement.style.setProperty('--default-color', this.libraryService.convertToHexColor(this.host,this.defaultColor));
      if (this.activeColor)
        this.host.nativeElement.style.setProperty('--active-color', this.libraryService.convertToHexColor(this.host,this.activeColor));
    }
  }
}
