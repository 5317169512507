import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Accordion } from 'src/app/_generic-components-lib/accordion-module/accordion.model';

@Injectable({
  providedIn: 'root'
})
export class MockContactusService {

  constructor() { }

  getFaqs(): Observable<Array<Accordion>> {
    return of([
      {
        id: '1',
        title: 'When will the platform be online?',
        description: 'For now we don\'t have an actual date for the release, sign-up on our newsletter to learn more.',
        animationState: 'hide'
      },
      {
        id: '2',
        title: 'Who are the partners?',
        description: 'We are in discussion with potential partners and will soon update this area with them.',
        animationState: 'hide'
      },
      {
        id: '3',
        title: 'Will the platform be available in other countries?',
        description: 'Yes, that is planned for the future!',
        animationState: 'hide'
      },
      {
        id: '4',
        title: 'How can I contribute?',
        description: 'Simple, you donate using your crypto currency (ETH).',
        animationState: 'hide'
      }
    ])
  }
}
