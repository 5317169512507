<div (click)="clickOutside($event);" class="outside-dropdown"></div>

<div class="dropdown aux" id="dropdownContainer"></div>
<ng-container *ngIf="fCName == null; else formInput">
  <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'"
    [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">

    <ng-container *ngIf="dropdownOptions.length !== 0; else noResults">
      <div *ngFor="let option of dropdownOptions">
        <div *ngIf="option.name!.toLowerCase()" class="option-item"
          [ngClass]="{'selected': option.selected, 'justify-content-center': !checkIcon, 'no-icon': !checkIcon }" (click)="toggleOptionSelected(option.id!)">
          <span class="d-flex align-items-center" [ngClass]="!checkIcon? 'title': 'name'">{{option.name}}</span>
          <i *ngIf="option.selected && !activeText && checkIcon" class="check-icon bi bi-check-lg"></i>
          <span class="branding-font-color active-text" *ngIf="activeText && option.active">{{ 'Active' }}</span>
          <span class="inactive-text" *ngIf="activeText && !option.active">{{ 'Inactive' }}</span>
        </div>
      </div>
    </ng-container>

    <ng-template #noResults>
      <div class="option-item">
        <span class="title">{{ noResultsLabel }}</span>
      </div>
    </ng-template>
  </div>
</ng-container>


<ng-template #formInput>
  <form [formGroup]="form">
    <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'"
      [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">
      <ng-container *ngIf="dropdownOptions.length !== 0; else noResults">
        <div *ngFor="let option of dropdownOptions">
          <div *ngIf="option.name!.toLowerCase()" class="option-item"
            [ngClass]="{'selected': option.selected, 'justify-content-center': !checkIcon, 'no-icon': !checkIcon }" (click)="toggleOptionSelected(option.id!)">
            <span class="d-flex align-items-center" [ngClass]="!checkIcon? 'title': 'name'">{{option.name}}</span>
            <i *ngIf="option.selected && !activeText && checkIcon" class="check-icon bi bi-check-lg"></i>
            <span class="branding-font-color active-text" *ngIf="activeText && option.active">{{ 'Active' }}</span>
            <span class="inactive-text" *ngIf="activeText && !option.active">{{ 'Inactive' }}</span>
          </div>
        </div>
      </ng-container>

      <ng-template #noResults>
        <div class="option-item">
          <span class="title">{{ noResultsLabel }}</span>
        </div>
      </ng-template>
    </div>
  </form>
</ng-template>
