import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { HeaderItem } from '../generic-list-header.model';

@Component({
  selector: 'app-generic-list-header',
  templateUrl: './generic-list-header.component.html',
  styleUrls: ['./generic-list-header.component.sass']
})
export class GenericListHeaderComponent implements OnInit {

  @Input() fontColor: string;
  @Input() brandColor: string;
  @Input() errorColor: string;
  @Input() defaultColor: string;
  @Input() activeColor: string;

  @Input() fontSize: string;

  @Input() headerList: Array<HeaderItem>;

  isMobile: boolean = false;

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    this.setCSSVars();
  }

  setCSSVars(): void {
    if (this.fontColor || this.brandColor || this.errorColor || this.fontSize || this.defaultColor || this.activeColor) {

      //set variables
      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.fontColor);
      if (this.brandColor)
        this.host.nativeElement.style.setProperty('--brand-color', this.brandColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--error-color', this.errorColor);
      if (this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);
      if (this.defaultColor)
        this.host.nativeElement.style.setProperty('--default-color', this.defaultColor);
      if (this.activeColor)
        this.host.nativeElement.style.setProperty('--active-color', this.activeColor);
    }
  }
}
