import { getCurrencySymbol } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LanguagesService } from 'src/app/_services/_languages/languages.service';
import { environment } from 'src/environments/environment';
import { Category, Money } from '../../_services/_products/products.service.model';

@Component({
  selector: 'app-product-content',
  templateUrl: './product-content.component.html',
  styleUrls: ['./product-content.component.sass']
})
export class ProductContentComponent implements OnInit {

  @Input() serial: string;
  @Input() endDate: string;
  @Input() purchaseDate: string;
  @Input() dateWarning: boolean;
  @Input() prices: Array<Money>;
  @Input() lowestPriceValue: number;
  @Input() quantity: number;
  @Input() quantityWarningNumber: number;
  @Input() quantityWarningColor: string;
  @Input() category: Array<Category> = [];
  @Input() isBlocked: boolean;
  @Input() isHistoryItem: boolean;

  public categoryClass: string;

  public environment = environment;
  public getCurrencySymbol = getCurrencySymbol;

  public currency: Currency;

  constructor(
    public generalService: GeneralService,
    public languageService: LanguagesService,
    public translateService: TranslateService,
    public labelsService: LabelsService,
    private host: ElementRef) {
      const sessionLanguage = localStorage.getItem('SessionLanguageCode');

      this.translateService.currentLang = environment.defaultLanguage;
      this.translateService.setDefaultLang(environment.defaultLanguage);
      this.translateService.use(environment.defaultLanguage);
    }

  ngOnInit(): void {
    this.setCSSVars();
    this.setCSSClass();

    if (this.prices) {
      const currencyAux: Currency | undefined = this.generalService.generalInfo.brandingInfo.currencies?.find(c => c.type === this.prices[0].unit);
      this.currency = currencyAux ? currencyAux : {
        type: this.prices[0].unit,
        name: this.prices[0].unit,
        requiresWallet: true,
        paymentTypes: []
      };
    }
  }

  private setCSSVars(): void {
    if(this.quantityWarningColor) {
      this.host.nativeElement.style.setProperty('--msg-color', this.quantityWarningColor);
    }
  }

  private setCSSClass(): void {
    if(this.category && this.category.length > 0) {
      switch (this.category[0].name) {
        case 'Legendary' :
          this.categoryClass = 'legendary';
          break;

        case 'Artifact':
          this.categoryClass = 'artifact';
          break;

        case 'Special':
          this.categoryClass = 'special';
          break;

        case 'Relic':
          this.categoryClass = 'relic';
          break;

        case 'Common':
          this.categoryClass = 'common';
          break;

        case 'Digital-Art':
          this.categoryClass = 'digital-art';
          break;

        default:
          break;
      }
    }
  }
}
