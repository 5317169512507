<div class="table-header">
  <div class="header-wrapper">
    <div class="header-container static-first-element" [ngStyle]="{'width': isMobile ? headerList[0].sizes![0].sizeSmall + 'vw' : headerList[0].sizes![0].size + '%' }">
      <span>{{headerList[0].name}}</span>
    </div>
  </div>
  <ng-container *ngFor="let header of headerList; let i = index">
    <div class="header-wrapper" *ngIf="i > 0">
      <div class="header-container" [ngStyle]="{'width': isMobile ? header.sizes![0].sizeSmall + 'vw' : header.sizes![0].size + '%' }">
        <ng-container>
          <span>{{header.name }}</span>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
