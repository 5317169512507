import { Component, OnInit } from '@angular/core';
import { Menu, MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-history-container',
  templateUrl: './history-container.component.html',
  styleUrls: ['./history-container.component.sass']
})
export class HistoryContainerComponent implements OnInit {

  public mainBannerInfo: MenuBanner;
  public environment = environment;

  public currentMenu: Menu;
  public subMenuList: Array<Menu> | undefined;

  public isLoaded: boolean = false;

  constructor(
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/history')!;
    this.mainBannerInfo = this.currentMenu.bannerContent!;
    this.subMenuList = this.currentMenu.childs;

    if (this.subMenuList && this.subMenuList.length > 0) {
      this.generalService.navigateTo(this.currentMenu.viewUrl + this.subMenuList[0].viewUrl);
    }

    this.isLoaded = true;
  }
}
