
<div class="main-page-container">
  <ng-container *ngIf="isLoaded">
    <!-- ####### NAVBAR & MENU ####### -->
    <header>
      <app-navbar></app-navbar>
    </header>

    <div class="page-router-container custom-scroll" id="scrollable-container" #ScrollableContainer *ngIf="showRoute">
      <!-- ####### Main router ####### -->
      <router-outlet *ngIf="openNavigation"></router-outlet>

      <!-- ####### Footer ####### -->
      <footer>
        <app-footer></app-footer>
      </footer>
    </div>
    <app-generic-popup *ngIf="hasPopups && popupToShow"
      [closeClickOutside]="popupToShow.closeClickOutside!"
      [preImgTitle]="popupToShow.preImgTitle!"
      [image]="popupToShow.image!"
      [title]="popupToShow.title!"
      [descriptions]="popupToShow.descriptions!"
      [buttons]="popupToShow.buttons!"
      [enableXButton]="popupToShow.enableXButton"
      [buttonsVertical]="popupToShow.buttonsVertical!"
      (popUpClosed)="popupClosed()"
      (buttonClicked)="popupActionClicked($event)">
    </app-generic-popup>

    <div class="onboarding-wrapper" *ngIf="showOnboarding">
      <app-onboarding-container (close)="closeOnboarding()"></app-onboarding-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoaded && errorLoading">
    <!-- TODO Check what to do in this case -->
    {{errorToShow}}
  </ng-container>

  <!-- ####### TutorialPopup ####### -->
  <app-tutorial-popup *ngIf="showTutorial" (close)="showTutorial = false"></app-tutorial-popup>


  <!-- ####### Loading ####### -->
  <app-main-loading class="loading" [ngStyle]="{'display': isLoading ? 'block' : 'none' }"></app-main-loading>

</div>
