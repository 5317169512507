<div class="no-items-wrapper">
  <div class="no-items-container">

    <img [src]="'assets/imgs/environments/' + environment.tenantName + '/icons/empty-list-top-icon.svg'" class="empty-list-top-icon">

    <div class="no-items-icon">
      <img [src]="image" [alt]="imageAlt" />
    </div>

    <div class="no-items-information">
      <div class="no-items-title">
        {{ title }}
      </div>

      <div class="no-items-description" *ngIf="description">
        {{ description }}
      </div>
    </div>

    <img [src]="'assets/imgs/environments/' + environment.tenantName + '/icons/empty-list-bottom-icon.svg'" class="empty-list-bottom-icon">
  </div>
</div>
