<mat-radio-group [(ngModel)]="selectedValue" [disabled]="isDisabled" [ngClass]="{'display-vertically': vertical}">
  <ng-container *ngFor="let rbutton of radioButtons">
    <div class="radio-button-wrapper" [ngClass]="{'d-flex': rbutton.hasTooltip, 'align-items-center': rbutton.hasTooltip}">
      <mat-radio-button class="radio-margin" [ngClass]="{'show-label-first': labelFirst}"
        [value]="rbutton.value" (click)="!isDisabled ? onValueChange(rbutton.value) : ''">
        <span class="lbl-text">{{ rbutton.label }}</span>
      </mat-radio-button>
      <app-tooltip [textContent]="rbutton.tooltipText!" *ngIf="rbutton.hasTooltip"></app-tooltip>
    </div>
  </ng-container>
</mat-radio-group>
