<div *ngIf="animationState === 'show'" (click)="clickOutside($event);" class="outside-dropdown"></div>
<div class="chips-dropdown-container" (click)="toggleList()">
  <div class="chips-selected" [ngClass]="{'listOpen': animationState === 'show' ? true : false }">
    <div class="chips-selected-options" [ngStyle]="{'padding-top': auxiliarList.length > 0 }">
      <p *ngIf="!auxiliarList.length">{{defaultLabel}}</p>
      <ng-container *ngFor="let chip of auxiliarList; let i = index">
        <div class="chip-item" *ngIf="chip.isSelected" (click)="deSelectChip($event, chip)">
            <span>{{chip.name}}</span>
        </div>
      </ng-container>
    </div>
    <div class="arrow-button-container" [ngClass]="{'activeBg': auxiliarList.length > 1}">
      <div class="button"></div>
    </div>
  </div>

  <div class="chips-list-wrapper" [@Slide]="animationState">
    <div class="chips-list-container">
      <ng-container *ngFor="let chip of chipsList; let i = index">
        <div class="chip-item" [ngClass]="{'selected': chip.isSelected}" (click)="selectChip($event, chip)">
            <span>{{chip.name}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
