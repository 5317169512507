import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircularStepsComponent } from './circular-steps/circular-steps.component';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';



@NgModule({
  declarations: [
    CircularStepsComponent
  ],
  imports: [
    CommonModule,
    LabelPipeModule
  ],
  exports: [
    CircularStepsComponent
  ]
})
export class StepsModule { }
