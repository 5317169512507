<div class="item-info-slider-container swiper-container w-100 h-100" [ngClass]="{'locked': isLocked}">
  <swiper #swiperRef class="item-info-swiper"
    [slidesPerView]="1"
    [centeredSlides]="true"
    [pagination]="imageList && imageList.length > 1 ? { dynamicBullets: true, dynamicMainBullets: 3 } : false"
    [navigation]="imageList && imageList.length > 1 ? true : false"
    [spaceBetween]="20"
    [speed]="500"
    (activeIndexChange)="test($event)"
    >

    <ng-container *ngIf="imageList.length > 0">
      <ng-template swiperSlide class="item-info-item-container" *ngFor="let item of imageList; let index = index">
        <div class="item-info-image">
          <ng-container [ngSwitch]="item.type">
            <img [src]="item.href ? item.href : 'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'"
              class="w-100" *ngSwitchCase="'IMAGE'">
            <video class="w-100" *ngSwitchCase="'VIDEO'" [autoplay]="false" [loop]="true" [muted]="false" [controls]="true" playsinline id="video-{{index}}">
              <source [src]="item.href" type="video/mp4">
            </video>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="imageList.length === 0">
      <ng-template #emptyImageList swiperSlide class="item-info-item-container">
        <div class="item-info-image">
          <img [src]="'assets/imgs/environments/' + environment.tenantName + '/default-images/default-image-small-rectangle.svg'" class="w-100">
        </div>
      </ng-template>
    </ng-container>
  </swiper>

  <div class="item-type-container" *ngIf="itemTypeImage">
    <img [src]="itemTypeImage">
    <span>{{ itemTypeName }}</span>
  </div>
</div>
