import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { FilterGroup, FilterItem, FilterTypeEnum } from './filter.model';
import { FiltersListService } from './filters-list.service';

@Component({
  selector: 'app-filters-list',
  templateUrl: './filters-list.component.html',
  styleUrls: ['./filters-list.component.sass'],
})
export class FiltersListComponent implements OnInit {

  @Input() filtersTitle: string;
  @Input() clearFiltersLabel: string;

  @Input() filterList: Array<FilterGroup>;
  @Input() isMobile: boolean;
  @Input() activeFilterCount: number;

  @Output() filtersUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public environment = environment;

  constructor(
    private generalService: GeneralService,
    private host: ElementRef<HTMLElement>,
    public labelService: LabelsService,
    public filtersListService: FiltersListService
    ) { }

  filterTypes = FilterTypeEnum;

  ngOnInit(): void {
    this.host.nativeElement.style.setProperty('--down-arrow-image-url', `url("assets/imgs/down-chevron.svg")`);
  }

  public toggleFilterGroup(group: FilterGroup): void {
    group.animationStarted = true;
    group.isOpen = !group.isOpen;

    this.generalService.asyncFunction(() => {
      group.animationStarted = false;
    }, 500);
  }

  public isFiltersRadioUpdated(group: FilterGroup, filterSelected: number): void {
    this.filtersListService.radioBtnfilterChanged(group, filterSelected);
    this.filtersUpdated.emit(true);
  }

  public isFiltersCheckboxUpdated(group: FilterGroup, filter: FilterItem, event: boolean): void {
    this.filtersListService.filterSelected(group, filter, event);
    this.filtersUpdated.emit(true);
  }

  public clearFilters(): void {
    this.filterList.forEach(group => {
      group.values.forEach(v => {
        if (this.isRadioButton(v)) {
          v.selected = false;
        } else {
          v.selected = false;
        }
      });
    });

    this.filtersListService.selectedFilters.forEach(group => {
      group.items = [];
    });

    this.filtersUpdated.emit(true);
  }

  /**
   * Checks if a filter is of type RadioButton
  */
  public isRadioButton(filter: FilterItem | RadioButton): filter is RadioButton {
    return (<RadioButton>filter).label !== undefined;
  }

  public closeFilter(): void {
    this.close.emit(true);
  }
}
