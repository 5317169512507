import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';
import { GenericPhoneInputService } from '../_services/generic-phone-input.service';
import { Country, CountryISO } from '../phone-number.model';
import { phoneNumberValidator } from './generic-phone-input.validator';

@Component({
  selector: 'app-generic-phone-input',
  templateUrl: './generic-phone-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './generic-phone-input.component.sass'
  ]
})
export class GenericPhoneInputComponent extends InputBaseComponent implements OnInit {
  @Input() noResultsLabel: string;

  @Input() selectedCountryISO: CountryISO;

  @Input() allowDropdown: boolean = false;
  @Input() dropdownBorderRadius: string;

  @Output() isInputValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();

  public form: UntypedFormGroup;

  public allCountries: Array<Country> = [];

  public dropdownOpen: boolean = false;

  public selectedCountry: Country = {
    areaCodes: undefined,
    dialCode: '',
    htmlId: '',
    iso2: '',
    flagClass: '',
    name: '',
    placeHolder: '',
    priority: 0
  };

  selectedOption?: string;

  public hasError: boolean = false;

  private phoneUtil = lpn.PhoneNumberUtil.getInstance();

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService,
    private phoneService: GenericPhoneInputService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    this.allCountries = this.phoneService.allCountries;

    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    if (this.fGChildName) {
      this.form = this.form.get(this.fGChildName) as UntypedFormGroup;
    }

    if (this.form) {
      const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.form.get('countryCode')?.value + this.form.get('phoneInput')?.value);
      const countryCode = this.phoneUtil.getRegionCodeForNumber(parsedNumber) ? this.phoneUtil.getRegionCodeForNumber(parsedNumber) + '' : 'PT';
      this.selectedCountryISO = this.allCountries.find(country => country.iso2 === countryCode.toLowerCase())!.iso2 as CountryISO;

      this.emitOptionSelected(this.selectedCountryISO);
      // this.form.get('countryCode')?.patchValue(this.selectedCountry.iso2);

      if (this.form.get('phoneInput')?.value)
        this.form.markAllAsTouched();
    }


    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public validatePhoneNumber(countryCode: string, phoneNumber: string): void {
    const validationError = phoneNumberValidator(`${countryCode},${phoneNumber}`);
    if(validationError) {
      this.hasError = true;
    } else {
      this.hasError = false
    }
  }

  public setSelectedCountry(iso: string = ''): void {
    const selectedISO = iso ? iso : this.selectedCountryISO;
    this.selectedCountry = this.allCountries.find(country => country.iso2 === selectedISO)!;

    if(this.form)
      this.form.get('countryCode')?.patchValue(this.selectedCountry.dialCode);
  }

  public openDropdown(): void {
    this.dropdownOpen = true;
  }

  public countrySelect(country: Country): void {
    this.dropdownOpen = false;

    // Check if there is a form control or ngModel input
    if(this.fCName || this.inputValue) {
      // Get the phone number from the form control or ngModel
      const phoneNumber = this.fCName ? this.form.get(this.fCName)?.value : this.inputValue;
      if(phoneNumber) {
        const isValid = phoneNumberValidator(this.form) ?? { valid : false };
        //Update the form control or ngModel validation
        if (this.fCName) {
          this.form.get(this.fCName)?.setErrors(isValid);
        } else {
          this.hasError = isValid ? true : false;
        }
      }
    }
  }

  public emitOptionSelected(option: string): void {
    this.selectedOption = option;
    this.dropdownOpen = false;
    this.setSelectedCountry(this.selectedOption);
    this.countrySelect(this.selectedCountry);

    this.optionSelected.emit(option);
  }

  public clickOutside(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dropdownOpen = false;
  }
}
