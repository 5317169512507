import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderEntryDTO, OrderEntryReplyDTO, OrderEntryStatusEnum, RelatedPartyTypes } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.model';

@Injectable({
  providedIn: 'root'
})
export class MockOrderEntryService {

  constructor() { }

  createOrderEntry(): Observable<OrderEntryDTO> {
    return of({
      id: 'a342f9ce-a848-4652-9b7c-5e8ecb2dd4e2',
      productOrderItems : [
          {
              quantity : 1,
              action : "add",
              product : {
                  productSpecification : {
                      id : "577aa125-ceb6-4705-b634-036680c61277"
                  }
              }
          }
      ],
      relatedParties : [
          {
              id : "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
              type : "WALLET"
          },
          {
              id : "29a9854f-4a4b-4c70-bd39-34694c69ddec",
              type : "BUYER"
          }
      ],
      payment : {
          mediumType : "MBWAY",
          characteristic : {
              phoneNumber : "912345678"
          }
      }
  });
  }

  getOrderEntry(): Observable<OrderEntryDTO> {
    return of({
      id: 'a342f9ce-a848-4652-9b7c-5e8ecb2dd4e2',
      status: 'completed',
      productOrderItems : [
          {
              quantity : 1,
              action : "add",
              product : {
                  productSpecification : {
                      id : "577aa125-ceb6-4705-b634-036680c61277"
                  }
              }
          }
      ],
      relatedParties : [
          {
              id : "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
              type : "WALLET"
          },
          {
              id : "29a9854f-4a4b-4c70-bd39-34694c69ddec",
              type : "BUYER"
          }
      ],
      payment : {
          mediumType : "MBWAY",
          characteristic : {
              phoneNumber : "912345678"
          }
      }
  });
  }

  getOrderEntryList(): Observable<Array<OrderEntryReplyDTO>> {
    return of([
      {
        id: "b900d5a6-53b7-40c0-b174-d8d127269944",
        status: OrderEntryStatusEnum.failed,
        created: "2023-03-06T12:32:11.3238957Z",
        productOrderItems: [
          {
            id: "5ab7e6d6-ce37-42c4-8af8-0723c07ea7a4",
            quantity: 1,
            action: "add",
            product: {
              productSpecification: {
                id: "9c8b43ac-9689-442c-a4ce-77a0d35d8710",
                name: "Load Test",
                serial: "1/1",
                partnerName: "LIGA PORTUGUESA CONTRA O CANCRO"
              }
            }
          }
        ],
        relatedParties: [
          {
            id: "9dacea7e-2707-45ff-b842-afe6841cb573",
            type: "BUYER"
          }
        ],
        payment: {
          mediumType: "MBWAY",
          characteristic: {
              phoneNumber: "920890890"
          }
        }
      }
    ]);
  }
}
