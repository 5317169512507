import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { UserGoalService } from '../_services/user-goal.service';
import { GoalOption } from '../user-goal.model';

@Component({
  selector: 'app-edit-user-goal-popup',
  templateUrl: './edit-user-goal-popup.component.html',
  styleUrls: ['./edit-user-goal-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class EditUserGoalPopupComponent implements OnInit, AfterViewInit {

  @Input() isAddingGoal: boolean = true;

  @Output() newGoalSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() popUpClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public animationState: string = 'hide';
  public isLoaded: boolean = false;

  public possibleGoalOptionDTOs: Array<GoalOption> = [];
  public selectedGoalOptionDTO: GoalOption;

  public environment = environment;

  constructor(
    public labelsService: LabelsService,
    public userGoalService: UserGoalService,
    public generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.userGoalService.getUserGoalOptionDTOsList().pipe(take(1)).subscribe({
      next: resp => {
        this.possibleGoalOptionDTOs = resp;
        this.isLoaded = true;
      },
      error: (err) => {
        this.closePopUp();
      }
    });
  }

  ngAfterViewInit(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        this.animationState = 'show';
    })})();
  }

  public optionSelected(option: GoalOption): void {
    this.possibleGoalOptionDTOs.forEach(c => c.selected = false);
    option.selected = true;

    this.selectedGoalOptionDTO = option;
  }

  public btnAction(): void {
    const updatedUserDetails = {
      goalId: this.selectedGoalOptionDTO.itemId
    };

    this.generalService.updateUserDetails(updatedUserDetails).subscribe(() => {
      this.newGoalSelected.emit(this.selectedGoalOptionDTO.itemId);
      this.closePopUp();
    });
  }

  public closePopUp(): void {
    this.animationState = 'hide';
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 300)).then(() => {
        this.popUpClosed.emit(true);
    })})();
  }


}
