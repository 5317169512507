import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ChartContainer, ChartTypes } from '../dashboard.model';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass']
})
export class ChartComponent implements OnInit {
  @Input() chartContainer: ChartContainer;

  public chartTypes = ChartTypes;
  public legendPosition = LegendPosition;

  public selectedTab: number = 0;

  public total: number;

  constructor(public generalService: GeneralService) {
  }

  ngOnInit(): void {
    this.selectTab(0);
  }

  public selectTab(index: number): void {
    this.selectedTab = index;

    if(this.chartContainer.chartTabs && this.chartContainer.chartTabs.length > 0) {
      this.total = this.chartContainer.chartTabs[this.selectedTab].data.reduce((acc, curr) => acc + curr.value, 0);
    }
  }
}
