import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericListHeaderComponent } from './generic-list-header/generic-list-header.component';



@NgModule({
  declarations: [
    GenericListHeaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenericListHeaderComponent
  ]
})
export class GenericListHeaderModule { }
