import { Component, ElementRef, ViewChild } from '@angular/core';
import { Rive } from "@rive-app/canvas";
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-loading',
  templateUrl: './main-loading.component.html',
  styleUrls: ['./main-loading.component.sass']
})
export class MainLoadingComponent {
  @ViewChild('lottieAnimation', { static: false }) private lottieAnimation: LottieComponent;
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;

  public environment = environment;
  private r: Rive;

  public lottieOptions: AnimationOptions;

  public lottieAnimationItem: AnimationItem | null = null;
  public animation: Animation;

  constructor(public generalService: GeneralService) {
    window.addEventListener('resize', () => {
      this.screenResized();
    });

    this.screenResized();
  }

  ngOnInit(): void {
    switch(environment.customLoadingAnimationType) {
      case 'lottie':
        this.lottieOptions = {
          autoplay: false,
          loop: true,
          path: `assets/imgs/environments/${environment.tenantName}/custom-loading-animation.json`
        };
        break;

      case 'rive':
        this.initiateRiveAnimation(`assets/imgs/environments/${environment.tenantName}/custom-loading-animation.riv`);
        break;
    }
  }

  private screenResized(): void {
    this.generalService.isMobile$.next(window.innerWidth / window.innerHeight <= 1 || window.innerWidth <= 760);

    if (this.lottieAnimation) {
      this.lottieAnimationItem?.play();
    }

    if (this.canvasRive) {
      this.r.resizeDrawingSurfaceToCanvas();
      this.r?.play();
    }
  }

  private initiateRiveAnimation(riveAnimationUrl: string): void {
    this.generalService.asyncFunction(() => {
      this.r = new Rive({
        src: riveAnimationUrl,
        canvas: this.canvasRive.nativeElement,
        autoplay: true,
        onLoad: () => {
          this.r.resizeDrawingSurfaceToCanvas();
          this.r?.play();
        },
      });
    }, 0);
  }
}
