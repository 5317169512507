import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryService } from '../__services/library.service';
import { ToolTipComponent } from './tooltip/tooltip.component';



@NgModule({
  declarations: [
    ToolTipComponent
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    ToolTipComponent
  ],
  providers: [
    LibraryService
  ]
})
export class ToolTipModule { }
