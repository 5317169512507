<div class="product-code-container" [ngClass]="{ 'faded' : productCode.state !== productCodeStatus.PURCHASED }">
  <img [src]="'assets/imgs/environments/' + environment.tenantName + '/products/product-code-image.png'">

  <div class="code-info">
    <div class="code-title-label">
      <span>{{ labelService.productHistoryDetailsCodeTitle | label }}</span>

      <ng-container *ngIf="productCode.state !== productCodeStatus.PURCHASED">
        <span class="code-state" [ngSwitch]="productCode.state">
          <ng-container *ngSwitchCase="productCodeStatus.REDEEMED">{{ labelService.productHistoryDetailsCodeRedeemedLabel | label }}</ng-container>
          <ng-container *ngSwitchCase="productCodeStatus.EXPIRED">{{ labelService.productHistoryDetailsCodeExpiredLabel | label }}</ng-container>
        </span>
      </ng-container>
    </div>

    <div class="code-container" *ngIf="productCode.code">
      {{ productCode.code }}
    </div>

    <div class="date-container">
      <p *ngIf="productCode.purchaseDate">{{ (labelService.productHistoryDetailsPurchaseDateLabel | label) + ' ' + (productCode.purchaseDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}</p>
      <p *ngIf="productCode.redeemDate">{{ (labelService.productHistoryDetailsRedeemDateLabel | label) + ' ' + (productCode.redeemDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}</p>
      <p *ngIf="productCode.expirationDate && !productCode.redeemDate">
        {{ ((productCode.state === productCodeStatus.EXPIRED ? labelService.productHistoryDetailsExpireDateLabel : labelService.productHistoryDetailsValidUntilDateLabel) | label)
        + ' ' + (productCode.expirationDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}
      </p>
    </div>
  </div>
</div>
