
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params, Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
// import { environment.useKeycloak ? AuthGuardKeycloak : AuthGuard } from '../_guards/auth.guard';
import { KeycloakService } from 'keycloak-angular';
import { apisToMock } from '../_mock_api/_main-mock-service/_main-mock.model';
import { MainMockService } from '../_mock_api/_main-mock-service/_main-mock.service';
import { LanguagesService } from './_languages/languages.service';

@Injectable()
export class ApiService {
  private keycloakService: KeycloakService;
  // count for the requests made
  public numberRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // number of retries
  private retryNumber = 0;
  private maxRetries = 3;

  constructor(
    private http: HttpClient,
    // private environment.useKeycloak ? AuthGuardKeycloak : AuthGuard: environment.useKeycloak ? AuthGuardKeycloak : AuthGuard, Uncomment when needed, also unit tests will require this and Jwt
    private router: Router,
    private mainMockService: MainMockService,
    private languageService: LanguagesService,
  ) {
    if(environment.useKeycloak) {
      this.keycloakService = Inject(KeycloakService);
    }
  }

  private setHeaders(apiVersion: string = '1.0'): HttpHeaders {
    const token: string | null = localStorage.getItem(environment.keycloakConfig.clientId + '-jwt');

    if (token) {
      return new HttpHeaders({

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Version': apiVersion,
        'X-Forwarded-Proto': 'https',
        'X-Tenant': environment.tenantId,
        'X-Application': environment.applicationId,
        'X-LanguageCode': environment.defaultLanguage.toLocaleUpperCase(), // TODO dynamic languageCode
        'Authorization': 'Bearer ' + token
      });

    } else {
      return new HttpHeaders({

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Version': apiVersion,
        'X-Forwarded-Proto': 'https',
        'X-Tenant': environment.tenantId,
        'X-Application': environment.applicationId,
        'X-LanguageCode': environment.defaultLanguage.toLocaleUpperCase(), // TODO dynamic languageCode
      });
    }
  }

  public getFromFile(url: string, loading: boolean = false): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders();

    // set request as a variable
    const request = () =>
      this.http.get(url, { 'headers': headers, observe: 'response' })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            if (this.retryNumber < this.maxRetries) {
              // send request as variable
              return this.formatErrors(res, request);
            }
            return res.json();
          })
        );

    return request();
  }

  public get(path: string, params: Params = {}, apiVersion: string, loading: boolean = true, useMockData: boolean = false, itemId: string = '', mockedTimeout: number = 700): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders(apiVersion);
    const url = `${environment.apiBaseUrl}${path}`;

    if (environment.allServicesMocked || useMockData) { // To mock API response
      const timeToWait: number = mockedTimeout;

      setTimeout(() => {
        this.setRequestsValue(-1);
      }, timeToWait);

      return this.mainMockService.mockApiCall(this.chooseApiToMock(path, itemId), timeToWait);
    }

    // set request as a variable
    const request = () =>
      this.http.get(url, { 'headers': headers, observe: 'response', params: params })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            if (this.retryNumber < this.maxRetries) {
              // send request as variable
              return this.formatErrors(res, request);
            }
            return res.json();
          })
        );

    return request();
  }

  public patch(path: string, body: Object = {}, apiVersion: string, loading: boolean = true, useMockData: boolean = false): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders(apiVersion);
    const url = `${environment.apiBaseUrl}${path}`;

    if (environment.allServicesMocked || useMockData) { // To mock API response
      const timeToWait: number = 700;
      setTimeout(() => {
        this.setRequestsValue(-1);
      }, timeToWait)
      return this.mainMockService.mockApiCall(this.chooseApiToMock(path), timeToWait);
    }

    // set request as a variable
    const request = () =>
      this.http.patch(url, body, { headers: headers, observe: 'response' })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            /* if (this.retryNumber < this.maxRetries) {
              TODO Retry request
              // send request as variable
              return this.formatErrors(res, request);
            } */
            throw JSON.parse(JSON.stringify(res));
          })
        );

    return request();
  }

  public put(path: string, body: Object = {}, apiVersion: string, loading: boolean = true, useMockData: boolean = false): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders(apiVersion);
    const url = `${environment.apiBaseUrl}${path}`;

    if (environment.allServicesMocked || useMockData) { // To mock API response
      const timeToWait: number = 700;
      setTimeout(() => {
        this.setRequestsValue(-1);
      }, timeToWait)
      return this.mainMockService.mockApiCall(this.chooseApiToMock(path), timeToWait);
    }

    // set request as a variable
    const request = () =>
      this.http.put(url, body, { headers: headers, observe: 'response' })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            /* if (this.retryNumber < this.maxRetries) {
              TODO Retry request
              // send request as variable
              return this.formatErrors(res, request);
            } */
            throw JSON.parse(JSON.stringify(res));
          })
        );

    return request();
  }

  public delete(path: string, apiVersion: string, loading: boolean = true, useMockData: boolean = false): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders(apiVersion);
    const url = `${environment.apiBaseUrl}${path}`;

    if (environment.allServicesMocked || useMockData) { // To mock API response
      const timeToWait: number = 700;
      setTimeout(() => {
        this.setRequestsValue(-1);
      }, timeToWait)
      return this.mainMockService.mockApiCall(this.chooseApiToMock(path), timeToWait);
    }

    // set request as a variable
    const request = () =>
      this.http.delete(url, { headers: headers, observe: 'response' })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            /* if (this.retryNumber < this.maxRetries) {
              TODO Retry request
              // send request as variable
              return this.formatErrors(res, request);
            } */
            throw JSON.parse(JSON.stringify(res));
          })
        );

    return request();
  }

  public post(path: string, body: Object = {}, apiVersion: string, loading: boolean = true, useMockData: boolean = false): Observable<any> {
    if (loading) {
      this.setRequestsValue(1);
    }

    const headers: HttpHeaders = this.setHeaders(apiVersion);
    const url = `${environment.apiBaseUrl}${path}`;

    if (environment.allServicesMocked || useMockData) { // To mock API response
      const timeToWait: number = 700;
      setTimeout(() => {
        this.setRequestsValue(-1);
      }, timeToWait)
      return this.mainMockService.mockApiCall(this.chooseApiToMock(path), timeToWait);
    }

    // set request as a variable
    const request = () =>
      this.http.post(url, body, { headers: headers, observe: 'response' })
        .pipe(
          map(
            (response: any) => {
              if (loading) {
                this.setRequestsValue(-1);
              }
              return response.body;
            }
          ),
          catchError(res => {
            if (loading) {
              this.setRequestsValue(-1);
            }
            /* if (this.retryNumber < this.maxRetries) {
              TODO Retry request
              // send request as variable
              return this.formatErrors(res, request);
            } */
            throw JSON.parse(JSON.stringify(res));
          })
        );

    return request();
  }

  private formatErrors(error: any, request: () => any): Observable<JSON> {
    let responseFormated: Subject<JSON> = new Subject<JSON>();

    if (error.status === 401) {
      this.refreshToken().subscribe({
        next: resp => {
          // if refresh token successfully, retry request
          responseFormated.next(request()); // TODO check this response
        },

        error: err => {
          // redirect to login if fail
          responseFormated.next(error.json());
          this.router.navigate(['landing-page']);
        }
      });

    } else {
      responseFormated.next(error.json());
    }
    return responseFormated.asObservable();
  }

  // TODO
  private requestHandler(loading: boolean) : any {
    return map(
      (response: any) => {
        if (loading) {
          this.setRequestsValue(-1);
        }
        return response.body;
      }
    ),
    catchError(res => {
      if (loading) {
        this.setRequestsValue(-1);
      }
      /* if (this.retryNumber < this.maxRetries) {
        TODO Retry request
        // send request as variable
        return this.formatErrors(res, request);
      } */
      throw JSON.parse(JSON.stringify(res));
    });
  }

  private refreshToken(): Observable<string> {
    const refresh$: Subject<string> = new Subject<string>();

    if (environment.useKeycloak && !this.keycloakService!.isTokenExpired()) {
      this.keycloakService!.updateToken().then(() => {
        refresh$.next('tokenUpdated');
      }, (err: string) => {
        throw err;
      });
    } else {
      throw '';
    }

    refresh$.next('');
    return refresh$.asObservable();
  }

  private setRequestsValue(value: number): void {
    this.numberRequests.next(this.numberRequests.value + value);
  }

  private chooseApiToMock(path: string, itemId?: string): apisToMock {
    switch(path) {
      case 'user-management/user/me':
        return apisToMock.me;
      case `tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/labels`:
        return apisToMock.getLabels;
      case 'tenant-management/public/tenant/languages':
        return apisToMock.getLanguages;
      case 'General/GetInfo':
        return apisToMock.getInfo;
      case `tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/banners`:
        return apisToMock.getLPBanners;
      case 'Marketplace/GetTiers':
        return apisToMock.marketplaceGetTiers;
      case 'user/me':
        return apisToMock.me;
      case `tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/menus?type=APP`:
        return apisToMock.getMenus;
      case `product-catalog/public/product-filter`:
        return apisToMock.marketplaceGetFilters;
      case `product-catalog/public/product`:
        return apisToMock.marketplaceGetProducts;
      case `product-catalog/public/product/${itemId}`:
        return apisToMock.marketplaceGetProduct;
      case `partner-management/public/partner`:
        return apisToMock.getPartnersList;
      case `partner-management/public/partner/${itemId}`:
        return apisToMock.getPartnerDetails;
      case `partner-management/public/partner-category`:
        return apisToMock.getCategories;
      case `orderentry-management/product-order-instant`:
        return apisToMock.createOrderEntry;
      case `orderentry-management/product-order/${itemId}`:
        return apisToMock.getOrderEntry;
      case `orderentry-management/product-order`:
        return apisToMock.getOrderEntryList;
      case `product-catalog/product/me`:
        return apisToMock.getHistoryProductList;
      case `user/transaction`:
        return apisToMock.getUserTransactionList;
      case `user/transactionHeaders`:
        return apisToMock.getUserTransactionListHeaders;
      case `terms-and-conditions`:
        return apisToMock.getTerms;
      case `contacts/faqs`:
        return apisToMock.getFAQs;
      case `tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/tutorial`:
        return apisToMock.getTutorialInfo;
      case `blog/public/article`:
        return apisToMock.getBlogList;
      case `blog/public/article/${itemId}`:
        return apisToMock.getBlogDetails;
      case `blog/public/article/article-filter`:
        return apisToMock.getBlogFilters;
      case `our-products/public`:
        return apisToMock.getOurProductsList;
      case `about-us/public`:
        return apisToMock.getAboutUsInfo;
      case `tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/onboarding`:
        return apisToMock.getOnboardingList;
      case `tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/onboarding/${itemId}`:
        return apisToMock.getOnboardingStep;
      case `userGoal/GetOptionsList`:
        return apisToMock.getUserGoalOptionDTOsList;
      case `transaction-management/transaction/me`:
        return apisToMock.getHistoryTransactionList;
      case `activity-management/public/activity`:
        return apisToMock.getActivityList;
      case `activity-management/public/activity/${itemId}`:
        return apisToMock.getActivityInfo;
      case `activity-management/public/activity/input`:
        return apisToMock.addEditActivityUserInput;
      case `activity-management/public/activity/input/${itemId}`:
        return apisToMock.removeActivityUserInput;
      default:
        return 0;
    }
  }

}
