import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransactionDTO, TransactionStatus, TransactionType } from 'src/app/pages/history-module/_services/transactions/transactions.model';

@Injectable({
  providedIn: 'root'
})
export class MockTransactionHistoryService {

  constructor() { }

  getTransactionsList(): Observable<Array<TransactionDTO>> {
    return of([
      {
        transactionId: "9de48485-8989-4776-9997-add4782b4959",
        description: "Bilhete Geral MEO Sudoeste",
        status: TransactionStatus.COMPLETED,
        walletType: "PINK_COINS",
        transactionType: TransactionType.SPENT,
        transactionDate: "2023-09-18T16:37:20.8047428Z",
        points: 1200.0,
        balance: 8400.0
      },
      {
        transactionId: "4e796a7e-fb56-48c4-a9f2-751f3f826db2",
        description: "Bilhete Geral MEO Sudoeste",
        status: TransactionStatus.COMPLETED,
        walletType: "PINK_COINS",
        transactionType: TransactionType.SPENT,
        transactionDate: "2023-09-18T16:35:42.003554Z",
        points: 400.0,
        balance: 9600.0
      },
      {
        transactionId: "5a9494fc-4fde-457a-b29c-83a53b2a800b",
        description: "Teste",
        status: TransactionStatus.COMPLETED,
        walletType: "PINK_COINS",
        transactionType: TransactionType.WON,
        transactionDate: "2023-09-18T16:35:03.703667Z",
        points: 10000.0,
        balance: 10000.0
      }
    ]);
  }
}
