import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ImageContent } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { environment } from 'src/environments/environment';
import SwiperCore, {
  A11y, Autoplay,
  Navigation,
  Pagination,
  Scrollbar
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-item-image-slider',
  templateUrl: './item-image-slider.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./item-image-slider.component.sass']
})
export class ItemImageSliderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("swiperRef", { static: false }) sliderRef?: SwiperComponent;

  @Input() itemTypeImage: string;
  @Input() itemTypeName: string;
  @Input() isLocked: boolean = false;
  @Input() imageList: Array<ImageContent>;
  @Input() paginationBulletColor: string;

  public activeSlideIndex: number = 0;
  public environment = environment;

  constructor(private generalService: GeneralService,
    private host: ElementRef) {
    window.addEventListener('resize', () => {
      this.changeImageHeight();
    });
  }

  ngOnInit(): void {
    this.setCSSVars();
  }

  ngAfterViewInit(): void {
    this.changeImageHeight();
  }

  changeImageHeight(): void {
    let docStyle = document.documentElement.style;
    const imageHeight = document.getElementsByClassName('item-info-slider-container')[0].clientWidth / 2.5;


    //set variables
    docStyle.setProperty('--image-height', imageHeight+'px');
  }

  test(event: any) {
    // pause all videos
    for (let index = 0; index < this.imageList.length; index++) {
      const videoElement: any = document.getElementById('video-' + index);

      if (videoElement) {
        videoElement.pause();
      }
    }

    // play current slide video
    this.activeSlideIndex = event[0].activeIndex;
    const videoElement: any = document.getElementById('video-' + this.activeSlideIndex);

    if (videoElement) {
      let playPromise: Promise<any> = videoElement.play();

      if(playPromise !== undefined) {
        playPromise.then().catch((error: Error) => {
          console.log(error);
        });
      }
    }
  }

  private setCSSVars(): void {
    if(this.paginationBulletColor ) {
      if(this.paginationBulletColor) {
        this.host.nativeElement.style.setProperty('--pagination-bullet-color', this.paginationBulletColor);
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', () => this.changeImageHeight());
  }
}
