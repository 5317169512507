<div class="input-group input-dropdown-container" (click)="inputFocused = disabled ? false : true;" (mouseover)="hover = true" (mouseleave)="hover = false">
  <input type="text" readonly class="form-control" [(ngModel)]="inputValue" [placeholder]="inputPlaceholder" [ngClass]="{'hover branding-border-color' : hover || inputFocused}"
  value="{{ selectedOption?.name }}">
  <ng-container *ngIf="arrowIconUrl; else matIcon">
    <img [src]="arrowIconUrl">
  </ng-container>

  <ng-template #matIcon>
    <div class="icon-container">
      <div class="input-group-text arrow-suffix" role="button" [ngClass]="{'hover branding-border-color' : hover || inputFocused}">
        <i class="bi bi-chevron-down"></i>
      </div>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="inputFocused">
<app-generic-dropdown [dropdownOptions]="dropdownOptions" [checkIcon]="checkIcon" [fCName]="fCName" [fGName]="fGName" [brandColor]="brandColor" [inputHeight]="dropdownHeight"
  [errorColor]="errorColor" [lightBrandColor]="lightBrandColor" [fontColor]="fontColor" [fontSize]="fontSize" (optionSelected)="emitOptionSelected($event)" [optionToSelect]="optionToSelect ? optionToSelect : selectedOption!"
  (closeDropdown)="inputFocused = false"></app-generic-dropdown>
</ng-container>


