import { CommonModule, registerLocaleData } from '@angular/common';
import myLocaleEN from '@angular/common/locales/en';
import myLocalePT from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { GenericListItemComponent } from './generic-list-item/generic-list-item.component';


registerLocaleData(myLocalePT);
registerLocaleData(myLocaleEN);

@NgModule({
  declarations: [
    GenericListItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenericListItemComponent
  ]
})
export class GenericListItemModule { }
