import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { ListBaseComponent } from '../../_extended-components/list-base-component/list-base.component';
import { HeaderItem } from '../list-header.model';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.sass']
})
export class ListHeaderComponent extends ListBaseComponent implements OnInit {

  @Input() fullSize: boolean;

  @Input() headerList: Array<HeaderItem>;
  @Input() sortFilter: number = 0;
  @Input() showArrows: boolean = true;
  @Input() clickable: boolean = true;

  @Input() firstItemHasImg: boolean = false;

  @Output() headerEvent: EventEmitter<number | string> = new EventEmitter<number | string>();

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService)
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  clickHeader(headerId: number | string): void {
    this.headerEvent.emit(headerId)
  }
}
