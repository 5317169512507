import { Component, ContentChildren, ElementRef, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LanguagesService } from 'src/app/_services/_languages/languages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-item-card',
  templateUrl: './list-item-card.component.html',
  styleUrls: ['./list-item-card.component.sass']
})
export class ListItemCardComponent implements OnInit {
  @ContentChildren(TemplateRef) tmpls: QueryList<TemplateRef<any>>;

  @Input() image: string;
  @Input() itemType: string;
  @Input() itemTypeImage: string;
  @Input() title: string;
  @Input() isBlocked: boolean;
  @Input() isImageVertical: boolean;

  @Input() highlighted: boolean;
  @Input() highlightBackgroundColor: string;
  @Input() highlightFontColor: string;

  @Input() shadowBrandColor: string;

  categoryClass: string;

  public tmplsArray: Array<TemplateRef<any>> = [];

  public environment = environment;

  constructor(
    public generalService: GeneralService,
    public languageService: LanguagesService,
    private translateService: TranslateService,
    private host: ElementRef) {
      const sessionLanguage = localStorage.getItem('SessionLanguageCode');

      this.translateService.currentLang = environment.defaultLanguage;
      this.translateService.setDefaultLang(environment.defaultLanguage);
      this.translateService.use(environment.defaultLanguage);
    }

  ngOnInit(): void {
    this.setCssVars();
  }

  ngAfterContentInit(): void {
    this.tmplsArray = this.tmpls.toArray();
  }

  private setCssVars(): void {
    if (this.highlightBackgroundColor) {
      this.host.nativeElement.style.setProperty('--highlight-background-color', this.highlightBackgroundColor);
    }

    if (this.highlightFontColor) {
      this.host.nativeElement.style.setProperty('--highlight-font-color', this.highlightFontColor);
    }

    if(this.shadowBrandColor) {
      this.host.nativeElement.style.setProperty('--list-shadow-brand-color', this.shadowBrandColor);
    }
  }

}
