import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  constructor(
    private httpClient: HttpClient
  ) {
    this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAqCHxkE7kWdTuU5cs7zgRiRjbyIsONRHc&libraries=geometry', 'callback').subscribe();
  }

  getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
}
