import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LibraryService } from '../__services/library.service';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarService } from './snackbar/snackbar.service';



@NgModule({
  declarations: [
    SnackbarComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  providers: [
    SnackbarService,
    LibraryService
  ]
})
export class SnackbarsModule { }
