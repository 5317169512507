  <div class="d-flex justify-content-between align-items-center w-100 ps-3 pe-3 mt-2 mb-2">
    <div class="points-container"></div>

    <div class="quantity-container"></div>
  </div>

  <div class="d-flex justify-content-between w-100 ps-3 pe-3 mt-2">
    <div class="d-flex justify-content-between align-items-baseline">
      <div class="category-container me-1"></div>
    </div>

    <div class="date-container"></div>
  </div>
