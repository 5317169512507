<div class="banner-container" [ngClass]="{ 'small-banner-container': smallBanner && !(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)),
  'points-banner': environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async) }" [ngStyle]="{ 'min-height': containerHeight }">
  <div class="banner-gradient" *ngIf="useGradient"></div>
  <img class="banner-background-image" [src]="backgroundImageUrl" *ngIf="backgroundImageUrl">
  <div class="text-container">
    <p class="banner-title" *ngIf="title && !smallBanner">{{ title }}</p>
    <span class="banner-title" *ngIf="title && smallBanner">{{ title }}</span>
    <p class="banner-description" *ngIf="description && !(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async))">{{ description }}</p>

    <div class="balance-container" *ngIf="environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)">
      <img [src]="'assets/imgs/environments/' + environment.tenantName + '/icons/balance-icon.svg'">
      <span class="points">{{ generalService.userDefaultWallet ? generalService.userDefaultWallet.currentValue : 0 }}</span>
      <span class="points-label">{{ labelsService.bannersBalanceLabel | label }}</span>
    </div>
  </div>

  <div class="banner-image-container" [ngClass]="{ 'no-bottom-margin' : alignImageBottom }" [ngStyle]="{ 'width': imageSize }">
    <img [src]="imageUrl" [alt]="title" *ngIf="imageUrl">
  </div>
</div>
