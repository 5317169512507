import { FiscalAddress } from "src/app/_services/_general-service/general-service.model";

export interface GetProductListReply {
  items: Array<ProductDTO>;
  totalItems: number;
}

export interface Product {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  type: ProductType;
  endDate?: string;
  purchaseDate?: string;
  images?: Array<ImageContent>;
  serial?: string;
  prices: Array<Money>;
  lowestPrice?: number;
  quantity?: number;
  characteristics?: Array<Characteristics>;
  traits?: Array<Trait>;
  categories?: Array<Category>;
  collection?: Collection;
  partner?: ProductPartner;
  isBlocked?: boolean;
  locations?: Array<FiscalAddress>;

  redeemSteps?: Array<RedeemStep>;
  totalPurchased?: number;
  purchaseLimit?: number;
  quantityReserved?: number;
}

export interface ProductDTO {
  productId: string;
  tenantId: string;
  name: string;
  description: string;
  type: string;
  endDate?: string;
  purchaseDate?: string;
  images?: Array<ImageContentDTO>;
  serial?: string;
  prices: Array<Money>;
  lowestPrice?: number;
  quantity?: number;
  characteristics?: Array<CharacteristicsDTO>;
  categories?: Array<CategoryDTO>;
  traits?: Array<Trait>;
  collection?: CollectionDTO;
  partner?: ProductPartnerDTO;
  isBlocked?: boolean;
  locations?: Array<FiscalAddress>;

  redeemSteps?: Array<RedeemStepDTO>;
  totalPurchased?: number;
  purchaseLimit?: number;
  quantityReserved?: number;
}

export interface RedeemStepDTO {
  order: number;
  description: string
}

export interface RedeemStep {
  order: number;
  description: string
}

export interface ImageContentDTO {
  href: string;
  content?: string;
  type: string;
}

export interface ImageContent {
  href: string;
  content?: string;
  type: ContentType;
}

export interface Money {
  amount: number;
  unit: string;
  selected?: boolean;
}

export interface Characteristics {
  id: string;
  name: string;
}

export interface CharacteristicsDTO {
  id: string;
  name: string;
}

export interface Collection {
  id: string;
  name: string;
}

export interface CollectionDTO {
  id: string;
  name: string;
}

export interface Category {
  id : string;
  name : string;
}

export interface CategoryDTO {
  id: string;
  name: string;
}

export interface Trait {
  value: string;
  name: string;
}

export interface TraitDTO {
  value: string;
  name: string;
}

export interface ProductPartnerDTO {
  id: string;
  name: string;
  image?: string;
}

export interface ProductPartner {
  id: string;
  name: string;
  image?: string;
}

export enum ProductTypeEnum {
  NFT = 'NFT',
  VOUCHER = 'VOUCHER'
}

export type ProductType = keyof typeof ProductTypeEnum;

export enum ProductStatusEnum {
  Created = 'Created',
  Active = 'Active',
  Hidden = 'Hidden'
}

export type ProductStatus = keyof typeof ProductStatusEnum;


export enum ContentTypeEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type ContentType = keyof typeof ContentTypeEnum;
