import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { ListBaseComponent } from '../../_extended-components/list-base-component/list-base.component';
import { HeaderItem } from '../../list-header-module/list-header.model';
import { ActionId, ExpandableListItem, ItemState, ListItem } from '../list-item.model';

@Component({
  selector: 'app-list-item-expandable',
  templateUrl: './list-item-expandable.component.html',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    '../../_extended-components/list-base-component/list-base.component.sass',
    './list-item-expandable.component.sass'
  ],
  animations: [
    trigger('Expand', [
      state('open', style({
        height: '*'
      })),
      state('close', style({
        height: 0
      })),
      transition('open <=> close',  animate('300ms ease-in-out')),
    ]),
    trigger('Rotate', [
      state('clockwise', style({
        transform: 'translate(-50%, -50%) rotate(180deg)'
      })),
      state('counterClockwise', style({
        transform: 'translate(-50%, -50%)'
      })),
      transition('clockwise <=> counterClockwise',  animate('300ms ease-in-out')),
    ])
  ]
})
export class ListItemExpandableComponent extends ListBaseComponent implements OnInit {

  @Input() expandableFontSize: string;
  @Input() headerFontColor: string = '#8692A6';
  @Input() headerFontWeight: string;

  @Input() item: ListItem;

  @Input() expandableItem: Array<ExpandableListItem>;
  @Input() expandableHeader: Array<HeaderItem> = [];
  @Input() expandableBackgroundOpacity: string;
  @Input() showId: boolean = true;

  @Input() fullSize: boolean;
  @Input() itemMargin: string = '10px 10px 10px 13px';

  @Output() itemEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() actionEvent: EventEmitter<ActionId> = new EventEmitter<ActionId>();

  private itemState = ItemState; //This enum allows the verification of the State if it's active, archived etc.
  public  ActionId = ActionId; // This enum allows the verification of the multi function button type if it's a toggle for example.

  public animationState: string = 'close';
  public arrowAnimationState: string = 'counterClockwise';

  public actionActive: boolean = false;

  public enableClick: boolean = true;

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  // Click event to handle the emit of the item.
  public itemClicked(): void {
    if(!this.item.notClickable) {
      this.item.selected = true;
      this.itemEvent.emit(this.item.id);
    }

    if(this.isMobile && this.item.expand) {
      this.asyncFunction(() => {
        this.animationState = this.animationState === 'close' ? 'open' : 'close';
        this.arrowAnimationState = this.arrowAnimationState === 'counterClockwise' ? 'clockwise' : 'counterClockwise'
      }, 300)
    }
  }

  //Click event for the multi function button
  public actionClicked(actionId: number): void {
    if (actionId !== null) {
      if(actionId === ActionId.visibility) {
        this.actionActive = !this.actionActive;
      }
      this.actionEvent.emit(actionId);
    }
  }
}
