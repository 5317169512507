import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TierItem } from './tier-filter.model';

@Component({
  selector: 'app-image-overflow-tiers-filter',
  templateUrl: './image-overflow-tiers-filter.component.html',
  styleUrls: ['./image-overflow-tiers-filter.component.sass']
})
export class ImageOverflowTiersFilterComponent implements OnInit {
  @Input() tierList: Array<TierItem>;
  @Output() tierClicked: EventEmitter<number> = new EventEmitter<number>();

  showSlider: boolean = false;
  slidesPerPage: number = 2;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 760) {
        this.showSlider = true;

        this.slidesPerPage = 2;
        if (window.innerWidth < 560) { // TODO check breaking point
          this.slidesPerPage = 1;
        }

      } else
        this.showSlider = false;
    });
  }

  tierAction(tier: TierItem): void {
    this.tierClicked.emit(tier.Id);
  }
}
