import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { OurProductsInfo, OurProductsInfoDTO, OurProductsIntroBanner, OurProductsIntroBannerDTO, ProductInfo, ProductInfoDTO } from '../our-products.model';
import { ApiService } from 'src/app/_services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OurProductsService {

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getProductsList(): Observable<OurProductsInfo> {
    return this.apiService.get(`our-products/public`, {}, '1.0', true, environment.useMockedData.ourProductsGetList, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
        map((response: OurProductsInfoDTO | GenericErrorReply) => {
          if(this.generalService.isValidServerReply(response)) {
            response = response as OurProductsInfoDTO;

            const productsList = this.getOurProductsInfoFromDTO(response);
            return productsList;
          } else {
            throw response;
          }
        }
      ));
  }

  private getOurProductsInfoFromDTO(productsInfoDTO: OurProductsInfoDTO): OurProductsInfo {
    let ourProductsInfo: OurProductsInfo = {
      introBanners: this.getProductsIntroBannersFromDTO(productsInfoDTO.introBanners),
      products: this.getProductsListFromDTO(productsInfoDTO.products),
    };

    return ourProductsInfo;
  }

  private getProductsListFromDTO(productsDTO: Array<ProductInfoDTO>): Array<ProductInfo> {
    const productsList: Array<ProductInfo> = [];

    productsDTO.forEach(dto => {
      productsList.push({
        id: dto.id,
        productName: dto.productName,
        mainColor: dto.mainColor,

        // known for banner
        backgroundColor: dto.backgroundColor,
        squareImageUrl: dto.squareImageUrl,
        mobileImagePreviewUrl: dto.mobileImagePreviewUrl,

        // product description banner
        mainProductLogoUrl: dto.mainProductLogoUrl,
        mainProductImageUrl: dto.mainProductImageUrl,
        mainProductTitle: dto.mainProductTitle,
        mainProductDescriptionHTML: dto.mainProductDescriptionHTML,
        productCertificationsImageUrl: dto.productCertificationsImageUrl,

        // product preview banner
        productPreviewBannerImageUrl: dto.productPreviewBannerImageUrl,
      });
    });

    return productsList;
  }

  private getProductsIntroBannersFromDTO(productsBannersDTO: Array<OurProductsIntroBannerDTO>): Array<OurProductsIntroBanner> {
    const introBanners: Array<OurProductsIntroBanner> = [];

    productsBannersDTO.forEach(dto => {
      introBanners.push({
        titleAboveBanner: dto.titleAboveBanner,

        bannerImageUrl: dto.bannerImageUrl,
        bannerTitleImageUrl: dto.bannerTitleImageUrl,
        bannerTitle: dto.bannerTitle,
        bannerSubTitle: dto.bannerSubTitle,
        bannerDescriptionHTML: dto.bannerDescriptionHTML
      });
    });

    return introBanners;
  }
}
