export interface GoalOptionDTO {
  itemId: string;
  img: string;
  title: string;
}

export interface GoalOption {
  itemId: string;
  img: string;
  title: string;
  selected?: boolean;
}
