<!-- ##################### Intro Banner ##################### -->
<p class="pre-banner-title mt-5" *ngIf="bannerInfo.titleAboveBanner">{{bannerInfo.titleAboveBanner}}</p>

<div class="intro-banner" [ngStyle]="{'flex-flow' : flexFlow}">
  <div class="intro-info-container" [ngStyle]="{'width': bannerInfo.bannerImageUrl ? '45%' : '100%'}">
    <img class="main-product-logo" [src]="bannerInfo.bannerTitleImageUrl" *ngIf="bannerInfo.bannerTitleImageUrl">

    <p class="banner-title mt-5" *ngIf="bannerInfo.bannerTitle">{{ bannerInfo.bannerTitle }}</p>
    <p class="banner-subtitle mt-5" *ngIf="bannerInfo.bannerSubTitle">{{ bannerInfo.bannerSubTitle }}</p>
    <div class="banner-description" [innerHTML]="bannerInfo.bannerDescriptionHTML | safehtml" *ngIf="bannerInfo.bannerDescriptionHTML"></div>
  </div>

  <img class="main-banner-image" [src]="bannerInfo.bannerImageUrl" *ngIf="bannerInfo.bannerImageUrl">
</div>
