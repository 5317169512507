import { Pipe, PipeTransform } from '@angular/core';
import { DeepCopy } from '../../_directives/deep-copy/deep-copy.service';
import { LabelsService } from '../../_services/_labels/labels.service';
import { LabelState } from 'src/app/_services/_general-service/general-service.model';

@Pipe({
  name: 'label',
})
export class LabelPipe implements PipeTransform {

  /**
   * @param deepCopyDirective - Deep Copy Directive will create a copy of value without the need to create a secondary variable to do it. It can be found inside the _directives folder.
   * @param labelService - Label service will allow to obtain the information regarding the label we want to manipulate with this pipe.
  */

  constructor(private labelService: LabelsService, private deepCopyDirective: DeepCopy) {}

  transform(value: any, ...args: any[]): string {
    let label = this.deepCopyDirective.deepCopy(this.labelService.getLabel(value));
    if (label == null) {
      return value;
    }
    /*  switch(label.Format) {
      // Add switch cases that are need
    } */

    // This validation will only happen if the label being used has any parameters if it does the args array will contain the objects.
    if (args.length > 0) {
      for(let key in args[0]) {
        label.labelValue = label.labelValue.replaceAll(`{{${key}}}`, args[0][key]);
      }
    }

    if (label.state === LabelState.DISABLED) {
      return '';
    }

    return label.labelValue || '';
  }
}
