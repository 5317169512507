<div class="image-card-container">
  <div id="card-image-scroll-container" #AnimationScrollableContainer>
    <div  id="card-image-container" #ImageContainer>
      <div class="image-container">
        <img [src]="(generalService.isMobile | async) ? content.mobileImageUrl : content.imageUrl" id="card-image">
      </div>

      <div id="icons-container" #IconsContainer *ngIf="content.iconUrls && content.iconUrls.length > 0">
        <img class="icon" [src]="content.iconUrls[0]">
        <img class="icon" [src]="content.iconUrls[1]" *ngIf="content.iconUrls.length > 1">
      </div>

      <div id="text-container" #TextContainer>
        <p class="content-title">{{content.title}}</p>
        <p class="content-text">{{content.text}}</p>
    </div>
    </div>
  </div>
</div>
