import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CarouselBanner, CarouselType } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./carousel.component.sass']
})
export class CarouselComponent implements OnInit {
  @ViewChild("swiperRef", { static: false }) swiperRef?: SwiperComponent;

  @Input() content: CarouselBanner;

  public CarouselType = CarouselType;

  public environment = environment;

  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {
  }

  public carouselSlideAction(carouselType: CarouselType, slideId: string): void {
    switch(carouselType) {
      case CarouselType.BLOG:
        this.generalService.navigateTo(`blog/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;

      case CarouselType.COLLECTIONS:
        this.generalService.navigateTo(`marketplace/product/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;

      case CarouselType.INSTITUTIONS:
        this.generalService.navigateTo(`/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;
    }
  }
}
