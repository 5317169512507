import { getCurrencySymbol } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgProgressComponent } from 'ngx-progressbar';
import { Subject, take, takeUntil } from 'rxjs';
import { HeaderItem } from 'src/app/_generic-components-lib/list-header-module/list-header.model';
import { ActionId, ListItem } from 'src/app/_generic-components-lib/list-item-module/list-item.model';
import { MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../../marketplace/_services/_products/products.service';
import { Product } from '../../marketplace/_services/_products/products.service.model';
import { ImageType } from '../../tutorial/tutorial.model';
import { HomeService } from '../_services/home.service';
import { PotencialRewards } from '../_services/home.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('progressBar') progressBar: NgProgressComponent;
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;

  private destroy: Subject<boolean> = new Subject<boolean>();

  public getCurrencySymbol = getCurrencySymbol;

  public mainBannerInfo: MenuBanner;

  public isLoaded: boolean;
  public environment = environment;
  public isMobile: boolean = false;

  public userGoal: Product;
  public userGoalBackgroundImg: string;

  public activitiesList: Array<ListItem> = [];
  public activitiesHeaderList: Array<HeaderItem> = [];

  public showEditUserGoalPopup: boolean = false;
  public userHasGoal: boolean = false;

  private goalCompleted: boolean = false;

  public potencialRewardsLoading: boolean = true;
  public potencialRewards: PotencialRewards = {
    potentialPartnerRewards: 0,
    potentialUserInputRewards: 0
  };

  constructor(
    public labelService: LabelsService,
    public generalService: GeneralService,
    private host: ElementRef,
    public homeService: HomeService,
    private productsService: ProductsService,
    private popupService: PopupService,
    private labelPipe: LabelPipe
  ) {
    this.generalService.userDetails.pipe(takeUntil(this.destroy)).subscribe(() => {
      if (this.generalService.userDetails$.value.goalId) {
        this.goalSelected(this.generalService.userDetails$.value.goalId);
      }
    });
  }

  ngOnInit(): void {
    this.setCssVars();

    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/home');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    if (this.generalService.userDetails$.value.goalId) {
      this.userHasGoal = true;

      this.goalSelected(this.generalService.userDetails$.value.goalId);
    } else {
      this.isLoaded = true;
      this.generalService.asyncFunction(() => this.initProgressBar());
    }

    this.generalService.isMobile.pipe(takeUntil(this.destroy)).subscribe((resp: boolean) => {this.isMobile = resp});

    this.potencialRewardsLoading = true;
    this.homeService.getPotencialRewards().subscribe(
      {
        next: potencialRewards => {
          this.potencialRewards = potencialRewards;
          this.potencialRewardsLoading = false;
        },
        error: err => {
          this.potencialRewardsLoading = false;
        }
      }
    );
  }

  ngAfterViewInit() {
    this.homeService.getActivities().pipe(take(1)).subscribe(resp => {
      this.activitiesHeaderList = resp.headerItemsList;
      this.activitiesList = resp.itemsList;
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  private initProgressBar(): void {
    // set percentage of progress
    this.progressBar.set(0);

    if (this.userGoal && this.userGoal.prices) {
      this.generalService.asyncFunction(() => {
        this.progressBar.set(Math.min((this.generalService.userDefaultWallet.currentValue * 100) / this.userGoal.prices[0].amount, 100));
      }, 0);

      this.generalService.asyncFunction(() => {
        this.host.nativeElement.style.setProperty('--user-balance-width-percentage', Math.min((this.generalService.userDefaultWallet.currentValue * 100) / this.userGoal.prices[0].amount, 100) + '%');
      }, 0);
    }

    this.generalService.asyncFunction(() => {
      this.progressAnimationCompleted();
    }, 800);
  }

  public progressAnimationCompleted(): void {
    if (this.userGoal && this.userGoal.prices && this.generalService.userDefaultWallet.currentValue >= this.userGoal.prices[0].amount) {
      this.host.nativeElement.style.setProperty('--progress-end-circle-background-color', 'var(--main-brand-color)');
      this.host.nativeElement.style.setProperty('--progress-unfilled-background-color', 'var(--main-brand-color)');

      this.completedGoal();
    } else {
      this.host.nativeElement.style.setProperty('--progress-end-circle-background-color', '#D9D9D9');
      this.host.nativeElement.style.setProperty('--progress-unfilled-background-color', '#D9D9D9');
    }
  }

  public promptEditGoal(): void {
    this.showEditUserGoalPopup = true;
  }

  public goalSelected(goalId: string): void {
    this.goalCompleted = false;

    this.productsService.getProductDetail(goalId).pipe(take(1)).subscribe({
      next: selectedGoal => {
        this.userGoal = selectedGoal;
        this.userHasGoal = true;
        const foundImage = selectedGoal.images?.find(img => img.type === ImageType.IMAGE)?.href;
        this.userGoalBackgroundImg = foundImage ? foundImage : `assets/imgs/environments/${environment.tenantName}/default-images/default-image-small-rectangle.svg`;

        this.isLoaded = true;
        if (this.generalService.userDefaultWallet.currentValue < this.userGoal.prices[0].amount) {
          this.progressAnimationCompleted();
        }

        this.generalService.asyncFunction(() => this.initProgressBar());
      },
      error: err => {
        this.userHasGoal = false;
        this.isLoaded = true;

        this.generalService.asyncFunction(() => this.initProgressBar());
      }
    });
  }

  public redirectToGoal(): void {
    this.generalService.navigateTo('marketplace/product/' + this.userGoal.id);
  }

  public showMoreActivities(): void {
    this.generalService.navigateTo('activities');
  }

  private setCssVars(): void {
    this.host.nativeElement.style.setProperty('--top-pseudo-element', `url('../../../../../assets/imgs/environments/${environment.tenantName}/home/top-pseudo-element.svg')`);
    this.host.nativeElement.style.setProperty('--bottom-pseudo-element', `url('../../../../../assets/imgs/environments/${environment.tenantName}/home/bottom-pseudo-element.svg')`);
  }

  private completedGoal(): void {
    if (!this.goalCompleted) {
      this.goalCompleted = true;

      this.popupService.setPopupToOpen(
        { text: '' },
        'assets/imgs/environments/' + environment.tenantName + '/home/goal-achieved-icon.svg',
        {
          text: this.labelPipe.transform(this.labelService.completedGoalPopupTitle),
          style: {
            marginTop: '0'
          }
        },
        [
          {
            text: this.labelPipe.transform(this.labelService.completedGoalPopupDescription),
            style: {
              fontColor: '#757575',
              fontSize: 'var(--small-font-size)',
              fontWeight: '700',
              marginBottom: '0',
            }
          },
          {
            text: this.userGoal.name,
            style: {
              fontColor: 'var(--main-brand-color)',
              fontSize: 'var(--small-font-size)',
              fontWeight: '700'
            }
          }
        ],
        [
          {
            text: this.labelPipe.transform(this.labelService.completedGoalPopupCancelButton),
            isCloseBtn: true,
            actionText: 'close',
            style: {
              backgroundColor: '#424242',
              fontColor: '#ffffff',
              fontWeight: '700'
            }
          },
          {
            text: this.labelPipe.transform(this.labelService.completedGoalPopupGoToButton),
            isCloseBtn: true,
            actionText: 'goToVoucher',
            actionValue: this.userGoal.id,
            style: {
              backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
              fontColor: this.environment.brandStyleVariables.mainBackgroundColor,
              fontWeight: '700'
            }
          }
        ],
        false,
        true,
        this.isMobile
      );
    }
  }

  public listItemActionClicked(actionId: ActionId, activityId: string): void {
    switch (actionId) {
      case ActionId.goTo:
        this.generalService.navigateTo(`activities/${activityId}`);
        break;
    }
  }
}
