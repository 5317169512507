import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { Menu, UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { FilterChip, FilterGroup, FilterItem, FilterTypeEnum, SelectedFilterGroup } from 'src/app/pages/_shared-components/filters-list-module/filters-list/filter.model';
import { FiltersListService } from 'src/app/pages/_shared-components/filters-list-module/filters-list/filters-list.service';
import { environment } from 'src/environments/environment';
import { ProductHistoryItem } from '../../_services/products/product-history.model';
import { ProductsHistoryService } from '../../_services/products/products-history.service';

@Component({
  selector: 'app-product-history-list-container',
  templateUrl: './product-history-list-container.component.html',
  styleUrls: ['./product-history-list-container.component.sass']
})
export class ProductHistoryListContainerComponent implements OnInit {
  @ViewChild('productListContainer', { static: false }) public productListContainer: ElementRef;

  public currentMenu: Menu;

  public itemList: Array<ProductHistoryItem> = [];
  public loadingListItems: Array<number> = [1, 2, 3, 4, 5, 6];
  public filterList: Array<FilterGroup> = [];
  public filterChips: Array<FilterChip> = [];

  public userDetails: UserDetails;

  private queryParameters: Params = {};

  public searchString: string = '';

  public selectedFilters: Array<SelectedFilterGroup> = [];
  public activeFilterCount: number = 0;

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;

  public listPercentageFromBottom: number = 0;

  public environment = environment;

  constructor(
    public mainComponent: AppComponent,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private productHistoryService: ProductsHistoryService,
    private filtersService: FiltersListService,
    public labelService: LabelsService,
  ) { }

  ngOnInit(): void {
    this.currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/history')!;

    this.userDetails = this.generalService.userDetails$.value;

    this.route.queryParams.subscribe((params: Params) => {
      this.queryParameters = params;
    });

    this.isListLoading = true;
    this.productHistoryService.getEndOfScrollReached().subscribe(() => {
      if (!this.isListLoading) {
        this.isListLoading = true;
        this.getMoreItems(this.queryParameters);
      }
    });

        /* this.getFilters().subscribe(() => {
      this.filterList.forEach(group => {
        this.selectedFilters.push({
          queryParameter: group.queryParameter,
          items: []
        });
      });
      this.filtersService.selectedFilters = this.selectedFilters; */
      this.updateFilters(this.queryParameters);

      this.isLoaded = true;
    /* }); */
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next(this.currentMenu.viewUrl + '/products');
  }

  public filtersUpdated(): void {
    this.activeFilterCount = 0;

    this.selectedFilters.forEach(group => {
      this.activeFilterCount += group.items.length;

      // add chips when a new filter is selected
      group.items.forEach(item => {
        if (this.filterChips.find(chip => (group.queryParameter === chip.queryParameter && chip.value === item.value)) === undefined) {
          this.filterChips.push({
            queryParameter: group.queryParameter,
            name: item.name,
            value: item.value
          });
        }
      });
    });

    let chipsToDelete: Array<FilterChip> = [];

    // remove chip when a filter is removed
    this.filterChips.forEach(chip => {
      let chipFound = false;

      this.selectedFilters.forEach(group => {
        if (chip.queryParameter === group.queryParameter && group.items.find(item => item.value === chip.value) !== undefined) {
          chipFound = true;
        }
      });

      if (!chipFound) {
        chipsToDelete.push(chip);
      }
    });

    chipsToDelete.forEach(chip => this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1));

    this.updateQueryParameters();
  }

  /**
 * Deletes a chip from selected filters chip list
 *
 * @param chip - FilterChip
 */
  public deleteChip(chip: FilterChip): void {
    // delete chip from list
    this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1);

    // remove filter from selected filters
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (this.selectedFilters[i].queryParameter === chip.queryParameter) {
        this.selectedFilters[i].items.splice(this.selectedFilters[i].items.findIndex(c => c.value === chip.value), 1);
        this.activeFilterCount--;
      }
    }

    // toggle off filter
    this.filterList.forEach(group => {
      if (group.queryParameter === chip.queryParameter) {
        switch (group.type) {
          case FilterTypeEnum.Checkbox:
            (group.values.find(v => (v as FilterItem).id === chip.value) as FilterItem).selected = false;
            break;
          case FilterTypeEnum.Radio:
            (group.values.find(v => (v as RadioButton).value === chip.value) as RadioButton).selected = false;
            break;
          case FilterTypeEnum.Range:
            break;
          default:
            break;
        }
      }
    });
  }

  /**
 * Updates each filter based on the query parameters
 *
 * @param params - QueryParameters on the URL
 *
 * We loop all the queryParameters on the url and obtain the key of each query param and save it on a variable
 * then we check if each variable is valid, if so we run the update filter function.
 */
  private updateFilters(params: Params): void {
    Object.keys(params).forEach((key: string) => {
      let group = this.filterList.find(group => group.queryParameter === key);

      if(group) {
        this.updateFilter(group, params, key);
      }
    });
    this.filtersUpdated();
  }

  /**
 * Updates each filter based on the query parameters
 *
 * @param group - FilterGroup this can be a RadioButtonGroup or CheckBoxGroup
 * @param params - QueryParameters that exist on the url
 * @param key - name of the key of each param.
 *
 * The UpdateFilter function first create a new array by splitting all the parameters after that we loop each value
 * and check if they belong to a groupItem (RadioButtonGroup) if not they are a CheckBoxGroup in the end of each we set it
 * to true and run the change detection for each group
 */
  private updateFilter(group: FilterGroup, params: Params, key: string): void {
  let values = params[key].split(',');

  values.forEach((value: string) => {
    let groupItem;
    switch (group.type) {
      case FilterTypeEnum.Checkbox:
        groupItem = (group.values.find(v => (v as FilterItem).id === +value) as FilterItem);

        if(groupItem) {
          groupItem.selected = true;
          this.filtersService.filterSelected(group, groupItem, true);
        }
        break;
      case FilterTypeEnum.Radio:
        groupItem = (group.values.find(v => (v as RadioButton).value === +value) as RadioButton);

        if(groupItem) {
          groupItem.selected = true;
          this.filtersService.radioBtnfilterChanged(group, +value);
        }
        break;
      case FilterTypeEnum.Range:
        break;
      default:
        break;
    }
  });
}

  public listItemClicked(productId: string): void {
    this.generalService.navigateTo(`history/products/${productId}`);
    this.generalService.autoScrollTop('page-router-container');
  }

  public onScroll(): void {
    if(this.productHistoryService.infiniteScrollActive) {
      this.productHistoryService.getEndOfScrollReached().next(true);
    }
  }

  public search(): void {
    this.updateQueryParameters();
  }

  private updateQueryParameters(): void {
    let queryParams: Params = {};

    this.updateSearchParam(queryParams);

    this.getItems(1, queryParams);
    this.generalService.navigateToWithQueryParams(this.currentMenu.viewUrl + '/products', queryParams);
  }

  private getMoreItems(params: Params = {}): void {
    if(this.productHistoryService.totalPages > this.productHistoryService.currentPageIndex) {
      this.productHistoryService.currentPageIndex++;
      this.getItems(this.productHistoryService.currentPageIndex, params);
    }
  }

  private updateSearchParam(params: Params): void {

    if ('search' in this.queryParameters) {
      params['search'] = this.queryParameters['search'];
    }

    if (this.searchString) {
      params['search'] = this.searchString;
    } else {
      delete params['search']
    }
  }

  private getItems(page: number, params: Params = {}): void {
    if(!this.isListLoading) {
      this.isListLoading = true;
    }

    this.productHistoryService.getUserProductsList(page, params).subscribe({
      next: list => {
        if(this.productHistoryService.currentPageIndex > 1 && this.itemList) {
          this.itemList = this.itemList.concat(list);
        } else {
          this.itemList = list;
        }

        this.isListLoading = false;
      },
      error: error => {
        this.isListLoading = false;
      }
    });
  }

}
