<div class="article-item-content mt-auto">
  <div class="description-container">
    <p class="description-text"></p>
    <p class="description-text"></p>
    <p class="description-text"></p>
  </div>

  <div class="article-publish-date-container">
    <p class="publish-date-label"></p>

    <span class="publish-date"></span>
  </div>
</div>
