import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingContainerComponent } from './onboarding-container/onboarding-container.component';
import { StepsModule } from '../_shared-components/steps-module/steps.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';


@NgModule({
  declarations: [
    OnboardingContainerComponent
  ],
  imports: [
    CommonModule,
    StepsModule,
    LabelPipeModule,
    ButtonsModule
  ],
  exports: [
    OnboardingContainerComponent
  ]
})
export class OnboardingModule { }
