import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasePopupComponent } from './purchase-popup/purchase-popup.component';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';



@NgModule({
  declarations: [
    PurchasePopupComponent
  ],
  imports: [
    CommonModule,
    LabelPipeModule,
    ButtonsModule
  ],
  exports: [
    PurchasePopupComponent
  ]
})
export class PurchasePopupModule { }
