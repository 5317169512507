import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnimationTypes, BannerInfo, BannerTypes, CarouselType, ContentType, GetGeneralInfoReply, Label, MediumType, MenuDTO, UserDetailsDTO } from 'src/app/_services/_general-service/general-service.model';
import { PaymentTypeEnum } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MockGeneralService {

  getInfo(): Observable<GetGeneralInfoReply> {
    const givinData: GetGeneralInfoReply = {
      brandingInfo: {
        brandImageUrl: 'assets/mock/environments/GIVIN/brand-icon.png',
        phoneContact: '+351 21345678',
        emailContact: 'info@givin.com',
        socialMediaList: [
          {
            id: 1,
            name: 'Facebook',
            redirectUrl: 'https://www.facebook.com',
            iconUrl: 'assets/imgs/environments/GIVIN/social-media/facebook-icon.svg',
          }, {
            id: 2,
            name: 'Youtube',
            redirectUrl: 'https://www.youtube.com',
            iconUrl: 'assets/imgs/environments/GIVIN/social-media/youtube-icon.svg',
          }, {
            id: 3,
            name: 'Whatsapp',
            redirectUrl: 'https://www.whatsapp.com',
            iconUrl: 'assets/imgs/environments/GIVIN/social-media/whatsapp-icon.svg',
          }
        ],
        clientName: 'GIVIN',
      }
    };

    const gamifyouData: GetGeneralInfoReply = {
      brandingInfo: {
        brandImageUrl: 'assets/mock/environments/Gamifyou/brand-icon.png',
        phoneContact: '213 174 421',
        emailContact: 'info@gamifyou.com',
        socialMediaList: [
          {
            id: 1,
            name: 'Instagram',
            redirectUrl: 'https://www.instagram.com/gamifyou',
            iconUrl: 'assets/imgs/environments/Gamifyou/social-media/instagram-icon.svg',
          },
          {
            id: 2,
            name: 'Facebook',
            redirectUrl: 'https://www.facebook.com/gamifyou',
            iconUrl: 'assets/imgs/environments/Gamifyou/social-media/facebook-icon.svg',
          },
          {
            id: 3,
            name: 'Linkedin',
            redirectUrl: 'https://www.linkedin.com/company/gamifyou',
            iconUrl: 'assets/imgs/environments/Gamifyou/social-media/linkedin-icon.svg',
          }
        ],
        clientName: 'Gamifyou',

        brandHQAddress: {
          addressLine1: 'Av. José Malhoa, nº 16 F, Piso 1',
          addressLine2: 'Bloco A - Edifício Europa',
          postalCode: '1070-159',
          city: 'Lisbon',
          country: 'Portugal'
        },
        brandHQMapImageUrl: 'assets/mock/environments/Gamifyou/brand-hq-map.png'
      }
    };

    const groupYourData: GetGeneralInfoReply = {
      brandingInfo: {
        brandImageUrl: 'assets/mock/environments/YOUR/brand-icon.png',
        phoneContact: '213 174 421',
        emailContact: 'info@groupyour.com',
        socialMediaList: [
          {
            id: 1,
            name: 'Instagram',
            redirectUrl: 'https://www.instagram.com/YOUR',
            iconUrl: 'assets/imgs/environments/YOUR/social-media/instagram-icon.svg',
          },
          {
            id: 2,
            name: 'Facebook',
            redirectUrl: 'https://www.facebook.com/YOUR',
            iconUrl: 'assets/imgs/environments/YOUR/social-media/facebook-icon.svg',
          },
          {
            id: 3,
            name: 'Linkedin',
            redirectUrl: 'https://www.linkedin.com/company/gamifyou',
            iconUrl: 'assets/imgs/environments/YOUR/social-media/linkedin-icon.svg',
          }
        ],
        clientName: 'Your',

        brandHQAddress: {
          addressLine1: 'Av. José Malhoa, nº 16 F, Piso 1',
          addressLine2: 'Bloco A - Edifício Europa',
          postalCode: '1070-159',
          city: 'Lisbon',
          country: 'Portugal'
        },
        brandHQMapImageUrl: 'assets/mock/environments/YOUR/brand-hq-map.png',
        currencies: [
          {
            type: "PINK_COINS",
            name: "Pinkcoins",
            requiresWallet: true,
            paymentTypes: [
              PaymentTypeEnum.GAMIFICATION_COIN
            ]
          }
        ]
      }
    };

    return of(environment.tenantName === 'GIVIN' ? givinData : environment.tenantName === 'YOUR'? groupYourData : gamifyouData);
  }

  getLabels(): Observable<Array<Label>> {
    const givinData: Array<Label> = [
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'header_login_btn',
        'labelValue': 'Connect to Wallet'
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'header_login_btn_active_wallet',
        'labelValue': 'Wallet Connected'
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'marketplace_list_no_items',
        'labelValue': 'Cannot find any results'
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'marketplace_listitem_serial',
        'labelValue': 'Serial'
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'marketplace_listitem_quantity',
        'labelValue': '{{count}} available'
      },
      {
        "labelId": "a0a1e10a-e9ea-452e-8305-6f1bf367324d",
        "labelKey": "marketplace_listitem_lowestask",
        "labelValue": "Lowest ask . {{price}}{{unit}}"
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'marketplace_listitem_price',
        'labelValue': '{{price}}{{unit}}'
      },
      {
        'labelId': 'a0a1e10a-e9ea-452e-8305-6f1bf367324d',
        'labelKey': 'marketplace_listitem_date',
        'labelValue': 'Ends {{date}}'
      },
      {
        "labelId": "18b30479-accc-4865-97e1-09a8f1ffa6f4",
        "labelKey": "footer_newsletter_title",
        "labelValue": "Newsletter"
      },
      {
        "labelId": "da59a855-9704-4372-83e6-9b52bddae1f3",
        "labelKey": "footer_brand_description",
        "labelValue": "A GIVIN é uma plataforma que pretende fazer a diferença no mercado das doações, tornando todo o processo mais simples, seguro e acessível a todos. Pretendemos potenciar os ganhos das instituições disponibilizando, no nosso mercado, NFTs oficiais (Non-Fungible Tokens) que podem depois, através da sua compra e venda, fazer com que os doadores contribuam ainda mais para as causas em que acreditam."
      },
      {
        "labelId": "2651ae70-30f4-4f7e-be44-d127aa0214cd",
        "labelKey": "footer_contacts_email_title",
        "labelValue": "Contacte-nos"
      },
      {
        "labelId": "4ceec1ae-4a31-4e5d-8623-48ea18b1c103",
        "labelKey": "footer_newsletter_placeholder",
        "labelValue": "Inserir email"
      },
      {
        "labelId": "9a21660c-896d-4094-b3c4-a56b4b845368",
        "labelKey": "footer_newsletter_submit_button",
        "labelValue": "Submeter"
      },
      {
        "labelId": "b5606ab7-82aa-4a30-adfe-3d3235c984db",
        "labelKey": "footer_newsletter_description",
        "labelValue": "Sê o primeiro a saber de notícias, novas experiências e eventos! Cancela a subscrição sempre que quiseres."
      },
      {
        "labelId": "177de61a-814b-4c89-98dc-a055ae6ce760",
        "labelKey": "footer_all_rights_reserved",
        "labelValue": "© 2022-2023 GIVIN, Todos os direitos reservados"
      },
      {
        "labelId": "74ac9125-5083-4386-8d2d-b3849bb737c5",
        "labelKey": "footer_shortcut_about",
        "labelValue": "Sobre nós"
      },
      {
        "labelId": "c1dee36b-8ac8-48be-8101-70e11155ffdd",
        "labelKey": "footer_shortcut_privacy",
        "labelValue": "Política de privacidade"
      },
      {
        "labelId": "d1dc6dce-beef-414c-80cc-ab5785125f95",
        "labelKey": "footer_shortcut_terms",
        "labelValue": "Termos de utilização"
      },
      {
        "labelId": "f35ea310-de48-4af6-88a3-07541b3de4b5",
        "labelKey": "newsletter_popup_title_error",
        "labelValue": "Ocorreu um erro"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "newsletter_popup_error_message",
        "labelValue": "Por favor, insere um email válido"
      }
    ];

    const gamifyouData: Array<Label> = [
      {
        "labelId": "18b30479-accc-4865-97e1-09a8f1ffa6f4",
        "labelKey": "footer_newsletter_title",
        "labelValue": "Newsletter"
      },
      {
        "labelId": "da59a855-9704-4372-83e6-9b52bddae1f3",
        "labelKey": "footer_brand_description",
        "labelValue": "Gamifyou by InnoWave is a cloud-based gamification platform that is widely used and has in-depth customisation features allowing you to promote good practices among your teams, citizens, and clients."
      },
      {
        "labelId": "2651ae70-30f4-4f7e-be44-d127aa0214cd",
        "labelKey": "footer_contacts_email_title",
        "labelValue": "Contact us"
      },
      {
        "labelId": "4ceec1ae-4a31-4e5d-8623-48ea18b1c103",
        "labelKey": "footer_newsletter_placeholder",
        "labelValue": "Insert email"
      },
      {
        "labelId": "9a21660c-896d-4094-b3c4-a56b4b845368",
        "labelKey": "footer_newsletter_submit_button",
        "labelValue": "Submit"
      },
      {
        "labelId": "b5606ab7-82aa-4a30-adfe-3d3235c984db",
        "labelKey": "footer_newsletter_description",
        "labelValue": "Be the first one to know about news, new institutions and events. Unsubscribe whenever you like."
      },
      {
        "labelId": "177de61a-814b-4c89-98dc-a055ae6ce760",
        "labelKey": "footer_all_rights_reserved",
        "labelValue": "© 2022-2023 Gamifyou, All rights reserved"
      },
      {
        "labelId": "74ac9125-5083-4386-8d2d-b3849bb737c5",
        "labelKey": "footer_shortcut_about",
        "labelValue": "About us"
      },
      {
        "labelId": "c1dee36b-8ac8-48be-8101-70e11155ffdd",
        "labelKey": "footer_shortcut_privacy",
        "labelValue": "Privacy policy"
      },
      {
        "labelId": "d1dc6dce-beef-414c-80cc-ab5785125f95",
        "labelKey": "footer_shortcut_terms",
        "labelValue": "Terms of use"
      },
      {
        "labelId": "f35ea310-de48-4af6-88a3-07541b3de4b5",
        "labelKey": "newsletter_popup_title_error",
        "labelValue": "An error occurred."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "newsletter_popup_error_message",
        "labelValue": "Please insert a valid email address."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "general_demo_btn",
        "labelValue": "Ask for a demo"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_banner_title",
        "labelValue": "Blog"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_title",
        "labelValue": "Articles list"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_description",
        "labelValue": "See here all available articles."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_search_placeholder",
        "labelValue": "Search..."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_filter_button_label",
        "labelValue": "Open filter"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_filter_button_close_label",
        "labelValue": "Close filter"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_filter_title",
        "labelValue": "Filters"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "news_list_filters_clear_label",
        "labelValue": "Clear filters"
      },
      {
        "labelId": "5cd433da-58df-123d-b045-1db39b2abaf1",
        "labelKey": "news_article_publish_date_label",
        "labelValue": "Posted"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "our_products_banner_title",
        "labelValue": "Our Products"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "our_products_known_for_banner_title",
        "labelValue": "Known for"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_form_title",
        "labelValue": "Ask us for a demo."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_form_description",
        "labelValue": "Get answers to your unique questions and find out why Gamifyou is the right option for your business."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_name_title",
        "labelValue": "Name"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_name_placeholder",
        "labelValue": "Enter name"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_phone_title",
        "labelValue": "Phone number"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_phone_placeholder",
        "labelValue": "Enter phone number"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_email_title",
        "labelValue": "Email address"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_email_placeholder",
        "labelValue": "Enter email address"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_message_title",
        "labelValue": "Message"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_message_placeholder",
        "labelValue": "Enter message"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "my_account_details_error_field",
        "labelValue": "This field is required"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "my_account_details_error_text_field",
        "labelValue": "This field has an error. Please check and fix it before submiting."
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_submit_button",
        "labelValue": "Request a demo"
      },
      {
        "labelId": "5cd433da-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "contact_us_headquarters_address_title",
        "labelValue": "Come and get to know us."
      },
      {
        "labelId": "123asewd-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "newsletter_popup_title_success",
        "labelValue": "Success"
      },
      {
        "labelId": "35245adawd1-58df-48f6-b045-1db39b2abaf1",
        "labelKey": "newsletter_popup_success_message",
        "labelValue": "Congratulations! You have successfully subscribed to our newsletter!"
      },
      {
        "labelId": "37b27324-1fb5-4995-bb99-6998ef94b91f",
        "labelKey": "error_page_code",
        "labelValue": "404"
      },
      {
        "labelId": "967b0a78-317a-47eb-bd2e-1b3dd6cfb091",
        "labelKey": "error_page_title",
        "labelValue": "Page not found"
      },
      {
        "labelId": "3d6a56b6-c9d2-4bc8-b2ea-ac4aaeb9a49f",
        "labelKey": "error_page_description",
        "labelValue": "We couldn't find the page you were trying to access.\nPlease return to the home screen."
      },
      {
        "labelId": "4352rs332-c9d2-4bc8-b2ea-ac4aaeb9a49f",
        "labelKey": "blog_article_details_page_title",
        "labelValue": "Detailed information"
      }
    ];

    return of(environment.tenantName === 'GIVIN' ? givinData : gamifyouData);
  }

  getLPBanners(): Observable<Array<BannerInfo>> {
    const givinData: Array<BannerInfo> = [
      {
        bannerId: 1,
        type: BannerTypes.VIDEO,
        content: {
          videoUrl: 'assets/mock/Video.mp4',
          mobileVideoUrl: 'assets/mock/Video mobile.mp4',
          animationDesktop: undefined,
          animationMobile: undefined,
          title: 'Making donations can be Awesome.',
          subtitle: 'Donations change lives!',
          fontColor: '#0aadaf'
        }
      }, {
        bannerId: 2,
        type: BannerTypes.DESCRIPTION_LIST,
        content: {
          backgroundColor: environment.brandStyleVariables.mainBrandColor,
          descriptionList: [
            {
              title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
              subtitle: 'Insert Text',
              imageUrl: 'assets/mock/DescriptionImage.svg',
            }, {
              title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
              subtitle: 'Insert Text',
              imageUrl: 'assets/mock/DescriptionImage2.png',
            }/* , {
              Title: '',
              Subtitle: '',
              ImageUrl: 'assets/mock/DescriptionImage2.png',
            }, {
              Title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
              Subtitle: 'Insert Text',
              ImageUrl: '',
            } */, {
              title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.',
              subtitle: 'Insert Text',
              imageUrl: 'assets/mock/DescriptionImage3.png',
            }
          ],
        }
      }, {
        bannerId: 3,
        type: BannerTypes.ACTION_INCENTIVE,
        content: {
          backgroundColor: '#ffffff',
          fontColor: environment.brandStyleVariables.mainBrandColor,
          title: 'The time is now.',
          imageUrl: 'assets/mock/ActionIncentiveImage.png',
          description: 'New ways to stay in the moment',
          actionText: 'Donate now',
          actionIconUrl: 'assets/mock/ActionIcon.svg',
          actionUrl: 'https://www.google.com',
        }
      },{
        bannerId: 4,
        type: BannerTypes.CAROUSEL,
        content: {
          backgroundColor: '#ffffff',
          mainCarouselContent: [{
            id: '1',
            imageUrl: 'assets/mock/carousel-item.jpg',
            type: CarouselType.NEWS,
            title: 'Christ Falling on the Way to Calvary.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '2',
            imageUrl: 'assets/mock/carousel-item.jpg',
            type: CarouselType.NEWS,
            title: 'Receiving the body of St Francis Xavier in St Paul s Church in Goa.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '3',
            imageUrl: 'assets/mock/carousel-item.jpg',
            type: CarouselType.NEWS,
            title: 'Christ Falling on the Way to Calvary.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '4',
            imageUrl: 'assets/mock/carousel-item.jpg',
            type: CarouselType.NEWS,
            title: 'Receiving the body of St Francis Xavier in St Paul s Church in Goa.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          }],
          smallCarouselContent: [{
            id: '1',
            imageUrl: 'assets/mock/carousel-item.jpg',
            title: 'Christ Falling on the Way to Calvary.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '2',
            imageUrl: 'assets/mock/carousel-item.jpg',
            title: 'Receiving the body of St Francis Xavier in St Paul s Church in Goa.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '3',
            imageUrl: 'assets/mock/carousel-item.jpg',
            title: 'Christ Falling on the Way to Calvary.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '4',
            imageUrl: 'assets/mock/carousel-item.jpg',
            title: 'Receiving the body of St Francis Xavier in St Paul s Church in Goa.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '5',
            imageUrl: 'assets/mock/carousel-item.jpg',
            title: 'Christ Falling on the Way to Calvary.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          }]
        }
      }, {
        bannerId: 5,
        type: BannerTypes.PRODUCT_DETAIL,
        content: {
          backgroundColor: '#ffffff',
          title: 'You can make a difference right now.',
          subtitle: 'Donating online is easy, takes just a few moments, and is completely secure.',
          text: 'Monthly donations save lives and ensure that hungry people get the nutritious food they need, no matter what.',
          imageUrl: 'assets/mock/product-details-main-image.svg',
          iconUrls: ['assets/mock/product-details-left-icon.svg', 'assets/mock/product-details-right-icon.svg'],
        }
      }, {
        bannerId: 6,
        type: BannerTypes.IMAGE_CARD,
        content: {
          backgroundColor: '#ffffff',
          title: 'Your donation will be immeadiately put to use.',
          text: 'Whether you decide to make a one-off donation or buy an NFT, you can change a life forever today.\n\nAs soon as you complete your donation, your gift will start its journey.',
          imageUrl: 'assets/mock/CardImage.png',
          mobileImageUrl: 'assets/mock/CardImage.png',
          iconUrls: ['assets/mock/image-card-left-icon.svg', 'assets/mock/image-card-right-icon.svg'],
          scrollAnimationEnabled: true
        }
      }, {
        bannerId: 7,
        type: BannerTypes.FEATURED_TITLE,
        content: {
          backgroundColor: '#ffffff',
          title: 'Everything is possible.',
          fontColor: environment.brandStyleVariables.mainBrandColor,
          imageUrl: 'assets/mock/featured-title-icon.svg',
          scrollAnimationEnabled: true
        }
      }
    ];

    const gamifyouData: Array<BannerInfo> = [
      {
        bannerId: 1,
        type: BannerTypes.VIDEO,
        content: {
          videoUrl: 'assets/mock/environments/Gamifyou/banners/gamifyou_intro_desktop.mp4',
          mobileVideoUrl: 'assets/mock/environments/Gamifyou/banners/gamifyou_intro_mobile.mp4',
          animationDesktop: {
            type: AnimationTypes.LOTTIE,
            url: 'assets/mock/environments/Gamifyou/banners/desktop_gamifyou_intro.json',
            loop: false
          },
          animationMobile: {
            type: AnimationTypes.LOTTIE,
            url: 'assets/mock/environments/Gamifyou/banners/mobile_gamifyou_intro.json',
            loop: false
          },
          title: 'Making donations can be Awesome.',
          subtitle: 'Donations change lives!',
          fontColor: '#0aadaf'
        }
      }, {
        bannerId: 2,
        type: BannerTypes.DESCRIPTION_LIST,
        content: {
          backgroundColor: environment.brandStyleVariables.mainBrandColor,
          descriptionList: [
            {
              title: 'Increase the engagement with your audience.',
              subtitle: 'Change behaviors, drive innovation and achieve your goals.',
              /* animation: {
                type: 'rive',
                url: 'assets/mock/environments/Gamifyou/test.riv'
              } */
              animation: {
                type: AnimationTypes.LOTTIE,
                url: 'assets/mock/environments/Gamifyou/banners/Gamifas.json',
                loop: true
              }
            }, {
              title: 'Gamification brings new ways of working.',
              subtitle: 'GamifYou is a data and analytics platform that helps companies improve engagement with their customers.\n\nThrough advanced techniques\n(AI, gamification) we generate smart insights.​',
              imageUrl: 'assets/mock/environments/Gamifyou/banners/Frame_512767.png',
            },{
              title: 'Gamification Inspire and motivate.',
              subtitle: 'Gamification goal is to motivate and engage people to change behaviours, drive innovation and to achieve their goals. ',
              imageUrl: 'assets/mock/environments/Gamifyou/banners/Frame_512768.png',
            }
          ],
        }
      },/* {
        bannerId: 4,
        type: 3,
        content: {
          backgroundColor: '#ffffff',
          mainCarouselContent: [{
            id: '1',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            type: 3,
            title: 'Emotion & Gamification.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '2',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            type: 3,
            title: ' How Gamification makes you happy.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '3',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            type: 3,
            title: 'Benefits of Gamification in Logistics.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '4',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            type: 3,
            title: '5 Gamification Trends you can’t lose for 2022.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '5',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            type: 3,
            title: 'How Gamification can improve Mental Health.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg'
          }],
          smallCarouselContent: [{
            id: '1',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            title: 'How Gamification is helping HR Teams.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '2',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            title: 'How Gamification is helping the Health Industry.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '3',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            title: 'How Gamification and IoT work Together.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '4',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            title: 'Gamification: the secret sauce for Fintechs.',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          },
          {
            id: '5',
            imageUrl: 'assets/mock/environments/Gamifyou/banners/pexels-andrea-piacquadio-826349-1.png',
            title: 'What the Heck is Engagement?',
            iconUrl: 'assets/imgs/arrow-right-btn.svg',
          }]
        }
      }, */
      {
        bannerId: 7,
        type: BannerTypes.FEATURED_TITLE,
        content: {
          backgroundColor: '#ffffff',
          title: 'More points, more experiences.',
          fontColor: environment.brandStyleVariables.mainBrandColor,
          imageUrl: 'assets/mock/environments/Gamifyou/banners/featured-title-icon.svg',
          scrollAnimationEnabled: true
        }
      },
      {
        bannerId: 5,
        type: BannerTypes.PRODUCT_DETAIL,
        content: {
          backgroundColor: '#ffffff',
          title: 'Gamification API',
          subtitle: 'Integrated solutions',
          text: 'Our gamification API solution was built to improve your project through gamification. You have your platform, we have the solution, an easy and quick implementation way to add gamification in your business.',
          imageUrl: 'assets/mock/environments/Gamifyou/banners/product-details-main-image.png',
          iconUrls: ['assets/mock/environments/Gamifyou/banners/product-details-left-icon.png', 'assets/mock/environments/Gamifyou/banners/product-details-right-icon.png'],
          bulletPoints: [
            'Engaging Challenges',
            'Cloud Native',
            'Connection to your solutions (API)',
            'Gamification Backoffice',
            'Security and data protection best practices'
          ],
          /* incentive: {
            title: 'Get started',
            subTitle: 'Start using now!',
            description: 'See how it works!\nIn a few steps we setup your solution.'
          } */
        }
      }
    ];

    return of(environment.tenantName === 'GIVIN' ? givinData : gamifyouData);
  }

  getUserDetails(): Observable<UserDetailsDTO> {
    return of({
      id: '17820380-4b25-4e3b-ad6a-b7c29f5c9da5',
      tenantId: 'GIVIN',
      firstName: 'Gamifyou',
      lastName: 'user',
      contacts: [{
        mediumType: MediumType.emailAddress,
        preferred: true,
        characteristic: {
          emailAddress: 'user@gamifyou.com'
        }
      }],
      images: {
        coverImage: {
          href: 'assets/mock/my-account-banner.png',
          content: 'string',
          type: ContentType.IMAGE
        },
        profilePictureImage: {
          href: 'assets/mock/profile-picture.png',
          content: 'string',
          type: ContentType.IMAGE
        }
      }
    });
  }

  getMenus(): Observable<Array<MenuDTO>> {
    const givinData: Array<MenuDTO> = [
      {
        menuId: "20020080-4b25-4e3b-ad6a-b7c29f5c9df0",
        name: "Marketplace",
        viewUrl: "/marketplace",
        iconUrl: null,
        fatherId: null,
        childs: []
      },
      {
        menuId: "122341213-4b25-4e3b-ad6a-1jg1b123v12v3",
        name: "Instituições",
        viewUrl: "/institutions",
        iconUrl: null,
        fatherId: null,
        childs: []
      },
      {
        menuId: "45234123-4b25-4e3b-ad6a-123cb12b3v1",
        name: "Contact us",
        viewUrl: "/contact-us",
        iconUrl: null,
        fatherId: null,
        childs: [],

        bannerContent: {
          title: 'Contact us',
          description: null,
          backgroundImageUrl: 'assets/mock/environments/GIVIN/contact-us/contact_us_main_banner.png',
          itemImageUrl: '',
          useGradient: false
        }
      },
      {
        menuId: "123123123-4b25-4e3b-ad6a-b7c29f5c9df0",
        name: "Home",
        viewUrl: "/home",
        iconUrl: null,
        fatherId: null,
        childs: [],
        isHidden: true,

        bannerContent: {
          title: 'Bem vindo ao desafio',
          description: null,
          backgroundImageUrl: 'assets/mock/environments/Gamifyou/about-us/about_us_main_banner.png',
          itemImageUrl: null,
          useGradient: false
        }
      },
      {
        menuId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
        name: "A Minha Conta",
        viewUrl: "/my-account",
        iconUrl: null,
        fatherId: null,
        childs: [
          {
            menuId: '63eb706b-9f54-45e6-af3f-d708513dde13',
            name: "Logout",
            viewUrl: "/logout",
            iconUrl: null,
            fatherId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
            childs: [],
            isHidden: true,
          },
          {
            menuId: '8df8dfd5-0652-40b2-9af3-d1866786c46d',
            name: "As Minhas Colecções",
            viewUrl: "/my-collections",
            iconUrl: null,
            fatherId: '21eda828-ea46-4870-bdd7-d288fb6e81e7',
            childs: [],
            isHidden: true,
          }
        ],
        isHidden: true,
      }

    ];

    const gamifyouData: Array<MenuDTO> = [
      {
        menuId: "20020080-4b25-4e3b-ad6a-b7c29f5c9df0",
        name: "Our Products",
        viewUrl: "/our-products",
        iconUrl: null,
        fatherId: null,
        childs: [],

        bannerContent: null
      },
      {
        menuId: "12sefrs3123-4b25-4e3b-ad6a-23434234234",
        name: "About us",
        viewUrl: "/about-us",
        iconUrl: null,
        fatherId: null,
        childs: [],

        bannerContent: {
          title: 'About us',
          description: null,
          backgroundImageUrl: 'assets/mock/environments/Gamifyou/about-us/about_us_main_banner.png',
          itemImageUrl: null,
          useGradient: false
        }
      },
      {
        menuId: "123123123-4b25-4e3b-ad6a-b7c29f5c9df0",
        name: "Blog",
        viewUrl: "/blog",
        iconUrl: null,
        fatherId: null,
        childs: [],

        bannerContent: null
      },
      {
        menuId: "123123123-4b25-4e3b-ad6a-b7c29f545ergff0",
        name: "Contact us",
        viewUrl: "/contact-us",
        iconUrl: null,
        fatherId: null,
        childs: [],

        bannerContent: {
          title: 'Contact us',
          description: null,
          backgroundImageUrl: 'assets/mock/environments/Gamifyou/contact-us/contact_us_main_banner.png',
          itemImageUrl: 'assets/mock/environments/Gamifyou/contact-us/Gamifas-planet.png',
          useGradient: false
        }
      }
    ]

    return of(environment.tenantName === 'GIVIN' ? givinData : gamifyouData);
  }


}
