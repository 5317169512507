import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import player from 'lottie-web';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { NgProgressModule } from 'ngx-progressbar';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { CheckboxInputModule } from 'src/app/_generic-components-lib/inputs/checkbox-input-module/checkbox-input.module';
import { GenericPhoneInputModule } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input.module';
import { NumericInputModule } from 'src/app/_generic-components-lib/inputs/numeric-input-module/numeric-input.module';
import { SingleLineTextInputModule } from 'src/app/_generic-components-lib/inputs/single-line-text-input-module/single-line-text-input.module';
import { TextareaInputModule } from 'src/app/_generic-components-lib/inputs/textarea-input-module/textarea-input-module.module';
import { ListHeaderModule } from 'src/app/_generic-components-lib/list-header-module/list-header.module';
import { ListItemModule } from 'src/app/_generic-components-lib/list-item-module/list-item-module.module';
import { GenericPopupModule } from 'src/app/_generic-components-lib/popups/generic-popup-module/generic-popup.module';
import { FormValidatorsModule } from 'src/app/_shared-modules/form-validators-module/form-validators.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { SwiperModule } from 'swiper/angular';
import { ItemImageSliderModule } from '../_shared-components/item-image-slider-module/item-image-slider.module';
import { ListItemCardsModule } from '../_shared-components/list-item-cards/list-item-cards.module';
import { LoadingListModule } from '../_shared-components/loading-list-module/loading-list.module';
import { NoItemsModule } from '../_shared-components/no-items-module/no-items.module';
import { PageBannerModule } from '../_shared-components/page-banner-module/page-banner.module';
import { RiveAnimationModule } from '../_shared-components/rive-animation-module/rive-animation.module';
import { ActivitiesListContainerComponent } from './activities-list-container/activities-list-container.component';
import { ActivityListItemComponent } from './activities-list-container/activity-list-item/activity-list-item.component';
import { ActivityLoadingContentComponent } from './activities-list-container/activity-loading-content/activity-loading-content.component';
import { ActivityDetailsCardComponent } from './activity-details-container/activity-details-card/activity-details-card.component';
import { ActivityTypeInputComponent } from './activity-details-container/activity-details-card/activity-types/input/activity-type-input.component';
import { ActivityInputFormPopupComponent } from './activity-details-container/activity-details-card/activity-types/input/input-form-popup/activity-input-form-popup.component';
import { ActivitySubTasksListComponent } from './activity-details-container/activity-details-card/activity-types/multi-step/activity-sub-tasks-list/activity-sub-tasks-list.component';
import { ActivityTypeMultiStepComponent } from './activity-details-container/activity-details-card/activity-types/multi-step/activity-type-multi-step.component';
import { ActivityDetailsContainerComponent } from './activity-details-container/activity-details-container.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ActivitiesListContainerComponent,
    ActivityListItemComponent,
    ActivityLoadingContentComponent,
    ActivityDetailsContainerComponent,
    ActivityDetailsCardComponent,
    ActivityTypeInputComponent,
    ActivityTypeMultiStepComponent,
    ActivityInputFormPopupComponent,
    ActivitySubTasksListComponent
  ],
  imports: [
    CommonModule,
    LabelPipeModule,
    ListItemCardsModule,
    LoadingListModule,
    InfiniteScrollModule,
    SingleLineTextInputModule,
    PageBannerModule,
    ItemImageSliderModule,
    ButtonsModule,
    GenericPopupModule,
    NoItemsModule,
    CheckboxInputModule,
    MatProgressBarModule,
    RiveAnimationModule,
    SwiperModule,
    ReactiveFormsModule,
    ListItemModule,
    ListHeaderModule,
    GenericPhoneInputModule,
    NumericInputModule,
    TextareaInputModule,
    GenericPopupModule,
    NgProgressModule,
    FormValidatorsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [
    ActivityListItemComponent,
    ActivityLoadingContentComponent
  ],
  providers: []
})
export class ActivitiesModule { }
