import { Injectable } from "@angular/core";

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    protected readonly router: Router,
    protected jwtHelper: JwtHelperService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // if page access is not restricted, only redirect to login if user not logged in and token is valid (do auto login)
    if (!route.data["restricted"]) {
      // TODO If we have another login type implement token validation.

      return true;
    }

   // TODO Force the user to log in if currently unauthenticated.
    return true;
  }
}
