import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { GenericTabsModule } from 'src/app/_generic-components-lib/tabs/generic-tabs-module/generic-tabs.module';
import { CustomTranslateLoader } from 'src/app/_services/custom-translate-loader.service';
import { SafeHtmlPipeModule } from 'src/app/_shared-modules/safe-html-pipe-module/safe-html-pipe.module';
import { TermsAndConditionsComponent } from './terms/terms-and-conditions.component';


@NgModule({
  declarations: [
    TermsAndConditionsComponent
  ],
  imports: [
    CommonModule,
    GenericTabsModule,
    SafeHtmlPipeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    TermsAndConditionsComponent
  ],
  providers: [
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
})
export class TermsAndConditionsModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}

// In order to wait for translations and only after init the module
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    locationInitialized.then(() => {
      const langToSet = !translate.currentLang ? 'en' : translate.currentLang;
      translate.setDefaultLang(sessionLanguage? sessionLanguage : 'en');
      translate.use(langToSet).subscribe({
        error: (err => {
          console.error('Error importing translations before init ', err);
        }),
        complete: (() => {
          resolve(null);
        })
      });
    });
  });
}
