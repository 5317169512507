<div class="banners-wrapper">
  <div class="banner-container" *ngIf="isLoaded && generalService.banners.length > 0 && generalService.banners[0].type === bannerTypes.VIDEO">
    <div class="banner-video">
      <ng-container *ngIf="!mobileVideo; else mobile">
        <div class="main-banner-texts" [ngStyle]="{ 'color' : $any(generalService.banners[0].content).fontColor }" *ngIf="!$any(generalService.banners[0].content).animationDesktop; else animation">
          <p class="title" *ngIf="$any(generalService.banners[0].content).title">{{ $any(generalService.banners[0].content).title }}</p>
          <p class="subtitle" *ngIf="$any(generalService.banners[0].content).subtitle">{{ $any(generalService.banners[0].content).subtitle }}</p>
        </div>
        <ng-template #animation [ngSwitch]="$any(generalService.banners[0].content).animationDesktop?.type">
          <img *ngSwitchCase="animationTypes.GIF" width="'100%'" height="'100%'"
            [src]="$any(generalService.banners[0].content).animationDesktop.url">

          <ng-lottie *ngSwitchCase="animationTypes.LOTTIE"
            [width]="'90%'"
            [height]="'90%'"
            [options]="options">
          </ng-lottie>

          <app-rive-animation-component *ngSwitchCase="animationTypes.RIVE"
            [loop]="$any(generalService.banners[0].content).animationDesktop.loop"
            [animationFileUrl]="$any(generalService.banners[0].content).animationDesktop.url"></app-rive-animation-component>

        </ng-template>
        <video [autoplay]="true" [loop]="true" [muted]="true" playsinline id="video">
          <source [src]="$any(generalService.banners[0].content).videoUrl" type="video/mp4">
        </video>
      </ng-container>

      <ng-template #mobile>
        <div class="main-banner-texts" [ngStyle]="{ 'color' : $any(generalService.banners[0].content).fontColor }" *ngIf="!$any(generalService.banners[0].content).animationMobile; else animation">
          <p class="title" *ngIf="$any(generalService.banners[0].content).title">{{ $any(generalService.banners[0].content).title }}</p>
          <p class="subtitle" *ngIf="$any(generalService.banners[0].content).subtitle">{{ $any(generalService.banners[0].content).subtitle }}</p>
        </div>
        <ng-template #animation [ngSwitch]="$any(generalService.banners[0].content).animationMobile?.type">
          <img *ngSwitchCase="animationTypes.GIF" width="'100%'" height="'100%'"
            [src]="$any(generalService.banners[0].content).animationMobile.url">

          <ng-lottie *ngSwitchCase="animationTypes.LOTTIE"
            [width]="'100%'"
            [height]="'100%'"
            [options]="optionsMobile">
          </ng-lottie>

          <app-rive-animation-component *ngSwitchCase="animationTypes.RIVE"
            [loop]="$any(generalService.banners[0].content).animationMobile.loop"
            [animationFileUrl]="$any(generalService.banners[0].content).animationMobile.url"></app-rive-animation-component>

        </ng-template>

        <video [autoplay]="true" [loop]="true" [muted]="true" playsinline id="video-mobile">
          <source [src]="$any(generalService.banners[0].content).mobileVideoUrl" type="video/mp4">
        </video>
      </ng-template>
    </div>

    <div class="down-arrow-button">
      <img src="assets/imgs/down-arrow.svg">
    </div>
  </div>

  <div class="lp-main-container" *ngIf="isLoaded">

    <!-- switch case with every banner -->
    <ng-container *ngFor="let banner of generalService.banners; let index = index" [ngSwitch]="banner.type">
      <ng-container *ngSwitchCase="bannerTypes.DESCRIPTION_LIST">
        <app-description-list [content]="$any(banner.content)"></app-description-list>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.CAROUSEL">
        <app-carousel [content]="$any(banner.content)" *ngIf="($any(banner.content).mainCarouselContent && $any(banner.content).mainCarouselContent.length > 0)
          || ($any(banner.content).smallCarouselContent && $any(banner.content).smallCarouselContent.length > 0)"></app-carousel>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.ACTION_INCENTIVE">
        <app-action-incentive [content]="$any(banner.content)"></app-action-incentive>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.PRODUCT_DETAIL">
        <app-product-detail [content]="$any(banner.content)"></app-product-detail>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.IMAGE_CARD">
        <app-image-card [content]="$any(banner.content)"></app-image-card>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.FEATURED_TITLE">
        <app-featured-title [content]="$any(banner.content)"></app-featured-title>
      </ng-container>

      <ng-container *ngSwitchCase="bannerTypes.PARTNER_LIST">
        <app-partner-list [content]="$any(banner.content)"></app-partner-list>
      </ng-container>
    </ng-container>
  </div>
</div>

