import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LanguagesService } from 'src/app/_services/_languages/languages.service';
import { Money } from 'src/app/pages/_shared-components/generic-list-item-module/generic-list-item.model';
import { Category } from 'src/app/pages/_shared-components/list-item-cards/cardItem.model';
import { ProductPartner } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-collection-content',
  templateUrl: './collection-content.component.html',
  styleUrls: ['./collection-content.component.sass']
})
export class CollectionContentComponent implements OnInit {

  @Input() serial: string;
  @Input() purchaseDate: string;
  @Input() dateWarning: boolean;
  @Input() prices: Array<Money>;
  @Input() lowestPriceValue: number;
  @Input() partner: ProductPartner;
  @Input() quantity: number;
  @Input() quantityWarningNumber: number;
  @Input() quantityWarningColor: string;
  @Input() category: Array<Category> = [];
  @Input() isBlocked: boolean;

  public environment = environment;

  constructor(public generalService: GeneralService,
    public languageService: LanguagesService,
    public translateService: TranslateService,
    private host: ElementRef) {
      const sessionLanguage = localStorage.getItem('SessionLanguageCode');

      this.translateService.currentLang = environment.defaultLanguage;
      this.translateService.setDefaultLang(environment.defaultLanguage);
      this.translateService.use(environment.defaultLanguage);
    }

  ngOnInit(): void {
  }

}
