import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LibraryService } from '../__services/library.service';
import { AccordionComponent } from './accordion/accordion.component';



@NgModule({
  declarations: [
    AccordionComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  exports: [
    AccordionComponent
  ],
  providers: [LibraryService]
})
export class AccordionModule { }
