import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EmailAddress, FiscalAddress, UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.sass']
})

export class AccountDetailsComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() userDetails: UserDetails;
  @Input() editMode: boolean;
  @Input() resetForm: boolean;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateUserDetails: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formIsReset: EventEmitter<boolean> = new EventEmitter<boolean>();

  public accountDetailsFormContainer: FormGroup;
  private auxFormValues: FormGroup;

  public minDate: Date = new Date('01/01/1900');

  public environment = environment;

  constructor(
    private fb: FormBuilder,
    public labelService: LabelsService,
    public generalService: GeneralService,
    private translateService: TranslateService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.accountDetailsFormContainer = this.fb.group({
      accountDetailsForm: this.fb.group({
        firstname: new UntypedFormControl(this.userDetails.firstName, [
          Validators.pattern("^[a-zA-Z]+$"),
          Validators.required
        ]),
        lastname: new UntypedFormControl(this.userDetails.lastName, [
          Validators.pattern("^[a-zA-Z]+$"),
          Validators.required
        ]),
        birthdate: new UntypedFormControl(this.userDetails.birthdate, []),
        emailaddress: new UntypedFormControl((this.userDetails.emailAddress?.emailAddress as EmailAddress)?.emailAddress, [
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"),
          Validators.required
        ]),
        fiscalnumber: new UntypedFormControl(this.userDetails.fiscalNumber, [
          Validators.maxLength(9),
          Validators.minLength(9),
          Validators.required
        ]),
        addressline1: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.addressLine1 : '', []),
        addressline2: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.addressLine2 : '', []),
        postalcode: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.postalCode : '', []),
        city: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.city : '', []),
        country: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.country : '', [])
      }),
    });

    this.copyForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resetForm']) {
      if(changes['resetForm'].currentValue){
        this.accountDetailsFormContainer.reset();
        Object.keys(this.accountDetailsFormContainer.controls).forEach(key => {
          this.accountDetailsFormContainer.get(key)?.setErrors(null);
          this.accountDetailsFormContainer.get(key)?.setValue(this.auxFormValues.get(key)?.value);
        });
        this.formIsReset.emit(false);
      }
    }
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('account-details');
  }

  private copyForm(): void {
    this.auxFormValues = this.fb.group(this.accountDetailsFormContainer.getRawValue());
  }

  closeDetails(): void {
    this.buttonClick.emit(false);
  }

  saveDetails(): void {
    this.accountDetailsFormContainer.markAllAsTouched();
    this.updateUserDetails.emit(this.accountDetailsFormContainer);
  }
}
