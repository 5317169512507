import { Component, ElementRef, Input } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-tabs-base-component',
  template: '',
  styleUrls: ['./tabs-base.component.sass']
})
export class TabsBaseComponent extends BaseComponent {

  @Input() defaultBackground: string;
  @Input() defaultLightBackground: string;
  @Input() activeColor: string;

  @Input() stepHeight: string;

  constructor(override host: ElementRef, override libraryService: LibraryService) {
    super(host, libraryService);
  }

  setSpecificCSSVars(): void {
    if (this.defaultBackground || this.defaultLightBackground || this.stepHeight) {

      if (this.defaultBackground)
        this.host.nativeElement.style.setProperty('--default-color', this.libraryService.convertToHexColor(this.host, this.defaultBackground));
      if (this.defaultLightBackground)
        this.host.nativeElement.style.setProperty('--default-color-lighter', this.libraryService.convertToHexColor(this.host,this.defaultLightBackground));
      if (this.stepHeight)
        this.host.nativeElement.style.setProperty('--step-height', this.stepHeight);
      if (this.activeColor)
        this.host.nativeElement.style.setProperty('--active-font-color', this.libraryService.convertToHexColor(this.host, this.activeColor));
    }
  }
}
