import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { TabsBaseComponent } from '../../../_extended-components/tabs-base-component/tabs-base.component';
import { NavigationItem } from '../../generic-tabs-model';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/tabs-base-component/tabs-base.component.sass',
    './navigation-tabs.component.sass'
  ],
  animations: [
    trigger('FadeIn', [
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      transition('hide <=> show', animate('600ms ease-in-out')),
    ])
  ]
})
export class NavigationTabsComponent extends TabsBaseComponent implements OnInit {

  @Input() hasRouteIcon: boolean;
  @Input() routeIcon: string;

  @Input() NavMenuItems: Array<NavigationItem>;

  @Output() clickedTab: EventEmitter<string> = new EventEmitter<string>();

  activeLinkIndex: number;

  globalAnimationState: string = 'hide';

  constructor(
    private router: Router,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.activeLinkIndex = this.NavMenuItems!.indexOf(this.NavMenuItems.find((tab: NavigationItem) => tab.url === this.router.url)!);
    });

    super.asyncFunction(() => {
      this.globalAnimationState = 'show';
    }, 0);

    super.setCSSVars();
  }

  emitClickedTab(Id: string): void {
    this.clickedTab.emit(Id);
  }
}
