import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, Injectable, Optional } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, catchError, map, of, take } from 'rxjs';
import { SnackBarStatusType } from 'src/app/_generic-components-lib/snackbars-module/snackbar/snackbar.model';
import { SnackbarService } from 'src/app/_generic-components-lib/snackbars-module/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { LanguagesService } from '../_languages/languages.service';
import { ApiService } from '../api.service';
import { PopupService } from '../popup-service.service';
import {
  BannerInfo, Contact, ContactDTO,
  EmailAddress,
  FiscalAddress,
  GeneralInfo, GenericErrorReply, GetGeneralInfoReply,
  Language, LanguageDTO, MediumType, Menu, MenuBanner, MenuBannerDTO, MenuDTO,
  UserDetails, UserDetailsDTO,
  Wallet,
  errorTypes
} from './general-service.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  public static SUCCESS_CODE = 0;
  public generalInfo: GeneralInfo;

  public keycloakService: KeycloakService;

  public banners: Array<BannerInfo>;

  public userDetails$: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>({});
  public userDetails: Observable<UserDetails> = this.userDetails$.asObservable();

  public userDefaultWallet: Wallet;
  public menuList: Array<Menu> = [];

  public scrollableContainerRef: ElementRef;

  public isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUserLoggedIn: Observable<boolean> = this.isUserLoggedIn$.asObservable();

  public useKeycloak: boolean;
  public useWallet: boolean;

  private errorTitleGeneric: string;
  private errorButtonGeneric: string;

  public institutionActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public instituionActive: Observable<boolean> = this.institutionActive$.asObservable();

  public scrollFunctionsArray: Array<{ id: string, fn: () => any }> = [];

  public currentScreen$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentScreen = this.currentScreen$.asObservable();

  public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMobile: Observable<boolean> = this.isMobile$.asObservable();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private snackbarService: SnackbarService,
    private popupService: PopupService,
    private languageService: LanguagesService,
    private translateService: TranslateService,
    @Optional() private optionalKeycloakService: KeycloakService
  ) {
    this.useKeycloak = environment.useKeycloak;
    this.useWallet = environment.useWallet;

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.errorTitleGeneric = this.translateService.instant('generic_popup_error_title');
    this.errorButtonGeneric = this.translateService.instant('generic_popup_error_button');

    if(environment.useKeycloak) {
      this.keycloakService = optionalKeycloakService;
    }

    window.addEventListener('resize', () => {
      this.isMobile$.next(window.innerWidth / window.innerHeight <= 1 || window.innerWidth <= 760);
    });
  }

  // Generic async function that receives a function as a parameter and sets a timeout
  public asyncFunction(delayedFn: () => any, timeout: number = 0): any {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), timeout)).then(() => {
        delayedFn();
    })})();
  }

  /**
   * Checks if element is currently in view
   *
   * @param elementRef
   * @returns {isInView: boolean, topShown: boolean, bottomShown: boolean, percentageShown: number}
   */
  public isElementInView(elementRef: ElementRef): {isInView: boolean, topShown: boolean, bottomShown: boolean, percentageShown: number} {
    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const rect = elementRef.nativeElement.getBoundingClientRect();

    const topShown = rect.top <= viewportHeight && rect.top >= 0;
    const bottomShown = rect.bottom <= viewportHeight && rect.bottom >= 0;

    // height of intersection between element and viewport
    const intersectionHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
    const percentageShown = intersectionHeight / rect.height * 100;

    return {isInView: topShown || bottomShown, topShown: topShown, bottomShown: bottomShown, percentageShown: percentageShown};
  }

  /**
   * add a event to page scroll. Used mainly for scroll animations (ex: paralax effects)
   *
   * @param fnToAdd { id: string, fn: () => any }
   */
  public addScrollableEvent(fnToAdd: { id: string, fn: () => any }): void {
    let eventFound = this.scrollFunctionsArray.findIndex(event => event.id === fnToAdd.id);

    if (eventFound !== -1) {
      this.scrollFunctionsArray.splice(eventFound, 1);
    }

    this.scrollFunctionsArray.push(fnToAdd);
  }

  public scrollEventListener(): void {
    const scrollableContainer: HTMLElement = this.scrollableContainerRef.nativeElement;

    scrollableContainer.addEventListener('scroll', () => {
      this.scrollFunctionsArray.forEach(f => {
        f.fn();
      });
    }, { passive: true});

    scrollableContainer.addEventListener('touchmove', () => {
      this.scrollFunctionsArray.forEach(f => {
        f.fn();
      });
    }, { passive: true});
  }

  public navigateTo(url: string, external: boolean = false): void {
    if (external) {
      window.location.href = url;
    } else {
      this.getUserDetails().pipe(take(1)).subscribe();
      this.router.navigate([url]);
      this.autoScrollTop('page-router-container')
    }
  }

  public navigateToWithQueryParams(url: string, params: Params): void {
    this.getUserDetails().pipe(take(1)).subscribe();
    this.router.navigate([url], { queryParams: params });
  }

  private handleErrors(data: GenericErrorReply, formOpenSnackbar: boolean = false): void {
    // TODO colocar switch case do isValidServerReply apos alterações do BE e retirar do isValidServerReply e chamar o handleErrors;
  }

  public isValidServerReply(data: any | GenericErrorReply, formOpenSnackbar: boolean = false): boolean {
    if (data && data.hasOwnProperty('errorCode')) {
      switch (data.errorType) {
        case errorTypes.FORM_ERROR:
          // TODO probably will be handled on the api call itself
          if (formOpenSnackbar) {
            this.snackbarService.openSnackBar(data.detail, '', SnackBarStatusType.error, 5000, 'bottom', 'center', 'assets/imgs/error-icon.svg');
          }
          break;
        case errorTypes.SNACKBAR_ERROR:
          // open a snackbar with the error
          this.snackbarService.openSnackBar(data.detail, '', SnackBarStatusType.error, 5000, 'bottom', 'center', 'assets/imgs/error-icon.svg');
          break;
        case errorTypes.POPUP_ERROR:
          // open a popup with the error message
          this.popupService.setPopupToOpen(
            {
              text: this.errorTitleGeneric
            },
            'assets/imgs/environments/' + environment.tenantName + '/popup-error-image.png',
            {text: ''},
            [this.popupService.getSimpleDescription(data.detail)],
            // [this.popupService.getSimpleDescription('Tenta novamente mais tarde.')],
            [{
              text: 'OK',
              isCloseBtn: true,
              actionText: '',
              style: {
                backgroundColor: 'var(--main-brand-color)',
                fontColor: '#ffffff'
              }
            }]
          );
          break;
        case errorTypes.BREAKING_POPUP_ERROR:
          // open a popup that refreshes page on close
          this.popupService.setPopupToOpen(
            {
              text: this.errorTitleGeneric
            },
            'assets/imgs/environments/' + environment.tenantName + '/popup-error-image.png',
            {text: ''},
            // TODO [this.popupService.getSimpleDescription(data.detail)],
            [this.popupService.getSimpleDescription('Tenta novamente mais tarde.')],
            [{
              text: this.errorButtonGeneric,
              isCloseBtn: false,
              actionText: 'breakingError',
              style: {
                backgroundColor: 'var(--main-brand-color)',
                fontColor: '#ffffff'
              }
            }]
          );
          break;
        default:
          break;
      }
    }

    return !data || !data.hasOwnProperty('errorCode');
  }

  public getGeneralInfo(): Observable<GeneralInfo> {
    return this.apiService.get('General/GetInfo', {}, '1.0', true, environment.useMockedData.generalGetInfo).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: GetGeneralInfoReply | GenericErrorReply) => {
        if (this.isValidServerReply(response)) {
          const info = response as GetGeneralInfoReply;
          this.generalInfo = info;
          // TODO Handle response
          return info;
        } else {
          throw response;
        }
      }));
  }

  public getLandingPageBanners(): Observable<Array<BannerInfo>> {
    if (this.banners != null) {
      return of(this.banners);
    }

    return this.apiService.get(`tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/banners`, {}, '1.0', true, environment.useMockedData.generalGetLPBanners).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error.error);
            throw error.message;
          }
        ),
      map((response: Array<BannerInfo> | GenericErrorReply) => {
        if (this.isValidServerReply(response)) {
          this.banners = (response as Array<BannerInfo>);
          return this.banners;
        } else {
          throw response;
        }
      }));
  }

  public getUserDetails(): Observable<UserDetails> {
    return this.apiService.get('user-management/user/me', {}, '1.0', true, environment.useMockedData.generalGetUserDetails).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: UserDetailsDTO | GenericErrorReply) => {
        if(this.isValidServerReply(response)) {
          const userDetailsDTO = (response as UserDetailsDTO)
          this.userDetails$.next(this.getUserDetailsFromDTO(userDetailsDTO));
          this.userDefaultWallet = this.userDetails$.value.wallets![0];

          return this.userDetails$.value;
        } else {
          throw response;
        }
      }));
  }

  private getUserDetailsFromDTO(dto: UserDetailsDTO): UserDetails {
    let userDetails: UserDetails;

    userDetails = {
      tenantId: dto.tenantId,
      firstName: dto.firstName,
      lastName: dto.lastName,
      fiscalNumber: dto.fiscalNumber,
      birthdate: dto.birthDate ? new Date(dto.birthDate) : undefined,
      emailAddress: this.getContactFromDTO(dto.contacts?.find(c => c.mediumType === 'EMAIL_ADDRESS')),
      fiscalAddress: this.getContactFromDTO(dto.contacts?.find(c => c.mediumType === 'FISCAL_ADDRESS')),
      images: dto.images,
      showTutorial: dto.showTutorial,
      showOnboarding: dto.showOnboarding,
      category: dto.category,
      wallets: dto.wallets,
      goalId: dto.goalId
    }

    return userDetails;
  }

  private getContactFromDTO(dto: ContactDTO | undefined): Contact | undefined{
    let contact: Contact;

    if(dto) {
      contact = {
        mediumType: dto.mediumType,
        preferred: dto.preferred,
        emailAddress: dto.mediumType === 'EMAIL_ADDRESS' ? (dto.characteristic as EmailAddress) : undefined,
        fiscalAddress: dto.mediumType === 'FISCAL_ADDRESS' ? (dto.characteristic as FiscalAddress) : undefined,
      }
      return contact;
    }

    return undefined;
  }

  private getContactDTOFromContact(contact: Contact): ContactDTO {
    let contactDTO: ContactDTO;

    switch (contact.mediumType) {
      case MediumType.emailAddress:
        contactDTO = {
          mediumType: contact.mediumType,
          preferred: contact.preferred,
          characteristicObject: {
            emailAddress: contact.emailAddress!.emailAddress
          }
        }
        break;
      case MediumType.fiscalAddress:
        contactDTO = {
          mediumType: contact.mediumType,
          preferred: contact.preferred,
          characteristicObject: (contact.fiscalAddress as FiscalAddress),
        }
        break;
    }

    return contactDTO;
  }

  public getUserDetailsDTOFromDetails(details: UserDetails): UserDetailsDTO {
    let userDetailsDTO: UserDetailsDTO;
    let contacts: Array<ContactDTO> | undefined = undefined;

    if (details.emailAddress) {
      contacts = [this.getContactDTOFromContact(details.emailAddress)];
    }
    if (details.fiscalAddress) {
      if (!contacts) contacts = [];
      contacts.push(this.getContactDTOFromContact(details.fiscalAddress));
    }

    userDetailsDTO = {
      tenantId: details.tenantId,
      firstName: details.firstName,
      lastName: details.lastName,
      fiscalNumber: details.fiscalNumber,
      birthDate: details.birthdate?.toISOString(),
      contacts: contacts,
      images: details.images,
      showTutorial: details.showTutorial,
      showOnboarding: details.showOnboarding,
      goalId: details.goalId
    }

    return userDetailsDTO;
  }

  public updateUserDetails(details: UserDetails): Observable<null> {
    const detailsDTO = this.getUserDetailsDTOFromDetails(details);

    return this.apiService.patch('user-management/user/me', detailsDTO, '1.0', true, environment.useMockedData.generalUpdateUserDetails).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(!response || this.isValidServerReply(response)) {
          return null;
        } else {
          throw response;
        }
      }));
  }

  public getMenus(): Observable<Array<Menu>> {
    return this.apiService.get(`tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/menus?type=APP`, {}, '1.0', true, environment.useMockedData.generalGetMenus).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<MenuDTO> | GenericErrorReply) => {
        if(this.isValidServerReply(response)) {
          this.menuList = [];
          (response as Array<MenuDTO>).forEach((dto: MenuDTO) => {
            this.menuList.push({
                menuId: dto.menuId,
                name: dto.name,
                viewUrl: dto.viewUrl,
                iconUrl: dto.iconUrl ? dto.iconUrl : undefined,
                fatherId: dto.fatherId ? dto.fatherId : undefined,
                childs: dto.childs!.length > 0 ? (dto.childs as Array<Menu>) : undefined,
                isHidden: dto.isHidden || dto.viewUrl === '/my-account',

                bannerContent: dto.bannerContent ? this.getMenuMainBannerFromDTO(dto.bannerContent): undefined
            })
          });
          return this.menuList;
        } else {
          throw response;
        }
      })
    )
  }

  private getMenuMainBannerFromDTO(dto: MenuBannerDTO): MenuBanner {
    return {
      title: dto.title ? dto.title : undefined,
      description: dto.description ? dto.description : undefined,
      backgroundImageUrl: dto.backgroundImageUrl,
      itemImageUrl: dto.itemImageUrl ? dto.itemImageUrl : undefined,
      useGradient: dto.useGradient
    }
  }

  public registerNewsletter(userEmail: string): Observable<string> {
    const body = {
      email: userEmail
    };

    // return this.apiService.post(`general/newsletter`, body, '1.0', true, environment.useMockedData.generalRegisterNewsletter).pipe(
    return this.apiService.post(`tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/newsletter`, body, '1.0', true, environment.useMockedData.generalRegisterNewsletter).pipe(
      catchError(
        (error: HttpErrorResponse) => {

            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(!response || this.isValidServerReply(response)) {
          return 'Success';
        } else {
          throw response;
        }
      })
    )
  }

  public getLanguages(): Observable<Array<Language>> {
    return this.apiService.get(`tenant-management/public/tenant/languages`, {}, '1.0', true, environment.useMockedData.generalGetLanguages).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<LanguageDTO> | GenericErrorReply) => {
        if(this.isValidServerReply(response)) {
          (response as Array<LanguageDTO>).forEach((dto: LanguageDTO) => {
            this.languageService.languageList.push({
              languageId: dto.languageId,
              code: dto.code,
              name: dto.name,
              image: dto.image ? dto.image : undefined,
              isDefault: dto.isDefault
            })
          });
          this.languageService.selectedLanguage = this.languageService.languageList.find(lang => lang.isDefault === true);
          return this.languageService.languageList;
        } else {
          throw response;
        }
      })
    )
  }

  createDateString(dateString: string, monthFormat: Intl.DateTimeFormatOptions = {month: 'long'}): string {
    const date = new Date(dateString);
    const monthString = date.toLocaleString(this.getLocales(this.languageService.selectedLanguage?.code!), monthFormat);

    return `${date.getDate()} ${monthString.charAt(0).toUpperCase()}${monthString.slice(1)}, ${date.getFullYear()}`;
  }

  getLocales(code: string): string {
    switch(code) {
      case 'EN':
        return 'en-US';

      case 'PT':
        return 'pt-PT';

      default:
        return 'en-US';
    }
  }

  autoScrollTop(element: string, isId: boolean = false): void {
    this.asyncFunction(() => {
      if (isId) {
        document.getElementById(element)!.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      } else {
        document.getElementsByClassName(element)[0].scroll({
          behavior: 'smooth',
          top: 0
        });
      }
    }, 0);
  }

  public userLogin(): void {
    this.keycloakService.login({
      // force go to login page even if it could login without that flow
      prompt: 'login',

      // if current page is '' (landing-page) and there is a 'home' page, after login it should redirect to it,
      // else stay in the same page
      redirectUri:
        window.location.pathname === '' && this.menuList.some(m => m.viewUrl === '/home') ?
        (window.location.origin + '/home') : window.location.href
    });
  }

  public userLogout(logoutUrl: string | undefined): void {
    if (localStorage.getItem(environment.keycloakConfig.clientId + '-jwt') && this.keycloakService.isTokenExpired()) {
      localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
      this.navigateTo(logoutUrl ? logoutUrl : '');
    } else {
      localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
      this.keycloakService.logout(logoutUrl);
    }
  }

  // Takes a translationKey and returns an object to be used by the | i18nPlural pipe and combining the translate pipe for the actual translation
  public getPluralKey(translationKey: string): {[n: string]: string} {
    return {
      "=0": `${translationKey}.zero`,
      "=1": `${translationKey}.one`,
      "other": `${translationKey}.other`
    }
  }

  // Takes a css expression (it can handle any variable value + any expression like calc and max/min) and returns its computed value
  public getCssComputedValueFromExpression(expression: string): number {
    let tempElement = document.createElement('div');
    tempElement.style.visibility = 'hidden';
    tempElement.style.position = 'absolute';
    tempElement.style.width = '100%';
    tempElement.style.width = `calc(${expression})`;

    document.body.appendChild(tempElement);
    let finalValue = parseFloat(getComputedStyle(tempElement).width);
    document.body.removeChild(tempElement);

    return finalValue;
  }
}
