
<div class="action-incentive-container">
  <img class="background-image-container w-100" id="action-image-container" *ngIf="content.imageUrl" [src]="content.imageUrl">

  <div class="action-text-container" id="action-text-container">
    <span class="action-title">{{content.title}}</span>
    <span class="action-desc" *ngIf="content.description">{{content.description}}</span>

    <app-generic-button class="action-button" *ngIf="content.actionText"
      [buttonName]="content.actionText" [hasIcon]="content.actionIconUrl != null"
      [iconClass]="content.actionIconUrl ? content.actionIconUrl : ''"
      [fontSize]="'var(--small-font-size)'" [fontWeight]="'800'"
      [borderRadius]="'calc(var(--small-font-size) * 3)'"
      [buttonSpacing]="'0.55vw 1.5vw'"
      [fontColor]="'white'"
      [defaultIconColor]="'white'"
      (buttonAction)="actionClicked()">
    </app-generic-button>
  </div>
</div>
