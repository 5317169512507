import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActionIncentiveBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LandingPageService } from '../../landing-page.service';

@Component({
  selector: 'app-action-incentive',
  templateUrl: './action-incentive.component.html',
  styleUrls: ['./action-incentive.component.sass']
})
export class ActionIncentiveComponent {

  @Input() content: ActionIncentiveBanner

  constructor(
    private host: ElementRef<HTMLElement>,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if (this.content.backgroundColor) {

      //set variables
      if (this.content.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.content.backgroundColor);

      if (this.content.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.content.fontColor);
    }
  }

  public actionClicked(): void {
    if (this.content.actionUrl)
      this.generalService.navigateTo(this.content.actionUrl, this.content.actionUrl.includes('http'));
  }
}
