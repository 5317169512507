<ng-container *ngIf="loaded">
  <div class="backdrop-container" [@BackdropFadeIn]="popupAnimationState"></div>

  <div class="tutorial-popup-container" [@ZoomIn]="popupAnimationState">
    <!-- ===================== LEFT SIDE ===================== -->
    <div class="tutorial-explanation-container">
      <img [src]="'assets/imgs/environments/'+ environment.tenantName +'/negative-logo.svg'" class="brand-logo" *ngIf="!(generalService.isMobile | async)">

      <div class="text-container">
        <p class="sub-title" *ngIf="!(generalService.isMobile | async) || ((generalService.isMobile | async) && tutorialSteps[currentStep].subtitle)">{{ tutorialSteps[currentStep].subtitle }}</p>
        <p class="title" *ngIf="!(generalService.isMobile | async) || ((generalService.isMobile | async) && tutorialSteps[currentStep].title)">{{ tutorialSteps[currentStep].title }}</p>
        <p class="description" *ngIf="!(generalService.isMobile | async) || ((generalService.isMobile | async) && !tutorialSteps[currentStep].subtitle && tutorialSteps[currentStep].description)">{{ tutorialSteps[currentStep].description }}</p>
      </div>

      <app-generic-button class="jump-tutorial-button" *ngIf="!(generalService.isMobile | async) && currentStep !== totalSteps"
        [buttonName]="labelService.tutorialSkipTutorialLabel | label"
        [hasIcon]="true"
        [iconSize]="'var(--large-section-title-font-size)'"
        [iconClass]="'bi bi-arrow-right-short'"
        [fontColor]="'white'"
        [fontSize]="'var(--header-font-size)'"
        [fontWeight]="'600'"
        [iconColor]="'white'"
        [backgroundColor]="'transparent'"
        [brandColor]="'transparent'"
        [buttonSpacing]="'0px'"
        (buttonAction)="skipTutorial()">
      </app-generic-button>
    </div>

    <!-- ===================== RIGHT SIDE ===================== -->
    <div class="tutorial-image-container">
      <ng-container [ngSwitch]="tutorialSteps[currentStep].image.type">
        <img [src]="tutorialSteps[currentStep].image.url" *ngSwitchCase="imageType.IMAGE">

        <ng-lottie *ngSwitchCase="imageType.LOTTIE"
          [width]="'100%'"
          [height]="'100%'"
          [options]="{
            autoplay: true,
            loop: false,
            path: tutorialSteps[currentStep].image.url
          }">
        </ng-lottie>

        <canvas #canvasRive *ngSwitchCase="imageType.RIVE">
        </canvas>
      </ng-container>

      <div class="steps-container">
        <div class="prev-step-btn" *ngIf="!(generalService.isMobile | async) && currentStep !== 0">
          <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="'#323232'"
            [backgroundColor]="'#323232'"
            [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--small-font-size)'" [fontColor]="'white'" [buttonName]="labelService.tutorialPrevStepButtonLabel | label"
            [buttonSize]="'min(160px, 17.5vw)'" [minHeight]="'48px'" (click)="changeStep(-1)">
          </app-generic-button>
        </div>

        <div class="step-counter-container">
          <div class="step-circle" *ngFor="let step of tutorialSteps" [ngStyle]="{'background-color': step.selectedStep ? 'var(--main-brand-color)': '#EDEFF2' }"></div>
        </div>

        <div class="next-step-btn" [ngClass]="{'first-step-btn' : currentStep === 0}" *ngIf="!(generalService.isMobile | async)">
          <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--small-font-size)'" [fontColor]="'white'" [buttonName]="currentStep !== 0 ? (currentStep !== totalSteps ? (labelService.tutorialNextStepButtonLabel | label) : (labelService.tutorialLastStepButtonLabel | label)) : labelService.tutorialFirstStepButtonLabel | label"
            [buttonSize]="'min(160px, 17.5vw)'" [minHeight]="'48px'" (click)="currentStep !== totalSteps ? changeStep(1) : closeTutorial()">
          </app-generic-button>
        </div>
      </div>

      <div class="mobile-steps-container mt-3" *ngIf="(generalService.isMobile | async)">
        <app-generic-button class="jump-tutorial-button" *ngIf="currentStep === 0"
          [buttonName]="labelService.tutorialSkipTutorialLabel | label"
          [hasIcon]="true"
          [minHeight]="'100%'"
          [iconSize]="'var(--large-section-title-font-size)'"
          [iconClass]="'bi bi-arrow-right-short'"
          [fontColor]="'var(--main-brand-color)'"
          [fontSize]="'var(--main-info-font-size)'"
          [fontWeight]="'600'"
          [iconColor]="'var(--main-brand-color)'"
          [backgroundColor]="'transparent'"
          [brandColor]="'transparent'"
          [buttonSpacing]="'0px'"
          [buttonSize]="'min(125px, 35vw)'"
          (buttonAction)="skipTutorial()">
        </app-generic-button>

        <div class="prev-step-btn" *ngIf="currentStep !== 0">
          <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="'#323232'"
            [backgroundColor]="'#323232'"
            [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--main-info-font-size)'" [fontColor]="'white'" [buttonName]="labelService.tutorialPrevStepButtonLabel | label"
            [buttonSize]="'min(125px, 35vw)'" [minHeight]="'48px'" (click)="changeStep(-1)">
          </app-generic-button>
        </div>

        <div class="next-step-btn">
          <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--main-info-font-size)'" [fontColor]="'white'"
            [buttonName]="currentStep !== 0 ? (currentStep !== totalSteps ? (labelService.tutorialNextStepButtonLabel | label) : (labelService.tutorialLastStepButtonLabel | label)) : labelService.tutorialFirstStepButtonLabel | label"
            [buttonSize]="'min(125px, 35vw)'" [minHeight]="'48px'" (click)="currentStep !== totalSteps ? changeStep(1) : closeTutorial()">
          </app-generic-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
