import { Component, ElementRef, OnInit } from '@angular/core';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  selector: 'app-generic-icon-button',
  templateUrl: './generic-icon-button.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './generic-icon-button.component.sass'
  ]
})
export class GenericIconButtonComponent extends ButtonBaseComponent implements OnInit {

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }
}
