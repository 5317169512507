import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, map, Observable, Subject } from 'rxjs';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { ContentType, ContentTypeEnum, ImageContent, ImageContentDTO } from '../../../marketplace/_services/_products/products.service.model';
import { environment } from 'src/environments/environment';
import { ProductCode, ProductCodeDTO, ProductHistoryItem, ProductHistoryItemDTO } from './product-history.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsHistoryService {

  private endOfScrollReached$ = new Subject<boolean>();
  public infiniteScrollActive:boolean = false;

  public currentPageIndex: number = 0;
  private pageSize: number = 16;
  public totalPages: number = 1;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService) {}

  public getUserProductsList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<ProductHistoryItem>> {
    const currentParams: Params = {
      ...params,
      pageSize: pageSize ? pageSize : this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`product-catalog/product/me`, currentParams, '1.0', false, environment.useMockedData.historyGetProductsList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
    // map((response: GetProductListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
      map((response: Array<ProductHistoryItemDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetProductListReply;
          response = response as Array<ProductHistoryItemDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const productListDTO = response.items;
          const productListDTO = response;
          const productList = this.getProductListFromDTO(productListDTO);
          return productList;
        } else {
          throw response;
        }
      }
    ));
  }

  private getProductListFromDTO(dto: Array<ProductHistoryItemDTO>): Array<ProductHistoryItem> {
    let productList: Array<ProductHistoryItem> = [];

    dto.forEach((productDto: ProductHistoryItemDTO) => {
      productList.push({
        productId: productDto.productId,
        name: productDto.name,
        quantity: productDto.quantity,
        expirationDate: productDto.expirationDate,
        images: productDto.images ? this.getProductImageContentFromDTO(productDto.images) : [],
      });
    });

    return productList;
  }

  private getProductImageContentFromDTO(imagesDto: Array<ImageContentDTO>): Array<ImageContent> {
    let images: Array<ImageContent> = [];

    imagesDto.forEach(dto => {
      images.push({
        href: dto.href,
        content: dto.content ? dto.content : '',
        type: ContentTypeEnum[dto.type as ContentType],
      });
    });

    return images;
  }

  public getProductCodes(productId: string): Observable<Array<ProductCode>> {
    return this.apiService.get(`product-catalog/product/${productId}/me`, {}, '1.0', false, environment.useMockedData.historyGetProductsList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
    // map((response: GetProductListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
      map((response: Array<ProductCode> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          response = response as Array<ProductCode>;

          const productListDTO = response;
          const productList = this.getProductCodeListFromDTO(productListDTO);
          return productList;
        } else {
          throw response;
        }
      }
    ));
  }

  public getProductCodeListFromDTO(listDTO: Array<ProductCodeDTO>): Array<ProductCode> {
    let productCodeList: Array<ProductCode> = [];

    listDTO.forEach(dto => {
      productCodeList.push(dto);
    });

    return productCodeList;
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
