import { Component, Input, OnInit } from '@angular/core';
import { ChartContainer, SizeTypes } from '../dashboard.model';

@Component({
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.sass']
})
export class DashboardContainerComponent implements OnInit {

  @Input() charts: Array<ChartContainer>;

  public sizeTypes = SizeTypes;

  constructor() { }

  ngOnInit(): void {
  }

}
