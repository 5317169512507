import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HtmlContents } from 'src/app/pages/terms-and-conditions/terms.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MockTermsAndConditionsService {

  constructor() { }


  getTerms(): Observable<Array<HtmlContents>> {
    if (environment.tenantName === 'GIVIN') {
      return of([
        {
          id: "1",
          type: 'pp',
          html:
          `<html>

          <head>
          <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
          <meta name=Generator content="Microsoft Word 15 (filtered)">
          <style>
          <!--
          /* Font Definitions */
          @font-face
            {font-family:Wingdings;
            panose-1:5 0 0 0 0 0 0 0 0 0;}
          @font-face
            {font-family:"Cambria Math";
            panose-1:2 4 5 3 5 4 6 3 2 4;}
          @font-face
            {font-family:Calibri;
            panose-1:2 15 5 2 2 2 4 3 2 4;}
          @font-face
            {font-family:"Segoe UI Symbol";
            panose-1:2 11 5 2 4 2 4 2 2 3;}
          /* Style Definitions */
          p.MsoNormal, li.MsoNormal, div.MsoNormal
            {margin-top:0in;
            margin-right:.1pt;
            margin-bottom:.25pt;
            margin-left:36.55pt;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            color:black;}
          h2
            {mso-style-link:"Heading 2 Char";
            margin-top:2.0pt;
            margin-right:.1pt;
            margin-bottom:0in;
            margin-left:36.55pt;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            page-break-after:avoid;
            font-size:13.0pt;
            font-family:"Calibri Light",sans-serif;
            color:#2F5496;
            font-weight:normal;}
          a:link, span.MsoHyperlink
            {color:#0563C1;
            text-decoration:underline;}
          p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
            {margin-top:0in;
            margin-right:.1pt;
            margin-bottom:.25pt;
            margin-left:.5in;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            color:black;}
          p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
            {margin-top:0in;
            margin-right:.1pt;
            margin-bottom:0in;
            margin-left:.5in;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            color:black;}
          p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
            {margin-top:0in;
            margin-right:.1pt;
            margin-bottom:0in;
            margin-left:.5in;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            color:black;}
          p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
            {margin-top:0in;
            margin-right:.1pt;
            margin-bottom:.25pt;
            margin-left:.5in;
            text-align:justify;
            text-justify:inter-ideograph;
            text-indent:-.5pt;
            line-height:112%;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            color:black;}
          span.Heading2Char
            {mso-style-name:"Heading 2 Char";
            mso-style-link:"Heading 2";
            font-family:"Calibri Light",sans-serif;
            color:#2F5496;}
          .MsoPapDefault
            {margin-bottom:8.0pt;
            line-height:107%;}
          @page WordSection1
            {size:595.25pt 842.0pt;
            margin:74.05pt 71.6pt 83.85pt 72.05pt;}
          div.WordSection1
            {page:WordSection1;}
          /* List Definitions */
          ol
            {margin-bottom:0in;}
          ul
            {margin-bottom:0in;}
          -->
          </style>

          </head>

          <body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

          <div class=WordSection1>

          <p class=MsoNormal style='margin-left:0in;text-indent:0in'><b><span
          style='font-size:14.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>GIVIN - Política de Privacidade </span></b></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
          107%'> </p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 1.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Objeto</span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'> </span></b></h2>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT> </span><span lang=PT style='font-size:11.0pt;
          line-height:107%'> </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:.25pt;
          margin-left:22.5pt'><span lang=PT style='font-family:"Arial",sans-serif'>A
          presente Política de Privacidade destina-se a clarificar o acesso e o
          tratamento de Dados Pessoais no âmbito da utilização da plataforma GIVIN
          fornecida pela Fast Givin, LDA, sendo disponibilizada a todos os agentes que
          interagem com a plataforma, entre eles, os utilizadores e os parceiros. A aceitação
          e utilização da plataforma indica expressamente o consentimento perante a
          informação aqui disposta.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif;
          color:#4471C4'> </span><span lang=PT style='font-family:"Arial",sans-serif;
          color:#4472C4'> </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:.25pt;
          margin-left:22.5pt;text-indent:0in'><span style='font-family:"Arial",sans-serif'>Entende-se
          por:  </span></p>

          <p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:.15pt;
          margin-bottom:.25pt;margin-left:22.5pt;text-indent:4.5pt'><span lang=PT
          style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Parceiros –
          as Instituições aderentes à plataforma;  </span></p>

          <p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:.15pt;
          margin-bottom:.25pt;margin-left:22.5pt;text-indent:4.5pt'><span lang=PT
          style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Utilizadores
          – o sujeito em nome individual que utilizará a plataforma para realizar a
          doação.  </span></p>

          <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
          0in;line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%'> </span></p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 2.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Recolha e
          transferência de Dados Pessoais</span></b><b><span lang=PT style='font-size:
          11.0pt;line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> </span></b></h2>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%'> </span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os dados
          recolhidos e gerados com a utilização do GIVIN destinam-se à prestação do
          serviço descrito nos termos e condições da plataforma, sendo processados
          automaticamente pela Fast Givin, LDA, nos termos da legislação aplicável,
          garantindo a Fast Givin, LDA, os adequados níveis de privacidade e segurança no
          processamento dos mesmos.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:2.3pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Arial",sans-serif'>Para
          funcionamento da GIVIN, o utilizador deve facultar os dados mencionados infra,
          consentindo a sua utilização perante a finalidade de prestação do serviço:  </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.25pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Dados de contacto incluindo: Nome,
          e-mail, número de telefone e morada. </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.5pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Dados de login e informação de conta
          (nome de utilizador, palavra-passe, ID…). </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:2.3pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Dados base incluindo a data de
          nascimento, cidade, nacionalidade, sendo esta informação de caráter opcional. </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.3pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Dados de pagamento e informação fiscal
          (NIF e telemóvel usado no MBWAY). </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:.25pt;
          margin-left:40.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Preferências do utilizador incluindo
          cookies e preferências de marketing. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          1.5pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os dados
          trabalhados no âmbito da GIVIN, são única e exclusivamente recolhidos junto do
          seu titular, por via informática e diretamente na plataforma, consentindo o
          titular a sua utilização ao subscrever a participação na plataforma. Para
          utilizarem a plataforma, os utilizadores têm de efetuar um registo obrigatório
          onde introduzem os dados pessoais estritamente necessários para a correta e
          segura utilização da plataforma. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Ao fazer o
          seu registo diretamente na GIVIN o utilizador autoriza a integração dos seus
          dados na base de dados da Fast Givin, LDA, comprometendo-se a mesma a proceder
          ao tratamento de dados de forma lícita e com respeito pela legislação em vigor.
          </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.85pt;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
          Givin, LDA não terá qualquer intervenção na transferência de dados entre o
          utilizador e o parceiro.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Não será
          possível a utilização da plataforma GIVIN por menores, inviabilizando deste
          modo a recolha de dados desta categoria de sujeitos.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.85pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
          107%'><span lang=PT> </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
          107%'><span lang=PT> </span></p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 3.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Tratamento de
          Dados pessoais</span></b><b><span lang=PT style='font-size:11.0pt;line-height:
          112%;font-family:"Arial",sans-serif;color:windowtext'> </span></b></h2>

          <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
          0in;line-height:107%'><span lang=PT> </span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os dados
          pessoais são tratados com base no princípio de minimização, sob a finalidade de
          prestação do serviço.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Todos dados
          indicados na cláusula 2.ª, com exceção dos dados de pagamento, serão
          processados e armazenados nos sistemas e bases de dados da Fast Givin, LDA.
          Para avançar com a doação, devem ser introduzidos nos campos alvo na
          plataforma. Os dados de pagamento serão processados via plataformas de gateway
          de pagamentos, sendo o seu processamento e recolha da responsabilidade da
          Organização à qual o utilizador escolhe realizar a doação, consentindo o seu
          tratamento.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT> </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:1.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
          107%'><span lang=PT> </span></p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 4.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Práticas de
          Segurança</span></b><b><span lang=PT style='font-size:11.0pt;line-height:112%;
          font-family:"Arial",sans-serif;color:windowtext'> </span></b></h2>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT> </span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
          Givin, LDA, implementa as medidas técnicas, físicas e administrativas de
          segurança de modo a mitigar o risco de perda, mau uso, utilização não
          autorizada, divulgação ou modificação da informação.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:1.05pt;margin-left:40.5pt;text-align:left;text-indent:2.25pt;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A informação
          facultada pelo utilizador, será retida pelo tempo necessário para garantir a
          disponibilização do serviço pretendido, bem como, para garantia de melhoria da
          experiência do utilizador.     </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:2.25pt;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          0in;margin-left:40.5pt;text-indent:-.25in;line-height:115%'><span lang=PT
          style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os dados
          estão guardados em recursos de cloud AWS que, para além das medidas de
          segurança garantidas pela própria AWS, serão protegidos pelo uso de
          autenticação multifator para acesso aos mesmos. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:40.5pt;text-align:left;text-indent:2.25pt;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Se a base de
          dados for comprometida, os dados de acesso à plataforma estão protegidos por
          encriptação, impossibilitando o acesso e utilização dos mesmos. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.85pt;margin-left:40.5pt;text-align:left;text-indent:2.25pt;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
          Givin, LDA não se responsabiliza pela segurança dos dados facultados direta ou
          indiretamente ao Parceiro, cabendo a este último garantir nos seus sistemas a
          proteção dos dados em sua posse.  </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:40.5pt;text-align:left;text-indent:2.25pt;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:40.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>O utilizador
          é o único responsável pela segurança da sua palavra-passe, bem como pelo uso da
          sua conta. Para tal, não deve em caso algum partilhar a sua conta pessoal, bem
          como os seus dados de acesso. Caso, por qualquer que seja o motivo, as
          informações de login sejam comprometidas ou a conta seja extraviada, o
          utilizador tem a responsabilidade de contactar a GIVIN, a fim de solucionar a
          questão, através do e-mail: </span><u><span lang=PT style='font-family:"Arial",sans-serif;
          color:#0563C1'><a href="mailto:support@givin.io">support@givin.io</a></span></u><span
          lang=PT style='font-family:"Arial",sans-serif'>. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:.5in;text-align:left;text-indent:0in;line-height:
          107%'><span lang=PT>&nbsp;</span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT>&nbsp;</span></p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 5.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Decisões
          Automatizadas </span></b><b><span lang=PT style='font-size:11.0pt;line-height:
          112%;font-family:"Arial",sans-serif;color:windowtext'> </span></b></h2>

          <p class=MsoNormal><span lang=PT>&nbsp;</span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:13.8pt;
          margin-left:.5pt'><span lang=PT style='font-family:"Arial",sans-serif'>A
          plataforma GIVIN tem processos que não necessitam de intervenção manual que são
          executados com base nas interações entre o utilizador e a plataforma. Todos
          estes processos são estritamente operacionais e visam uma maior qualidade de
          experiência e utilização da plataforma.  </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:13.65pt;
          margin-left:.5pt'><span lang=PT style='font-family:"Arial",sans-serif'>Os
          processos automatizados na plataforma GIVIN são a confirmação da conta e a
          recuperação de palavra-passe. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
          107%'><b><span lang=PT> </span></b></p>

          <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 6.ª </span></b><b><span
          lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
          color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Direitos do
          Titular dos Dados</span></b><b><span lang=PT style='font-size:11.0pt;
          line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> </span></b></h2>

          <p class=MsoNormal><span lang=PT>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:36.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Todo e
          qualquer sujeito deve ser conhecedor dos seus direitos em matéria de proteção
          de dados, descritos no Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho
          de 27 de abril de 2016 (Regulamento Geral de Proteção de Dados), estando
          legalmente habilitado a exercer os mesmos mediante aplicabilidade ao âmbito da
          GIVIN. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:.8pt;margin-left:38.8pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:2.7pt;
          margin-left:36.5pt;text-indent:0in'><span lang=PT style='font-family:"Arial",sans-serif'>Direitos
          </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.35pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito à informação </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.35pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito ao acesso </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.35pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito à retificação </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.55pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito ao apagamento </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.3pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito à limitação de tratamento </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.35pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito à portabilidade </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:1.55pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito de oposição  </span></p>

          <p class=MsoNormal style='margin-top:0in;margin-right:.15pt;margin-bottom:.25pt;
          margin-left:54.5pt;text-indent:0in'><span lang=PT style='font-family:"Cambria Math",serif'>&#9135;</span><span
          lang=PT style='font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif'>Direito de não aceitar decisões
          individuais automatizadas   </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:38.8pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .25pt;margin-left:36.5pt;text-indent:-.25in'><span lang=PT style='font-family:
          "Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
          Givin, LDA será apenas responsável por garantir o cumprimento com os direitos
          afetos aos dados fornecidos pelo utilizador que se encontrem registados na sua
          base de dados. O utilizador deverá estar ciente de que o tratamento de dados
          presentes na base de dados das plataformas de gateway de pagamento não é da
          responsabilidade da Fast Givin, LDA, devendo o cumprimento dos direitos sob os
          dados ser exercido perante a unidade parceira. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:38.8pt;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p class=MsoListParagraph style='margin-top:0in;margin-right:.15pt;margin-bottom:
          .2in;margin-left:36.5pt;text-indent:-.25in;line-height:97%'><span lang=PT
          style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span lang=PT style='font-family:"Arial",sans-serif;color:#333333'>Os
          Cookies executam diversas funções, nomeadamente auxiliar os responsáveis pela
          plataforma a </span><span lang=PT style='font-size:11.0pt;line-height:97%;
          font-family:"Arial",sans-serif'> </span><span lang=PT style='font-family:"Arial",sans-serif;
          color:#333333'>perceber o modo como esta é utilizada, facilitando a respetiva
          navegação, guardar as preferências do </span><span lang=PT style='font-size:
          11.0pt;line-height:97%;font-family:"Arial",sans-serif'> </span><span lang=PT
          style='font-family:"Arial",sans-serif;color:#333333'>utilizador e, de modo
          geral, melhorar a experiência de utilização da plataforma. </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:3.05pt;margin-left:0in;text-align:left;text-indent:0in;
          line-height:107%'><span lang=PT> </span></p>

          <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
          margin-bottom:0in;margin-left:-.05pt;text-align:left;text-indent:0in;
          line-height:107%'><img border=0 width=1 height=1 id="Picture 362"
          src="Politica_de_Privacidade_GIVIN_1_files/image001.gif"><span
          style='font-size:11.0pt;line-height:107%'> </span></p>

          <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
          0in;line-height:107%'> </p>

          <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
          0in;line-height:107%'><span style='font-size:11.0pt;line-height:107%'> </span></p>

          <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
          0in;line-height:107%'> </p>

          </div>

          </body>

          </html>
          `,
          name: "Política de Privacidade",
          url: '/privacy-policy'
        },
        {
        id: "2",
        type: 'tc',
        html:
        `<html>

        <head>
        <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
        <meta name=Generator content="Microsoft Word 15 (filtered)">
        <style>
        <!--
        /* Font Definitions */
        @font-face
          {font-family:"Cambria Math";
          panose-1:2 4 5 3 5 4 6 3 2 4;}
        @font-face
          {font-family:Calibri;
          panose-1:2 15 5 2 2 2 4 3 2 4;}
        @font-face
          {font-family:"Segoe UI Symbol";
          panose-1:2 11 5 2 4 2 4 2 2 3;}
        /* Style Definitions */
        p.MsoNormal, li.MsoNormal, div.MsoNormal
          {margin-top:0in;
          margin-right:.75pt;
          margin-bottom:.2pt;
          margin-left:36.55pt;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        h2
          {mso-style-link:"Heading 2 Char";
          margin-top:2.0pt;
          margin-right:.75pt;
          margin-bottom:0in;
          margin-left:36.55pt;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          page-break-after:avoid;
          font-size:13.0pt;
          font-family:"Calibri Light",sans-serif;
          color:#2F5496;
          font-weight:normal;}
        p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
          {margin-top:0in;
          margin-right:.75pt;
          margin-bottom:.2pt;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
          {margin-top:0in;
          margin-right:.75pt;
          margin-bottom:0in;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
          {margin-top:0in;
          margin-right:.75pt;
          margin-bottom:0in;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
          {margin-top:0in;
          margin-right:.75pt;
          margin-bottom:.2pt;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        span.Heading2Char
          {mso-style-name:"Heading 2 Char";
          mso-style-link:"Heading 2";
          font-family:"Calibri Light",sans-serif;
          color:#2F5496;}
        .MsoPapDefault
          {margin-bottom:8.0pt;
          line-height:107%;}
        @page WordSection1
          {size:595.25pt 842.0pt;
          margin:74.05pt 71.55pt 79.1pt 72.05pt;}
        div.WordSection1
          {page:WordSection1;}
        /* List Definitions */
        ol
          {margin-bottom:0in;}
        ul
          {margin-bottom:0in;}
        -->
        </style>

        </head>

        <body lang=EN-US style='word-wrap:break-word'>

        <div class=WordSection1>

        <p class=MsoNormal style='margin-left:.5pt'><b><span lang=PT style='font-size:
        14.0pt;line-height:112%;font-family:"Arial",sans-serif'>GIVIN – Termos de utilização </span></b></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:2.25pt;margin-left:0in;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 1.ª </span></b><b><span
        lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Objeto </span></b></h2>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:.2pt;
        margin-left:35.8pt'><span lang=PT style='line-height:112%;font-family:"Arial",sans-serif'>Os
        presentes termos e condições gerais destinam-se a regular o acesso e a
        utilização da plataforma GIVIN fornecida pela Fast Givin, LDA, sendo
        disponibilizados a todos os agentes que interagem com a plataforma, entre eles,
        os utilizadores e os parceiros. A aceitação e utilização da plataforma indica expressamente
        a concordância total com todos os termos e condições presentes neste documento.</span><span
        lang=PT style='line-height:112%;font-family:"Arial",sans-serif;color:#4472C4'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.8pt;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='line-height:107%;font-family:"Arial",sans-serif;
        color:#4472C4'> </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:2.4pt;
        margin-left:35.8pt'><span style='line-height:112%;font-family:"Arial",sans-serif'>Entende-se
        por: </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:1.25pt;
        margin-left:72.05pt;text-indent:-.25in'><span lang=PT style='line-height:112%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:112%;font-family:"Arial",sans-serif'>Parceiros
        – as Instituições aderentes à plataforma; </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:.2pt;
        margin-left:72.05pt;text-indent:-.25in'><span lang=PT style='line-height:112%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:112%;font-family:"Arial",sans-serif'>Utilizadores
        – o sujeito em nome individual que utilizará a plataforma para realizar a doação.
        </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 2.ª </span></b><b><span
        lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Descrição e
        funções da APP </span></b></h2>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:3.45pt;
        margin-left:.5pt'><span lang=PT style='font-family:"Arial",sans-serif'>A GIVIN
        consiste numa plataforma informática que permite que os utilizadores façam
        doações às instituições parceiras, tendo a possibilidade de posteriormente
        receber um NFT (Non-Fungible Token), podendo ser transacionados pelo utilizador
        que os recebeu. </span><span style='font-family:"Arial",sans-serif'>Estes
        serviços são realizados: </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.85pt;margin-left:71.3pt;text-align:left;text-indent:-.25in;
        line-height:109%'><span lang=PT style='font-size:11.0pt;line-height:109%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:109%;font-family:"Arial",sans-serif'>Através
        de um website de arrecadação de fundos e divulgação de informações para
        organizações do Terceiro Sector. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:2.1pt;margin-left:71.3pt;text-align:left;text-indent:-.25in;
        line-height:109%'><span lang=PT style='font-size:11.0pt;line-height:109%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:109%;font-family:"Arial",sans-serif'>Permitindo
        que os parceiros recebam contribuições financeiras dos utilizadores registados
        na plataforma, podendo essas doações ser identificáveis ou de forma anónima. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.85pt;margin-left:71.3pt;text-align:left;text-indent:-.25in;
        line-height:109%'><span lang=PT style='font-size:11.0pt;line-height:109%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:109%;font-family:"Arial",sans-serif'>Aproximando
        as organizações parceiras dos utilizadores que  </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.85pt;margin-left:71.3pt;text-align:left;text-indent:-.25in;
        line-height:109%'><span style='font-size:11.0pt;line-height:109%;font-family:
        "Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='line-height:109%;font-family:"Arial",sans-serif'>pretende
        apoiar alguma causa.  </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:7.6pt;margin-left:71.3pt;text-align:left;text-indent:-.25in;
        line-height:109%'><span lang=PT style='font-size:11.0pt;line-height:109%;
        font-family:"Arial",sans-serif'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='line-height:109%;font-family:"Arial",sans-serif'>Disponibilizando
        NFTs em troca das doações efetuadas pelos utilizadores. </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><b><span lang=PT style='font-size:12.0pt;line-height:
        107%;font-family:"Arial",sans-serif'> </span></b></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 3.ª </span></b><b><span
        lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Condições
        gerais de uso </span></b></h2>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-size:12.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A GIVIN
        propõe-se a servir como ferramenta de doação, garantindo uma lista atualizada
        de instituições parceiras às quais o utilizador possa doar. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Ao aceder à
        plataforma GIVIN, o utilizador fica apto a utilizar as funcionalidades que esta
        disponibiliza.</span><span lang=PT style='font-size:11.0pt;line-height:112%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
        Givin, LDA adota todas as medidas necessárias para averiguar a idoneidade das
        instituições parceiras presentes na plataforma GIVIN. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A gestão das
        doações recebidas pelas instituições parceiras é da responsabilidade das
        próprias, não sendo possível imputar à Fast Givin, LDA quaisquer
        responsabilidades por danos ou eventuais desvios por parte de utilizadores ou
        parceiros. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A GIVIN
        reserva-se ao direito de aceitar ou recusar a entrada de novos utilizadores
        e/ou parceiros, bem como suspender, bloquear ou excluir utilizadores e/ou
        parceiros da plataforma, a seu exclusivo critério, principalmente quando
        verificado indícios de fraude ou lavagem de dinheiro através das doações
        realizadas por seu intermédio. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A GIVIN não
        possui nenhuma obrigação de monitorizar o conteúdo da página de cada uma das
        instituições parceiras, sendo o seu conteúdo da sua total responsabilidade. No
        entanto, reserva-se ao direito de remover, editar ou bloquear qualquer
        conteúdo, a seu exclusivo critério, quando entender, sem aviso prévio e por
        qualquer motivo, mediante o recebimento de reclamações ou denúncias de
        terceiros ou de autoridades relacionadas com o conteúdo.  </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os
        utilizadores e os parceiros não devem utilizar a plataforma GIVIN para
        quaisquer fins ou meios ilegais, difamatórios, discriminatórios, abusivos,
        ofensivos, pornográficos, obscenos, agressivos, injuriosos, vexatórios,
        enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça ou uso de
        falsa identidade, ou seja, qualquer uso escuso que possa prejudicar a GIVIN,
        algum dos seus parceiros, os utilizadores ou terceiros. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Em nenhuma
        hipótese a Fast Givin, LDA será responsabilizada por quaisquer danos suportados
        pelos utilizadores ou parceiros por eventual indisponibilidade temporária da
        plataforma GIVIN. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>O utilizador
        compromete-se a utilizar das funcionalidades da plataforma com boa-fé, de
        acordo com a legislação em vigor, a moral e os bons costumes. O uso da
        plataforma por parte dos utilizadores fica condicionado ao respeito às
        disposições constantes neste instrumento e à legislação portuguesa. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <h2 style='margin-left:.5pt'><b><span style='font-size:11.0pt;line-height:112%;
        font-family:"Arial",sans-serif;color:windowtext'>Cláusula 4.ª </span></b><b><span
        style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span style='font-size:11.0pt;line-height:
        112%;font-family:"Arial",sans-serif;color:windowtext'> Utilização e qualidade </span></b></h2>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span style='font-size:11.0pt;line-height:107%;font-family:
        "Arial",sans-serif'> </span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A utilização
        e a qualidade da GIVIN dependem do Browser e da versão em questão, devendo,
        para tal, o utilizador procurar aceder por um browser que detenha uma versão
        mais atual.  </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
        Givin, LDA não se responsabiliza por qualquer tentativa de entrada num browser
        que não permita a sua correta receção e execução (i.e., versão incompatível). </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>O Utilizador
        deverá manter e conservar o dispositivo no qual utilize a GIVIN em condições de
        segurança e seguir as práticas de segurança aconselhadas pelo fabricante,
        devendo instalar e manter atualizados os necessários softwares de segurança,
        nomeadamente antivírus.</span><span lang=PT style='font-size:11.0pt;line-height:
        112%;font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A GIVIN é
        fornecida para uso exclusivo do Utilizador, o qual se compromete a utilizá-la
        em conformidade com a lei e com os presentes Termos e Condições. O Utilizador
        obriga-se designadamente a abster-se de utilizar a GIVIN com fins ou efeitos
        ilícitos, contrários ao estabelecido nos presentes Termos e Condições, lesivos
        dos direitos e interesses de terceiros ou que, de qualquer forma, possam
        danificar, inutilizar, sobrecarregar ou deteriorar o funcionamento normal
        plataforma.</span><span lang=PT style='font-size:11.0pt;line-height:112%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
        Givin, LDA reserva-se no direito de bloquear o acesso à plataforma GIVIN e
        respetivas funcionalidades, caso o Utilizador incorra na utilização descrita no
        ponto anterior. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.85pt;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
        1.4pt;margin-left:.5in;text-indent:-.25in'><span lang=PT style='font-family:
        "Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Uma vez
        concluído o processo de doação, não é possível reverter a ação. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>O Utilizador
        deverá reportar aos prestadores da GIVIN quaisquer anomalias que detete
        relativamente ao uso da mesma, bem como quaisquer ações fraudulentas de
        terceiros, nomeadamente tentativas de manipulação no sentido de obter uma
        doação não prevista de NFTs. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:1.1pt;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>As doações
        podem apenas ser realizadas por meio de pagamento via MBWAY. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os
        pagamentos das doações realizados na plataforma serão processados através de
        plataformas de gateway de pagamentos, que podem variar a depender da
        Organização à qual o utilizador irá realizar suas doações. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:1.1pt;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>10.As
        plataformas de gateway de pagamento poderão cobrar taxas pela sua utilização,
        cabendo ao utilizador e/ou ao parceiro a responsabilidade de se informar acerca
        dos valores praticados. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.8pt;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
        Givin, LDA apenas se responsabiliza quanto ao correto funcionamento da
        plataforma, bem como das suas funcionalidades gerais, não estando assim
        dependente da Fast Givin, LDA nenhuma responsabilidade sobre o destino das
        doações, estando este à responsabilidade do parceiro. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.8pt;margin-left:39.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Doações
        feitas pela plataforma GIVIN são não-reembolsáveis. Para esclarecimento de
        questões em relação à doação o utilizador deve contactar o parceiro
        diretamente. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:1.8pt;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 5.ª </span></b><b><span
        lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>
        Caracterização do Serviço e Responsabilidade </span></b></h2>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:.2pt;
        margin-left:.25in;text-indent:0in'><span lang=PT style='font-family:"Arial",sans-serif'>O
        Utilizador reconhece que a GIVIN, o acesso à mesma e a sua utilização são
        feitos com recurso a sistemas informáticos e redes de comunicações externas que
        podem apresentar falhas, defeitos ou erros de funcionamento ou conceção,
        situações cujos prestadores são alheios. Caso surja algum erro, o utilizador
        deve contactar o suporte da plataforma através do e-mail support@givin.io. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.85pt;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:2.8pt;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 6.ª-
        Propriedade Intelectual e Industrial</span></b><b><span lang=PT
        style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'> </span></b></h2>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>A Fast
        Givin, LDA conserva integralmente os direitos de propriedade industrial,
        intelectual, de autor ou afins sobre a GIVIN e todas as suas componentes,
        incluindo o software, imagens, som e toda e qualquer informação, incluindo
        documentação, procedimentos e suas atualizações, desenvolvimentos ou
        derivações, que seja fornecida, divulgada ou transmitida ao Utilizador no
        âmbito do acesso, execução e utilização da plataforma. O conteúdo apresentado
        na GIVIN, é da responsabilidade das instituições parceiras, sendo
        responsabilidade da GIVIN a criação e atribuição dos NFTs a cada uma delas.
        Estas têm o copyright sobre os conteúdos da sua responsabilidade, seja ele
        imagem, texto ou som. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.8pt;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
        1.5pt;margin-left:.5in;text-indent:-.25in'><span lang=PT style='font-family:
        "Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Qualquer
        utilização da GIVIN feita pelo Utilizador ou Parceiro para outros fins que não
        os aqui autorizados, nomeadamente, cópias, totais ou parciais, farão incorrer o
        Utilizador ou o Parceiro em responsabilidade perante a Fast Givin, LDA, civil
        e/ou criminal, consoante aplicável, e na obrigação de indemnizar a Fast Givin,
        LDA por todos os danos causados. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:1.75pt;margin-left:0in;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <h2 style='margin-left:.5pt'><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'>Cláusula 7.ª </span></b><b><span
        lang=PT style='font-size:11.0pt;line-height:112%;font-family:"Arial",sans-serif;
        color:windowtext'>–</span></b><b><span lang=PT style='font-size:11.0pt;
        line-height:112%;font-family:"Arial",sans-serif;color:windowtext'> Lei
        aplicável e pacto de jurisdição </span></b></h2>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
        1.65pt;margin-left:.5in;text-indent:-.25in'><span lang=PT style='font-family:
        "Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>Os presentes
        Termos e Condições regem-se pela Legislação Portuguesa. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:3.0pt;
        line-height:107%'><span lang=PT style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-right:0in;text-indent:-.25in'><span
        lang=PT style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span lang=PT style='font-family:"Arial",sans-serif'>São
        exclusivamente competentes para resolver qualquer litígio emergente do presente
        contrato os tribunais portugueses e, de entre estes, os da comarca de Lisboa,
        com expressa renúncia a qualquer outro.</span><span lang=PT style='font-size:
        11.0pt;line-height:112%;font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:0in;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:11.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:1.5pt;
        margin-left:.25in;text-indent:0in'><span lang=PT style='font-family:"Arial",sans-serif'>Declaro,
        ao pressionar o botão “Li e aceito os termos e condições da GIVIN”, que todas e
        cada uma das presentes cláusulas me foram comunicadas na íntegra, de modo
        adequado e com a antecedência necessária, e que delas obtive conhecimento
        completo, efetivo e esclarecido, aceitando por este meio cada uma delas. </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:0in;
        margin-bottom:.25pt;margin-left:36.05pt;text-align:left;text-indent:0in;
        line-height:107%'><span lang=PT style='font-size:12.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><span lang=PT style='font-size:12.0pt;line-height:107%;
        font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin-top:0in;margin-right:448.7pt;
        margin-bottom:9.75pt;margin-left:0in;text-align:left;text-indent:0in;
        line-height:98%'><span lang=PT style='font-size:11.0pt;line-height:98%;
        font-family:"Arial",sans-serif'> </span><span lang=PT style='font-size:12.0pt;
        line-height:98%;font-family:"Arial",sans-serif'> </span></p>

        <p class=MsoNormal align=left style='margin:0in;text-align:left;text-indent:
        0in;line-height:107%'><b><span lang=PT style='font-size:22.0pt;line-height:
        107%;font-family:"Arial",sans-serif'> </span></b></p>

        </div>

        </body>

        </html>
        `,
        name: "Termos de Utilização",
        url: '/terms-of-use'
        }
      ]);
    }

    return of([
      {
        id: "1",
        type: 'pp',
        html:
        `<html>

        <head>
        <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
        <meta name=Generator content="Microsoft Word 15 (filtered)">
        <style>
        <!--
         /* Font Definitions */
         @font-face
          {font-family:Wingdings;
          panose-1:5 0 0 0 0 0 0 0 0 0;}
        @font-face
          {font-family:"Cambria Math";
          panose-1:2 4 5 3 5 4 6 3 2 4;}
        @font-face
          {font-family:Calibri;
          panose-1:2 15 5 2 2 2 4 3 2 4;}
        @font-face
          {font-family:"Segoe UI Symbol";
          panose-1:2 11 5 2 4 2 4 2 2 3;}
         /* Style Definitions */
         p.MsoNormal, li.MsoNormal, div.MsoNormal
          {margin-top:0in;
          margin-right:.1pt;
          margin-bottom:.25pt;
          margin-left:36.55pt;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        h2
          {mso-style-link:"Heading 2 Char";
          margin-top:2.0pt;
          margin-right:.1pt;
          margin-bottom:0in;
          margin-left:36.55pt;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          page-break-after:avoid;
          font-size:13.0pt;
          font-family:"Calibri Light",sans-serif;
          color:#2F5496;
          font-weight:normal;}
        a:link, span.MsoHyperlink
          {color:#0563C1;
          text-decoration:underline;}
        p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
          {margin-top:0in;
          margin-right:.1pt;
          margin-bottom:.25pt;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
          {margin-top:0in;
          margin-right:.1pt;
          margin-bottom:0in;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
          {margin-top:0in;
          margin-right:.1pt;
          margin-bottom:0in;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
          {margin-top:0in;
          margin-right:.1pt;
          margin-bottom:.25pt;
          margin-left:.5in;
          text-align:justify;
          text-justify:inter-ideograph;
          text-indent:-.5pt;
          line-height:112%;
          font-size:10.0pt;
          font-family:"Calibri",sans-serif;
          color:black;}
        span.Heading2Char
          {mso-style-name:"Heading 2 Char";
          mso-style-link:"Heading 2";
          font-family:"Calibri Light",sans-serif;
          color:#2F5496;}
        .MsoPapDefault
          {margin-bottom:8.0pt;
          line-height:107%;}
        @page WordSection1
          {size:595.25pt 842.0pt;
          margin:74.05pt 71.6pt 83.85pt 72.05pt;}
        div.WordSection1
          {page:WordSection1;}
         /* List Definitions */
         ol
          {margin-bottom:0in;}
        ul
          {margin-bottom:0in;}
        -->
        </style>

        </head>

        <body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

        <div>

        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla pellentesque dignissim enim sit amet venenatis urna. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Magna fermentum iaculis eu non diam phasellus vestibulum lorem sed. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Amet venenatis urna cursus eget nunc. In tellus integer feugiat scelerisque. Vitae ultricies leo integer malesuada. Lectus mauris ultrices eros in cursus turpis massa. Sem integer vitae justo eget magna fermentum iaculis eu non. Vitae congue mauris rhoncus aenean. Massa tincidunt dui ut ornare lectus sit amet est. Molestie at elementum eu facilisis sed odio. Malesuada bibendum arcu vitae elementum curabitur vitae. Aenean vel elit scelerisque mauris pellentesque pulvinar. Netus et malesuada fames ac turpis egestas. Leo a diam sollicitudin tempor id eu nisl nunc mi.

        <p></p>
Et malesuada fames ac turpis egestas sed tempus urna. Lacus vestibulum sed arcu non odio euismod. Nisl suscipit adipiscing bibendum est ultricies integer quis auctor. Amet mattis vulputate enim nulla. Diam maecenas ultricies mi eget. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Dui id ornare arcu odio ut sem. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Interdum consectetur libero id faucibus nisl tincidunt. Leo duis ut diam quam. Ut tellus elementum sagittis vitae et leo duis. Pellentesque dignissim enim sit amet venenatis urna cursus. Auctor elit sed vulputate mi sit amet. Senectus et netus et malesuada fames ac turpis egestas. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo.
<p></p>
Vestibulum lorem sed risus ultricies tristique nulla aliquet enim. Ut sem viverra aliquet eget sit amet tellus cras adipiscing. Viverra ipsum nunc aliquet bibendum enim facilisis. Ac turpis egestas sed tempus urna. Eros in cursus turpis massa tincidunt. Nisi scelerisque eu ultrices vitae auctor eu augue. Risus in hendrerit gravida rutrum quisque. Id leo in vitae turpis massa sed elementum. Nulla aliquet enim tortor at auctor urna nunc id cursus. Nunc non blandit massa enim nec dui nunc. Porta nibh venenatis cras sed felis eget. Neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing. Nibh cras pulvinar mattis nunc sed blandit libero volutpat sed. Ultrices dui sapien eget mi. Diam ut venenatis tellus in. Non consectetur a erat nam. Malesuada pellentesque elit eget gravida cum sociis. Ac orci phasellus egestas tellus. Porta lorem mollis aliquam ut porttitor leo. Sapien nec sagittis aliquam malesuada bibendum.
<p></p>
Auctor urna nunc id cursus metus aliquam eleifend mi. Leo a diam sollicitudin tempor id eu. Mattis molestie a iaculis at erat. Non pulvinar neque laoreet suspendisse interdum. Condimentum lacinia quis vel eros donec ac. Leo in vitae turpis massa sed elementum. Facilisis leo vel fringilla est ullamcorper. Neque viverra justo nec ultrices dui. Consectetur libero id faucibus nisl tincidunt eget nullam non. Ultricies mi eget mauris pharetra et ultrices neque. Ridiculus mus mauris vitae ultricies leo integer. Aliquam purus sit amet luctus venenatis lectus magna. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Nunc consequat interdum varius sit amet mattis vulputate. Turpis massa sed elementum tempus egestas sed sed risus pretium.
<p></p>
Bibendum arcu vitae elementum curabitur vitae. Proin libero nunc consequat interdum varius sit amet. Sed risus ultricies tristique nulla aliquet enim tortor at auctor. Ultrices eros in cursus turpis massa tincidunt dui ut. Est sit amet facilisis magna etiam tempor orci eu lobortis. Velit scelerisque in dictum non consectetur a. Ultrices vitae auctor eu augue. Pellentesque massa placerat duis ultricies. Nullam vehicula ipsum a arcu cursus. Maecenas ultricies mi eget mauris pharetra et ultrices neque. Sodales ut eu sem integer vitae justo eget. Purus in massa tempor nec feugiat nisl pretium fusce id. Elit ut aliquam purus sit amet. Tincidunt tortor aliquam nulla facilisi. Cum sociis natoque penatibus et magnis dis parturient montes.

        </div>

        </body>

        </html>
        `,
        name: "Política de Privacidade",
        url: '/privacy-policy'
      },
      {
      id: "2",
      type: 'tc',
      html:
      `<html>

      <head>
      <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
      <meta name=Generator content="Microsoft Word 15 (filtered)">
      <style>
      <!--
       /* Font Definitions */
       @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
      @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;}
      @font-face
        {font-family:"Segoe UI Symbol";
        panose-1:2 11 5 2 4 2 4 2 2 3;}
       /* Style Definitions */
       p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin-top:0in;
        margin-right:.75pt;
        margin-bottom:.2pt;
        margin-left:36.55pt;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        font-size:10.0pt;
        font-family:"Calibri",sans-serif;
        color:black;}
      h2
        {mso-style-link:"Heading 2 Char";
        margin-top:2.0pt;
        margin-right:.75pt;
        margin-bottom:0in;
        margin-left:36.55pt;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        page-break-after:avoid;
        font-size:13.0pt;
        font-family:"Calibri Light",sans-serif;
        color:#2F5496;
        font-weight:normal;}
      p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {margin-top:0in;
        margin-right:.75pt;
        margin-bottom:.2pt;
        margin-left:.5in;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        font-size:10.0pt;
        font-family:"Calibri",sans-serif;
        color:black;}
      p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
        {margin-top:0in;
        margin-right:.75pt;
        margin-bottom:0in;
        margin-left:.5in;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        font-size:10.0pt;
        font-family:"Calibri",sans-serif;
        color:black;}
      p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
        {margin-top:0in;
        margin-right:.75pt;
        margin-bottom:0in;
        margin-left:.5in;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        font-size:10.0pt;
        font-family:"Calibri",sans-serif;
        color:black;}
      p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
        {margin-top:0in;
        margin-right:.75pt;
        margin-bottom:.2pt;
        margin-left:.5in;
        text-align:justify;
        text-justify:inter-ideograph;
        text-indent:-.5pt;
        line-height:112%;
        font-size:10.0pt;
        font-family:"Calibri",sans-serif;
        color:black;}
      span.Heading2Char
        {mso-style-name:"Heading 2 Char";
        mso-style-link:"Heading 2";
        font-family:"Calibri Light",sans-serif;
        color:#2F5496;}
      .MsoPapDefault
        {margin-bottom:8.0pt;
        line-height:107%;}
      @page WordSection1
        {size:595.25pt 842.0pt;
        margin:74.05pt 71.55pt 79.1pt 72.05pt;}
      div.WordSection1
        {page:WordSection1;}
       /* List Definitions */
       ol
        {margin-bottom:0in;}
      ul
        {margin-bottom:0in;}
      -->
      </style>

      </head>

      <body lang=EN-US style='word-wrap:break-word'>

      <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla pellentesque dignissim enim sit amet venenatis urna. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Magna fermentum iaculis eu non diam phasellus vestibulum lorem sed. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Amet venenatis urna cursus eget nunc. In tellus integer feugiat scelerisque. Vitae ultricies leo integer malesuada. Lectus mauris ultrices eros in cursus turpis massa. Sem integer vitae justo eget magna fermentum iaculis eu non. Vitae congue mauris rhoncus aenean. Massa tincidunt dui ut ornare lectus sit amet est. Molestie at elementum eu facilisis sed odio. Malesuada bibendum arcu vitae elementum curabitur vitae. Aenean vel elit scelerisque mauris pellentesque pulvinar. Netus et malesuada fames ac turpis egestas. Leo a diam sollicitudin tempor id eu nisl nunc mi.
      <p></p>
      Et malesuada fames ac turpis egestas sed tempus urna. Lacus vestibulum sed arcu non odio euismod. Nisl suscipit adipiscing bibendum est ultricies integer quis auctor. Amet mattis vulputate enim nulla. Diam maecenas ultricies mi eget. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Dui id ornare arcu odio ut sem. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Interdum consectetur libero id faucibus nisl tincidunt. Leo duis ut diam quam. Ut tellus elementum sagittis vitae et leo duis. Pellentesque dignissim enim sit amet venenatis urna cursus. Auctor elit sed vulputate mi sit amet. Senectus et netus et malesuada fames ac turpis egestas. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo.
      <p></p>
      Vestibulum lorem sed risus ultricies tristique nulla aliquet enim. Ut sem viverra aliquet eget sit amet tellus cras adipiscing. Viverra ipsum nunc aliquet bibendum enim facilisis. Ac turpis egestas sed tempus urna. Eros in cursus turpis massa tincidunt. Nisi scelerisque eu ultrices vitae auctor eu augue. Risus in hendrerit gravida rutrum quisque. Id leo in vitae turpis massa sed elementum. Nulla aliquet enim tortor at auctor urna nunc id cursus. Nunc non blandit massa enim nec dui nunc. Porta nibh venenatis cras sed felis eget. Neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing. Nibh cras pulvinar mattis nunc sed blandit libero volutpat sed. Ultrices dui sapien eget mi. Diam ut venenatis tellus in. Non consectetur a erat nam. Malesuada pellentesque elit eget gravida cum sociis. Ac orci phasellus egestas tellus. Porta lorem mollis aliquam ut porttitor leo. Sapien nec sagittis aliquam malesuada bibendum.
      <p></p>
      Auctor urna nunc id cursus metus aliquam eleifend mi. Leo a diam sollicitudin tempor id eu. Mattis molestie a iaculis at erat. Non pulvinar neque laoreet suspendisse interdum. Condimentum lacinia quis vel eros donec ac. Leo in vitae turpis massa sed elementum. Facilisis leo vel fringilla est ullamcorper. Neque viverra justo nec ultrices dui. Consectetur libero id faucibus nisl tincidunt eget nullam non. Ultricies mi eget mauris pharetra et ultrices neque. Ridiculus mus mauris vitae ultricies leo integer. Aliquam purus sit amet luctus venenatis lectus magna. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Nunc consequat interdum varius sit amet mattis vulputate. Turpis massa sed elementum tempus egestas sed sed risus pretium.
      <p></p>
      Bibendum arcu vitae elementum curabitur vitae. Proin libero nunc consequat interdum varius sit amet. Sed risus ultricies tristique nulla aliquet enim tortor at auctor. Ultrices eros in cursus turpis massa tincidunt dui ut. Est sit amet facilisis magna etiam tempor orci eu lobortis. Velit scelerisque in dictum non consectetur a. Ultrices vitae auctor eu augue. Pellentesque massa placerat duis ultricies. Nullam vehicula ipsum a arcu cursus. Maecenas ultricies mi eget mauris pharetra et ultrices neque. Sodales ut eu sem integer vitae justo eget. Purus in massa tempor nec feugiat nisl pretium fusce id. Elit ut aliquam purus sit amet. Tincidunt tortor aliquam nulla facilisi. Cum sociis natoque penatibus et magnis dis parturient montes.
      <div>

      </body>

      </html>
      `,
      name: "Termos de Utilização",
      url: '/terms-of-use'
      }
    ]);
  }
}
