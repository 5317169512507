<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="'36vh'" [imageSize]="'35%'">
</app-page-banner>

<div class="main-content-container">
  <div class="description-container mb-3 mt-3" [innerHTML]="aboutUsDescription | safehtml" *ngIf="aboutUsDescription"></div>

  <div class="animation-container" *ngIf="!(generalService.isMobile | async) && animation && animation.show">
    <ng-lottie #lottieTimelineAnimation *ngIf="animation"
      [width]="'100%'"
      [height]="'100%'"
      [options]="lottieOptions"
      (animationCreated)="lottieAnimationItem = $event; animationCreated('lottie');"
      >
    </ng-lottie>

    <canvas #canvasRive *ngIf="animation.type === 'rive'">
    </canvas>
  </div>

  <div class="animation-container" *ngIf="(generalService.isMobile | async) && animationMobile && animationMobile.show">
    <ng-lottie #lottieTimelineAnimation
      [width]="'100%'"
      [height]="'100%'"
      [options]="lottieOptionsMobile"
      (animationCreated)="lottieAnimationItem = $event; animationCreated('lottie');"
      >
    </ng-lottie>

    <canvas #canvasRive *ngIf="animation && animation.type === 'rive'">
    </canvas>
  </div>

  <div class="our-team-container mb-3" *ngIf="showOurTeam">
    <!-- TODO -->
    <p class="banner-title mt-3">{{ 'Our Team' }}</p>
    <img class="team-image" [src]="ourTeamImageUrl" *ngIf="ourTeamImageUrl">
  </div>
</div>
