export interface TransactionDTO  {
  transactionId: string;
  transactionType: TransactionType;
  walletType: string;
  description: string;
  points: number;
  balance: number;
  transactionDate: string;
  status: TransactionStatus;
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED'
}

export enum TransactionType {
  WON = 'WON',
  SPENT = 'SPENT',
  EXPIRED = 'EXPIRED'
}
