<div (click)="clickOutside($event);" class="outside-dropdown"></div>

<div class="dropdown aux" id="dropdownContainer"></div>
<ng-container *ngIf="fCName == null; else formInput">
  <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'" [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">
    <div class="search-country">
      <i class="bi bi-search" [ngClass]="{'active': searchQuery !== ''}"></i>
      <input type="text" class="w-100" [placeholder]="placeholder" [(ngModel)]="searchQuery">
    </div>
    <ng-container *ngIf="countryList.length !== 0; else noResults">
      <div *ngFor="let country of countryList | filter: searchQuery">
        <div *ngIf="country.name!.toLowerCase()" class="option-item" [ngClass]="{'selected': country.selected }" (click)="toggleOptionSelected(country.iso2)">
          <div class="option-flag-container">
            <div class="country-flag" [ngClass]="country.flagClass || ''"></div>
          </div>
          <span class="d-flex align-items-center name">{{country.name}}</span>
          <span class="option-code">{{country.dialCode}}</span>
        </div>
      </div>
    </ng-container>

    <ng-template #noResults>
      <div class="option-item">
        <span class="title">{{noResultsLabel}}</span>
      </div>
    </ng-template>
  </div>
</ng-container>


<ng-template #formInput>
  <form [formGroup]="form">
    <div class="dropdown custom-scroll" (click)="$event.stopPropagation()" [@simpleFadeAnimation]="'in'" [ngClass]="{'show-from-above' : showDropdownAbove}" *ngIf="dropdownOpen">
      <div class="search-country">
        <i class="bi bi-search"></i>
        <input type="text" class="w-100" [placeholder]="placeholder" formControlName="{{fCName}}">
      </div>
      <ng-container *ngIf="countryList.length !== 0; else noResults">
        <div *ngFor="let country of countryList | filter: searchQuery">
          <div *ngIf="country.name!.toLowerCase()" class="option-item" [ngClass]="{'selected': country.selected }" (click)="toggleOptionSelected(country.iso2)">
            <div class="option-flag-container">
              <div class="country-flag" [ngClass]="country.flagClass || ''"></div>
            </div>
            <span class="d-flex align-items-center name">{{country.name}}</span>
            <span class="option-code">{{country.dialCode}}</span>
          </div>
        </div>
      </ng-container>

      <ng-template #noResults>
        <div class="option-item">
          <span class="title">{{noResultsLabel}}</span>
        </div>
      </ng-template>
    </div>
  </form>
</ng-template>


