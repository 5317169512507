<div class="loading-item-container w-100 pb-3" [ngClass]="{ 'vertical': isImageVertical }">
  <div class="main-img-container w-100" [ngClass]="{'mb-2': !isImageVertical}"></div>

  <div class="title-container w-100 ps-3 pe-3">
    <p class="item-title" [ngClass]="{'ps-3 pe-3 mt-2 mb-2': !isImageVertical}"></p>
  </div>

  <div class="mt-2 ps-3 pe-3 w-100">
    <ng-container *ngTemplateOutlet="tmplsArray[0]"></ng-container>
  </div>
</div>
