import { Injectable } from '@angular/core';
import { AboutUsInfoDTO } from 'src/app/pages/about-us/about-us.model';
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MockAboutUsService {

  constructor() { }

  getAboutUsInfo(): Observable<AboutUsInfoDTO> {
    return of({
      aboutUsDescription: `<p style="font-weight: 900; font-size: var(--large-section-title-font-size)">About us</p>`
                        + `&nbsp;`
                        + `<p style="color: var(--main-brand-color); font-size: var(--large-section-title-font-size); font-weight: 900">“Talent wins games, but teamwork and intelligence wins championships.”</p>`
                        + `<p style="color: var(--main-brand-color); font-size: var(--header-font-size)">Michael Jordan</p>`
                        + `&nbsp;`
                        + `<div style="font-size: var(--section-description-font-size); font-weight: 500">`
                          + `<p>Gamification brings new ways of working with productivity, engagement, communication, and learning, and it has proved to be a powerful motivator allowing organizations and governments to promote desirable behaviours in their employees and citizens.</p>`
                          + `<p><span style="color: var(--main-brand-color)"><b>Gamifyou</b></span> by InnoWave is a cloud-based gamification platform that is widely used and has in-depth customisation features allowing you to promote good practices among your teams, citizens, and clients.</p>`
                          + `<p><span style="color: var(--main-brand-color)"><b>Gamifyou</b></span> has been successful in inculcating good behaviours through rewards and meaningful community engagement.</p>`
                          + `<p><b>A startup with a remarkable background.</b></p>`
                        + `</div>`,
      animation: {
        type: 'lottie',
        show: true
      },
      animationMobile: {
        type: 'lottie',
        show: true
      },
      showOurTeam: true,
      ourTeamImageUrl: 'assets/mock/environments/Gamifyou/about-us/team-image.png'
    });
  }
}
