import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ProductInfo } from '../our-products.model';

@Component({
  selector: 'app-our-product-banner',
  templateUrl: './our-product-banner.component.html',
  styleUrls: ['./our-product-banner.component.sass']
})
export class OurProductBannerComponent implements OnInit {

  @Input() productInfo: ProductInfo;
  @Input() flexFlow: string;

  constructor(
    private host: ElementRef
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    this.host.nativeElement.style.setProperty('--pseudo-element-color', this.productInfo.mainColor);
  }

}
