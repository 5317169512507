import { Component, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './toggle-button.component.sass'
  ]
})
export class ToggleButtonComponent extends ButtonBaseComponent implements OnInit, OnChanges {

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }
}
