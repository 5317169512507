export interface ChartContainer {
  type: ChartTypes;
  size: SizeTypes;
  title: string;
  totalLabel?: string;
  chartTabs: Array<ChartTab>;
}

export interface ChartTab {
  tabName?: string;
  colors: Array<CustomColor>
  data: Array<ChartData>;
}

export interface CustomColor {
  name: string;
  value: string;
}

export interface ChartData {
  name: string;
  value: number;
}

export enum ChartTypes {
  VERTICAL_BARS = 'VERTICAL_BARS',
  PIE = 'PIE',
  ADVANCED_PIE = 'ADVANCED_PIE',
  PIE_GRID = 'PIE_GRID'
}

export enum SizeTypes {
  FULL = 'FULL',
  HALF = 'HALF'
}
