import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateAdapter } from './custom-date-adapter';
import { DateInputRangeComponent } from './date-input-range/date-input-range.component';
import { CustomHeader, DateInputComponent } from './date-input/date-input.component';

// Material imports
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LibraryService } from '../../__services/library.service';


@NgModule({
  declarations: [DateInputComponent, CustomHeader, DateInputRangeComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DateInputComponent,
    DateInputRangeComponent
  ],
  providers: [
    FormGroupDirective,
    HttpClient,
    CustomDateAdapter,
    DateInputComponent,
    LibraryService
  ]
})
export class DateInputModule { }
