import { Injectable } from "@angular/core";

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardKeycloak extends KeycloakAuthGuard {

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected jwtHelper: JwtHelperService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // change route if needed
    let routeToRedirect: UrlTree = this.router.parseUrl(state.url);
    let returnValue: UrlTree | boolean = true;

    if (route.data['shouldRedirectIfLoggedIn']) {
      routeToRedirect = this.router.parseUrl(route.data['pathToRedirect']);
      returnValue = routeToRedirect.toString() === state.url ? true: routeToRedirect; // check to avoid infinite route change (infinite loop)
    }


    // when there is no login, there are no restricted routes so we should jump over every verification
    if (!environment.useKeycloak) {
      return true
    }

    // if page access is not restricted, only redirect to login if user not logged in and token is valid (do auto login)
    if (!route.data["restricted"]) {
      return await this.keycloak.isLoggedIn().then(async resp => {
        if (!resp && this.isTokenValid()) {
          await this.keycloak.login({
            redirectUri: window.location.origin + routeToRedirect.toString()
          });
        }

        if (resp) {
          return returnValue;
        } else {
          return true;
        }
      });
    }

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated && !this.isTokenValid()) {
      /* history.pushState({page: 1}, 'back'); // force entrance in history of current page to allow navigation back from login page
      await this.keycloak.login({
        redirectUri: window.location.origin + routeToRedirect.toString()
      }); */
      return this.router.parseUrl('/');

    } else {
      // Check if token is expired and handle logout if it is
      if (!this.isTokenValid()) {
        localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
        await this.keycloak.logout(window.location.origin /* delete this parameter in order to stay in the same page when session expires */).then(() => {
          this.keycloak.clearToken();
        });

        return this.router.parseUrl('/');
        /* history.pushState({page: 1}, 'back'); // force entrance in history of current page to allow navigation back from login page
        await this.keycloak.login({
          // force go to login page even if it could login without that flow
          prompt: 'login',

          redirectUri: window.location.origin + routeToRedirect.toString()
        });

        return false; */
      }

      // if not authenticated but token is valid, do a seemless login
      if (!this.authenticated) {
        await this.keycloak.login({
          redirectUri: window.location.origin + routeToRedirect.toString()
        });
      }

      // Get the roles required from the route.
      const requiredRoles = route.data["roles"];

      /* console.log(requiredRoles);
      console.log(this.roles); */ // TODO

      // Allow the user to proceed if no additional roles are required to access the route.
      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
        return returnValue;
      }

      // Allow the user to proceed if all the required roles are present.
      return requiredRoles.every((role) => this.roles.includes(role)) ? returnValue : false;
    }
  }

  private isTokenValid(): boolean {
    let token: string | undefined | null = localStorage.getItem(environment.keycloakConfig.clientId + '-jwt');

    if (!token) {
      token = this.keycloak.getKeycloakInstance().token;
    }

    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }

    return false;
  }

}
