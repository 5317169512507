import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';
import { Observable, Subject, take } from 'rxjs';
import { phoneNumberValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input/generic-phone-input.validator';
import { CountryISO } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/phone-number.model';
import { GenericPopUp } from 'src/app/_generic-components-lib/popups/popups.model';
import { UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { NavigationService } from 'src/app/_services/_navigation-service/navigation-service.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { OrderEntryStatusEnum } from '../_services/_order-entry/order-entry.model';
import { OrderEntryService } from '../_services/_order-entry/order-entry.service';
import { ProductsService } from '../_services/_products/products.service';

@Component({
  selector: 'app-product-payment-page',
  templateUrl: './product-payment-page.component.html',
  styleUrls: ['./product-payment-page.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class ProductPaymentPageComponent implements OnInit, AfterViewInit {

  queryParameters: Params = {};

  productQuantityToBuy: number = 1;

  public popupToShow: GenericPopUp;
  public showConfirmPopup: boolean = false;
  public showPopup: boolean = false;
  public loadingPopUpAnimationFinished: boolean = false;
  public popupAnimationState: string = 'hide';
  public paymentResult: string = 'pending';

  public isLoaded: boolean = false;

  public purchaseFormContainer: FormGroup;

  public phoneString: string;
  public walletString: string;

  public environment = environment;

  public userDetails: UserDetails;

  private orderEntryCheckInterval = 10; // interval to check the orderEntry state in seconds

  private productId: string;

  options: AnimationOptions = {
    autoplay: true,
    loop: true,
    path: 'assets/imgs/environments/' + environment.tenantName + '/payment-loading-popup-animation.json'
  };

  public CountryISO = CountryISO;

  constructor(
    private fb: UntypedFormBuilder,
    public productsService: ProductsService,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    public labelService: LabelsService,
    private labelPipe: LabelPipe,
    private navigationService: NavigationService,
    private orderEntryService: OrderEntryService,
    private popupService: PopupService,
    private translateService: TranslateService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.userDetails = this.generalService.userDetails$.value;

    this.purchaseFormContainer = this.fb.group({
      purchaseForm: this.fb.group({
        phoneInput: new UntypedFormControl('', [
          Validators.required,
          phoneNumberValidator
        ]),
        countryCode: new UntypedFormControl('', [
          Validators.required
        ])
      })
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.queryParameters = params;

      if (this.queryParameters['quantity']) {
        this.productQuantityToBuy = this.queryParameters['quantity'];
      }
    });

    this.route.params.subscribe(params => {
      this.productId = params['id']; //

      if (!this.productsService.productInfo) {
        this.productsService.getProductDetail(this.productId).subscribe(product => {

          if (!product.quantity || product.quantity < 1) {
            this.generalService.navigateTo('/marketplace/product/' + product.id);
          } else {

            this.productsService.productInfo = product;

            const pendingTransactionId = localStorage.getItem('pendingTransactionId');

            this.isLoaded = true;

            if (pendingTransactionId) {
              this.phoneString = localStorage.getItem('pendingTransactionPhoneNumber')!;

              this.generalService.asyncFunction(() => {
                this.transactionFlow(pendingTransactionId);
              });
            }
          }

        });
      } else {
        this.isLoaded = true;
      }
    });

      // TODO get quantity from QueryParameters

  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('payment-product-' + this.productId);
  }

  public cancelPurchase(): void {
    this.navigationService.back();
  }

  public openEditDetails(): void {
    const queryParams: Params = {
      openDetails: true
    };

    this.generalService.navigateToWithQueryParams('my-account', queryParams);
  }

  public openPaymentConfirmPopup(): void {
    this.popupToShow = {
      image: 'assets/imgs/environments/' + environment.tenantName + '/popup-warning-image.png',
      title: {text: this.labelPipe.transform(this.labelService.paymentDetailsPopUpPurchaseConfirmationTitle)},
      descriptions: [{
        text: this.labelPipe.transform(this.labelService.paymentDetailsPopUpPurchaseConfirmationDescription)
      }],
      buttons: [
        {
          text: this.labelPipe.transform(this.labelService.paymentDetailsPopUpPurchaseConfirmationButton),
          isCloseBtn: false,
          actionText: 'paymentConfirmed',
          style: {
            backgroundColor: 'var(--main-brand-color)',
            fontColor: '#ffffff'
          }
        }, {
          text: this.labelPipe.transform(this.labelService.paymentDetailsPopUpPurchaseConfirmationCancelButton),
          isCloseBtn: true,
          actionText: 'cancel',
          style: {
            backgroundColor: '#ff3e3e',
            fontColor: '#ffffff'
          }
        }
      ],
      enableXButton: true
    };

    this.showConfirmPopup = true;
  }

  public popupActionClicked(action: { actionText?: string, actionValue?: string }): void {
    switch(action.actionText) {
      case 'cancel':
        this.showConfirmPopup = false;
        break;

      case 'paymentConfirmed':
        this.showConfirmPopup = false;
        this.executePayment();
        break;

      default:
        this.showConfirmPopup = false;
        break;
    }
  }

  public executePayment(): void {
    this.saveFormInfo();
    // TODO
    /* this.orderEntryService.createOrderEntry(this.productQuantityToBuy, this.walletString, this.phoneString).subscribe({ next: id => {
      this.saveTransactionInfoLocalStorage(id);

      this.transactionFlow(id);
    }, error: err => {
      this.togglePopup(false);
    }}); */
  }

  public saveFormInfo(): void {
    this.purchaseFormContainer.markAllAsTouched();
    this.phoneString = this.purchaseFormContainer.get('phoneInput')?.value.number;
  }

  private transactionFlow(transactionId: string): void {

    this.togglePopup(true);
    this.checkOrderEntryStatus(transactionId).subscribe(resp => {
      this.removeTransactionInfoLocalStorage()

      if (resp) {
        this.generalService.asyncFunction(() => {
          this.generalService.asyncFunction(() => {
            this.togglePopup(false);
          }, 300);

          this.generalService.asyncFunction(() => {
            this.popupService.setPopupToOpen(
              {text: ''},
              'assets/imgs/environments/' + environment.tenantName + '/success-nft-sold-icon.png',
              {
                text: this.labelPipe.transform(this.labelService.orderEntryPopupSuccessTitle)
              },
              [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.orderEntryPopupSuccessDescription))],
              [{
                text: 'OK',
                isCloseBtn: true,
                actionText: 'transactions',
                style: {
                  backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
                  fontColor: this.environment.brandStyleVariables.mainBackgroundColor
                }
              }]
            );
          }, 300);

          // TODO change Page info to "Purchased completed" + transaction id + date + remove buy button

        }, 2000);

      } else {
        this.generalService.navigateTo('/marketplace/product/' + this.productsService.productInfo.id);
      }
    });

  }

  private checkOrderEntryStatus(id: string): Observable<boolean> {
    const orderStatus$: Subject<boolean> = new Subject<boolean>();

    let interval: NodeJS.Timeout;

    (async () => {
      await new Promise<void>(() => {
        interval = setInterval(() => {
          this.orderEntryService.getOrderEntry(id).pipe(take(1)).subscribe({
            next: resp => {
              switch (resp.status) {
                case OrderEntryStatusEnum.completed:
                  orderStatus$.next(true);

                  this.paymentResult = 'success';
                  this.options = {
                    autoplay: true,
                    loop: false,
                    path: 'assets/imgs/environments/' + environment.tenantName + '/payment-popup-success-animation.json'
                  };

                  clearInterval(interval);
                  break;
                case OrderEntryStatusEnum.failed:
                  this.generalService.asyncFunction(() => {
                    this.togglePopup(false);

                    this.popupService.setPopupToOpen({
                      text: this.labelPipe.transform(this.labelService.orderEntryPopUpErrorTitle)
                    },
                    'assets/imgs/environments/' + environment.tenantName + '/popup-error-image.png',
                    {text: ''},
                    [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.orderEntryPopUpErrorDescription))],
                    [{
                      text: 'OK',
                      isCloseBtn: true,
                      actionText: '',
                      style: {
                        backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
                        fontColor: this.environment.brandStyleVariables.mainBackgroundColor
                      }
                    }]);
                  }, 300);

                  clearInterval(interval);
                  orderStatus$.next(false);
                  break;
                case OrderEntryStatusEnum.rejected:
                  this.generalService.asyncFunction(() => {
                    this.togglePopup(false);

                    this.popupService.setPopupToOpen({
                      text: this.labelPipe.transform(this.labelService.orderEntryPopUpErrorTitle)
                    },
                    'assets/imgs/environments/' + environment.tenantName + '/popup-error-image.png',
                    {text: ''},
                    [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.orderEntryPopUpErrorPaymentRejectedDescription))],
                    [{
                      text: 'OK',
                      isCloseBtn: true,
                      actionText: '',
                      style: {
                        backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
                        fontColor: this.environment.brandStyleVariables.mainBackgroundColor
                      }
                    }]);
                  }, 300);

                  clearInterval(interval);
                  orderStatus$.next(false);
                  break;
                default:
                  break;
              }

            }, error: () => {
              clearInterval(interval);
              this.generalService.asyncFunction(() => {
                this.togglePopup(false);

                this.generalService.navigateTo('/marketplace/product/' + this.productsService.productInfo.id);
              }, 300);
            }
          });
        }, this.orderEntryCheckInterval * 1000)
      })
    })();

    return orderStatus$.asObservable();
  }

  private togglePopup(state: boolean) {
    if (state) {
      this.showPopup = state;
      this.popupAnimationState = state ? 'show' : 'hide';
    }

    if (!state) {
      this.generalService.asyncFunction(() => {
        this.showPopup = state;
      }, 300);
      this.popupAnimationState = state ? 'show' : 'hide';
    }
  }

  private saveTransactionInfoLocalStorage(transactionId: string): void {
    localStorage.setItem('pendingTransactionId', transactionId);
    localStorage.setItem('pendingTransactionPhoneNumber', this.phoneString);
    localStorage.setItem('pendingTransactionProductId', this.productsService.productInfo.id);
    localStorage.setItem('pendingTransactionProductQuantity', this.productQuantityToBuy + '');
  }

  private removeTransactionInfoLocalStorage(): void {
    localStorage.removeItem('pendingTransactionId');
    localStorage.removeItem('pendingTransactionPhoneNumber');
    localStorage.removeItem('pendingTransactionProductId');
    localStorage.removeItem('pendingTransactionProductQuantity');
  }
}
