<div class="loading-list-item-container">
  <div class="loading-list-item-left-side">

  </div>

  <div class="loading-list-item-right-side">
    <div class="loading-list-item-info">
      <p class="title"></p>

      <div class="loading-list-item-desc"></div>

      <div class="loading-list-item-properties-container">
        <ul>
            <li></li>
            <li></li>
        </ul>
      </div>
    </div>

    <div class="loading-list-item-buttons-container">
        <div class="loading-list-item-button"></div>
    </div>
  </div>
</div>
