import { Category } from "../_shared-components/list-item-cards/cardItem.model";

export interface GetPartnersListReply {
  items: Array<PartnerDTO>;
  totalItems: number;
}

export interface Partner {
  id: string;
  tenantId: string,
  name: string;
  description: string;
  url: string;
  contacts?: Array<Contact>;
  categories: Array<Category>;
  labels?: Array<PartnerLabel>;
  images: PartnerImagesContent;
}

export interface PartnerDTO {
  tenantId: string;
  id: string;
  name: string;
  description: string;
  url: string;
  contacts: Array<Contact>;
  categories: Array<Category>;
  labels?: Array<PartnerLabel>;
  images: PartnerImagesContent;
}

export interface Contact {
  mediumType: MediumType;
  preferred?: boolean;
  characteristic: PostalAddress | EmailAddress;
}

export interface PostalAddress {
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  gpsCoords?: GpsCoords;
}

export interface PartnerImagesContent {
  coverImage: ImageContent;
  profilePictureImage: ImageContent;
}

export interface ImageContent {
  type?: string;
  href: string;
}

export interface EmailAddress {
  emailAddress: string;
}

export interface GpsCoords {
  x: number;
  y: number;
}

export interface PartnerLabel {
  name: string;
  value: string;
}

export enum MediumType {
  postalAddress = 'postalAddress',
  emailAddress = 'emailAddress'
}

export interface PartnerFilterGroup {
  queryParameter: string;
  items: Array<PartnerFilterItem>;
}

export interface PartnerFilterItem {
  id: string;
  name: string;
  isSelected?: boolean;
}
