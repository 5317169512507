import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MediumType, PartnerDTO } from 'src/app/pages/partners/partners.model';
import { CategoryDTO } from 'src/app/pages/_shared-components/list-item-cards/cardItem.model';

@Injectable({
  providedIn: 'root',
})
export class MockPartnerService {
  constructor() {}

  getPartnersList(): Observable<Array<PartnerDTO>> {
    return of([
        {
          "id": "ami",
          "tenantId": "GIVIN",
          "name": "AMI",
          "description": "Fundada em 1984, a AMI \u00e9 uma ONG portuguesa com o Ser Humano no centro de todas as suas preocupa\u00e7\u00f5es.Na \u00e1rea internacional, a AMI desenvolve Miss\u00f5es de Emerg\u00eancia e Miss\u00f5es de Desenvolvimento com equipas expatriadas assim como Projetos Internacionais em Parceria com Organiza\u00e7\u00f5es Locais.Desde 1994 a AMI disp\u00f5e em Portugal de 16 Equipamentos e Respostas Sociais (9 Centros Porta Amiga; 3 Centros para Pessoas sem abrigo; 2 Equipas de Rua; 1 Servi\u00e7o de Apoio Domicili\u00e1rio e 1 polo de rece\u00e7\u00e3o de alimentos.A AMI promove, ainda, uma cidadania ativa na \u00e1rea do Associativismo, Forma\u00e7\u00e3o e Ambiente.",
          "url": "http://www.ami.org.pt",
          "images": {
            "coverImage": {
              "type": "",
              "href": ""
            },
            "profilePictureImage": {
              "type": "",
              "href": ""
            }
          },
          "status": "Listed",
          "type": "Institution",
          "contacts": [
            {
              "mediumType": MediumType.emailAddress,
              "preferred": true,
              "characteristic": { "emailAddress": "" }
            }
          ],
          "categories": [
            {
              "id": "b618d679-2316-4b5e-808a-ee99f4d4c323",
              "name": "Humanitário"
            },
            { "id": "87704468-f3ec-48db-ad89-6b1d6646bccf", "name": "Saúde" },
            {
              "id": "b9204872-1cf5-4cc8-bc9c-cf599af8949d",
              "name": "Crianças e Jovens"
            }
          ]
        },
        {
          "id": "amnistia-internacional",
          "tenantId": "GIVIN",
          "name": "AMNISTIA INTERNACIONAL",
          "description": "Na Amnistia Internacional, h\u00e1 mais de 60 anos que mudamos milhares de vidas: contribu\u00edmos para leis mais justas, ajudamos a libertar pessoas e a unir fam\u00edlias e salvamos crian\u00e7as, mulheres e homens, em conflitos armados, ou em pa\u00edses repressivos. Investigamos, denunciamos e agimos e, assim, trabalhamos para que os direitos humanos sejam respeitados, em Portugal e no mundo inteiro.Tudo isto \u00e9 feito gra\u00e7as a pessoas comuns, que acreditam na liberdade, na justi\u00e7a e na igualdade. Apoie este movimento que est\u00e1 a mudar o mundo. O seu donativo hoje, pode salvar vidas.",
          "url": "https://www.amnistia.pt",
          "images": {
            "coverImage": {
              "type": "",
              "href": ""
            },
            "profilePictureImage": {
              "type": "",
              "href": ""
            }
          },
          "status": "Listed",
          "type": "Institution",
          "contacts": [
            {
              "mediumType": MediumType.emailAddress,
              "preferred": true,
              "characteristic": { "emailAddress": "" }
            }
          ],
          "categories": [
            {
              "id": "b618d679-2316-4b5e-808a-ee99f4d4c323",
              "name": "Humanitário"
            },
            { "id": "8aa923df-58a8-43a6-8b08-5dd3af73b072", "name": "Direitos Humanos" }
          ]
        },
        {
          "id": "caritas-portuguesa",
          "tenantId": "GIVIN",
          "name": "CÁRITAS PORTUGUESA",
          "description": "A rede C\u00e1ritas \u00e9 constitu\u00edda, em Portugal, por vinte C\u00e1ritas Diocesanas, unidas na C\u00e1ritas Portuguesa, e in\u00fameros grupos locais que atuam em proximidade, nas par\u00f3quias e comunidades. Com interven\u00e7\u00e3o em todo o territ\u00f3rio nacional, a C\u00e1ritas articula as suas a\u00e7\u00f5es \u00e0s mais variadas necessidades dos muitos que a procuram. Temos como miss\u00e3o o Desenvolvimento Humano Integral e a defesa do Bem-Comum intervindo em ordem \u00e0 transforma\u00e7\u00e3o da sociedade. Tendo como orienta\u00e7\u00e3o a Centralidade e a Dignidade da Pessoa, atuamos junto dos grupos mais vulner\u00e1veis e desprotegidos. Prestamos ainda assist\u00eancia e ajuda humanit\u00e1ria em situa\u00e7\u00f5es de calamidade e emerg\u00eancia (nacional e internacional).",
          "url": "https://www.caritas.pt/",
          "images": {
            "coverImage": {
              "type": "",
              "href": ""
            },
            "profilePictureImage": {
              "type": "",
              "href": ""
            }
          },
          "status": "Listed",
          "type": "Institution",
          "contacts": [
            {
              "mediumType": MediumType.emailAddress,
              "preferred": true,
              "characteristic": { "emailAddress": "" }
            }
          ],
          "categories": [
            { "id": "31b52f9f-97ab-4042-9637-0aa1148ea864", "name": "Pobreza" },
            { "id": "f2edbed4-ffde-4c34-86cd-f59804c86f0e", "name": "Religiosa" }
          ]
        },
        {
          "id": "fundacao-ais",
          "tenantId": "GIVIN",
          "name": "FUNDAÇÃO AIS",
          "description": "A Funda\u00e7\u00e3o AIS \u00e9 o secretariado portugu\u00eas da organiza\u00e7\u00e3o internacional Aid to the Church in Need (ACN), que teve in\u00edcio no Natal de 1947.Para al\u00e9m da sede na Alemanha, existem secretariados em 23 pa\u00edses espalhados pela Europa, Am\u00e9rica e Austr\u00e1lia. Anualmente, apoiamos cerca de 5.000 projectos pastorais e de emerg\u00eancia humanit\u00e1ria em mais de 145 pa\u00edses. Mission\u00e1rios, sacerdotes, leigos, religiosas e catequistas dependem do nosso apoio para subsistirem e poderem levar uma mensagem de esperan\u00e7a \u00e0s popula\u00e7\u00f5es que mais precisam.A Funda\u00e7\u00e3o AIS n\u00e3o recebe subs\u00eddios do Estado nem apoios p\u00fablicos, mas depende exclusivamente da generosidade e ajuda de benfeitores particulares.",
          "url": "https://www.fundacao-ais.pt",
          "images": {
            "coverImage": {
              "type": "",
              "href": ""
            },
            "profilePictureImage": {
              "type": "",
              "href": ""
            }
          },
          "status": "Listed",
          "type": "Institution",
          "contacts": [
            {
              "mediumType": MediumType.emailAddress,
              "preferred": true,
              "characteristic": { "emailAddress": "" }
            }
          ],
          "categories": [
            {
              "id": "b618d679-2316-4b5e-808a-ee99f4d4c323",
              "name": "Humanitário"
            },
            {
              "id": "b9204872-1cf5-4cc8-bc9c-cf599af8949d",
              "name": "Crianças e Jovens"
            },
            {
              "id": "f2edbed4-ffde-4c34-86cd-f59804c86f0e",
              "name": "Religiosa"
            }
          ]
        },
        {
          "id": "liga-portuguesa-contra-o-cancro",
          "tenantId": "GIVIN",
          "name": "LIGA PORTUGUESA CONTRA O CANCRO",
          "description": "A Liga Portuguesa Contra o Cancro fundada em 4 de abril de 1941, \u00e9 uma Associa\u00e7\u00e3o Cultural e de Servi\u00e7o Social, declarada de Utilidade P\u00fablica por despacho de 85/04/17 do Senhor Primeiro-Ministro, publicado no Di\u00e1rio da Rep\u00fablica n\u00ba 99, II S\u00e9rie, de 30 de abril. Assume-se como uma entidade de refer\u00eancia nacional no apoio ao doente oncol\u00f3gico e fam\u00edlia, na promo\u00e7\u00e3o da sa\u00fade, na preven\u00e7\u00e3o do cancro e no est\u00edmulo \u00e0 forma\u00e7\u00e3o e investiga\u00e7\u00e3o em oncologia.",
          "url": "http://www.ligacontracancro.pt",
          "images": {
            "coverImage": {
              "type": "",
              "href": ""
            },
            "profilePictureImage": {
              "type": "",
              "href": ""
            }
          },
          "status": "Listed",
          "type": "Institution",
          "contacts": [
            {
              "mediumType": MediumType.emailAddress,
              "preferred": true,
              "characteristic": { "emailAddress": "" }
            }
          ],
          "categories": [
            { "id": "87704468-f3ec-48db-ad89-6b1d6646bccf", "name": "Saúde" }
          ]
        }
    ]);
  }

  getPartnerDetails(): Observable<PartnerDTO> {
    return of({
        "id": "amnistia-internacional",
        "tenantId": "GIVIN",
        "name": "AMNISTIA INTERNACIONAL",
        "description": "Na Amnistia Internacional, h\u00e1 mais de 60 anos que mudamos milhares de vidas: contribu\u00edmos para leis mais justas, ajudamos a libertar pessoas e a unir fam\u00edlias e salvamos crian\u00e7as, mulheres e homens, em conflitos armados, ou em pa\u00edses repressivos. Investigamos, denunciamos e agimos e, assim, trabalhamos para que os direitos humanos sejam respeitados, em Portugal e no mundo inteiro.Tudo isto \u00e9 feito gra\u00e7as a pessoas comuns, que acreditam na liberdade, na justi\u00e7a e na igualdade. Apoie este movimento que est\u00e1 a mudar o mundo. O seu donativo hoje, pode salvar vidas.",
        "url": "https://www.amnistia.pt",
        "images": {
          "coverImage": {
            "type": "",
            "href": ""
          },
          "profilePictureImage": {
            "type": "",
            "href": ""
          }
        },
        "status": "Listed",
        "type": "Institution",
        "contacts": [
            {
                "mediumType": MediumType.emailAddress,
                "preferred": true,
                "characteristic": {
                    "emailAddress": ""
                }
            }
        ],
        "labels": [{
          "name": "WHAT_WE_DO",
          "value": "Somos um movimento global com mais de 10 milhões de pessoas, que mobiliza a humanidade em cada uma, para que os direitos humanos sejam uma realidade universal. Somos independentes de qualquer governo, ideologia política, interesse económico ou religião e somos financiados essencialmente por membros e donativos individuais. Por isso, a nossa força são as pessoas, ativistas, apoiantes, membros, quem defende respeita e difunde os direitos humanos.",
        },{
          "name": "",
          "value": "Não importa quem seja, ou onde estiver."
        }, {
          "name": "",
          "value": "A NOSSA VISÃO É A DE UM MUNDO EM QUE TODAS AS PESSOAS TÊM OS SEUS DIREITOS PROTEGIDOS"

        },{
          "name": "",
          "value": "Acreditamos que os abusos dos direitos humanos em qualquer lado são preocupação de todas as pessoas independentemente do local onde estejam. A Amnistia Internacional é um movimento mundial de pessoas que fazem campanha pelo reconhecimento e respeito dos direitos humanos internacionalmente consagrados para todos. Indignados com os abusos aos direitos humanos, mas inspirados pela esperança de um mundo com mais justiça, trabalhamos no sentido de melhorar a vida das pessoas através de campanhas e da solidariedade internacional. \n\n A nossa missão consiste em investigar e gerar ações no sentido de prevenir e acabar com as graves violações dos direitos humanos e no sentido de exigir justiça para aqueles cujos direitos foram violados. Os nossos membros e apoiantes exercem influência sobre governos, órgãos políticos, empresas e grupos intergovernamentais."
        }],
        "categories": [
          {
            "id": "b618d679-2316-4b5e-808a-ee99f4d4c323",
            "name": "Humanitário"
          },
          {
            "id": "8aa923df-58a8-43a6-8b08-5dd3af73b072",
            "name": "Direitos Humanos"
          }
        ]
    });
  }

  getCategories(): Observable<Array<CategoryDTO>> {
    return of([
      {
          "id": "29f8afc2-a364-48a9-8b7a-ea33ce106174",
          "name": "Children and young people"
      },
      {
          "id": "082e75dd-4883-44b3-9359-08f83cf867e0",
          "name": "Health"
      }
  ]);
  }
}
