import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HeaderItem } from 'src/app/_generic-components-lib/list-header-module/list-header.model';
import { ActionId, ListItem } from 'src/app/_generic-components-lib/list-item-module/list-item.model';
import { GenericPopUp, PopUpButton, Title } from 'src/app/_generic-components-lib/popups/popups.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { ActivitiesService } from 'src/app/pages/activities/_services/activities/activities.service';
import { ActivityInfo, FormInputItemStatus, FormInputListItem } from 'src/app/pages/activities/activities.model';
import { environment } from 'src/environments/environment';
import { ActivityInputTypeService } from './_services/activity-input-type.service';
import { DynamicInput } from './activity-type-input.model';

@Component({
  selector: 'app-activity-type-input',
  templateUrl: './activity-type-input.component.html',
  styleUrls: ['./activity-type-input.component.sass']
})
export class ActivityTypeInputComponent implements OnInit, OnChanges {

  public popupToShow: GenericPopUp | undefined;

  @Input() activity: ActivityInfo;
  @Output() triggerGetActivityInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

  public form: FormInputListItem;

  public userInputsList: Array<ListItem> = [];
  public userInputsListItemHeader: Array<HeaderItem> = [];

  public environment = environment;

  public showInputPopup: boolean;
  public inputPopupAdd: boolean = true;

  constructor(
    public labelService: LabelsService,
    private activitiesService: ActivitiesService,
    private activityInputTypeService: ActivityInputTypeService,
    private popupService: PopupService,
    private labelPipe: LabelPipe,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.initUserInputsList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initUserInputsList();
  }

  private initUserInputsList(): void {
    if (this.activity.userInputs && this.activity.userInputs.length > 0) {
      this.userInputsList = [];
      this.userInputsListItemHeader = this.activityInputTypeService.getListItemHeader(this.activity.userInputs[0]);

      this.activity.userInputs.forEach( (userInput, index) => {
        this.userInputsList.push(this.activityInputTypeService.getListItemFromUserInput(userInput, index));
      });
    }
  }

  public openInputPopup(add: boolean = true): void {
    this.inputPopupAdd = add;
    this.showInputPopup = true;

    if (this.inputPopupAdd) {
      this.form = {
        userInputId: null,
        status: FormInputItemStatus.CREATING,
        inputs: this.activity.inputs!
      };
    }
  }

  public closeInputPopup(): void {
    this.showInputPopup = false;
  }

  public formSubmited(formSubmited: {id?: string | null, form: FormGroup}): void {
    this.closeInputPopup();
    const dynamicInputs: Array<DynamicInput> = this.activitiesService.setFormValuesToActivityInputs(this.activity, formSubmited.form);

    if (this.inputPopupAdd) {
      // Add new UserInput
      this.activityInputTypeService.addUserInput(this.activity.activityId, dynamicInputs).subscribe(() => {
        this.triggerGetActivityInfo.emit(true);

        this.popupService.setPopupToOpen(
          {text: ''},
          'assets/imgs/environments/' + environment.tenantName + '/popup-success-image.png',
          {
            text: this.labelPipe.transform(this.labelService.activityTypeInputSuccessCreationTitle)
          },
          [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.activityTypeInputSuccessCreationDescription))],
          [{
            text: 'OK',
            isCloseBtn: true,
            actionText: '',
            style: {
              backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
              fontColor: this.environment.brandStyleVariables.mainBackgroundColor
            }
          }]
        );
      });

    } else {
      // EDIT UserInput
      this.activityInputTypeService.editUserInfo(formSubmited.id!, dynamicInputs).subscribe(() => {
        this.triggerGetActivityInfo.emit(true);

        this.popupService.setPopupToOpen(
          {text: ''},
          'assets/imgs/environments/' + environment.tenantName + '/popup-success-image.png',
          {
            text: this.labelPipe.transform(this.labelService.activityTypeInputSuccessEditTitle)
          },
          [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.activityTypeInputSuccessEditDescription))],
          [{
            text: 'OK',
            isCloseBtn: true,
            actionText: '',
            style: {
              backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
              fontColor: this.environment.brandStyleVariables.mainBackgroundColor
            }
          }]
        );
      });
    }
  }

  public userInputTriggerRemoveConfirmation(id: string): void {
    const preImageTitle: Title = {
      text: ''
    };
    const image: string = `assets/imgs/environments/${environment.tenantName}/popup-confirm-delete-image.png`;
    const title: Title = this.popupService.getTitle(this.labelPipe.transform(this.labelService.activityTypeInputRemoveConfirmationTitle));
    const buttons: Array<PopUpButton> = [
      {
        text: this.labelPipe.transform(this.labelService.popupLogoutConfirmationCancelButton),
        isCloseBtn: true,
        actionText: '',
        style: {
          backgroundColor: '#424242',
          fontColor: '#ffffff'
        },
      },
      {
        text: this.labelPipe.transform(this.labelService.popupLogoutConfirmationButton),
        isCloseBtn: true,
        actionText: 'remove',
        actionValue: id,
        style: {
          backgroundColor: environment.brandStyleVariables.mainBrandColor,
          fontColor: environment.brandStyleVariables.mainBackgroundColor
        }
      }
    ];

    this.popupToShow = {
      preImgTitle: preImageTitle,
      image: image,
      title: title,
      descriptions: [{ text: '' }],
      buttons: buttons,
      closeClickOutside: false,
      enableXButton: false
    };

    this.closeInputPopup();
  }

  public popupActionClicked(action: {actionText?: string, actionValue?: string}): void {
    switch (action.actionText) {
      case 'remove':
        this.activityInputTypeService.removeUserInput(action.actionValue!).subscribe(() => {
          this.triggerGetActivityInfo.emit(true);

          this.popupService.setPopupToOpen(
            {text: ''},
            'assets/imgs/environments/' + environment.tenantName + '/popup-success-image.png',
            {
              text: this.labelPipe.transform(this.labelService.activityTypeInputSuccessRemoveTitle)
            },
            [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.activityTypeInputSuccessRemoveDescription))],
            [{
              text: 'OK',
              isCloseBtn: true,
              actionText: '',
              style: {
                backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
                fontColor: this.environment.brandStyleVariables.mainBackgroundColor
              }
            }]
          );
        });
        break;
    }

    this.popupClosed();
  }

  public popupClosed(): void {
    this.generalService.asyncFunction(() => {
      this.popupToShow = undefined;
    }, 300);
  }

  public actionClicked(inputClickedIndex: number, actionClicked: ActionId): void {
    this.form = this.activity.userInputs![inputClickedIndex];

    switch (actionClicked) {
      case ActionId.show:
        this.openInputPopup(false);
        break;

      case ActionId.edit:
        this.openInputPopup(false);
        break;
    }
  }

}
