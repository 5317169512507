import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryService } from '../__services/library.service';
import { ListHeaderComponent } from './list-header/list-header.component';


@NgModule({
  declarations: [
    ListHeaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ListHeaderComponent
  ],
  providers: [
    LibraryService
  ]
})
export class ListHeaderModule { }
