<div class="list-item-content-container w-100 ps-3 pe-3 mt-auto">
  <div class="points-container right-top-column" *ngIf="prices.length > 0">
    <ng-container>{{ 'marketplace_listitem_price' | label: { price: prices[0].amount, unit: (prices[0].unit === 'EUR' ? '€' : 'Ξ') } | lowercase }}</ng-container>
  </div>

  <div class="left-top-column">
    <!-- <div class="category-container me-1 {{categoryClass}}">
      <ng-container *ngIf="category && category.length > 0">
        <span>{{ category[0].name }}</span>
      </ng-container>
    </div>
    <span class="seperator">.</span> -->
    <div class="serials-container" *ngIf="serial">
      <span>{{ 'marketplace_listitem_serial' | label }} {{ serial }}</span>
    </div>
  </div>

  <div class="partner-container left-bottom-column" *ngIf="partner">
    <div class="partner-img">
      <img [src]="partner.image" [alt]="partner.name">
    </div>

    <div class="partner-name">
      {{partner.name}}
    </div>
  </div>

  <div class="date-container right-bottom-column">
    <ng-container>
      <img class="warning  me-2" *ngIf="dateWarning" [src]="'assets/imgs/icons/red-warning-icon.svg'">
      <span *ngIf="purchaseDate">{{ purchaseDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase }}</span>
    </ng-container>
  </div>
</div>
