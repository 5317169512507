import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DescriptionListBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-description-list',
  templateUrl: './description-list.component.html',
  styleUrls: ['./description-list.component.sass']
})
export class DescriptionListComponent implements OnInit {
  @Input() content: DescriptionListBanner;

  public environment = environment;

  constructor(
    private host: ElementRef<HTMLElement>,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if (this.content.backgroundColor) {

      //set variables
      if (this.content.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.content.backgroundColor);

      this.host.nativeElement.style.setProperty('--title-letter-spacing', '5px');
      this.host.nativeElement.style.setProperty('--sub-title-letter-spacing', '2px');
      this.host.nativeElement.style.setProperty('--title-transform', 'uppercase');
      this.host.nativeElement.style.setProperty('--sub-title-transform', 'none');
      this.host.nativeElement.style.setProperty('--sub-title-font-weight', '400');
    }
  }
}
