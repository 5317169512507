import { Injectable } from '@angular/core';
import { ActionId, ListItem, State } from 'src/app/_generic-components-lib/list-item-module/list-item.model';
import { ActivityInfo, FormInputItemStatus, FormInputListItem } from 'src/app/pages/activities/activities.model';
import { DynamicInput, DynamicInputDTO, GenericKeyValue, InputType } from '../activity-type-input.model';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { HeaderItem } from 'src/app/_generic-components-lib/list-header-module/list-header.model';
import { ApiService } from 'src/app/_services/api.service';
import { Observable, of, catchError, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityInputTypeService {
  private maxColumns: number = 3;

  constructor(
    private labelsService: LabelsService,
    private labelPipe: LabelPipe,
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getListItemHeader(userInput: FormInputListItem): Array<HeaderItem> {
    const header: Array<HeaderItem> = [];

    for (let i = 0; i < this.maxColumns; i++) {
      switch (userInput.inputs[i].type) {
        case InputType.TOGGLE:
          this.maxColumns++;
          break;

        case InputType.CHECKBOX:
          this.maxColumns++;
          break;

        default:
          header.push({
            id: i,
            name: userInput.inputs[i].label,
            styles: {
              fontColor: '#8692A6',
              fontSize: 'var(--smallest-font-size)',
              fontWeight: '400',
              textAlign: 'left',
              padding: i === 0 ? '0 0 0 1vw' : '0'
            },
            sizes: {
              size: 20,
              sizeSmall: header.length === 0 ? 35 : undefined
            }
          });
          break;
      }
    }

    header.push({
      id: this.maxColumns,
      name: this.labelPipe.transform(this.labelsService.activityTypeInputStateHeaderLabel),
      styles: {
        fontColor: '#8692A6',
        fontSize: 'var(--smallest-font-size)',
        fontWeight: '400',
        textAlign: 'center'
      },
      sizes: {
        size: 20,
        sizeSmall: 35
      }
    });

    header.push({
      id: this.maxColumns + 1,
      name: '',
      styles: {
        fontColor: '#8692A6',
        fontSize: 'var(--smallest-font-size)',
        fontWeight: '400',
        textAlign: 'center'
      },
      sizes: {
        size: 15,
        sizeSmall: 25
      }
    });

    return header;
  }

  public getListItemFromUserInput(userInput: FormInputListItem, index: number): ListItem {
    const listItem: ListItem = {
      id: index + '',
      info: [],
      itemType: 'UserInput',
      notClickable: true
    };

    for (let i = 0; i < this.maxColumns; i++) {
      switch (userInput.inputs[i].type) {
        case InputType.TOGGLE:
          this.maxColumns++;
          break;

        case InputType.CHECKBOX:
          this.maxColumns++;
          break;

        default:
          listItem.info.push({
            description: userInput.inputs[i].value as string,
            styles: {
              fontSize: 'var(--smallest-font-size)',
              fontWeight: '400',
              textAlign: 'left',
              padding: i === 0 ? '0 0 0 1vw' : '0'
            },
            sizes: {
              size: 20,
              sizeSmall: listItem.info.length === 0 ? 35 : undefined
            }
          });
          break;
      }
    }

    let translatedUserInputState: string = this.labelPipe.transform(this.labelsService.activityTypeInputStateCompletedLabel);
    let userInputStateFontColor: string = '#5ED788';
    let userInputActionId: ActionId = ActionId.show;

    switch (userInput.status) {
      case FormInputItemStatus.COMPLETED:
        translatedUserInputState = this.labelPipe.transform(this.labelsService.activityTypeInputStateCompletedLabel);
        userInputStateFontColor = '#5ED788';
        userInputActionId = ActionId.show;
        break;

      case FormInputItemStatus.PENDING:
        translatedUserInputState = this.labelPipe.transform(this.labelsService.activityTypeInputStatePendingLabel);
        userInputStateFontColor = '#EF870D';
        userInputActionId = ActionId.edit;
        break;

      case FormInputItemStatus.REJECTED:
        translatedUserInputState = this.labelPipe.transform(this.labelsService.activityTypeInputStateRejectedLabel);
        userInputStateFontColor = '#FF3E3E';
        userInputActionId = ActionId.show;
        break;
    }

    listItem.info.push({
      description: translatedUserInputState,
      styles: {
        fontSize: 'var(--smallest-font-size)',
        fontWeight: '400',
        fontColor: userInputStateFontColor,
        textAlign: 'center',
        whiteSpace: 'nowrap'
      },
      sizes: {
        size: 20,
        sizeSmall: 35
      }
    });

    listItem.info.push({
      actions: [{
        bootstrapIconClass: userInputActionId === ActionId.show ? 'bi-eye' : 'bi-pencil',
        tooltipText: '',
        actionId: userInputActionId,
        backgroundColor: userInputActionId === ActionId.show ? 'var(--main-brand-color)' : 'transparent',
        borderRadius: '20vw',
        buttonHeight: 'calc(var(--smaller-font-size) * 2)',
        buttonWidth: 'calc(var(--smaller-font-size) * 2)'
      }],
      styles: {
        fontSize: userInputActionId === ActionId.show ? 'round(var(--smaller-font-size))' : 'round(var(--main-info-font-size))',
        fontWeight: '400',
        fontColor: userInputActionId === ActionId.show ? 'white' : 'var(--main-brand-color)',
        textAlign: 'right'
      },
      sizes: {
        size: 15,
        sizeSmall: 25
      }
    });

    return listItem;
  }

  public addUserInput(activityId: string, activityInputs: Array<DynamicInput>): Observable<boolean> {
    const body = {
      activityId: activityId,
      data: this.getActivityInputsAsGenericKeyValue(activityInputs)
    };

    return this.apiService.put(`activity-management/public/activity/input`, body, '1.0', true, environment.useMockedData.activitiesInputAddUserInput).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {

          return true;
        } else {
          throw response;
        }
      }
    ));
  }

  public editUserInfo(userInputId: string, inputData: Array<DynamicInput>): Observable<boolean> {
    const body = {
      userInputId: userInputId,
      data: this.getActivityInputsAsGenericKeyValue(inputData)
    };

    return this.apiService.patch(`activity-management/public/activity/input`, body, '1.0', true, environment.useMockedData.activitiesInputEditUserInput).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {

          return true;
        } else {
          throw response;
        }
      }
    ));
  }

  public removeUserInput(userInputId: string): Observable<boolean> {
    return this.apiService.delete(`activity-management/public/activity/input/${userInputId}`, '1.0', true, environment.useMockedData.activitiesInputDeleteUserInput).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: null | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {

          return true;
        } else {
          throw response;
        }
      }
    ));
  }

  private getActivityInputsAsGenericKeyValue(userInputs: Array<DynamicInput>): Array<GenericKeyValue> {
    const genericKeyValues: Array<GenericKeyValue> = [];

    userInputs.forEach(input => {
      genericKeyValues.push({
        key: input.order + '',
        value: input.value ? input.value + '' : ''
      });
    });

    return genericKeyValues;
  }
}
