import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  selector: 'app-single-line-text-input',
  templateUrl: './single-line-text-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './single-line-text-input.component.sass'
  ]
})
export class SingleLineTextInputComponent extends InputBaseComponent implements OnInit, OnChanges {

  @Input() isPassword: boolean = false;
  @Input() hasSuffix: boolean = false;
  @Input() isSuffixIcon: boolean; // Determines if suffix is icon or text
  @Input() alignLeft: boolean;

  @Input() showError: boolean;

  @Input() inactiveSuffixLabel: string;
  @Input() activeSuffixLabel: string;

  @Input() activeSuffix: string;
  @Input() inactiveSuffix: string;

  @Input() debounceTime: number;

  hideValue: boolean = false;
  suffixToggle: boolean = false;

  suffixClassName: string;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    this.hideValue = this.isPassword;
    super.setCSSVars();
    super.setSpecificCSSVars();

    this.updateIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateIcon();
  }

  //update icon class name with what comes from @input
  updateIcon(): string {
    return this.suffixClassName = this.suffixToggle ? this.activeSuffix : this.inactiveSuffix
  }

  suffixToggled(): void {
    this.suffixToggle = !this.suffixToggle;

    if(this.isPassword)
      this.hideValue = !this.hideValue;

    this.updateIcon();
    this.suffixClicked.emit(this.suffixToggle);
  }
}
