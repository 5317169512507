import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { phoneNumberValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/generic-phone-input/generic-phone-input.validator';
import { CountryISO } from 'src/app/_generic-components-lib/inputs/generic-phone-input-module/phone-number.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { ValidatorsService } from 'src/app/_shared-modules/form-validators-module/validators/validators.service';
import { FormInputItemStatus, FormInputListItem } from 'src/app/pages/activities/activities.model';
import { environment } from 'src/environments/environment';
import { DynamicInput, InputType, Option, WidthOptions } from '../activity-type-input.model';

@Component({
  selector: 'app-activity-input-form-popup',
  templateUrl: './activity-input-form-popup.component.html',
  styleUrls: ['./activity-input-form-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('SlideIn', [
      state('hide', style({
        opacity: 0,
        transform: 'translate(-50%, 100%)'
      })),
      state('show', style({
        opacity: 1,
        transform: 'translate(-50%, -50%)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ]
})
export class ActivityInputFormPopupComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() form: FormInputListItem;

  @Output() popUpClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formSubmited: EventEmitter<{id?: string | null, form: FormGroup}> = new EventEmitter<{id?: string | null, form: FormGroup}>();
  @Output() userInputRemoved: EventEmitter<string> = new EventEmitter<string>();

  private phoneUtil = lpn.PhoneNumberUtil.getInstance();
  public editing: boolean;

  public isMobile: boolean;

  public environment = environment;

  public animationState: string = 'hide';
  public mobileAnimationState: string = 'hide';

  public widthOptions = WidthOptions;
  public inputType = InputType;
  public CountryISO = CountryISO;

  public dynamicFormGroup: FormGroup;

  public formInputItemStatus = FormInputItemStatus;

  public isLoaded: boolean = false;

  constructor(
    public labelService: LabelsService,
    private fb: FormBuilder,
    public generalService: GeneralService,
    private formValidators: ValidatorsService
  ) { }

  ngOnInit(): void {
    if (this.form.status === this.formInputItemStatus.CREATING) {
      this.editing = true;

      const formGroup: any = {};

      this.form.inputs?.forEach((input) => {
        // add to form group each input
        formGroup[input.order] = this.getFormControlFromInput(input);
      });

      this.dynamicFormGroup = this.fb.group({
        form: this.fb.group(formGroup)
      });
    }
    this.generalService.isMobile.subscribe((resp: boolean) => { this.isMobile = resp;});

    this.isLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['form'] && changes['form'].currentValue) {
      this.form.inputs.forEach(input => {
        this.setOptionChecked(input);
      });
    }
  }

  ngAfterViewInit(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        if(this.isMobile) {
          this.mobileAnimationState = 'show';
        } else {
          this.animationState = 'show';
        }
    })})();
  }

  public closePopUp(): void {
    if(this.isMobile) {
      this.mobileAnimationState = 'hide';
    } else {
      this.animationState = 'hide';
    }
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 300)).then(() => {
        this.popUpClosed.emit(true);
    })})();
  }

  private getFormControlFromInput(input: DynamicInput): FormControl<any> | FormGroup<any> {
    const validators: any = [];
    let formControl: FormControl<any> = new FormControl();


    if (input.required) {
      validators.push(Validators.required);
      validators.push(this.formValidators.noEmptySpacesValidator);
    }

    if (input.maxLength) {
      validators.push(Validators.maxLength(input.maxLength));
    }

    switch (input.type) {
      case InputType.SINGLE_LINE:
        formControl = new FormControl<string | null>((input.value as string) || null, validators);
        break;

      case InputType.TEXT_AREA:
        formControl = new FormControl<string | null>((input.value as string) || null, validators);
        break;

      case InputType.NUMBER:
        formControl = new FormControl<number | null>((input.value as number) || null, validators);
        break;

      case InputType.PHONE_NUMBER:
        let parsedNumber;

        if (input.value) {
          try {
            parsedNumber = this.phoneUtil.parseAndKeepRawInput(input.value as string);
          } catch {
            input.value = '+351' + input.value;
            parsedNumber = this.phoneUtil.parseAndKeepRawInput(input.value);
          }
        }

        const countryCode = parsedNumber?.getCountryCode() ? '+' + parsedNumber.getCountryCode() : '+351';

        // remove country code from phone number
        const phoneNumber = input.value ? (input.value as string).split(countryCode)[1].trim() : null;

        validators.push(phoneNumberValidator);
        const phoneInputFC = new FormControl<string | null>(phoneNumber, validators);
        const countryCodeFC = new FormControl<string | null>(countryCode, []);

        return new FormGroup<any>({
          'phoneInput': phoneInputFC,
          'countryCode': countryCodeFC
        });

      case InputType.EMAIL:
        validators.push(Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"));
        formControl = new FormControl<string | null>((input.value as string) || null, validators);
        break;

      case InputType.RADIO_GROUP:
        // TODO
        break;

      case InputType.CHECKBOX:
        formControl = new FormControl<string | null>((input.value as string) || null, validators);

        const selectedOption = input.options?.find(opt => opt.id === input.value);
        if(selectedOption)
          selectedOption.isChecked = true;

        break;

      case InputType.TOGGLE:
        // TODO
        break;
    }

    return formControl;
  }

  public addCheckboxValueToOptions(option: Option, input: DynamicInput, options: Array<Option>): void {
    const inputToUse = this.dynamicFormGroup.get('form')!.get(`${input.order}`)!;

    options.forEach(opt => {
      if(opt.id !== option.id)
        opt.isChecked = false
    });

    option.isChecked = !option.isChecked;
    inputToUse.patchValue(!option.isChecked ? null : option.id);
  }

  public saveForm(): void {
    this.formSubmited.emit({
      id: this.form.userInputId,
      form: this.dynamicFormGroup
    });
  }

  private setOptionChecked(input: DynamicInput): void {
    if(input.type === this.inputType.CHECKBOX) {
      const selectedOption = input.options?.find(opt => opt.id === input.value);
      if(selectedOption)
        selectedOption.isChecked = true;
    }
  }

  public removeUserInput(): void {
    this.userInputRemoved.emit(this.form.userInputId!);
  }

  public startEditing(): void {
    this.editing = true;

    const formGroup: any = {};

    this.form.inputs?.forEach((input) => {
      // add to form group each input
      formGroup[input.order] = this.getFormControlFromInput(input);
    });

    this.dynamicFormGroup = this.fb.group({
      form: this.fb.group(formGroup)
    });
  }
}
