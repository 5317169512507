import { AfterViewInit, Injectable } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService implements AfterViewInit {
  constructor(
    private generalService: GeneralService
  ) {

  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('landing-page');
  }

}
