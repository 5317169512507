<div class="tier-list">
  <div class="tier-item" *ngFor="let tier of tierList" (click)="tierAction(tier)">
    <div class="tier-card">
      <div class="card-background-container">
        <img class="image-background" [src]="tier.BackgroundImageUrl" [alt]="tier.Title" *ngIf="tier.BackgroundImageUrl">
        <img class="main-image" [src]="tier.MainImageUrl" [alt]="tier.Title" *ngIf="tier.MainImageUrl">
        <img class="hover-image" [src]="tier.HoverImageUrl" [alt]="tier.Title" *ngIf="tier.HoverImageUrl">
      </div>

      <p class="tier-title">{{tier.Title}}</p>
      <p class="tier-description">{{tier.Description}}</p>
    </div>
  </div>
</div>
