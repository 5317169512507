import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { Country } from '../../generic-phone-input-module/phone-number.model';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.sass'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class CountryDropdownComponent extends DropdownBaseComponent implements OnInit, OnChanges {

  @Input() checkIconUrl: string;

  @Input() countryList: Array<Country>;
  @Input() countryToSelected: Country;

  @Input() placeholder: string;

  @Input() retrieveISO: boolean = true; // This will allow for the option Id or name to be emitted based on the value of this

  @Output() countrySelected: EventEmitter<string> = new EventEmitter<string>();

  dropdownOpen: boolean = false;
  showDropdownAbove: boolean = false; // TODO automatic turn on

  searchQuery: string = '';

  selectedOption?: string;

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    super.setCSSVars();
    super.setSpecificCSSVars();

    this.dropdownOpen = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['countryToSelected']?.previousValue !== changes['countryToSelected']?.currentValue && changes['countryToSelected']?.previousValue !== undefined) {
      if(this.countryToSelected) {
        this.toggleOptionSelected(this.countryToSelected.iso2);
      } else {
        this.clearSelection();
      }
    }
  }

  toggleOptionSelected(optionISO: string): void {
    this.countryList.forEach(option => {
      if(option.iso2 === optionISO) {
          option.selected = true;
          this.selectedOption = option.iso2;
        if(this.form) {
          this.form.get(this.fCName)!.setValue(this.retrieveISO ? option.iso2 + '' : option.name);
        }
      } else {
        option.selected = false;
      }
    });

    this.countrySelected.emit(this.selectedOption);
  }

  clickOutside(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = false;

    this.asyncFunction(() => {
      this.closeDropdown.emit(true);
    }, 150);
  }

  clearSelection(): void {
    this.countryList.forEach((option: Country) => {
      option.selected = false;
    });
    this.selectedOption = undefined;
  }
}
