import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from 'src/app/_generic-components-lib/inputs/buttons-module/buttons-module.module';
import { RegisterBannerComponent } from './register-banner/register-banner.component';



@NgModule({
  declarations: [RegisterBannerComponent],
  imports: [
    CommonModule,
    ButtonsModule
  ],
  exports: [RegisterBannerComponent]
})
export class RegisterBannerModule { }
