import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { RadioButton } from 'src/app/_generic-components-lib/inputs/radio-button-input-module/radio-group/radio-group.component';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { FilterChip, FilterGroup, FilterItem, FilterTypeEnum, SelectedFilterGroup } from '../../_shared-components/filters-list-module/filters-list/filter.model';
import { FiltersListService } from '../../_shared-components/filters-list-module/filters-list/filters-list.service';
import { BlogService } from '../_services/blog.service';
import { Article } from '../blog.model';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.sass']
})
export class BlogListComponent implements OnInit {

  @ViewChild('articleContainerList', { static: false }) public articleListContainerRef: ElementRef;

  public filterList: Array<FilterGroup> = [];
  public loadingListItems: Array<number> = [];
  public showFilters: boolean = false;
  private queryParameters: Params = {};

  public isListLoading: boolean = false;

  public searchString: string = '';

  public listPercentageFromBottom: number = 0;

  public selectedFilters: Array<SelectedFilterGroup> = [];
  public activeFilterCount: number = 0;

  public filterChips: Array<FilterChip> = [];
  public articleList: Array<Article> = [];

  public environment = environment;
  public isLoaded: boolean = false;

  constructor(
    private blogService: BlogService,
    public mainComponent: AppComponent,
    public generalService: GeneralService,
    public labelService: LabelsService,
    private route: ActivatedRoute,
    private filtersService: FiltersListService,
    private translateService: TranslateService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParameters = params;
    });

    this.isListLoading = true;
    this.blogService.getEndOfScrollReached().subscribe(() => {
      if (!this.isListLoading) {
        this.isListLoading = true;
        this.getMoreItems(this.queryParameters);
      }
    });

    this.generalService.asyncFunction(() => this.getListPercentageFromBottom(), 0);

    this.searchString = this.queryParameters['search'];

    // amount of items that will be shown when list is loading
    this.loadingListItems = [1, 2, 3, 4, 5, 6, 7, 8];

    this.getFilters().subscribe(() => {
      this.filterList.forEach(group => {
        this.selectedFilters.push({
          queryParameter: group.queryParameter,
          items: []
        });
      });
      this.filtersService.selectedFilters = this.selectedFilters;
      this.updateFilters(this.queryParameters);

      this.isLoaded = true;
      this.generalService.asyncFunction(() => {this.getListPercentageFromBottom();}, 0);
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('blog');
  }

  private getFilters(): Observable<boolean> {
    const main$: Subject<boolean> = new Subject<boolean>();
    const filters$: Subject<boolean> = new Subject<boolean>();

    this.blogService.getFilters().subscribe(resp => {
      this.filterList = resp;

      filters$.next(true);
    });

    combineLatest([filters$.asObservable()]).subscribe(
      ([filters]) => {
        main$.next(filters);
    });

    return main$.asObservable();
  }

  public search(): void {
    this.updateQueryParameters();
  }

  /**
   * Whenever there is an update on selected filters (either filter was deselected, all filters cleared, or a new filter selected) update all lists
   */
  public filtersUpdated(): void {
    this.activeFilterCount = 0;

    this.selectedFilters.forEach(group => {
      this.activeFilterCount += group.items.length;

      // add chips when a new filter is selected
      group.items.forEach(item => {
        if (this.filterChips.find(chip => (group.queryParameter === chip.queryParameter && chip.value === item.value)) === undefined) {
          this.filterChips.push({
            queryParameter: group.queryParameter,
            name: item.name,
            value: item.value
          });
        }
      });
    });

    let chipsToDelete: Array<FilterChip> = [];

    // remove chip when a filter is removed
    this.filterChips.forEach(chip => {
      let chipFound = false;

      this.selectedFilters.forEach(group => {
        if (chip.queryParameter === group.queryParameter && group.items.find(item => item.value === chip.value) !== undefined) {
          chipFound = true;
        }
      });

      if (!chipFound) {
        chipsToDelete.push(chip);
      }
    });

    chipsToDelete.forEach(chip => this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1));

    this.updateQueryParameters();
  }

    /**
   * Deletes a chip from selected filters chip list
   *
   * @param chip - FilterChip
   */
  public deleteChip(chip: FilterChip): void {
    // delete chip from list
    this.filterChips.splice(this.filterChips.findIndex(c => c.queryParameter === chip.queryParameter && c.value === chip.value), 1);

    // remove filter from selected filters
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if (this.selectedFilters[i].queryParameter === chip.queryParameter) {
        this.selectedFilters[i].items.splice(this.selectedFilters[i].items.findIndex(c => c.value === chip.value), 1);
        this.activeFilterCount--;
      }
    }

    // toggle off filter
    this.filterList.forEach(group => {
      if (group.queryParameter === chip.queryParameter) {
        switch (group.type) {
          case FilterTypeEnum.Checkbox:
            (group.values.find(v => (v as FilterItem).id === chip.value) as FilterItem).selected = false;
            break;
          case FilterTypeEnum.Radio:
            (group.values.find(v => (v as RadioButton).value === chip.value) as RadioButton).selected = false;
            break;
          case FilterTypeEnum.Range:
            break;
          default:
            break;
        }
      }
    });

    this.updateQueryParameters();
  }

  public articleItemClicked(articleId: string): void {
    this.generalService.navigateTo(`blog/${articleId}`);
    this.generalService.autoScrollTop('page-router-container');
  }

  public onScroll(): void {
    if(this.blogService.infiniteScrollActive) {
      this.blogService.getEndOfScrollReached().next(true);
    }
  }

  private getMoreItems(params: Params = {}): void {
    if(this.blogService.totalPages > this.blogService.currentPageIndex) {
      this.blogService.currentPageIndex++;
      this.getItems(this.blogService.currentPageIndex, params);
    }
  }

  private getItems(page: number, params: Params = {}): void {
    if(!this.isListLoading){
      this.isListLoading = true;
    }

    this.blogService.getArticleList(page, params).subscribe({
      next: list => {
        if(this.blogService.currentPageIndex > 1 && this.articleList) {
          this.articleList = this.articleList.concat(list);
        } else {
          this.articleList = list;
        }

        this.generalService.asyncFunction(() => {
          this.getListPercentageFromBottom();
        }, 0);
        this.isListLoading = false;
      },
      error: error => {
        this.isListLoading = false;
      }
    });
  }

  /**
   * Updates each filter based on the query parameters
   *
   * @param params - QueryParameters on the URL
   *
   * We loop all the queryParameters on the url and obtain the key of each query param and save it on a variable
   * then we check if each variable is valid, if so we run the update filter function.
   */
  private updateFilters(params: Params): void {
    Object.keys(params).forEach((key: string) => {
      let group = this.filterList.find(group => group.queryParameter === key);

      if(group) {
        this.updateFilter(group, params, key);
      }
    });
    this.filtersUpdated();
  }

  /**
   * Updates each filter based on the query parameters
   *
   * @param group - FilterGroup this can be a RadioButtonGroup or CheckBoxGroup
   * @param params - QueryParameters that exist on the url
   * @param key - name of the key of each param.
   *
   * The UpdateFilter function first create a new array by splitting all the parameters after that we loop each value
   * and check if they belong to a groupItem (RadioButtonGroup) if not they are a CheckBoxGroup in the end of each we set it
   * to true and run the change detection for each group
   */
  private updateFilter(group: FilterGroup, params: Params, key: string): void {
    let values = params[key].split(',');

    values.forEach((value: string) => {
      let groupItem;
      switch (group.type) {
        case FilterTypeEnum.Checkbox:
          groupItem = (group.values.find(v => (v as FilterItem).id === +value) as FilterItem);

          if(groupItem) {
            groupItem.selected = true;
            this.filtersService.filterSelected(group, groupItem, true);
          }
          break;
        case FilterTypeEnum.Radio:
          groupItem = (group.values.find(v => (v as RadioButton).value === +value) as RadioButton);

          if(groupItem) {
            groupItem.selected = true;
            this.filtersService.radioBtnfilterChanged(group, +value);
          }
          break;
        case FilterTypeEnum.Range:
          break;
        default:
          break;
      }
    });
  }

  private updateQueryParameters(): void {
    let queryParams: Params = {};
    this.selectedFilters.forEach(group => {
      if(group.items.length > 0) {
        queryParams[group.queryParameter] = group.items.map(item => item.value).toString();
      }
    });

    this.updateSearchParam(queryParams);

    this.getItems(1, queryParams);
    this.generalService.navigateToWithQueryParams('/blog', queryParams);
  }

  private updateSearchParam(params: Params): void {

    if ('search' in this.queryParameters) {
      params['search'] = this.queryParameters['search'];
    }

    if (this.searchString){
      params['search'] = this.searchString;
    } else {
      delete params['search']
    }
  }

  private getListPercentageFromBottom(): void {
    if (this.isLoaded) {
      let listPos = this.articleListContainerRef.nativeElement.offsetTop;
      let listHeight = this.articleListContainerRef.nativeElement.clientHeight;
      let totalPageHeight = this.mainComponent.scrollableContainerRef.nativeElement.scrollHeight; // page height with scroll

      let percentageFromBottom = (Math.abs(listPos) + listHeight) / totalPageHeight * 100;

      this.listPercentageFromBottom = (100 - percentageFromBottom) / 10 - 0.1; // We divide by 10 to get the distance of the list from the bottom of page with a small margin of 0.5 which can be adjusted.
    }
  }
}
