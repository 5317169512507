<ng-container *ngIf="isLoaded">
  <div class="backdrop-container" [@BackdropFadeIn]="animationState"></div>

  <div [@ZoomIn]="animationState" class="popup-container card-shadow">
    <div class="close-button" (click)="closePopUp()">
      <i class="bi bi-x"></i>
    </div>

    <p class="subtitle">{{ labelsService.editGoalPopupSubtitle | label }}</p>
    <p class="title">{{ labelsService.editGoalPopupTitle | label }}</p>

    <div class="card-content custom-scroll">
      <!-- ################### OPTIONS ################### -->
      <div class="options-container" *ngIf="possibleGoalOptionDTOs && possibleGoalOptionDTOs.length > 0">
        <ng-container *ngFor="let option of possibleGoalOptionDTOs; let index = index;">
          <div class="option"  *ngIf="index < 6"
            (click)="optionSelected(option)"
            [ngClass]="{ selected: option.selected }"
            [ngStyle]="{ 'background-image': 'url(' + option.img + ')' }">

            <i class="selected-icon bi bi-check-circle-fill" *ngIf="option.selected"></i>
            <div class="option-title" *ngIf="option.title">
              <span>{{ option.title }}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <app-generic-button class="action-button" [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [toggledOffColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
        [hasIcon]="isAddingGoal" [iconClass]="isAddingGoal ? 'bi bi-plus-lg' : ''" [iconColor]="'white'" [fontColor]="'white'"
        [buttonName]="isAddingGoal ? ( labelsService.editGoalPopupAddButtonLabel | label ) : ( labelsService.editGoalPopupEditButtonLabel | label )"
        [buttonSize]="'min(160px, 17.5vw)'" [minHeight]="'48px'" (click)="btnAction()"
        [isDisabled]="!selectedGoalOptionDTO">
      </app-generic-button>
    </div>
  </div>
</ng-container>
