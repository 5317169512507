import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';
import { CustomDateAdapter } from '../custom-date-adapter';


@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './date-input.component.sass'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DateInputComponent extends InputBaseComponent implements OnInit {
  @ViewChild('formInput', {
    static: true,
    read: MatInput
  }) formInput: MatInput;

  @ViewChild('dateInputContainer') inputContainer: ElementRef;

  @Input() customIcon: boolean;
  @Input() customIconClass: string = 'bi-calendar4';

  @Input() forcedLocaleFormat: string;
  @Input() mobile: boolean;
  @Input() placeholder: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() savedDate: Date | undefined;
  @Input() uniqueId: string;

  @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  customHeader = CustomHeader;

  calendar: MatCalendar<Date>;
  showMultiYearPicker: boolean = false;
  dateAdapter: CustomDateAdapter;

  form: UntypedFormGroup;
  inputLoaded: boolean;

  constructor(
    public rootFormGroup: FormGroupDirective,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    public _adapter: DateAdapter<any>,
    public fb: UntypedFormBuilder,
    override host: ElementRef,
    override libraryService: LibraryService,
    @Inject(MAT_DATE_LOCALE) public _locale: string
    ) {
      super(host, libraryService);
    }

  ngOnInit() {
    if (this.fGName) {
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;
    } else {
      this.fCName = 'date';
      this.form = this.fb.group({
        date: new UntypedFormControl(null),
      });
    }

    if(this.fCName) {
      this.form.get(this.fCName)!.setValue(this.savedDate);
    }

    if (!this.minDate) {
      this.minDate = new Date();
    }

    super.setCSSVars();
    super.setSpecificCSSVars();
    this.setCSSValues();

    if (this.forcedLocaleFormat) {
      this._locale = this.forcedLocaleFormat;
      this._adapter.setLocale(this._locale);
    }

    if (this.savedDate)
      this.form.get(this.fCName)!.setValue(this.savedDate);

    this.inputLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.inputLoaded) {
      if (changes['minDate']) {
        const previousMinDate = (new Date(changes['minDate'].previousValue)).getTime();
        const currentDate = (new Date(this.formInput.value)).getTime();

        if (previousMinDate > currentDate) {
          this.formInput.value = undefined;
        }
      }

      if (changes['maxDate']) {
        const previousMaxDate = (new Date(changes['maxDate'].previousValue)).getTime();
        const currentDate = (new Date(this.formInput.value)).getTime();

        if (previousMaxDate < currentDate) {
          this.formInput.value = undefined;
        }
      }

      if (changes['savedDate']) {
        this.form.get(this.fCName)!.setValue(this.savedDate);
      }

      if (changes['isDisabled']) {
        this.form.get(this.fCName)!.setValue(this.savedDate);
      }

      this.setCSSValues();
    }
  }

  public onYearSelected(year: number): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(new Date(year), 0);
    this.showMultiYearPicker = false;
  }

  onDate(date: Date) {
    if (date)
      this.dateChanged.emit(date);
  }

  setCSSValues(): void {
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 0)).then(() => {
        const matFFFlex = Array.from(this.inputContainer.nativeElement.getElementsByClassName('mat-form-field-flex') as HTMLCollectionOf<HTMLElement>)[0];

        if (this.backgroundColor) {
          matFFFlex.style.backgroundColor = this.backgroundColor;
        }
    })})();

  }
}

/** Custom header component for datepicker. */
@Component({
  selector: 'custom-header',
  styleUrls: [
    './date-input.component.sass'
  ],
  template: `
    <div class="custom-header">
      <div class="mat-icon-button-container" mat-icon-button (click)="changeDate(-1)">
        <i class="bi bi-chevron-left"></i>
      </div>
      <div class="mat-calendar-multi-year-select" (click)="openYearView()">
        <div mat-button>
          <p>{{periodLabel}}</p>
          <i class="bi bi-chevron-expand"></i>
        </div>
      </div>
      <div class="mat-icon-button-container" mat-icon-button (click)="changeDate(1)">
        <i class="bi bi-chevron-right"></i>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeader implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  public setPickerView: string;

  constructor(
    private _calendar: MatCalendar<Date>,
    private _dateAdapter: CustomDateAdapter,
    cdr: ChangeDetectorRef,
    private host: ElementRef,
    private dateInputComponent: DateInputComponent
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnInit(): void {
    this.dateInputComponent.calendar = this._calendar;
    this.dateInputComponent.dateAdapter = this._dateAdapter;
    document.getElementsByTagName('mat-datepicker-content')[0].setAttribute('id', `datepicker-${this.dateInputComponent.uniqueId}`);
    this.setCSSVars();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, {year: 'numeric'})
      .toLocaleUpperCase();
  }

  public changeDate(amount: -1 | 1): void {
    // increment or decrement month or year
    this._calendar.activeDate =
    this._calendar.currentView === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, amount)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, this._calendar.currentView === 'multi-year' ? amount*24 : amount);
  }

  openYearView(): void {
    this._calendar.currentView = 'multi-year';
  }

  setCSSVars(): void {
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--brand-color', this.dateInputComponent.brandColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--font-color', this.dateInputComponent.fontColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--error-color', this.dateInputComponent.errorColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--font-size', this.dateInputComponent.fontSize);
  }
}
