import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs'
import { TutorialStep, TutorialStepDTO } from '../tutorial.model';
import { ApiService } from 'src/app/_services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getTutorialInfo(): Observable<Array<TutorialStep>> {
    return this.apiService.get(`tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/tutorial`, {}, '1.0', true, environment.useMockedData.tutorialGetInfo).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<TutorialStep>) => {
        if(this.generalService.isValidServerReply(response)) {
          const tutorialStepsDTO = (response as Array<TutorialStepDTO>);
          const tutorialSteps = this.getTutorialStepsFromDTO(tutorialStepsDTO);

          return tutorialSteps;
        } else {
          throw response;
        }
    }));
  }

  private getTutorialStepsFromDTO(stepsDTO: Array<TutorialStepDTO>): Array<TutorialStep> {
    const tutorialSteps: Array<TutorialStep> = [];

    stepsDTO.forEach(dto => {
      tutorialSteps.push({
        subtitle: dto.subtitle,
        title: dto.title,
        description: dto.description,
        image: dto.image,
        selectedStep: false
      });
    });

    return tutorialSteps;
  }
}
