import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base-component/base.component';

@Component({
  selector: 'app-button-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base-component/base.component.sass',
    './button-base.component.sass'
  ]
})
export class ButtonBaseComponent extends BaseComponent {

  @Input() toggledOffColor: string;
  @Input() buttonBackgroundColor: string;
  @Input() hoveredBackgroundColor: string;
  @Input() iconColor: string;
  @Input() hoveredIconColor: string;
  @Input() defaultIconColor: string;

  @Input() buttonSize: string;
  @Input() buttonSpacing: string;
  @Input() minHeight: string;
  @Input() iconSize: string;
  @Input() groupGap: string;
  @Input() verticalMargin: string = '15px';
  @Input() justifyContent: string = 'unset';
  @Input() inputSize: string;

  @Input() toggleOnBgColor: string;
  @Input() toggleWidth: string | null;
  @Input() toggleHeight: string | null;

  @Input() isDisabled: boolean;
  @Input() iconClass: string;

  @Input() buttonName: string;

  @Input() hasBorder: boolean;

  @Input() hasIcon: boolean;
  @Input() hasPrefixIcon: boolean;
  @Input() isActive: boolean;
  @Input() imageAsButton: boolean;

  @Output() buttonAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() buttonActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isHovered: boolean;

  constructor(
    override host: ElementRef,
    override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  public emitButtonAction(): void {
    if (!this.isDisabled) {
      this.buttonAction.emit(true);
    }
  }

  public toggleAction(): void {
    this.isActive = !this.isActive;

    this.buttonActive.emit(this.isActive);
  }

  public setSpecificCSSVars(): void {
    if(this.minHeight || this.buttonSize || this.buttonSpacing || this.toggledOffColor || this.iconSize || this.buttonBackgroundColor
      || this.iconColor || this.hoveredIconColor || this.hoveredBackgroundColor || this.toggleWidth || this.toggleHeight || this.toggleOnBgColor
      || this.groupGap || this.verticalMargin || this.justifyContent || this.inputSize || this.defaultIconColor) {

      if(this.buttonSize)
        this.host.nativeElement.style.setProperty('--button-size', this.buttonSize);
      if(this.buttonSpacing)
        this.host.nativeElement.style.setProperty('--button-spacing', this.buttonSpacing);
      if(this.minHeight)
        this.host.nativeElement.style.setProperty('--min-height', this.minHeight);
      if(this.toggledOffColor)
        this.host.nativeElement.style.setProperty('--toggled-off-bg', this.libraryService.convertToHexColor(this.host, this.toggledOffColor));
      if(this.iconSize)
        this.host.nativeElement.style.setProperty('--icon-size', this.iconSize);
      if(this.buttonBackgroundColor)
        this.host.nativeElement.style.setProperty('--button-background-color', this.libraryService.convertToHexColor(this.host, this.buttonBackgroundColor));
      if (this.hoveredBackgroundColor)
        this.host.nativeElement.style.setProperty('--hovered-background-color', this.libraryService.convertToHexColor(this.host, this.hoveredBackgroundColor));
      if (this.iconColor)
        this.host.nativeElement.style.setProperty('--icon-color', this.libraryService.convertToHexColor(this.host, this.iconColor));
      if (this.hoveredIconColor)
        this.host.nativeElement.style.setProperty('--hovered-icon-color', this.libraryService.convertToHexColor(this.host,this.hoveredIconColor));
      if (this.toggleWidth)
        this.host.nativeElement.style.setProperty('--toggle-width', this.toggleWidth);
      if (this.toggleHeight)
        this.host.nativeElement.style.setProperty('--toggle-height', this.toggleHeight);
      if (this.toggleOnBgColor)
        this.host.nativeElement.style.setProperty('--active-background', this.libraryService.convertToHexColor(this.host,this.toggleOnBgColor));
      if(this.justifyContent)
        this.host.nativeElement.style.setProperty('--labels-justify-content', this.justifyContent);
      if(this.verticalMargin)
        this.host.nativeElement.style.setProperty('--vertical-margin', this.verticalMargin);
      if(this.groupGap)
        this.host.nativeElement.style.setProperty('--group-spacing', this.groupGap);
      if(this.inputSize)
        this.host.nativeElement.style.setProperty('--input-size', this.inputSize);
      if (this.defaultIconColor)
        this.host.nativeElement.style.setProperty('--icon-default-color', this.libraryService.convertToHexColor(this.host, this.defaultIconColor));
    }
  }
}
