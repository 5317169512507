import { Injectable } from '@angular/core';
import { CountryCode } from '../country-code-data';
import { Country } from '../phone-number.model';

@Injectable({
  providedIn: 'root'
})
export class GenericPhoneInputService {

  public allCountries: Array<Country> = [];

  constructor(private countryCodeData: CountryCode) {
    this.fetchCountryData();
  }

  private fetchCountryData(): void {
    this.allCountries = [];

    this.countryCodeData.allCountries.forEach((c) => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        htmlId: `country-item-${c[1].toString()}`,
        flagClass: `country-flag-${c[1].toString().toLocaleLowerCase()}`,
        placeHolder: '',
      }

      this.allCountries.push(country);
    });
  }
}
