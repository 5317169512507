import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ImageType, TutorialStepDTO } from 'src/app/pages/tutorial/tutorial.model';

@Injectable({
  providedIn: 'root'
})
export class MockTutorialService {

  constructor() { }

  getTutorialInfo(): Observable<Array<TutorialStepDTO>> {
    return of([
      {
        subtitle: 'Como jogar o You win, Your win',
        title: 'Realiza missões e conquista o teu sonho.',
        description: '',
        image: {
          type: ImageType.RIVE,
          url: 'https://gaminngeneric.blob.core.windows.net/gaminn-generic/BackOffice_Assets/DEV/Wake_Up.riv',
        },
      },
      {
        subtitle: '',
        title: 'Define o teu objectivo!',
        description: 'Compromete-te com o teu sonho e cumpre missões para o conquistares.',
        image: {
          type: ImageType.LOTTIE,
          url: 'assets/mock/environments/Gamifyou/banners/Gamifas.json',
        },
      },
      {
        subtitle: '',
        title: 'Ganha Pinkcoins ao concluíres as missões!',
        description: 'O esforço terá a sua recompensa em Pinkcoins.',
        image: {
          type: ImageType.LOTTIE,
          url: 'assets/mock/environments/Gamifyou/banners/Gamifas.json',
        },
      },
      {
        subtitle: '',
        title: 'Acumula Pinkcoins até atingires o teu sonho.',
        description: 'E, no caminho, podes converter em vouchers de experiências ao teu gosto.',
        image: {
          type: ImageType.LOTTIE,
          url: 'assets/mock/environments/Gamifyou/banners/Gamifas.json',
        },
      },
      {
        subtitle: 'Parabéns!',
        title: 'Estás pronto para começar a ganhar Pinkcoins!',
        description: 'Aproveita os desafios da vida e transforma-os em oportunidades.',
        image: {
          type: ImageType.LOTTIE,
          url: 'assets/mock/environments/Gamifyou/banners/Gamifas.json',
        },
      }
    ])
  }
}
