<div class="custom-date-container position-relative w-100" #dateInputContainer *ngIf="!mobile; else mobileUI ">
  <label *ngIf="label !== null" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>

  <mat-form-field class="custom-formfield custom-date-formfield w-100" [ngClass]="{'disabled': isDisabled}" appearance="fill" (click)="picker.open()">
    <form [formGroup]="form">
      <input #formInput matInput [matDatepicker]="picker" readonly autocomplete="off"
        formControlName="{{fCName}}"
        placeholder="{{ placeholder }}" (dateChange)="onDate($event.value)"
        [min]="minDate"
        [max]="maxDate"
      >
    </form>

    <mat-datepicker-toggle matSuffix matRippleDisabled [for]="picker" [disabled]="isDisabled">
      <mat-icon matDatepickerToggleIcon svgIcon="customIcon" *ngIf="customIcon">
        <i class="bi {{customIconClass}}"></i>
      </mat-icon>
    </mat-datepicker-toggle>

    <mat-datepicker #picker [disabled]="isDisabled" [calendarHeaderComponent]="customHeader"></mat-datepicker>
    <mat-multi-year-view [activeDate]="calendar.activeDate" *ngIf="showMultiYearPicker" (yearSelected)="onYearSelected($event)"></mat-multi-year-view>
  </mat-form-field>
</div>

<ng-template #mobileUI>
  <fieldset class="custom-date-container position-relative w-100" #dateInputContainer>
    <label *ngIf="label !== null" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>

    <mat-form-field class="custom-formfield custom-date-formfield w-100" [ngClass]="{'disabled': isDisabled}" appearance="fill" (click)="mobilePicker.open()">

      <form [formGroup]="form">
        <input #formInput matInput [matDatepicker]="mobilePicker" readonly autocomplete="off"
          formControlName="{{fCName}}" [disabled]="isDisabled"
          placeholder="{{ placeholder }}" (dateChange)="onDate($event.value)"
          [min]="minDate"
          [max]="maxDate"
        >
      </form>

      <mat-datepicker-toggle matSuffix matRippleDisabled [for]="mobilePicker" [disabled]="isDisabled">
        <mat-icon matDatepickerToggleIcon svgIcon="customIcon" *ngIf="customIcon">
          <i class="bi bi-calendar4"></i>
        </mat-icon>
      </mat-datepicker-toggle>

      <mat-datepicker #mobilePicker [disabled]="isDisabled" [calendarHeaderComponent]="customHeader" touchUi></mat-datepicker>
      <mat-multi-year-view [activeDate]="calendar.activeDate" *ngIf="showMultiYearPicker" (yearSelected)="onYearSelected($event)" touchUi></mat-multi-year-view>
    </mat-form-field>
  </fieldset>
</ng-template>
