import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { LibraryService } from 'src/app/_generic-components-lib/__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';

@Component({
  selector: 'app-language-multi-dropdown',
  templateUrl: './language-multi-dropdown.component.html',
  styleUrls: [
    '../../../_base-component/base-component/base.component.sass',
    '../../../_extended-components/dropdown-base-component/dropdown-base.component.sass',
    './language-multi-dropdown.component.sass'
  ],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class LanguageMultiDropdownComponent extends DropdownBaseComponent implements OnInit {

  @Input() activeText: boolean = false;

  @Input() languageOptions: Array<LanguageContent>;

  @Input() retrieveId: boolean = true; // This will allow for the option Id or name to be emitted based on the value of this

  @Output() optionsSelected: EventEmitter<Array<LanguageContent>> = new EventEmitter<Array<LanguageContent>>();

  dropdownOpen: boolean = false;
  showDropdownAbove: boolean = false; // TODO automatic turn on

  selectedOptions: Array<LanguageContent> = [];

  form: UntypedFormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    override host: ElementRef,
    override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    if (this.fGName)
      this.form = this.rootFormGroup.control.get(this.fGName) as UntypedFormGroup;

    super.setCSSVars();
    super.setSpecificCSSVars();

    this.dropdownOpen = true;
    this.selectedOptions = this.languageOptions.filter(language => language.isSelected);
  }

  toggleOptionSelected(language: LanguageContent, isSelected: boolean): void {
    const index = this.languageOptions.findIndex(option => option.languageId === language.languageId);
    if (index !== -1) {
      this.languageOptions[index].isSelected = isSelected;
      if (isSelected) {
        if (!this.selectedOptions.find(option => option.languageId === language.languageId)) {
          this.selectedOptions.push(language);
        }
      } else {
        this.selectedOptions = this.selectedOptions.filter(option => option.languageId !== language.languageId);
      }
      if (this.form) {
        this.form.get(this.fCName)!.setValue(this.retrieveId ? language.languageId + '' : language.name);
      }
    }
    this.optionsSelected.emit(this.selectedOptions);
  }

  clickOutside(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = false;

    (async () => {
        await new Promise<void>(resolve => setTimeout(() => resolve(), 150)).then(() => {
            this.closeDropdown.emit(true);
        });
    })();
  }

  clearSelection(): void {
    this.languageOptions.forEach((option: LanguageContent) => {
      option.isSelected = false;
    });
    this.selectedOptions = [];
  }
}

export interface Language {
  id: number;
  name: string;
  code: string;
  isSelected?: boolean;
  isDefault?: boolean;
  showContent?: boolean;
}

export interface LanguageContent {
  languageId: string;
  name: string;
  isSelected?: boolean;
  isDefault?: boolean;
  showContent?: boolean;
  content: Array<MultipleLanguageInput>;
}

export interface MultipleLanguageInput {
  labelText: string;
  placeholderText: string;
  inputMaxChars: number;
  inputLinesToShow: number;
  value: string;
  isRequired?: boolean;
  showError?: boolean;
}
