import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericPopupModule } from 'src/app/_generic-components-lib/popups/generic-popup-module/generic-popup.module';
import { LabelPipeModule } from 'src/app/_shared-modules/label-pipe-module/label-pipe-.module';
import { WalletSidebarComponent } from './wallet-sidebar/wallet-sidebar.component';


@NgModule({
  declarations: [
    WalletSidebarComponent,
  ],
  imports: [
    CommonModule,
    LabelPipeModule,
    GenericPopupModule
  ],
  exports: [
    WalletSidebarComponent
  ]
})
export class WalletConnectModuleModule { }
