<div class="table-header">
  <ng-container *ngFor="let header of headerList; let i = index">
    <div class="header-container" [ngClass]="{ 'first-item-with-img': i === 0 && firstItemHasImg }"
      [ngStyle]="{
        'width': fullSize ? header.sizes!.size + '%' : header.sizes!.sizeSmall + '%',
        'justify-content': header.styles?.textAlign === 'left' ? 'flex-start' : (header.styles?.textAlign === 'right' ? 'flex-end' : 'center'),
        'cursor': clickable? 'pointer': 'auto'
      }" (click)="clickHeader(header.id)">
      <ng-container *ngIf="fullSize || header.sizes!.sizeSmall">
        <span [ngStyle]="{
          'font-size': header.styles?.fontSize ? header.styles?.fontSize : 'var(--font-size)',
          'color': header.styles?.fontColor,
          'font-weight': header.styles?.fontWeight,
          'text-align': header.styles?.textAlign,
          'padding': header.styles?.padding
        }">{{header.name }}</span>
        <div class="sort-arrows-container" *ngIf="showArrows && header.name !== ''"
          [ngStyle]="{ 'font-size': header.styles?.fontSize ? header.styles?.fontSize : 'var(--font-size)' }">
          <div class="up-arrow" [ngClass]="{'toggle': header.id === sortFilter}"></div>
          <div class="down-arrow" [ngClass]="{'toggle': header.id === -sortFilter}"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
