import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';
@Pipe({
  name: 'safehtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer){}

  transform(value: any): SafeHtml {
    const sanitizedValue = DOMPurify.sanitize(value, {
      /*
      * To allow specific tags, remove the unwanted tags from the ALLOWED_TAGS array as well as the attributes.
      * FORCE_BODY needs to be true if the style tag is required for styling. example: <style> body{color:red} </style>
      */
      ALLOWED_TAGS: ['hr','u','strike','i','b','h1','h2','h3','h4','h5','h6','h7','h8','h9','pre','p','ol','li','ul','table','font','tr','td','th','span', 'div','a','blockquote','div','style','img','video','br'],
      ALLOWED_ATTR: ['style', 'href', 'color', 'target', 'size', 'colspan', 'class', 'rowspan', 'width', 'valign', 'align'],
      // USE_PROFILES: {svg: true, svgFilters: true}, // to allow all SVG elements and SVG Filters
      // KEEP_CONTENT: false, //To disable the content of a removed element we can use KEEP_CONTENT: false
      // USE_PROFILES: {html: true}, will allow all safe HTML elements but neither SVG nor MathML using it will override ALLOWED_TAGS
      // FORBID_TAGS: ['p'], //To not allow certain tags we can use
      // FORBID_CONTENTS: ['a'], // To not allow certain content
      FORCE_BODY: true
    });

    //On the return if we do not bypassSecurityTrustHtml Angular by default will not allow any html tags to be passed
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedValue);
  }
}
