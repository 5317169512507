import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductDetailBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LandingPageService } from '../../landing-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.sass']
})
export class ProductDetailComponent implements OnInit {
  @ViewChild('scrollableContainer') public scrollableContainerRef: ElementRef;
  @ViewChild('ImagesContainerRef') public imagesContainerRef: ElementRef;
  @ViewChild('MainImage') public mainImageRef: ElementRef;
  @ViewChild('LeftIcon') public leftIconRef: ElementRef;
  @ViewChild('LeftBottomIcon') public LeftBottomIconRef: ElementRef;
  @ViewChild('RightIcon') public rightIconRef: ElementRef;

  @Input() content: ProductDetailBanner;

  animationPercentage: number = 0;
  headerHeight: number = 0;

  constructor(
    private host: ElementRef<HTMLElement>,
    private generalService: GeneralService,
    private landingPageService: LandingPageService
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  ngAfterViewInit(): void {
    this.scrollAnimation();
  }

  setCSSVars(): void {
    if (this.content.backgroundColor) {

      //set variables
      if (this.content.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.content.backgroundColor);
    }
  }

  scrollAnimation(): void {
    const imagesContainer = this.imagesContainerRef.nativeElement;

    const mainImage = this.mainImageRef.nativeElement;
    const leftIcon = this.leftIconRef.nativeElement;
    const rightIcon = this.rightIconRef.nativeElement;

    this.headerHeight = this.generalService.getCssComputedValueFromExpression(window.getComputedStyle(this.generalService.scrollableContainerRef.nativeElement).getPropertyValue('--navbar-height'));

    window.addEventListener('resize', () => {
      this.headerHeight = this.generalService.getCssComputedValueFromExpression(window.getComputedStyle(this.generalService.scrollableContainerRef.nativeElement).getPropertyValue('--navbar-height'));

      this.animation(imagesContainer, mainImage, leftIcon, rightIcon);
    });

    this.generalService.addScrollableEvent({id: 'productDetail', fn: () => {
      this.animationLogic(imagesContainer, mainImage, leftIcon, rightIcon);
    }});
  }

  private animationLogic(imagesContainer: HTMLElement, mainImage: HTMLElement, leftIcon: HTMLElement, rightIcon: HTMLElement) {
    if (window.innerWidth > 760 && imagesContainer.getBoundingClientRect().y < 0 && imagesContainer.getBoundingClientRect().y + imagesContainer.clientHeight > mainImage.clientHeight) {
      // imagesContainer.style.height = window.innerHeight * 3 + 'px';
      this.animation(imagesContainer, mainImage, leftIcon, rightIcon);
    }
  }

  animation(imagesContainer: HTMLElement, mainImage: HTMLElement, leftIcon: HTMLElement, rightIcon: HTMLElement): void {
    this.animationPercentage = ((imagesContainer.getBoundingClientRect().y * - 1) * 100) / (imagesContainer.clientHeight - mainImage.clientHeight);

    if (this.animationPercentage <= 10) {
      leftIcon.style.top = 50 + '%';
      rightIcon.style.top = 50 + '%';
    } else if (this.animationPercentage <= 80) {
      leftIcon.style.top = 'calc( 50% + ' + ((this.animationPercentage - 10) / (80 - 10) * (window.innerHeight / 10)) * -1 + 'px)';
      rightIcon.style.top = 'calc( 50% + ' + (this.animationPercentage - 10) / (80 - 10) * (window.innerHeight / 10) + 'px)';
    } else {
      leftIcon.style.top = 'calc(50% + ' + (window.innerHeight / 10) * -1 + 'px)';
      rightIcon.style.top = 'calc(50% + ' + (window.innerHeight / 10) + 'px)';
    }
  }

}
