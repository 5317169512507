import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ActivitiesService } from 'src/app/pages/activities/_services/activities/activities.service';
import { ActivityInfo } from 'src/app/pages/activities/activities.model';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { TranslateService } from '@ngx-translate/core';
import { getCurrencySymbol } from '@angular/common';


@Component({
  selector: 'app-activity-sub-tasks-list',
  templateUrl: './activity-sub-tasks-list.component.html',
  styleUrls: ['./activity-sub-tasks-list.component.sass'],
  animations: [
    trigger('FadeIn', [
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      transition('hide <=> show', animate('600ms ease-in-out')),
    ])
  ]
})
export class ActivitySubTasksListComponent implements OnInit {

  public getCurrencySymbol = getCurrencySymbol;

  public mainBannerInfo: MenuBanner;
  public environment = environment;

  private activityId: string;
  public activityInfo: ActivityInfo;

  public animationState: string = 'hide';

  public isLoaded: boolean = false;

  constructor(
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    public labelsService: LabelsService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/activities');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.route.params.subscribe(params => {
      this.activityId = params['id'];

      if (!this.activitiesService.activityInfo || this.activitiesService.activityInfo.activityId !== this.activityId) {
        this.getActivityDetails();
      } else {
        this.initiateActivityInfo(this.activitiesService.activityInfo);
      }
    });
  }

  private getActivityDetails(): void {
    this.activitiesService.getActivityDetails(this.activityId).pipe(take(1)).subscribe({
      next: activityInfo => {
        this.initiateActivityInfo(activityInfo);

      }/* ,
      error: err => {
        this.generalService.navigateTo('/404');
      } */
    });
  }

  private initiateActivityInfo(activityInfo: ActivityInfo): void {
    this.activityInfo = activityInfo;
    this.isLoaded = true;
    this.generalService.asyncFunction(() => { this.animationState = 'show'; });
  }
}
